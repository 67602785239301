import * as React from 'react';

interface IProps {
    children?: React.ReactNode;
}

const PopupContent = ({ children }: IProps) => (
    <div className="Popup__Content">{children}</div>
);

export default PopupContent;
