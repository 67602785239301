import Loader from '@slkit/Loader';
import * as React from 'react';

interface IProps {
    center?: boolean;
    fullscreen?: boolean;
    opaque?: boolean;
}

const LoadingIndicator = ({ center, fullscreen = true, opaque }: IProps) => (
    <Loader center={center} fullscreen={fullscreen} opaque={opaque} />
);

export default LoadingIndicator;
