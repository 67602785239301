import * as React from 'react';

export interface IPaymentConfiguration {
    environment: 'test' | 'live';
    googlePayMerchantId?: string;
    googlePaySdkUrl?: string;
    merchantId?: string;
    originKey?: string;
    sdkUrl?: string;
}

const usePaymentConfiguration = (): IPaymentConfiguration => {
    const keys: Array<keyof IPaymentConfiguration> = [
        'environment',
        'googlePayMerchantId',
        'googlePaySdkUrl',
        'merchantId',
        'originKey',
        'sdkUrl',
    ];

    const config: IPaymentConfiguration = {
        environment: 'test',
    };

    keys.forEach(key => {
        const valueMeta = document.querySelector(`meta[name='x-adyen-${key}']`);

        if (valueMeta) {
            const v = valueMeta.getAttribute('content') as string;
            if (key === 'environment') {
                config[key] = v === 'live' ? 'live' : 'test';
            } else {
                config[key] = v;
            }
        }
    });

    const values = keys.map(k => config[k]);

    return React.useMemo(() => config, values);
};

export default usePaymentConfiguration;
