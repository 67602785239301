import Modal from '@slkit/Modal';
import useI18n, { Language } from 'lib/hooks/useI18n';
import * as React from 'react';
import useLanguagePicker from './useLanguagePicker';
import './index.scss';

import { ReactComponent as SVG_DE } from '@slkit/assets/svgs/kiosk-lang-de.svg';
import { ReactComponent as SVG_EN } from '@slkit/assets/svgs/kiosk-lang-en.svg';
import { ReactComponent as SVG_SK } from '@slkit/assets/svgs/kiosk-lang-sk.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import { ILanguageOption } from './LanguageOptions';

const ICONS = {
    [Language.DE]: SVG_DE,
    [Language.EN]: SVG_EN,
    [Language.SK]: SVG_SK,
};

interface IProps {
    isActive?: boolean;
    onClose?: () => void;
}

const LanguagePickerModal = ({ isActive, onClose }: IProps) => {
    const { t: tDE } = useI18n('kiosk.language-picker', Language.DE);
    const { t: tEN } = useI18n('kiosk.language-picker', Language.EN);
    const { t: tSK } = useI18n('kiosk.language-picker', Language.SK);

    const { handleSelect, languages, currentLanguage } = useLanguagePicker();
    const [loading, setLoading] = React.useState(false);

    const handleLanguageSelect = (lang: ILanguageOption) => {
        if (lang.key === currentLanguage.key) {
            onClose?.();
            return;
        }

        setLoading(true);
        handleSelect(lang);
    };

    return (
        <Modal
            isActive={isActive}
            hideCloseButton
            hideHeader
            className="KioskLanguagePicker"
            onClose={onClose}
        >
            {loading && <LoadingIndicator fullscreen opaque />}
            <div>
                {[tSK, tEN, tDE].map((t, i) => (
                    <div key={i} className="KioskLanguagePicker__Heading">
                        <div className="KioskLanguagePicker__Title">
                            {t('title')}
                        </div>
                        <div className="KioskLanguagePicker__Subtitle">
                            {t('subtitle')}
                        </div>
                    </div>
                ))}
            </div>

            <div className="KioskLanguagePicker__Buttons">
                {['en', 'sk', 'de'].map(langKey => {
                    const lang = languages.find(l => l.key === langKey)!;
                    const Icon = ICONS[langKey];

                    return (
                        <button
                            key={lang.key}
                            onClick={() => handleLanguageSelect(lang)}
                        >
                            <div>
                                <Icon />
                            </div>
                            {lang.title}
                        </button>
                    );
                })}
            </div>
        </Modal>
    );
};

export default LanguagePickerModal;
