import * as cx from 'classnames';
import { IBusStopOption } from 'components/BusStopSelect/BusStopSelect';
import * as React from 'react';
import './index.scss';

interface IProps {
    children?: React.ReactNode;
    hideLocation?: boolean;
    hovered?: boolean;
    item: IBusStopOption;
    onMouseEnter: (option: IBusStopOption) => void;
    onMouseLeave: (option: IBusStopOption) => void;
    onClick: (option: IBusStopOption) => void;
    title: string;
} //

const Location = React.memo(
    ({
        children,
        hideLocation = false,
        hovered,
        item,
        onMouseEnter,
        onMouseLeave,
        onClick,
        title,
    }: IProps) => {
        const handlers = React.useMemo(
            () => ({
                onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    onClick(item);
                    e.preventDefault();
                    e.stopPropagation();
                },
                onMouseEnter: () => onMouseEnter(item),
                onMouseLeave: () => onMouseLeave(item),
            }),
            [item, onMouseEnter, onMouseLeave, onClick]
        );
        return (
            <div className="Location">
                {!hideLocation && (
                    <div
                        id={item.type + '_' + item.id}
                        className={cx('Location__Cell', {
                            'Location__Cell--hovered': hovered,
                        })}
                        {...handlers}
                    >
                        {title}
                    </div>
                )}
                {children}
            </div>
        );
    }
);

export default Location;
