import AddonOption from '@slkit/AddonOption';
import { formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { CurrencyEnum, LegAddonFragment } from '../../../../graphql';

interface IProps {
    addon: LegAddonFragment;
    count: number;
    currency: CurrencyEnum;
    onCountChange: (c: number) => Promise<boolean>;
}

const AddonOptionContainer = ({
    addon,
    count,
    currency,
    onCountChange,
}: IProps) => {
    const { i, t } = useI18n('extra.addons');

    let overridenMax = addon.detailsMaxQuantity;
    let turnitMax = count + (addon.salesLimit || 0);
    let max = overridenMax ?? turnitMax;

    // Singleton addons can only have one ordered per basket, even if more is available
    if (addon.detailsIsSingleton) {
        max = Math.min(max, 1);
    }
    const price = addon.unitPrice
        ? formatPrice(addon.unitPrice, currency)
        : undefined;

    let disabled = false;

    if (addon.detailsDoNotDisable && addon.detailsDoNotDisable === true) {
        disabled = false;
    } else {
        disabled = max === 0;
    }

    return (
        <React.Fragment key={addon.addonId || -1}>
            <AddonOption
                count={count}
                description={disabled ? t('not-available') : addon.description}
                disabled={disabled}
                iconSvg={addon.detailsIconSvg}
                learnMoreLink={i(t('learn-more-url'), {
                    addonKey: addon.detailsKey || '',
                })}
                maxCount={max}
                onCountChange={onCountChange}
                price={price}
                title={addon.serviceName}
            />
            {count > 0 && (addon.detailsTitle || addon.detailsDescription) && (
                <AddonOption
                    title={addon.detailsTitle}
                    description={addon.detailsDescription}
                    iconSvg={addon.detailsDescriptionIconSvg}
                    muted
                />
            )}
        </React.Fragment>
    );
};

export default AddonOptionContainer;
