import GenericTabs from '@slkit/GenericTabs';
import GenericTab from '@slkit/GenericTabs/GenericTab';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import * as React from 'react';

interface IProps {
    activeIndex: number | null;
    entries: IBasketEntry[];
    onSelect: (index: number) => void;
}

const JourneyPicker = ({ activeIndex, entries, onSelect }: IProps) => (
    <GenericTabs small>
        {entries.map((e, i) => (
            <GenericTab
                active={i === activeIndex}
                key={i}
                onClick={() => onSelect(i)}
                small
            >
                {e.outboundTripData.departureBusStop}
                <br />
                {e.outboundTripData.arrivalBusStop}
            </GenericTab>
        ))}
    </GenericTabs>
);

export default JourneyPicker;
