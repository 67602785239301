import SearchForm from '@slkit/SearchForm';
import { SearchFormBusStopMode } from '@slkit/SearchForm/SearchFormBusStop';
import SearchFormSeparator from '@slkit/SearchForm/SearchFormSeparator';
import BusStopSelect from 'components/BusStopSelect/BusStopSelect';
import DateSelect, { DateSelectMode } from 'components/DateSelect/DateSelect';
import FareClassesSelect from 'components/FareClassesSelect/FareClassesSelect';
import { now } from 'lib/helpers';
import * as React from 'react';

import { ITripSearchFormState } from './TripsSearchFormContainer';
import TripsSearchFormSubmitButton from './TripsSearchFormSubmitButton';

export interface ITripSearchFormOptions {
    color?: string;
    destinationUrl?: string;
    fastBasket?: boolean;
    flattenStops?: boolean;
    hideApproach?: boolean;
    hideDeparture?: boolean;
    hideFareClasses?: boolean;
    hideFrom?: boolean;
    hideTo?: boolean;
    showAllStops?: boolean;
    stopsTag?: string;
    subtitleApproach?: string;
    subtitleFrom?: string;
    subtitleTo?: string;
    syncDates?: boolean;
    titleApproach?: string;
    titleButton?: string;
    titleFareClasses?: string;
    titleFrom?: string;
    titleTo?: string;
    showJourneyPresets?: boolean;
    showTravelPass?: boolean;
}

interface IProps {
    availableDates?: string[];
    canSubmit: boolean;
    canSwitch: boolean;
    changeOnUpdate?: boolean;
    handleChange: (
        key: keyof ITripSearchFormState
    ) => (value) => Promise<boolean>;
    handleSwitch: () => void;
    options?: ITripSearchFormOptions;
    onSubmit: () => Promise<any> | void;
    state: ITripSearchFormState;
}

const TripsSearchForm = ({
    availableDates,
    canSwitch,
    canSubmit,
    changeOnUpdate,
    handleChange,
    handleSwitch,
    options = {},
    onSubmit,
    state,
}: IProps) => (
    <SearchForm>
        {!options.hideFrom && (
            <BusStopSelect
                except={state.stopTo}
                value={state.stopFrom}
                mode={SearchFormBusStopMode.DEPARTURE}
                onChange={handleChange('stopFrom')}
                canSwitch={canSwitch}
                onSwitch={options.hideTo ? undefined : handleSwitch}
                title={options.titleFrom}
                showAllStops={options.showAllStops}
                flattenStops={options.flattenStops}
                subtitle={options.subtitleFrom}
                stopsTag={options.stopsTag}
            />
        )}
        {!options.hideTo && (
            <>
                {!options.hideFrom && <SearchFormSeparator />}
                <BusStopSelect
                    except={state.stopFrom}
                    value={state.stopTo}
                    onChange={handleChange('stopTo')}
                    mode={SearchFormBusStopMode.APPROACH}
                    title={options.titleTo}
                    showAllStops={options.showAllStops}
                    flattenStops={options.flattenStops}
                    subtitle={options.subtitleTo}
                    stopsTag={options.stopsTag}
                />
            </>
        )}
        {!options.hideDeparture && (
            <>
                <SearchFormSeparator />
                <DateSelect
                    after={now()}
                    availableDates={availableDates}
                    // Don't limit before date if dates are syncing as that would force user
                    // to first select return date and only after they would be able to select
                    // departure date. Syncing dates will mostly be used without return visible
                    before={options.syncDates ? undefined : state.dateBack}
                    mode={DateSelectMode.DEPARTURE}
                    onChange={handleChange('dateThere')}
                    value={state.dateThere}
                />
            </>
        )}
        {!options.hideApproach && (
            <>
                <SearchFormSeparator />
                <DateSelect
                    after={state.dateThere}
                    availableDates={availableDates}
                    clearable
                    mode={DateSelectMode.APPROACH}
                    onChange={handleChange('dateBack')}
                    value={state.dateBack}
                    title={options.titleApproach}
                    subtitle={options.subtitleApproach}
                />
            </>
        )}
        {!options.hideFareClasses && (
            <>
                <SearchFormSeparator />
                <FareClassesSelect
                    changeOnUpdate={changeOnUpdate}
                    onChange={handleChange('fareClasses')}
                    value={state.fareClasses}
                    title={options.titleFareClasses}
                />
            </>
        )}
        <TripsSearchFormSubmitButton
            disabled={!canSubmit}
            onSubmit={onSubmit}
            title={options.titleButton}
        />
    </SearchForm>
);

export default TripsSearchForm;
