import useRouter from 'lib/hooks/useRouter';
import * as qs from 'query-string';
import * as React from 'react';

interface IQuery {
    [key: string]: string | undefined;
}

const useQuery = <T extends IQuery>(): T => {
    const { location } = useRouter();
    const queryParams = (qs.parse(location.search) as unknown) as T;

    return React.useMemo(() => queryParams, [location.search]);
};

export default useQuery;
