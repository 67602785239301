import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

interface IProps {
    children?: React.ReactNode;
    duration?: string | null;
}

const Duration = ({ children, duration }: IProps) => {
    const { t } = useI18n('duration');
    let durationString: string | null = null;
    if (duration) {
        const [days, hrs] = duration.split('.');
        if (hrs) {
            const [hours, minutes] = hrs.split(':').map(Number);
            durationString = `${Number(days)}${t('days')} ${hours}${t(
                'hours'
            )} ${minutes}${t('minutes')}`;
        } else {
            const [hours, minutes] = days.split(':').map(Number);
            durationString = `${hours}${t('hours')} ${minutes}${t('minutes')}`;
        }
    }

    return (
        <div className="Duration">
            <div className="Duration__Dots Duration__Dots--before">
                <div />
                <div />
                <div />
            </div>
            <div className="Duration__Content">
                {durationString}
                {children}
            </div>
            <div className="Duration__Dots Duration__Dots--after">
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Duration;
