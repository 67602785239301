import cx from 'classnames';
import * as React from 'react';

interface IProps {
    isActive: boolean;
}

const StepDivider = ({ isActive }: IProps) => (
    <div
        className={cx('Steps__Divider', {
            'Steps__Divider--active': isActive,
        })}
    />
);

export default StepDivider;
