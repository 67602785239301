/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdyenPaymentResultCodeEnum {
  Authorised = "Authorised",
  Cancelled = "Cancelled",
  Received = "Received",
  RedirectShopper = "RedirectShopper",
  Refused = "Refused",
}

export enum AdyenRedirectMethodEnum {
  GET = "GET",
  POST = "POST",
}

export enum CurrencyEnum {
  EUR = "EUR",
}

export enum GenderEnum {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum JourneySourceEnum {
  CRWS = "CRWS",
  TURNIT = "TURNIT",
}

export enum PaymentMethodEnum {
  card = "card",
}

export enum PriceClassEnum {
  Business = "Business",
  Campaign = "Campaign",
  CampaignBusiness = "CampaignBusiness",
  Regular = "Regular",
}

export interface AdyenBrowserInfoInput {
  userAgent?: string | null;
  acceptHeader?: string | null;
}

export interface AdyenPaymentInput {
  type: PaymentMethodEnum;
  encryptedCardNumber: string;
  encryptedExpiryMonth: string;
  encryptedExpiryYear: string;
  encryptedSecurityCode: string;
  remember?: boolean | null;
}

export interface BuyerInfoInput {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  streetHouseNo?: string | null;
  postalCode?: string | null;
  gender?: GenderEnum | null;
  city?: string | null;
  country?: string | null;
  companyName?: string | null;
  cultureXc?: string | null;
}

export interface CustomerInfoInput {
  passengerFirstName?: string | null;
  passengerLastName?: string | null;
  passengerFatherName?: string | null;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  dateOfBirth?: string | null;
  gender?: GenderEnum | null;
  postalCode?: string | null;
  postalCodeCountryId?: number | null;
  comment?: string | null;
  loyalityCardNumber?: string | null;
  nationalityId?: number | null;
  citizenshipId?: number | null;
  documentType?: string | null;
  documentNumber?: string | null;
  isVisaChecked?: boolean | null;
  corporateCard?: string | null;
  emergencyContact?: EmergencyContactInput | null;
}

export interface EmergencyContactInput {
  firstName?: string | null;
  lastName?: string | null;
  relationshipType?: string | null;
  email?: string | null;
  phoneNumber?: PhoneNumberInput | null;
  additionalPhoneNumber?: PhoneNumberInput | null;
}

export interface FareClassInput {
  id: string;
  count: number;
}

export interface JourneyInput {
  journeyId: string;
  priceClass: PriceClassEnum;
}

export interface JourneysInput {
  outboundJourney?: JourneyInput | null;
  inboundJourney?: JourneyInput | null;
}

export interface JourneysSearchInput {
  departureDate: any;
  currency: CurrencyEnum;
  fareClasses: FareClassInput[];
  originCityId?: number | null;
  originBusStopId?: number | null;
  destinationCityId?: number | null;
  destinationBusStopId?: number | null;
  promoCode?: string | null;
  isPartOfRoundTrip?: boolean | null;
  isAllergic?: boolean | null;
  isWheelchair?: boolean | null;
  includeBusPrice?: boolean | null;
  checkPaxSoldTogetherRules?: boolean | null;
  locale?: string | null;
}

export interface PhoneNumberInput {
  areaCode: string;
  number: string;
}

export interface RegisterUserInput {
  email: string;
  password: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  isSendingInfoAllowed?: boolean | null;
}

export interface ReservedSeatRequestInput {
  campaignCode?: string | null;
  tripBusProfiles: TripBusProfileItemInput[];
  currencyXc?: CurrencyEnum | null;
}

export interface SeatInfoItemInput {
  ticketNumber: string;
  seatNumber?: number | null;
  seatNumberLetter?: string | null;
  seatFloorNumber?: number | null;
  loyaltyLevelName?: string | null;
}

export interface TripBusProfileItemInput {
  tripBusProfileId: number;
  seatInfo?: SeatInfoItemInput[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
