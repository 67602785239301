import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import Tooltip from '@slkit/Tooltip';
import useFocusable from 'lib/hooks/useFocusable';
import * as React from 'react';

import InputField from '../InputField';

interface IProps {
    error?: string;
    info?: React.ReactNode;
    onSubmit?: () => void;
    submitTitle?: string;
}

const Promo = ({
    info,
    onSubmit,
    submitTitle,
    ...props
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
    const { fFocus, fBlur, isFocused, ref } = useFocusable(false);

    return (
        <div className="Promo">
            <InputField {...props} />
            <div className="Promo__PromoButtons">
                {info && (
                    <div ref={ref} onMouseEnter={fFocus} onMouseLeave={fBlur}>
                        <button className="Promo__Info">
                            <Icon name={ICON_NAME.INFO} />
                            <Tooltip active={isFocused}>{info}</Tooltip>
                        </button>
                    </div>
                )}
                {onSubmit && (
                    <button className="Promo__ApplyPromo" onClick={onSubmit}>
                        {submitTitle}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Promo;
