import Icon, { ICON_NAME } from '@slkit/Icon';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

export enum SearchFormBusStopMode {
    APPROACH = 'approach',
    DEPARTURE = 'departure',
}

interface ISearchFormBusStopOwnProps {
    error?: Error;
    inputValue?: string;
    loading?: boolean;
    mode?: SearchFormBusStopMode;
    text?: string;
    onClear?: () => void;
}

export type SearchFormBusStopProps = ISearchFormBusStopOwnProps &
    React.HTMLProps<HTMLInputElement>;

const SearchFormBusStop = React.forwardRef<
    HTMLInputElement,
    SearchFormBusStopProps
>(
    (
        {
            error,
            inputValue = '',
            loading,
            mode = SearchFormBusStopMode.DEPARTURE,
            placeholder: iPlaceholder,
            text,
            onClear,
            ...rest
        }: SearchFormBusStopProps,
        ref
    ) => {
        const { t } = useI18n('search_form');

        const placeholder = iPlaceholder || t(`${mode}.placeholder`);
        const contentText = text || t(`${mode}.subtitle`);

        return (
            <div className="SearchForm__Content">
                <input
                    className="SearchForm__Title"
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    disabled={loading || !!error}
                    ref={ref}
                    {...rest}
                />
                <div className="SearchForm__Text">{contentText}&nbsp;</div>
                {onClear && (
                    <button
                        className="SearchForm__Remove"
                        tabIndex={0}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClear();
                        }}
                        onFocus={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Icon name={ICON_NAME.BTNS_DESTINATION_CANCEL} />
                    </button>
                )}
            </div>
        );
    }
);

export default SearchFormBusStop;
