import './index.scss';

import Tag from '@slkit/Tag';
import { CurrencyEnum } from '__types__/globalTypes';
import { formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import find from 'lodash/find';
import * as React from 'react';

import { BusSeatFragment } from '../../../../graphql';
import BusSeat from './BusSeat';

interface IProps {
    seats: BusSeatFragment[];
    onSelect: (seat: BusSeatFragment) => void;
}

const Bus = ({ onSelect, seats }: IProps) => {
    const { t, i } = useI18n('seats_reservation');
    const pricedSeat = find(
        seats,
        seat =>
            seat.seatPrice && seat.seatPrice.amount && seat.seatPrice.currency
    ) as BusSeatFragment;
    return (
        <>
            <div className="Bus">
                <div className="Bus__Content">
                    {seats.map((seat, n) => (
                        <BusSeat key={n} onSelect={onSelect} seat={seat} />
                    ))}
                </div>
                <div className="Bus__Background" />
            </div>
            {pricedSeat && pricedSeat.seatPrice && (
                <div className="Bus__Legend">
                    <div>
                        <Tag color="black" text="S+" />
                        {i(t('premium-seats'), {
                            price: formatPrice(
                                pricedSeat.seatPrice.amount as number,
                                pricedSeat.seatPrice.currency as CurrencyEnum
                            ),
                        })}
                    </div>
                    <div className="Bus__Legend--small">
                        {t('age-restriction')}
                    </div>
                </div>
            )}
        </>
    );
};

export default Bus;
