import './index.scss';

import cx from 'classnames';
import * as React from 'react';
import { useKioskContext } from 'components/Kiosk/KioskLayout';

interface IProps {
    isActive?: boolean;
    opaque?: boolean;
}

const ModalBackdrop = React.forwardRef<
    HTMLDivElement,
    IProps & React.HTMLAttributes<HTMLDivElement> & IProps
>(
    (
        {
            isActive,
            className,
            opaque,
            ...props
        }: React.HTMLAttributes<HTMLDivElement> & IProps,
        ref
    ) => {
        const { isKiosk } = useKioskContext();
        React.useEffect(() => {
            if (!isKiosk) return;

            if (isActive) {
                document.body.style.overflow = 'hidden';
                return () => {
                    document.body.style.overflow = 'auto';
                };
            }
        }, [isActive]);
        return (
            <div
                className={cx('Modal', className, {
                    'Modal--active': isActive,
                    'Modal--opaque': opaque,
                })}
                ref={ref}
                {...props}
            />
        );
    }
);

export default ModalBackdrop;
