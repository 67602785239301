import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    next?: boolean;
    prev?: boolean;
}

const PagerButton = ({
    className,
    next = false,
    prev = false,
    ...props
}: IProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button
        className={cx('Button', {
            'Button--next': next,
            'Button--prev': prev,
        })}
        {...props}
    />
);

export default PagerButton;
