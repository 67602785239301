import { ApplicationDataContext } from 'lib/applicationDataContext';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Language, validateLanguage } from './useI18n';

const LANG_COOKIE = 'locale';

const useCurrentLanguage = (iLang?: Language) => {
    let lang: Language | undefined = validateLanguage(iLang);

    // Try lang from context
    if (!lang) {
        // Make sure context is optional, as this can be called from outside of context
        const ctx = useContext(ApplicationDataContext);
        if (ctx) {
            lang = ctx.language;
        }
    }

    // Try lang from cookie
    const [cookies, setCookie] = useCookies([LANG_COOKIE]);
    if (!lang) {
        lang = cookies[LANG_COOKIE] as Language;
    }

    // Try lang from path name
    if (!lang) {
        const pname = window.location.pathname;
        const langUrlMatch = pname.match(/\/(sk|en|de)\//);
        if (langUrlMatch) {
            lang = langUrlMatch[1] as Language;
        }
    }

    // As default language use SK
    const langValue = lang ?? Language.SK;

    return {
        lang: langValue,
        setLang: (l: Language) => setCookie(LANG_COOKIE, l, { path: '/' }),
    };
};

export default useCurrentLanguage;
