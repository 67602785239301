interface IFieldData {
    name: string;
    value: string;
}

const useCSRF = () => {
    const hiddenFields: IFieldData[] = [];

    const param = document.querySelector("meta[name='csrf-param']");
    const token = document.querySelector("meta[name='csrf-token']");
    if (param && token) {
        hiddenFields.push({
            name: param.getAttribute('content') as string,
            value: token.getAttribute('content') as string,
        });
    }

    return hiddenFields;
};

export default useCSRF;
