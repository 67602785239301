import * as React from 'react';
import './index.scss';

import Radio from '@slkit/Radio';
import Tag from '@slkit/Tag';
import useI18n from 'lib/hooks/useI18n';
import CardLabel, { CardIcon } from './CardLabel';

export interface ICard {
    accessory?: React.ReactNode;
    number: string;
    id: string;
    icon?: CardIcon;
    isPrimary?: boolean;
}

interface IProps {
    card: ICard;
    onChange?: (card: ICard) => void;
    selected: boolean;
}

const CardListItem = ({ card, onChange, selected }: IProps) => {
    const { t } = useI18n('payment.card');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        e.target.checked && onChange && onChange(card);

    return (
        <>
            <div className="CardList__Item">
                <Radio
                    label={<CardLabel icon={card.icon} number={card.number} />}
                    defaultValue={card.id}
                    name="card"
                    onChange={handleChange}
                    checked={selected}
                    variant="centered"
                />
                {card.isPrimary && <Tag color="red" text={t('primary-card')} />}
            </div>
            {selected && card.accessory && (
                <div className="CardList__ItemAccessory">{card.accessory}</div>
            )}
        </>
    );
};

export default CardListItem;
