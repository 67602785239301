import './index.scss';

import cx from 'classnames';
import * as React from 'react';

const List = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('List', className)} {...props} />
);

export default List;
