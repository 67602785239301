import { useCallback, useState } from 'react';

export default (initialLoading?: boolean) => {
    const [loading, setLoading] = useState(
        initialLoading === undefined ? false : initialLoading
    );
    const beginLoading = useCallback(
        <T>(param?: T, error?: T) => {
            setLoading(true);
            if (error) throw error;
            return Promise.resolve(param);
        },
        [setLoading]
    );
    const endLoading = useCallback(
        <T>(param?: T, error?: T) => {
            setLoading(false);
            if (error) throw error;
            return Promise.resolve(param);
        },
        [setLoading]
    );
    return {
        beginLoading,
        endLoading,
        loading,
        setLoading,
    };
};
