import './index.scss';

import * as cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import * as React from 'react';

interface IProps {
    id?: string;
    isChecked?: boolean;
    label: React.ReactNode;
    onChange: (isChecked: boolean) => void;
    variant?: 'default' | 'large';
}

const Check = ({
    id,
    isChecked = false,
    label,
    onChange,
    variant = 'default',
}: IProps) => {
    const [componentId] = React.useState(id || uniqueId('check_'));
    return (
        <div className={cx('Check', { 'Check--large': variant === 'large' })}>
            <input
                type="checkbox"
                checked={isChecked}
                id={componentId}
                onChange={event => onChange(event.target.checked)}
            />
            <label htmlFor={componentId}>{label}</label>
        </div>
    );
};

export default Check;
