import moment from 'moment';

export const customizeMoment = (lang: string) => {
    // updateLocale must be called before setting locale
    // See https://github.com/moment/moment/issues/5410
    moment.updateLocale('sk', {
        weekdaysShort: ['Ned', 'Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob'],
    });
    moment.locale(lang);
};
