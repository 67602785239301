import 'lib/polyfills';

import Application from 'modules/application';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('[data-application]').forEach(target => {
        ReactDOM.render(<Application />, target);
    });
    document.querySelectorAll('[data-not-found]').forEach(target => {
        ReactDOM.render(<Application.NotFound />, target);
    });

    if (module.hot) {
        module.hot.accept('modules/application', () => {
            document.querySelectorAll('[data-application]').forEach(target => {
                ReactDOM.render(require('modules/application'), target);
            });
            document.querySelectorAll('[data-not-found]').forEach(target => {
                ReactDOM.render(
                    require('modules/application').NotFound,
                    target
                );
            });
        });
    }
});
