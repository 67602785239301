import { ReactComponent as TIMES } from '@slkit/assets/svgs/times.svg';
import Icon, { ICON_NAME } from '@slkit/Icon';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { CurrencyEnum, useDeleteBasketMutation } from '../../../graphql';
import { ApplicationDataContext } from '../../lib/applicationDataContext';

export interface IBasketServiceEntry {
    name?: string;
    price?: string;
    from?: string;
    to?: string;
    fareClass?: string;
    fareClassId?: string;
    number?: string;
    priceValue?: number;
    currency?: CurrencyEnum;
}

interface IProps {
    entry: IBasketServiceEntry;
}

const BasketServiceEntry = ({ entry }: IProps) => {
    const { basketId, setBasketId } = React.useContext(ApplicationDataContext);
    const { t } = useI18n('passengers_info');

    if (!basketId) return null;

    // delete whole basket, since there is no Turnit API for removing service.
    const [deleteBasketMutation] = useDeleteBasketMutation({
        variables: {
            id: basketId,
        },
    });

    const handleDelete = () => {
        deleteBasketMutation().then(() => setBasketId());
    };

    return (
        <div className="BasketEntry BasketEntry--Service">
            <div className="Grid items-center pr-2">
                <div className="Grid__Column Grid__Column--1">
                    <div className="text-lg">
                        <b>{entry.name}</b>
                    </div>
                    {entry.from && entry.to && (
                        <div className="flex items-center text-sm">
                            <span className="text-subtle">{entry.from}</span>
                            <Icon
                                name={ICON_NAME.SWITCH}
                                className="text-primary-red ml-1/4 mr-1/4"
                            />
                            <span className="text-subtle">{entry.to}</span>
                        </div>
                    )}
                </div>
                <div className="Grid__Column text-right">
                    <div className="text-lg">
                        <b>{entry.price}</b>
                    </div>
                    {entry.fareClassId && (
                        <span className="text-subtle text-sm">
                            1x {t(entry.fareClassId)}
                        </span>
                    )}
                </div>
            </div>
            <div className="BasketEntry__DeleteButton">
                <button onClick={() => handleDelete()}>
                    <TIMES />
                </button>
            </div>
        </div>
    );
};

export default BasketServiceEntry;
