import { BusStopOptionType } from 'components/BusStopSelect/BusStopSelect';
import { formatDate, now } from 'lib/helpers';
import useRouter from 'lib/hooks/useRouter';
import localStorage from 'lib/localStorage';
import pickBy from 'lodash/pickBy';
import * as qs from 'query-string';
import * as React from 'react';

interface IFareClasses {
    [key: string]: number;
}

export interface IQueryParams {
    fC?: string;
    fS?: string;
    tC?: string;
    tS?: string;
    d?: string;
    a?: string;
    fcls?: string;
    affiliateId?: string;
    promoCode?: string;
    lang?: string;
    pass?: string;
}

export interface IParsedQueryParams {
    affiliateId?: string;
    from: {
        id: number;
        type: BusStopOptionType;
    };
    to: {
        id: number;
        type: BusStopOptionType;
    };
    there: string;
    back?: string;
    fareClasses: IFareClasses;
    promoCode?: string;
    lang?: string;
    travelPassNumber?: string;
}

interface IQueryParamsResult {
    parsedQueryParams: IParsedQueryParams;
    queryParams: IQueryParams;
}

const serializeFareClasses = (fareClasses: IFareClasses): string =>
    Object.keys(pickBy(fareClasses, v => v > 0))
        .map(k => `${k},${fareClasses[k]}`)
        .join(';');

const deserializeFareClasses = (fareClassesString?: string): IFareClasses => {
    const fareClasses: IFareClasses = {};
    if (!fareClassesString) return fareClasses;

    fareClassesString.split(';').map(fc => {
        const pts = fc.split(',');
        if (pts.length !== 2) return;
        fareClasses[pts[0]] = Number(pts[1]);
    });

    return fareClasses;
};

export const serializeParams = (params: IParsedQueryParams) => {
    const data: IQueryParams = {
        a: params.back ? formatDate(params.back) : undefined,
        affiliateId: params.affiliateId,
        d: formatDate(params.there),
        fcls: serializeFareClasses(params.fareClasses),
        lang: params.lang,
        pass: params.travelPassNumber,
        promoCode: params.promoCode,
    };

    if (params.from.type === 'CITY') {
        data.fC = params.from.id.toString();
    } else {
        data.fS = params.from.id.toString();
    }

    if (params.to.type === 'CITY') {
        data.tC = params.to.id.toString();
    } else {
        data.tS = params.to.id.toString();
    }

    return qs.stringify(data as { [key: string]: any }, {
        encode: false,
        sort: false,
    });
};

export const parseParams = (query: string): IQueryParamsResult => {
    const queryParams = (qs.parse(query) as unknown) as IQueryParams;

    const parsedQueryParams: IParsedQueryParams = {
        affiliateId: queryParams.affiliateId,
        back: queryParams.a ? formatDate(queryParams.a) : undefined,
        fareClasses: deserializeFareClasses(queryParams.fcls),
        from: {
            id: Number(queryParams.fC || queryParams.fS),
            type: queryParams.fC ? 'CITY' : 'STOP',
        },
        lang: queryParams.lang,
        promoCode: queryParams.promoCode,
        there: queryParams.d ? formatDate(queryParams.d) : now(),
        to: {
            id: Number(queryParams.tC || queryParams.tS),
            type: queryParams.tC ? 'CITY' : 'STOP',
        },
        travelPassNumber: queryParams.pass,
    };

    return { queryParams, parsedQueryParams };
};

const useQueryParams: () => IQueryParamsResult = () => {
    const { location } = useRouter();

    React.useEffect(() => {
        localStorage.setLastSearchQuery(location.search);
    }, [location.search]);

    return React.useMemo(() => parseParams(location.search), [location.search]);
};

export default useQueryParams;
