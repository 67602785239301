import { useContext } from 'react';
import { __RouterContext, RouteComponentProps } from 'react-router-dom';

export default function useRouter<TParams = {}>() {
    return useContext(__RouterContext) as RouteComponentProps<TParams>;
}

export const useHistory = () => {
    const router = useRouter();
    if (router === undefined) {
        return {
            push: (p: string) => {
                const path =
                    p[0] === '/' ? 'https://online.slovaklines.sk' + p : p;
                window.location.href = path;
            },
        };
    } else {
        return router.history;
    }
};
