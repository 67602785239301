import * as React from 'react';

interface IState {
    atBottom: boolean;
    atTop: boolean;
    scrollable: boolean;
}

export const useScrollStatus = () => {
    const [state, setState] = React.useState<IState>({
        atBottom: true,
        atTop: true,
        scrollable: false,
    });

    const targetElementRef = React.useRef<HTMLDivElement>(null);
    const scrollingParentRef = React.useRef<HTMLElement | Window | null>(null);

    const updateState = (newState: IState) => {
        if (
            newState.atBottom === state.atBottom &&
            newState.atTop === state.atTop &&
            newState.scrollable === state.scrollable
        ) {
            return;
        }
        setState(newState);
    };

    React.useEffect(() => {
        if (!targetElementRef.current) {
            scrollingParentRef.current = window;
        } else {
            scrollingParentRef.current = getElementsScrollingParent(
                targetElementRef.current
            );
        }

        const container = scrollingParentRef.current;

        if (!container) {
            updateState({ atBottom: true, atTop: true, scrollable: false });
            return;
        }

        const handleScroll = () => {
            let scrollOffset = 0;
            let windowHeight = 0;
            let contentHeight = 0;

            if (container === window) {
                scrollOffset = window.pageYOffset;
                windowHeight = window.innerHeight;
                contentHeight = window.document.body.clientHeight;
            } else {
                const elem = container as HTMLElement;
                scrollOffset = elem.scrollTop;
                windowHeight = elem.clientHeight;
                contentHeight = elem.scrollHeight;
            }

            updateState({
                atTop: scrollOffset <= 0,
                atBottom: scrollOffset >= contentHeight - windowHeight,
                scrollable: true,
            });
        };

        handleScroll();
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    });

    return {
        ref: targetElementRef,
        ...state,
        scrollingParentRef,
    };
};

const getElementsScrollingParent = (
    element: HTMLElement
): HTMLElement | Window | null => {
    let scrollingParent: HTMLElement | Window | null = null;
    let parent: HTMLElement | null = element;

    while (parent) {
        if (
            parent.clientHeight < parent.scrollHeight ||
            parent.tagName === 'HTML'
        ) {
            scrollingParent = parent;
            break;
        }

        // If any parent has set overflow and does not have height that needs to be scrolled
        // return null as there is nothing to scroll that would have effect on "stickines"
        const styles = window.getComputedStyle(parent);
        const anyOverflows =
            styles.overflow !== 'visible' ||
            styles.overflowX !== 'visible' ||
            styles.overflowY !== 'visible';
        if (anyOverflows) break;

        parent = parent.parentElement;
    }

    if (scrollingParent?.tagName === 'HTML') {
        scrollingParent = window;
    }

    return scrollingParent;
};
