import Icon, { ICON_NAME } from '@slkit/Icon';
import PopupHeader from '@slkit/Popup/PopupHeader';
import useI18n from 'lib/hooks/useI18n';
import padStart from 'lodash/padStart';
import * as moment from 'moment';
import * as React from 'react';

interface IProps {
    validUntil: string;
}

const format = (time: string) => {
    const d = moment.duration(moment(time).diff(moment()));
    return `${Math.floor(d.asMinutes())}:${padStart(
        d.seconds().toString(),
        2,
        '0'
    )}`;
};

const BasketHeader = ({ validUntil }: IProps) => {
    const { t } = useI18n('basket');
    const [remainingTime, setRemainingTime] = React.useState<string>('');

    React.useEffect(() => {
        const int = setInterval(() => {
            setRemainingTime(format(validUntil));
        }, 1000);
        setRemainingTime(format(validUntil));

        return () => clearInterval(int);
    }, [validUntil]);

    return (
        <PopupHeader>
            <Icon name={ICON_NAME.TIME} />
            <p>
                {t('header')} <strong>{remainingTime}</strong>
            </p>
        </PopupHeader>
    );
};

export default BasketHeader;
