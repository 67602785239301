import { useKioskContext } from 'components/Kiosk/KioskLayout';
import TripsSearchFormContainer from 'components/TripsSearchForm/TripsSearchFormContainer';
import { useHistory } from 'lib/hooks/useRouter';
import * as React from 'react';
import useQueryParams from './useQueryParams';

const SearchResultsTripsSearchFormContainer = () => {
    const { isKiosk } = useKioskContext();
    const baseSearchUrl = isKiosk ? '/kiosk/search' : '/search';

    const history = useHistory();
    // Selected trip stores trip displayed in sidebar
    const { parsedQueryParams } = useQueryParams();

    const handleSubmit = (_params, queryString) => {
        history.push(`${baseSearchUrl}?${queryString}`);
    };

    return (
        <TripsSearchFormContainer
            travelPassNumber={parsedQueryParams.travelPassNumber}
            initialValue={parsedQueryParams}
            onChange={handleSubmit}
            onSubmit={handleSubmit}
            options={{
                destinationUrl: '',
                showTravelPass: true,
                showJourneyPresets: isKiosk,
                hideFareClasses: isKiosk,
                hideApproach: isKiosk,
            }}
        />
    );
};

export default SearchResultsTripsSearchFormContainer;
