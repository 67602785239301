import * as React from 'react';

interface IProps {
    children: React.ReactNode;
}

const PopupHeader = ({ children }: IProps) => (
    <div className="Popup__Header">{children}</div>
);

export default PopupHeader;
