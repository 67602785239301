import Icon, { ICON_NAME } from '@slkit/Icon';
import * as cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    active?: boolean;
    className?: string;
    icon:
        | ICON_NAME.APPLE_PAY
        | ICON_NAME.B2B
        | ICON_NAME.CREDIT_CARD
        | ICON_NAME.VOUCHER
        | ICON_NAME.GPAY;
}

const PaymentMethodBadge = ({ active, className, icon }: IProps) => {
    return (
        <div
            className={cx('PaymentMethodBadge', className, {
                'PaymentMethodBadge--active': active,
            })}
        >
            <Icon name={icon} raw />
        </div>
    );
};

export default PaymentMethodBadge;
