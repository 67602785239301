import TravelPassesContainer from 'components/TravelPasses/TravelPassesContainer';
import Layout from 'modules/application/Layout';
import * as React from 'react';

const TravelPassesPage = () => (
    <Layout>
        <div className="container">
            <div className="Page">
                <TravelPassesContainer />
            </div>
        </div>
    </Layout>
);

export default TravelPassesPage;
