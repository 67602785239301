import update, { Spec } from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import { useReducer } from 'react';
import { AdyenCardBrand } from './adyen';

export type TCardFormFieldKey =
    | 'encryptedCardNumber'
    | 'encryptedExpiryDate'
    | 'encryptedSecurityCode';

interface ICardFormFieldData {
    blob?: string;
    encryptedFieldName: string;
    fieldType: string;
    txVariant: string;
    type: string;
    uid: string;
    valid: boolean;
}

export interface ICardFormFieldError {
    error: string;
    fieldType: string;
    i18n?: string;
}

interface ICardFormCardData {
    encryptedCardNumber: string;
    encryptedExpiryYear: string;
    encryptedExpiryMonth: string;
    encryptedSecurityCode: string;
}

interface ICardFormState {
    allValid: boolean;
    cardData?: ICardFormCardData;
    data: {
        encryptedCardNumber?: ICardFormFieldData;
        encryptedExpiryYear?: ICardFormFieldData;
        encryptedExpiryMonth?: ICardFormFieldData;
        encryptedSecurityCode?: ICardFormFieldData;
    };
    errors: {
        encryptedCardNumber?: ICardFormFieldError;
        encryptedExpiryDate?: ICardFormFieldError;
        encryptedSecurityCode?: ICardFormFieldError;
    };
    initialized: boolean;
    initializing: boolean;
    brand?: AdyenCardBrand;
}

interface ICardFormFieldDataAction {
    type: Action.DATA;
    payload: {
        encryptedFieldName: string;
        fieldType: string;
        valid: boolean;
    };
}

interface ICardFormFieldErrorAction {
    type: Action.ERROR;
    payload: {
        fieldType: string;
    };
}

interface ICardFormAllValidAction {
    type: Action.ALL_VALID;
    payload: {
        valid: boolean;
    };
}

interface ICardFormInitializedAction {
    type: Action.INITIALIZED;
}

interface ICardFormInitializingAction {
    type: Action.INITIALIZING;
}

interface ICardFormSetBrandAction {
    type: Action.SET_BRAND;
    payload: {
        brand: AdyenCardBrand;
    };
}

export enum Action {
    ALL_VALID = 'ALL_VALID',
    DATA = 'DATA',
    ERROR = 'ERROR',
    INITIALIZED = 'INITIALIZED',
    INITIALIZING = 'INITIALIZING',
    SET_BRAND = 'SET_BRAND',
}

const reducer = (
    state: ICardFormState,
    action:
        | ICardFormAllValidAction
        | ICardFormInitializedAction
        | ICardFormInitializingAction
        | ICardFormFieldDataAction
        | ICardFormFieldErrorAction
        | ICardFormSetBrandAction
) => {
    switch (action.type) {
        case Action.ALL_VALID:
            return buildCardData(
                update(state, {
                    allValid: { $set: action.payload.valid },
                })
            );
        case Action.DATA:
            if (
                !isEqual(
                    state.data[action.payload.encryptedFieldName],
                    action.payload
                )
            ) {
                return buildCardData(
                    update(state, {
                        data: {
                            [action.payload.encryptedFieldName]: {
                                $set: action.payload,
                            },
                        },
                    })
                );
            }
        case Action.ERROR:
            if (
                !isEqual(state.errors[action.payload.fieldType], action.payload)
            ) {
                return update(state, {
                    errors: {
                        [action.payload.fieldType]: {
                            $set: action.payload,
                        },
                    },
                });
            }
        case Action.INITIALIZED:
            return update(state, {
                initialized: { $set: true },
                initializing: { $set: false },
            });

        case Action.INITIALIZING:
            return update(state, {
                initializing: { $set: true },
            });

        case Action.SET_BRAND:
            return update(state, {
                brand: { $set: action.payload.brand },
            });
    }

    return state;
};

const initialState = {
    allValid: false,
    data: {},
    errors: {},
    initialized: false,
    initializing: false,
};

const useAdyenCardFormReducer = () => useReducer(reducer, initialState);

export default useAdyenCardFormReducer;

const buildCardData = (state: ICardFormState): ICardFormState => {
    const invalidResult = update(state, { cardData: { $set: undefined } });

    const keys: Array<keyof ICardFormCardData> = [
        'encryptedCardNumber',
        'encryptedExpiryMonth',
        'encryptedExpiryYear',
        'encryptedSecurityCode',
    ];
    for (const key of keys) {
        if (!state.data[key]) return invalidResult;
        const fieldData = state.data[key] as ICardFormFieldData;
        if (!fieldData.valid || !fieldData.blob) return invalidResult;
    }

    const data = state.data;
    const cardData = {
        encryptedCardNumber:
            (data.encryptedCardNumber && data.encryptedCardNumber.blob) || '',
        encryptedExpiryMonth:
            (data.encryptedExpiryMonth && data.encryptedExpiryMonth.blob) || '',
        encryptedExpiryYear:
            (data.encryptedExpiryYear && data.encryptedExpiryYear.blob) || '',
        encryptedSecurityCode:
            (data.encryptedSecurityCode && data.encryptedSecurityCode.blob) ||
            '',
    };

    return update(state, { cardData: { $set: cardData } });
};
