import cx from 'classnames';
import * as React from 'react';

const ModalContent = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('Modal__Content', className)} {...props} />
);

export default ModalContent;
