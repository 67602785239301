import PurchaseProcessContainer from 'components/PurchaseProcess/PurchaseProcessContainer';
import Layout from 'modules/application/Layout';
import * as React from 'react';

const PurchaseProgressPage = () => (
    <Layout>
        <div className="container">
            <div className="Page">
                <PurchaseProcessContainer />
            </div>
        </div>
    </Layout>
);

export default PurchaseProgressPage;
