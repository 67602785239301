import HeaderItem from '@slkit/Header/HeaderItem';
import { ICON_NAME } from '@slkit/Icon';
import LoginDialog from '@slkit/LoginDialog';
import LoginDropdown from '@slkit/LoginDropdown';
import Popup from '@slkit/Popup';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useFocusable from 'lib/hooks/useFocusable';
import { useTracking } from 'lib/hooks/useTracking';
import * as React from 'react';

import useCurrentUser from './useCurrentUser';

const LoginContainer = () => {
    const { buttonRef, fFocus, ref, isFocused } = useFocusable(false);
    const currentUser = useCurrentUser();
    const { setBasketId } = React.useContext(ApplicationDataContext);
    const { identify } = useTracking();

    React.useEffect(() => {
        if (currentUser && currentUser.email) {
            identify(currentUser.email);
        }
    }, [currentUser, identify]);

    return (
        <>
            <HeaderItem
                icon={ICON_NAME.USER}
                onClick={fFocus}
                ref={buttonRef}
            />
            <div ref={ref}>
                <Popup active={isFocused}>
                    {currentUser ? (
                        <LoginDropdown
                            currentUser={currentUser}
                            onLogout={() => setBasketId()}
                        />
                    ) : (
                        <LoginDialog />
                    )}
                </Popup>
            </div>
        </>
    );
};

export default LoginContainer;
