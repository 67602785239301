import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import Tag from '@slkit/Tag';
import cx from 'classnames';
import useI18n from 'lib/hooks/useI18n';
import * as moment from 'moment-timezone';
import * as React from 'react';

import { JourneyFragmentFragment } from '../../../../graphql';
import Duration from '../Duration';
import TimeBox from '../TimeBox';
import TripCTAButton from './TripCTAButton';

interface IProps {
    details?: React.ReactNode;
    lineSubType?: string;
    lineType?: string;
    notice?: boolean;
    onSelect?: (journey: JourneyFragmentFragment) => void;
    onDetail: (journey: JourneyFragmentFragment) => void;
    selected?: boolean;
    soldOut?: boolean;
    travelPass?: boolean;
    trip: JourneyFragmentFragment;
    variant?: 'kiosk' | 'default';
    brandName?: string | null;
}

const Trip = ({
    details,
    lineSubType,
    lineType,
    notice = false,
    onSelect,
    onDetail,
    selected = false,
    soldOut = false,
    travelPass = false,
    brandName,
    trip,
    variant = 'default',
}: React.PropsWithChildren<IProps>) => {
    const { t } = useI18n('search_results.trip');

    const cname = cx('Trip', {
        'Trip--active': selected,
        'Trip--soldOut': soldOut,
    });

    return (
        <div className={cname}>
            <TimeBox
                time={moment.tz(
                    trip.departureDateTime,
                    trip.departureDateTimeTimeZone
                )}
                cityName={trip.originCityName}
                stopName={trip.originStopName}
            />
            <Duration duration={trip.duration}>
                {lineType && <Tag color="red" text={lineType} />}
                {lineSubType && (
                    <Tag color="gray" secondary text={lineSubType} />
                )}
            </Duration>
            <TimeBox
                time={moment.tz(
                    trip.arrivalDateTime,
                    trip.arrivalDateTimeTimeZone
                )}
                cityName={trip.destinationCityName}
                stopName={trip.destinationStopName}
            />
            <div className="Trip__Fill" />
            <div className="Trip__ButtonGroup">
                <div className="Trip__Details">
                    {brandName && (
                        <div
                            className={cx(
                                'Trip__Label',
                                'Trip__labelBrandNameMobile'
                            )}
                        >
                            {brandName}
                        </div>
                    )}
                    <button
                        className={cx('Button', {
                            'Button--secondary': variant === 'default',
                            'Button--primary': variant === 'kiosk',
                        })}
                        onClick={() => onDetail(trip)}
                    >
                        {t('trip-detail')}
                    </button>
                    {brandName && (
                        <div
                            className={cx(
                                'Trip__Label',
                                'Trip__labelBrandName'
                            )}
                        >
                            {brandName}
                        </div>
                    )}
                </div>
                {variant === 'default' && (
                    <>
                        <TripCTAButton
                            className="Trip__CTA"
                            details={details}
                            notice={notice}
                            onClick={onSelect}
                            selected={selected}
                            soldOut={soldOut}
                            travelPass={travelPass}
                            trip={trip}
                        />
                        {trip.timetablePdfUrl && (
                            <div className="Trip__CTA">
                                <a
                                    href={trip.timetablePdfUrl}
                                    target="_blank"
                                    className="Button"
                                >
                                    <Icon name={ICON_NAME.DOCUMENT} />
                                    {t('timetable-pdf')}
                                </a>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Trip;
