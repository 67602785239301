import { ApolloError } from 'apollo-client';
import { Observable } from 'apollo-link';
import { IFareClassOption } from 'components/FareClassesSelect/FareClassesSelect';
import keys from 'lodash/keys';
import * as moment from 'moment';
import { CurrencyEnum } from '__types__/globalTypes';

export const hasValue = <T>(value: T | null | undefined): value is T => {
    return value !== undefined && value !== null;
};

export const selectItemBefore = <T>(array: T[], item: T): T => {
    const i = array.indexOf(item);
    if (i === -1) return array[array.length - 1]; // if item is not in array, return last
    if (i > 0) return array[i - 1];
    else return item;
};

export const selectItemAfter = <T>(array: T[], item: T): T => {
    const i = array.indexOf(item);
    if (i === -1) return array[0]; // if item is not in array, return first
    if (i < array.length - 1) return array[i + 1];
    return item;
};

export const formatPrice = (price?: number | null, currency?: CurrencyEnum) => {
    const locale = window._slCurrentLang || 'sk';
    if (price === null || price === undefined || !currency) return '';
    const priceString = price.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    return `${priceString} ${currency === 'EUR' ? '€' : currency}`;
};

export const formatFareClasses = (
    fareClasses: { [key: string]: number },
    options: IFareClassOption[]
) => {
    return keys(fareClasses)
        .map(type => {
            const count = fareClasses[type];
            if (count === 0) return null;
            const label = options.find(o => o.id === type);
            return label ? `${count} ${label.label}` : null;
        })
        .filter(v => v)
        .join(', ');
};

export const now = () => formatDate(new Date());

export const formatDate = (date: string | Date, format?: string) =>
    moment.parseZone(date).format(format || 'YYYY-MM-DD');

export const parseNames = (name: string) => {
    const separatorIndex = name.indexOf(' ');
    if (separatorIndex > -1) {
        return [
            name.substr(0, separatorIndex),
            name.substr(separatorIndex + 1),
        ];
    } else {
        return [name, null];
    }
};

export const attributesToProps = (attributes: NamedNodeMap | null) => {
    const r = {};
    if (!attributes) return r;
    for (const key in attributes) {
        if (attributes.hasOwnProperty(key)) {
            const attribute = attributes[key];
            switch (attribute.name) {
                case 'autocomplete':
                    r['autoComplete'] = attribute.value;
                    break;

                default:
                    r[attribute.name] = attribute.value;
                    break;
            }
        }
    }
    return r;
};

export const getErrorMessage = (error: Error): string => {
    const apolloError = error as ApolloError;

    if (apolloError.graphQLErrors && apolloError.graphQLErrors[0]) {
        return (apolloError.graphQLErrors[0] as any).message as string;
    }

    const unTypedError = error as any;
    if (
        unTypedError &&
        unTypedError.networkError &&
        unTypedError.networkError.result &&
        unTypedError.networkError.result.error &&
        unTypedError.networkError.result.error.message
    ) {
        return unTypedError.networkError.result.error.message;
    }

    return error.message;
};

export const focusError = () => {
    const errorInput = document.querySelector<HTMLInputElement>(
        '.InputField--invalid input'
    );

    if (errorInput) {
        // Also scroll to input
        // This is a somewhat workaround not being able to focus input
        // on mobile webkit devices, where .focus() only work when called from
        // onClick handler
        errorInput.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        errorInput.focus();
    }
};

// cookie can be set only on current level subdomains
// so from foo.bar.baz.com only '.bar.baz.com' is valid domain
export const getCookieDomain = () => {
    const pts = document.domain.split('.');
    if (pts.length > 1) {
        return '.' + pts.slice(1).join('.');
    } else {
        return document.domain;
    }
};

export const joinPath = (...pts: string[]): string =>
    pts
        .map(pt => pt.replace(/^\/+/, '').replace(/\/+$/, ''))
        .filter(Boolean)
        .join('/');

export const promiseToObservable = <T>(promise: Promise<T>) =>
    new Observable<T>(subscriber => {
        promise.then(
            value => {
                if (subscriber.closed) return;
                subscriber.next(value);
                subscriber.complete();
            },
            err => subscriber.error(err)
        );
    });

export const parseCurrencyXc = (currencyXc: string): string => {
    const pts = currencyXc.split('.');
    return pts[pts.length - 1] || '';
};
