import cx from 'classnames';
import * as React from 'react';

interface IProps {
    icon?: React.ReactNode;
}

const FooterLink = React.forwardRef<
    HTMLButtonElement,
    IProps & React.HTMLAttributes<HTMLButtonElement>
>(
    (
        {
            icon,
            className,
            children,
            ...props
        }: IProps & React.HTMLAttributes<HTMLButtonElement>,
        ref
    ) => (
        <button ref={ref} className={cx('Footer__Link', className)} {...props}>
            {icon}
            {children}
        </button>
    )
);

export default FooterLink;
