import * as React from 'react';

export const useCustomSearchFormStyles = (
    color: string | undefined
): [string | undefined, React.ReactNode] =>
    React.useMemo(() => {
        if (!color) return [undefined, null];

        const rand = Math.random()
            .toString()
            .substr(2);
        const id = 'SF' + rand;

        const colorString = `rgb(${color})`;

        const styles = `
.${id} .SearchForm ::selection {
    background: ${colorString};
}
.${id} .SearchForm .SearchForm__Cell--active > .Icon .fill,
.${id} .SearchForm .SearchForm__Cell--filled > .Icon .fill {
    fill: ${colorString};
}

.${id} .SearchForm .SearchForm__Button {
    background-color: ${colorString};
}

@media screen and (max-width: 767px) {
    .${id} .SearchForm__Cell--BusStop.SearchForm__Cell--active .SearchForm__Title {
        border: 1px solid ${colorString};
        box-shadow: 0px 0px 2px ${colorString};
    }
}

.${id} .JourneyPresets .JourneyPreset--primary {
    background-color: ${colorString};
    border-color: ${colorString};
}

.${id} .Counter .Icon .fill {
    fill: ${colorString}
}

.${id} .SearchForm .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--oneSelected) {
    background-color: rgba(${color}, 0.16);
    color: ${colorString};
}
.${id} .SearchForm .DayPicker-Day__cap::after {
    background-color: rgba(${color}, 0.16);
}

.${id} .DayPicker-Day:not(.DayPicker-Day--selected) .DayPicker-Day__circle,
.${id} .DayPicker-Day--selected.DayPicker-Day--oneSelected .DayPicker-Day__circle {
    background-color: rgba(${color}, 0.16);
}

.${id} .SearchForm .DayPicker-Day--oneSelected::after,
.${id} .SearchForm .DayPicker-Day:hover:not(.DayPicker-Day--disabled)::after,
.${id} .SearchForm .DayPicker-Day--start::after,
.${id} .SearchForm .DayPicker-Day--end::after {
    background-color: ${colorString};
}
                `;

        return [
            id,
            <style
                dangerouslySetInnerHTML={{
                    __html: styles,
                }}
            />,
        ];
    }, [color]);
