import { ReactComponent as IconLineAc } from '@slkit/assets/svgs/icon-line-ac.svg';
import { ReactComponent as IconLineUsers } from '@slkit/assets/svgs/icon-line-users.svg';
import { ReactComponent as IconLineWc } from '@slkit/assets/svgs/icon-line-wc.svg';
import { ReactComponent as IconLineWifi } from '@slkit/assets/svgs/icon-line-wifi.svg';
import ImageBadge from '@slkit/ImageBadge';
import * as React from 'react';
import {
    JourneyFragmentFragment,
    JourneyRouteFragment,
} from '../../../graphql';
import { useTripDescription } from './helpers';

interface IProps {
    trip: JourneyFragmentFragment;
    route: JourneyRouteFragment;
}

const BUS_EQUIPMENT_ICON: { [key: string]: React.FunctionComponent } = {
    'BUS_PROFILE_PROPERTY.A_C': IconLineAc,
    'BUS_PROFILE_PROPERTY.WC': IconLineWc,
    'BUS_PROFILE_PROPERTY.INTERNET': IconLineWifi,
};

const SearchTripDetailLineDetails = ({ trip, route }: IProps) => {
    const description = useTripDescription(trip, route);

    return (
        <>
            <div>
                <div className="flex items-center space-x-xs">
                    <ImageBadge
                        image={<IconLineUsers />}
                        title={
                            trip.availableRegularSeats?.toString() || undefined
                        }
                    />
                    {route.busEquipment.map(item => {
                        const IconComponent =
                            BUS_EQUIPMENT_ICON[item.code ?? ''];
                        if (IconComponent == null) return null;
                        return <ImageBadge image={<IconComponent />} />;
                    })}
                </div>
                <p className="SearchTripDetailLineDetails__Description">
                    {description}
                </p>
            </div>
            {/* <div>
                {trip.isAllergicPassengerOnBus !== null && (
                    <AttributeItem
                        title={t('feature.is-allergic-passenger-on-bus')}
                    >
                        {presentContent(trip.isAllergicPassengerOnBus)}
                    </AttributeItem>
                )}
                {trip.isPetOnBus !== null && (
                    <AttributeItem title={t('feature.is-pet-on-bus')}>
                        {presentContent(trip.isPetOnBus)}
                    </AttributeItem>
                )}
                {trip.isWheelchairAreaAvailable !== null && (
                    <AttributeItem
                        title={t('feature.is-wheelchair-area-available')}
                    >
                        {presentContent(trip.isWheelchairAreaAvailable)}
                    </AttributeItem>
                )}
            </div> */}
        </>
    );
};

export default SearchTripDetailLineDetails;
