import { IPaymentConfiguration } from 'components/PurchaseProcess/Payment/usePaymentConfiguration';
import useScript from 'lib/hooks/useScript';
import * as React from 'react';

type TGooglePayState = 'initializing' | 'ready' | 'error';
type TGooglePayEnvironment = 'TEST' | 'PRODUCTION';
type TGoogleIsReadyToPay = 'pending' | true | false;

interface IGooglePaymentOptions {
    totalPrice: string;
}
interface IGooglePaymentResult {
    token: string;
}

interface IGooglePayResult {
    state: TGooglePayState;
    googlePay?: google.payments.api.PaymentsClient;
    handlePayment?: (
        opts: IGooglePaymentOptions
    ) => Promise<IGooglePaymentResult>;
    isReadyToPay?: boolean;
    error?: Error;
}

const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
};

const useGooglePayLib = (options: IPaymentConfiguration): IGooglePayResult => {
    if (!options.googlePaySdkUrl || !options.merchantId) {
        const missing = options.googlePaySdkUrl
            ? 'merchantId'
            : 'googlePaySdkUrl';
        const eMessage = `Invalid GooglePay configuration, ${missing} is missing`;
        console.log('[useGooglePayLib]', eMessage);
        return {
            error: new Error(eMessage),
            state: 'error',
        };
    }

    const cardPaymentMethod: google.payments.api.CardPaymentMethod = {
        parameters: {
            allowedCardNetworks: [
                'AMEX',
                'DISCOVER',
                'INTERAC',
                'JCB',
                'MASTERCARD',
                'VISA',
            ],
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        },
        tokenizationSpecification: {
            parameters: {
                gateway: 'adyen',
                gatewayMerchantId: options.merchantId,
            },
            type: 'PAYMENT_GATEWAY',
        },
        type: 'CARD',
    };

    const environment: TGooglePayEnvironment = React.useMemo(() => {
        return options.environment === 'live' ? 'PRODUCTION' : 'TEST';
    }, []);

    const { loading, error } = useScript(options.googlePaySdkUrl);

    const res = React.useMemo((): IGooglePayResult => {
        console.log('[useGooglePay] Loading js');
        if (loading) return { state: 'initializing' };
        if (error) {
            console.log('[useGooglePay] Could not load SDK');
            return {
                error: new Error('Could not load GooglePay SDK javascript'),
                state: 'error',
            };
        }

        if (!google) {
            console.log('[useGooglePay] google lib is not loaded');
            return {
                error: new Error('Missing GooglePay lib'),
                state: 'error',
            };
        }

        console.log('[useGooglePay] Initializing PaymentsClient');
        const googlePay = new google.payments.api.PaymentsClient({
            environment,
        });

        return { state: 'ready', googlePay };
    }, [loading, error]);

    const [isReadyToPay, setIsReadyToPay] = React.useState<
        TGoogleIsReadyToPay
    >();

    React.useEffect(() => {
        if (!res.googlePay) return;
        if (isReadyToPay !== undefined) return;

        if (!options.merchantId) {
            console.log('[useGooglePay] merchantId missing');
            setIsReadyToPay(false);
            return;
        }

        // Prevent reinitializing
        setIsReadyToPay('pending');

        console.log('[useGooglePay] Fetching isReadyToPay');
        res.googlePay
            .isReadyToPay({
                ...baseRequest,
                allowedPaymentMethods: [cardPaymentMethod],
            })
            .then(result => {
                console.log('[useGooglePay] isReadyToPay result', result);
                setIsReadyToPay(result.result);
            })
            .catch(e => {
                console.error(e);
                setIsReadyToPay(false);
            });
    }, [res.googlePay]);

    const handlePayment = React.useCallback(
        (opts: IGooglePaymentOptions) => {
            if (!isReadyToPay || !res.googlePay) {
                return Promise.reject(new Error('GooglePay is not ready'));
            }

            const paymentDataRequest: google.payments.api.PaymentDataRequest = {
                ...baseRequest,
                allowedPaymentMethods: [cardPaymentMethod],
                merchantInfo: {
                    merchantId: options.googlePayMerchantId || '',
                    merchantName: 'SlovakLines',
                },
                transactionInfo: {
                    countryCode: 'SK',
                    currencyCode: 'EUR',
                    totalPrice: opts.totalPrice,
                    totalPriceStatus: 'FINAL',
                },
            };

            console.log('[useGooglePay] loadPaymentData', paymentDataRequest);

            return res.googlePay
                .loadPaymentData(paymentDataRequest)
                .then(result => {
                    console.log('[useGooglePay] result', result);
                    const token =
                        result.paymentMethodData.tokenizationData.token;
                    if (!token) {
                        throw new Error('paymentData does not contain token');
                    }

                    return { token };
                })
                .catch(e => {
                    if (e && e.statusCode === 'CANCELED') {
                        console.log('[useGooglePay] canceled');
                    } else {
                        console.log('[useGooglePay] loadPaymentData failed');
                        console.error(e);
                    }
                    throw e;
                });
        },
        [isReadyToPay, res.googlePay, options]
    );

    const p = isReadyToPay;
    const readyToPay = p === 'pending' || p === undefined ? undefined : p;

    return React.useMemo(() => {
        return {
            ...res,
            handlePayment,
            isReadyToPay: readyToPay,
        };
    }, [res, isReadyToPay]);
};

// Context provider stuff

interface IGooglePayContextProviderProps {
    children: React.ReactNode;
    options: IPaymentConfiguration;
}

const GooglePayContext = React.createContext<IGooglePayResult>({
    state: 'initializing',
});

export const GooglePayContextProvider = (
    props: IGooglePayContextProviderProps
) => {
    const ctx = useGooglePayLib(props.options);
    return (
        <GooglePayContext.Provider value={ctx}>
            {props.children}
        </GooglePayContext.Provider>
    );
};

export const useGooglePay = (): IGooglePayResult =>
    React.useContext(GooglePayContext);

export default useGooglePay;
