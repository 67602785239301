import DelayedCounter from '@slkit/Counter/DelayedCounter';
import Icon, { ICON_NAME } from '@slkit/Icon';
import * as cx from 'classnames';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

interface IProps {
    count?: number;
    description?: string | null;
    disabled?: boolean;
    icon?: ICON_NAME;
    iconSvg?: string | null;
    learnMoreLink?: string;
    maxCount?: number;
    muted?: boolean;
    onCountChange?: (count: number) => Promise<boolean>;
    price?: string;
    title?: string | null;
}

const AddonOption = ({
    count,
    description,
    disabled,
    icon,
    iconSvg,
    learnMoreLink,
    maxCount,
    muted,
    onCountChange,
    price,
    title,
}: IProps) => {
    const { t } = useI18n('extra.addons');

    return (
        <div
            className={cx('AddonOption', {
                'AddonOption--disabled': disabled,
                'AddonOption--muted': muted,
            })}
        >
            <div className="AddonOption__Icon">
                {iconSvg ? (
                    <Icon svg={iconSvg} />
                ) : (
                    <Icon name={icon || ICON_NAME.ADDON_PLACEHOLDER} />
                )}
            </div>
            <div className="AddonOption__Content">
                <p className="AddonOption__Title">
                    {title}{' '}
                    {!disabled && price && (
                        <>
                            <br />
                            <span className="AddonOption__Price">
                                ({price})
                            </span>
                        </>
                    )}
                </p>
                <p className="AddonOption__Description">
                    {description}{' '}
                    {!disabled && learnMoreLink && (
                        <a
                            className="Link"
                            href={learnMoreLink}
                            target="_blank"
                        >
                            {t('learn-more')}
                        </a>
                    )}
                </p>
            </div>
            {!disabled && count !== undefined && onCountChange && (
                <div className="AddonOption__Counter">
                    <DelayedCounter
                        count={count}
                        delay={1000}
                        max={maxCount}
                        onChange={onCountChange}
                    />
                </div>
            )}
        </div>
    );
};

export default AddonOption;
