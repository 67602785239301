import cx from 'classnames';
import * as React from 'react';
import './index.scss';
import Tab from './Tab';

export enum TabType {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}

interface IProps {
    activeTab?: TabType;
    className?: string;
    dateInbound?: string;
    dateOutbound: string;
    onTabSelect: (tab: TabType) => void;
    small?: boolean;
}

const Tabs = ({
    activeTab = TabType.OUTBOUND,
    className,
    dateOutbound,
    dateInbound,
    onTabSelect,
    small = false,
}: IProps) => {
    // Do not show tabs when there is no reason to
    if (!dateInbound) return null;

    const tabs = [TabType.OUTBOUND, TabType.INBOUND];

    return (
        <div
            className={cx(
                {
                    Tabs: !small,
                    TabsSmall: small,
                },
                className
            )}
        >
            {tabs.map(type => (
                <Tab
                    key={type}
                    date={type === TabType.INBOUND ? dateInbound : dateOutbound}
                    tab={type}
                    active={type === activeTab}
                    small={small}
                    onClick={() => onTabSelect(type)}
                />
            ))}
        </div>
    );
};

export default Tabs;
