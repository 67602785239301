import JourneyPreset from '@slkit/JourneyPreset';
import * as React from 'react';
import {
    JourneyPresetFragment,
    useJourneyPresetsQuery,
} from '../../../graphql';

interface IProps {
    onSelect: (preset: JourneyPresetFragment) => void;
}

const JourneyPresets = ({ onSelect }: IProps) => {
    const { data } = useJourneyPresetsQuery();
    if (!data || data.journeyPresets.length === 0) return null;

    return (
        <div className="JourneyPresets">
            {data.journeyPresets.map((preset, i) => (
                <JourneyPreset
                    name={preset.name}
                    from={preset.fromBusStop.name}
                    key={i}
                    onClick={() => onSelect(preset)}
                    primary={i === 0}
                    to={preset.toBusStop.name}
                />
            ))}
        </div>
    );
};

export default JourneyPresets;
