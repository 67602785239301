import * as React from 'react';
import { IBusStopOption, IValue } from './BusStopSelect';
import { normalizeSearchString } from './useBusStopsOptions';

// Memoize options
const useVisibleOptions = (
    filter: string,
    options: IBusStopOption[],
    except?: IValue,
    showAllStops?: boolean
) => {
    return React.useMemo(() => {
        const isExcept = (stop: IBusStopOption) =>
            except && except.type === 'STOP' ? except.id === stop.id : false;

        if (filter.length === 0) {
            return options
                .map(o =>
                    o.stops.length > 0
                        ? {
                              ...o,
                              stops: o.stops.filter(
                                  s =>
                                      (showAllStops || s.hasPriority) &&
                                      !isExcept(s)
                              ),
                          }
                        : o
                )
                .filter(
                    o => o.stops.length > 0 || showAllStops || o.hasPriority
                );
        }

        const sanitizedFilter = normalizeSearchString(
            filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        );
        const needles = sanitizedFilter.split(/\W+/);

        const matches = (searchString: string): boolean => {
            for (const needle of needles) {
                if (searchString.indexOf(needle) === -1) return false;
            }
            return true;
        };

        const f = (opt: IBusStopOption) =>
            ((showAllStops || matches(opt.searchString)) && !isExcept(opt)) ||
            opt.stops.length > 0;

        return options
            .map(o =>
                o.stops.length > 0
                    ? {
                          ...o,
                          stops: o.stops.filter(f),
                      }
                    : o
            )
            .filter(f);
    }, [filter, options, except]);
};

export default useVisibleOptions;
