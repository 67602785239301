import cx from 'classnames';
import * as React from 'react';
import './index.scss';

import Dialog from '../Dialog';

interface IProps {
    active: boolean;
}

const Dropdown = React.forwardRef<
    HTMLDivElement,
    IProps & React.HTMLAttributes<HTMLDivElement>
>(
    (
        {
            active,
            style,
            className,
            ...props
        }: IProps & React.HTMLAttributes<HTMLDivElement>,
        ref
    ) => {
        if (!active) return null;

        return (
            <Dialog
                ref={ref}
                style={{
                    left: '0px',
                    position: 'absolute',
                    top: '40px',
                    ...style,
                }}
            >
                <div className={cx('Dropdown', className)} {...props} />
            </Dialog>
        );
    }
);

export default Dropdown;
