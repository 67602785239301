import './index.scss';

import * as React from 'react';

import { ReactComponent as SVG } from '../assets/svgs/emptybasket.svg';

const Empty = () => (
    <div className="Empty">
        <SVG />
    </div>
);

export default Empty;
