import AppPromo from '@slkit/AppPromo';
import Button from '@slkit/Button';
import Grid, { GridColumn } from '@slkit/Grid';
import { ICON_NAME } from '@slkit/Icon';
import Message from '@slkit/Message';
import MessageText from '@slkit/Message/MessageText';
import MessageTitle from '@slkit/Message/MessageTitle';
import Narrow from '@slkit/Narrow';
import NarrowIconItem from '@slkit/Narrow/NarrowIconItem';
import NarrowSubtitle from '@slkit/Narrow/NarrowSubtitle';
import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import Spacer from '@slkit/Spacer';
import ErrorMessage from 'components/ErrorMessage';
import FormattedText from 'components/FormattedText';
import LoadingIndicator from 'components/LoadingIndicator';
import useCurrentUser from 'components/Login/useCurrentUser';
import { useLogPurchase } from 'components/PurchaseProcess/useCheckoutStep';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useBasketContent from 'lib/hooks/useBasketContent';
import useBasketEntries from 'lib/hooks/useBasketEntries';
import useBuyerInfo from 'lib/hooks/useBuyerInfo';
import useI18n from 'lib/hooks/useI18n';
import useRouter from 'lib/hooks/useRouter';
import * as printJS from 'print-js';
import * as React from 'react';
import { Redirect } from 'react-router';
import useBasketServiceEntries from '../../lib/hooks/useBasketServiceEntries';
import CreateAccount from './CreateAccount';
import ServicesContainer from './ServicesContainer';
import TicketsContainer from './TicketsContainer';
import useBasketState from './useBasketState';

const PurchaseSuccessContainer = () => {
    const {
        match: {
            params: { basketId },
        },
    } = useRouter<{ basketId: string }>();

    const { t, i } = useI18n('purchase_success');

    const { setBasketId, basketId: contextBasketId } = React.useContext(
        ApplicationDataContext
    );
    const { data, error: basketContentError } = useBasketContent(basketId);
    const { entries, loading: basketEntriesLoading } = useBasketEntries(
        basketId
    );
    const { entries: services } = useBasketServiceEntries(basketId);
    const { data: buyerInfoData } = useBuyerInfo(basketId);
    const {
        state: basketState,
        loading: basketStateLoading,
        error: basketStateError,
    } = useBasketState(basketId);

    const currentUser = useCurrentUser();

    const loading = basketEntriesLoading || basketStateLoading;
    const error = basketContentError || basketStateError;

    // Clear context basket id
    // Maybe should be done after payment is handled correctly, instead here
    React.useEffect(() => {
        if (contextBasketId && basketState && basketState.state === 'paid') {
            setBasketId();
        }
    }, [contextBasketId, basketState]);

    const logPurchase = useLogPurchase(basketId);

    if (!basketId) return <Redirect to="/" />;
    if (loading) return <LoadingIndicator />;

    logPurchase();

    let buyerEmail: string = '';
    if (buyerInfoData) {
        buyerEmail = buyerInfoData.basket.buyerInfo.email || '';
    }

    if (error) {
        return (
            <>
                <Narrow>
                    <ErrorMessage error={error} fixed />
                </Narrow>
                <AppPromo />
            </>
        );
    }

    if (!basketState || basketState.state !== 'paid') {
        return (
            <>
                <Narrow>
                    <ErrorMessage
                        error={new Error(t('basket_not_paid_yet'))}
                        fixed
                    />
                </Narrow>
                <AppPromo />
            </>
        );
    }

    const message = () => {
        if (!data) return '';

        let key = 'message-description';
        if (services.length === 0) {
            key = 'message-description-tickets-only';
        } else if (entries.length === 0) {
            key = 'message-description-passes-only';
        } else {
            key = 'message-description';
        }

        return i(t(key), {
            email: buyerEmail,
        });
    };

    // ticktsCount might not be exact number as it is only used for pluralization in title
    const ticketsCount = entries
        ? entries.length === 1 // If there is only one entry, check number of products on that entry
            ? entries[0].products.length
            : entries.length // otherwise return number of entries (0 or 2 or more)
        : 0;

    const printoutUrl = data && data.basket.printoutUrl;
    const insuranceUrl = t('insurance-url', undefined, '');

    return (
        <>
            <Narrow>
                <Message center>
                    <MessageTitle>{t('message-title')}</MessageTitle>
                    <MessageText>
                        <FormattedText>{message()}</FormattedText>
                    </MessageText>
                </Message>
                {services.length > 0 && (
                    <>
                        <NarrowTitle>
                            {t('your-passes', services.length)}
                        </NarrowTitle>
                        <ServicesContainer entries={services} />
                    </>
                )}
                {entries.length > 0 && (
                    <>
                        <NarrowTitle>
                            {t('your-tickets', ticketsCount)}
                        </NarrowTitle>
                        <TicketsContainer entries={entries} />
                        {printoutUrl && (
                            <Grid style={{ marginTop: '16px' }}>
                                <GridColumn isPadded gridWidth={1}>
                                    <a
                                        className="Button Button--secondary Button--download Button--red"
                                        href={printoutUrl}
                                        download
                                        target="_blank"
                                    >
                                        {t('download-tickets-button')}
                                    </a>
                                </GridColumn>
                                <GridColumn gridWidth={1}>
                                    <Button
                                        secondary
                                        red
                                        print
                                        onClick={() => printJS(printoutUrl)}
                                    >
                                        {t('print-tickets-button')}
                                    </Button>
                                </GridColumn>
                            </Grid>
                        )}
                    </>
                )}
                {insuranceUrl != '' && (
                    <>
                        <Spacer />
                        <NarrowSubtitle>{t('insurance-title')}</NarrowSubtitle>
                        <NarrowText>
                            <FormattedText>{t('insurance-info')}</FormattedText>
                        </NarrowText>
                        <Grid>
                            <GridColumn isPadded gridWidth={1}>
                                <a
                                    className="Button Button--secondary Button--insurance Button--red"
                                    href={t('insurance-url')}
                                    target="_blank"
                                >
                                    {t('insurance-action')}
                                </a>
                            </GridColumn>
                            <GridColumn gridWidth={1} />
                        </Grid>
                    </>
                )}
                {!currentUser && (
                    <>
                        <Spacer />
                        <CreateAccount
                            buyerData={
                                buyerInfoData && buyerInfoData.basket.buyerInfo
                            }
                            basketId={basketId}
                        />
                    </>
                )}
                <NarrowIconItem />
                <NarrowText>
                    <FormattedText>{t('recommendation')}</FormattedText>
                </NarrowText>
                <NarrowIconItem iconName={ICON_NAME.PLANT}>
                    <FormattedText>{t('plant-info')}</FormattedText>
                </NarrowIconItem>
                <NarrowIconItem iconName={ICON_NAME.CLOCK}>
                    <FormattedText>{t('clock-info')}</FormattedText>
                </NarrowIconItem>
                <NarrowIconItem iconName={ICON_NAME.BAGGAGE}>
                    <FormattedText>{t('baggage-info')}</FormattedText>
                </NarrowIconItem>
                <NarrowIconItem iconName={ICON_NAME.CANCEL_TICKET} noBorder>
                    <FormattedText>{t('return_product-info')}</FormattedText>
                </NarrowIconItem>
            </Narrow>
            <AppPromo />
        </>
    );
};

export default PurchaseSuccessContainer;
