import * as React from 'react';
import '../Dropdown/index.scss';
import './index.scss';

import InputField from '../InputField';

import Dialog from '../Dialog';

interface IProps {
    active: boolean;
    placeholder: string;
    inputValue: string;
    onInputChange: (value: string) => void;
}

const DropdownSearch = ({
    placeholder,
    active,
    inputValue,
    onInputChange,
    ...props
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
    if (!active) return null;
    return (
        <div
            style={{
                left: '0px',
                position: 'absolute',
                top: '40px',
                zIndex: 10,
            }}
        >
            <Dialog>
                <div className="DropdownSearch__Input">
                    <InputField
                        placeholder={placeholder}
                        autoFocus
                        value={inputValue}
                        onChange={e => onInputChange(e.target.value)}
                    />
                </div>
                <div className="DropdownSearch__Dropdown Dropdown" {...props} />
            </Dialog>
        </div>
    );
};

export default DropdownSearch;
