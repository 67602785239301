import useSeatPlan from 'lib/hooks/useSeatPlan';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import * as React from 'react';
import {
    BusSeatPlanFragment,
    PassengerJourneysFragment,
} from '../../../../graphql';

export interface IJourneyPassengerSeatInfo {
    canChooseSeatNumber: boolean;
    seat: IPassengerSeat;
    seatPlan?: BusSeatPlanFragment;
}

export interface IJourneyPassengerSeatsInfo {
    // Keyed by productId
    [key: string]: IJourneyPassengerSeatInfo;
}

interface IBusSeats {
    loading: boolean;
    error?: Error;
    reload?: () => Promise<any>;
    inbound: IJourneyPassengerSeatsInfo;
    outbound: IJourneyPassengerSeatsInfo;
}

const productSeat = (bus: BusSeatPlanFragment, productId: string) => {
    if (!bus.canChooseSeatNumber) return null;

    for (const seatsRow of bus.floors[0].seatRows) {
        const passengerSeat = find(seatsRow.seats, ['productGuid', productId]);
        if (passengerSeat) {
            return {
                seatNumber: passengerSeat.seatNumber,
                seatPrice: passengerSeat.seatPrice,
                seatRank: passengerSeat.seatRank,
            };
        }
    }

    return null;
};

const useBusSeats = (products: PassengerJourneysFragment[]): IBusSeats => {
    const productIds = React.useMemo(() => products.map(p => p.productId!), [
        products,
    ]);
    const { data, loading, error, reload } = useSeatPlan(productIds);

    const res = React.useMemo(() => {
        const r: IBusSeats = {
            error,
            inbound: {},
            loading,
            outbound: {},
            reload,
        };

        if (loading || error || !data) return r;

        const seatPlan = data.seatPlan;

        // As of FVSD-1395 there can be multiple busses per journey
        // Products can be in any of them

        // Order products prior to handling as later they will be in object
        const orderedProducts = orderBy(products, p => p.fareClass!);

        orderedProducts.forEach(product => {
            if (!product.productId) return;

            const inboundSeatPlan = seatPlan.find(
                sp => sp.isOutbound === false
            );
            const inboundBus =
                inboundSeatPlan &&
                inboundSeatPlan.buses.find(b =>
                    b.floors.find(floor =>
                        floor.seatRows.find(row =>
                            row.seats.find(
                                seat => seat.productGuid === product.productId
                            )
                        )
                    )
                );

            const outboundSeatPlan = seatPlan.find(
                sp => sp.isOutbound === true
            );
            const outboundBus =
                outboundSeatPlan &&
                outboundSeatPlan.buses.find(b =>
                    b.floors.find(floor =>
                        floor.seatRows.find(row =>
                            row.seats.find(
                                seat => seat.productGuid === product.productId
                            )
                        )
                    )
                );

            const basePassenger: IPassengerSeat = {
                passengerName: product.passengerName!,
                productId: product.productId!,
                requestedFareClass: product.fareClass!,
                seatNumber: null,
                seatPrice: null,
                seatRank: null,
                ticketNumber: null,
            };

            if (inboundBus && inboundBus.canChooseSeatNumber) {
                const ticketNumber =
                    product.inboundJourney &&
                    product.inboundJourney.legs &&
                    product.inboundJourney.legs[0] &&
                    product.inboundJourney.legs[0].id!;

                const inboundPassenger: IPassengerSeat = {
                    ...basePassenger,
                    ...productSeat(inboundBus, product.productId!),
                    ticketNumber,
                };

                r.inbound[product.productId] = {
                    canChooseSeatNumber:
                        inboundBus.canChooseSeatNumber || false,
                    seat: inboundPassenger,
                    seatPlan: inboundBus,
                };
            }

            if (outboundBus && outboundBus.canChooseSeatNumber) {
                const ticketNumber =
                    product.outboundJourney &&
                    product.outboundJourney.legs &&
                    product.outboundJourney.legs[0] &&
                    product.outboundJourney.legs[0].id!;
                const outboundPassenger: IPassengerSeat = {
                    ...basePassenger,
                    ...productSeat(outboundBus, product.productId!),
                    ticketNumber,
                };
                r.outbound[product.productId] = {
                    canChooseSeatNumber:
                        outboundBus.canChooseSeatNumber || false,
                    seat: outboundPassenger,
                    seatPlan: outboundBus,
                };
            }
        });

        return r;
    }, [data, products]);

    return res;
};

export default useBusSeats;
