// styles are in global
// import './index.scss';

import cx from 'classnames';
import * as React from 'react';

interface IColumnProps {
    gridWidth?: number;
    hasContentRight?: boolean;
    isPadded?: boolean;
}

export const GridColumn = ({
    gridWidth,
    hasContentRight,
    isPadded,
    ...rest
}: IColumnProps & React.HTMLProps<HTMLDivElement>) => (
    <div
        className={cx('Grid__Column', {
            [`Grid__Column--${gridWidth}`]: !!gridWidth,
            'Grid__Column--contentRight': hasContentRight,
            'Grid__Column--padded': isPadded,
        })}
        {...rest}
    />
);

const Grid = (props: React.HTMLProps<HTMLDivElement>) => (
    <div className="Grid" {...props} />
);

export default Grid;
