import * as React from 'react';
import Counter, { ICounterProps } from '.';

interface IProps extends ICounterProps {
    delay: number;
    onChange: (count: number) => Promise<boolean> | void;
}

const DelayedCounter = ({
    count: iCount,
    delay,
    onChange,
    ...props
}: IProps) => {
    const [count, setCount] = React.useState(iCount);
    const timeoutRef = React.useRef<number>();

    const cancel = () => {
        if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    };

    // When prop changes, update internal state and cancel any update
    React.useEffect(() => {
        setCount(iCount);
        cancel();
    }, [iCount]);

    // Cleanup timeouts when unmounting
    React.useEffect(() => () => cancel(), []);

    // When counter value changes, store it in internal state and trigger timeout to fire later
    const handleChange = (newCount: number) => {
        setCount(newCount);

        cancel();
        timeoutRef.current = window.setTimeout(() => {
            const promise = onChange(newCount);
            if (promise) {
                // If onchange returns promise, wait for that promise
                promise.then(res => {
                    if (!res) {
                        // If promise returns false, revert to value from props
                        setCount(iCount);
                    }
                });
            }
        }, delay);
    };

    return <Counter {...props} count={count} onChange={handleChange} />;
};

export default DelayedCounter;
