import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import InputField from '@slkit/InputField';
import AreaCodeSelect from 'components/AreaCodePicker/AreaCodePicker';
import Flag from 'components/Flag';
import { COUNTRY_ALPHA } from 'components/Flag/CountryAlphaEnum';
import useFocusable from 'lib/hooks/useFocusable';
import usePhoneAreaCodes from 'lib/hooks/usePhoneAreaCodes';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import * as React from 'react';
import { IconArrow } from '@slkit/Icon/IconArrow';

interface IProps {
    areaCode: string;
    error?: string;
    placeholder?: string;
    onAreaCodeChange: (code: IPhoneAreaCode) => void;
}

const PhoneNumber = ({
    areaCode,
    error,
    onAreaCodeChange,
    placeholder,
    ...rest
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
    const { ref, isFocused, fFocus, fBlur } = useFocusable(false);
    const { data } = usePhoneAreaCodes();
    const phoneAreaCodes = React.useMemo(
        () => data && orderBy(data.phoneAreaCodes, ['areaCode']),
        [data]
    );

    const handleSelect = (code: IPhoneAreaCode) => {
        onAreaCodeChange(code);
        fBlur();
    };
    const selectedCountry = find(phoneAreaCodes, ['areaCode', areaCode]);

    const countryAlpha =
        selectedCountry && selectedCountry.countryAlpha2
            ? (selectedCountry.countryAlpha2.toLowerCase() as COUNTRY_ALPHA)
            : undefined;

    return (
        <div className="PhoneNumber">
            <div ref={ref}>
                <button className="PhoneNumber__Selector" onClick={fFocus}>
                    {countryAlpha ? (
                        <Flag
                            countryAlpha2={countryAlpha}
                            className="PhoneNumber__Flag"
                        />
                    ) : (
                        <span className="PhoneNumber__Flag" />
                    )}
                    {areaCode}
                    <span className="PhoneNumber__Arrow">
                        <IconArrow direction="down" />
                    </span>
                </button>
                <AreaCodeSelect
                    active={isFocused}
                    onSelect={handleSelect}
                    phoneAreaCodes={phoneAreaCodes}
                />
            </div>
            <InputField
                className="InputField--phone"
                type="phone"
                name="phone_number"
                id="phone_number"
                placeholder={placeholder}
                error={error}
                {...rest}
            />
        </div>
    );
};

export default PhoneNumber;
