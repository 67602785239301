import * as React from 'react';

import './index.scss';

import { ReactComponent as SVG } from '../assets/svgs/error.svg';

interface IProps {
    children: React.ReactNode;
    title: React.ReactNode;
}

const ErrorPage = ({ children, title }: IProps) => (
    <div className="Error">
        <SVG />
        <h2 className="Error__Title">{title}</h2>
        <p className="Error__Text">{children}</p>
    </div>
);

export default ErrorPage;
