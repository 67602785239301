import cx from 'classnames';
import * as React from 'react';

interface IProps {
    active?: boolean;
    children: React.ReactNode;
    prefix?: React.ReactNode;
    small?: boolean;
    suffix?: React.ReactNode;
}

const GenericTab = ({
    active = false,
    children,
    prefix,
    small = false,
    suffix,
    ...rest
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
    const baseCName = small ? 'TabsSmall' : 'Tabs';
    const tabCName = baseCName + '__Tab';
    return (
        <div
            className={cx(tabCName, {
                [tabCName + '--active']: active,
            })}
            {...rest}
        >
            {prefix && <span className={baseCName + '__Name'}>{prefix}</span>}
            {children}
            {suffix && <span className={baseCName + '__Name'}>{suffix}</span>}
        </div>
    );
};

export default GenericTab;
