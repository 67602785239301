import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import {
    PasswordValidations,
    PasswordValidations_passwordValidations
} from 'queries/__types__/PasswordValidations';
import * as query from 'queries/PasswordValidations.graphql';
import * as React from 'react';

interface IPasswordValidations {
    loading: boolean;
    error?: ApolloError;
    validations?: PasswordValidations_passwordValidations[];
}

const processValidations = (
    validations: PasswordValidations_passwordValidations[]
): PasswordValidations_passwordValidations[] =>
    validations.map(v => ({
        ...v,
        pattern: v.pattern
            .replace('\\S', '.')
            .replace('\\^', '^')
            .replace('\\$', '$'),
    }));

const usePasswordValidations = () => {
    const { apolloClient: client } = React.useContext(ApplicationDataContext);
    const [data, setData] = React.useState<IPasswordValidations>({
        loading: true,
    });

    React.useEffect(() => {
        if (!client) return;
        console.log('query');
        client
            .query<PasswordValidations>({
                query,
            })
            .then(res => {
                setData({
                    loading: false,
                    validations: processValidations(
                        res.data.passwordValidations
                    ),
                });
            })
            .catch(error => {
                setData({
                    error,
                    loading: false,
                });
            });
    }, [client]);

    return data;
};

export default usePasswordValidations;
