import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import Layout from 'modules/application/Layout';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router';

import { useTravelPassQuery } from '../../graphql';

const TravelPassPage = () => {
    const {
        params: { guid },
    } = useRouteMatch<{ guid: string }>()!;
    const { data, error, loading } = useTravelPassQuery({
        variables: { guid },
    });

    const number =
        data &&
        data.travelPassesByGuid &&
        data.travelPassesByGuid[0] &&
        data.travelPassesByGuid[0].number;

    return (
        <Layout>
            <div className="container">
                <div className="Page">
                    {loading && <LoadingIndicator fullscreen />}
                    {number && <Redirect to={`/?pass=${number}`} />}
                    {error && <ErrorMessage error={error} fixed />}
                </div>
            </div>
        </Layout>
    );
};

export default TravelPassPage;
