import cx from 'classnames';
import * as React from 'react';

const PanelBody = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('PanelBody', className)} {...props} />
);

export default PanelBody;
