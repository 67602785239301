import { CurrencyEnum } from '__types__/globalTypes';
import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import * as React from 'react';

import { SeatPlanQuery, useSeatPlanQuery } from '../../../graphql';
import useBasketContent from './useBasketContent';

interface ISeatPlanResult {
    loading: boolean;
    data?: SeatPlanQuery['basket'];
    error?: ApolloError;
    reload?: () => Promise<any>;
}

const useSeatPlan = (productIds: string[]): ISeatPlanResult => {
    const { basketId: id } = React.useContext(ApplicationDataContext);

    const { data: basketData } = useBasketContent();
    const content = basketData && basketData.basket.content;
    const currency = (content && content.currency) || CurrencyEnum.EUR;

    const { data, error, loading, refetch } = useSeatPlanQuery({
        variables: {
            currency,
            id: id!,
            productIds,
        },
        skip: !id || !productIds || productIds.length === 0,
    });

    return { data: data && data.basket, error, loading, reload: refetch };
};

export default useSeatPlan;
