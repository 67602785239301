import { formatDate } from 'lib/helpers';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import * as React from 'react';
import PassengerForm from './PassengerForm';

interface IProps {
    entry: IBasketEntry;
    passengers: IPassengerData[];
    onChange: (id: string, changes: IPassengerDataAttributes) => void;
}

const tripPrefix = (tripData: ITripData) =>
    formatDate(tripData.departureDateTime, 'MMMM Do');

const tripTitle = (tripData: ITripData) =>
    `${tripData.departureBusStop} -> ${tripData.arrivalBusStop}`;

const EntryTitle = ({ entry }: { entry: IBasketEntry }) => (
    <>
        <span className="TabsSmall__Name">
            {tripPrefix(entry.outboundTripData)}
        </span>
        {tripTitle(entry.outboundTripData)}
        {entry.inboundTripData && (
            <>
                <br />
                <span className="TabsSmall__Name">
                    {tripPrefix(entry.inboundTripData)}
                </span>
                {tripTitle(entry.inboundTripData)}
            </>
        )}
    </>
);

const EntryPassengersForm = ({ entry, passengers, onChange }: IProps) => {
    const entryPassengers = passengers.filter(
        p => entry.products.find(product => product.productId === p.id) != null
    );
    return (
        <>
            <div className="TripForm__Title TabsSmall">
                <div className="TabsSmall__Tab TabsSmall__Tab--active TabsSmall__Tab--readonly">
                    <EntryTitle entry={entry} />
                </div>
            </div>
            {entryPassengers.map(
                passenger =>
                    passenger && (
                        <PassengerForm
                            key={passenger.id}
                            onChange={onChange}
                            passenger={passenger}
                        />
                    )
            )}
        </>
    );
};

export default EntryPassengersForm;
