import BasketEntry from '@slkit/BasketEntry';
import PopupContent from '@slkit/Popup/PopupContent';
import PopupFooter from '@slkit/Popup/PopupFooter';
import PopupItem from '@slkit/Popup/PopupItem';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import * as React from 'react';
import BasketServiceEntry, { IBasketServiceEntry } from './BasketServiceEntry';

interface IProps {
    actionTitle?: string;
    onAction?: () => void;
    entries: IBasketEntry[];
    services: IBasketServiceEntry[];
    onEntryDelete: (entry: IBasketEntry) => void;
}

const Basket = ({
    actionTitle,
    entries,
    onAction,
    onEntryDelete,
    services,
}: IProps) => (
    <>
        <PopupContent>
            {entries.map((entry, n) => (
                <PopupItem key={n}>
                    <BasketEntry
                        entry={entry}
                        onDelete={() => onEntryDelete(entry)}
                    />
                </PopupItem>
            ))}
            {services.map((entry, n) => (
                <PopupItem key={n}>
                    <BasketServiceEntry entry={entry} />
                </PopupItem>
            ))}
        </PopupContent>
        {actionTitle && onAction && (
            <PopupFooter>
                <button className="Popup__Button Button" onClick={onAction}>
                    {actionTitle}
                </button>
            </PopupFooter>
        )}
    </>
);

export default Basket;
