import Icon, { ICON_NAME } from '@slkit/Icon';
import * as React from 'react';

interface IProps {
    disabled?: boolean;
    title: string;
}

type SearchFormSwitchProps = IProps & React.HTMLAttributes<HTMLButtonElement>;

const SearchFormSwitch = React.forwardRef<
    HTMLButtonElement,
    SearchFormSwitchProps
>(({ title, ...rest }: SearchFormSwitchProps, ref) => (
    <button
        className="SearchForm__Switch"
        ref={ref}
        tabIndex={-1}
        title={title}
        {...rest}
    >
        <Icon name={ICON_NAME.BTNS_DESTINATION_SWITCH} />
        <span className="visually-hidden">{title}</span>
    </button>
));

export default SearchFormSwitch;
