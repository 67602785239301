import cx from 'classnames';
import * as React from 'react';
import './index.scss';

const Pager = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('Pager', className)} {...props} />
);

export default Pager;
