import cx from 'classnames';
import * as React from 'react';

import './index.scss';

interface IProps {
    active?: boolean;
    children?: React.ReactNode;
}

const Tooltip = ({ active, children }: IProps) => (
    <div className="Tooltip__Wrapper">
        <div className={cx('Tooltip', { 'Tooltip--isActive': active })}>
            {children}
        </div>
    </div>
);

export default Tooltip;
