import Icon from '@slkit/Icon';
import cx from 'classnames';
import { IBusStopOption } from 'components/BusStopSelect/BusStopSelect';
import * as React from 'react';

interface IProps {
    hovered?: boolean;
    item: IBusStopOption;
    onMouseEnter: (option: IBusStopOption) => void;
    onMouseLeave: (option: IBusStopOption) => void;
    onClick: (option: IBusStopOption) => void;
}

const LocationStop = React.memo(
    ({ hovered, item, onMouseEnter, onMouseLeave, onClick }: IProps) => {
        const handlers = React.useMemo(
            () => ({
                onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    onClick(item);
                    e.preventDefault();
                    e.stopPropagation();
                },
                onMouseEnter: () => onMouseEnter(item),
                onMouseLeave: () => onMouseLeave(item),
            }),
            [item, onMouseEnter, onMouseLeave, onClick]
        );

        return (
            <div
                id={item.type + '_' + item.id}
                className={cx('Location__Stop', {
                    'Location__Stop--hovered': hovered,
                })}
                {...handlers}
            >
                <div className="Location__StopContent">
                    <Icon name={item.icon} />
                    <div className="Location__StopLabel">
                        <p className="Location__StopTitle">{item.label}</p>
                        <p className="Location__StopSubtitle">{item.text}</p>
                    </div>
                    {item.tag && (
                        <div className="Location__StopTag">{item.tag}</div>
                    )}
                </div>
            </div>
        );
    }
);

export default LocationStop;
