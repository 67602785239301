import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import useI18n from 'lib/hooks/useI18n';
import useRouter from 'lib/hooks/useRouter';
import findIndex from 'lodash/findIndex';
import * as React from 'react';

import { Link } from 'react-router-dom';
import StepDivider from './StepDivider';

interface IParams {
    step: string;
}

interface IProps {
    showPassengersStep?: boolean;
    showExtraStep?: boolean;
    showPaymentStep?: boolean;
    stepKey?: string;
}

const Steps = ({
    showPassengersStep = true,
    showExtraStep = false,
    showPaymentStep = true,
    stepKey: iStepKey,
}: IProps) => {
    const { match } = useRouter<IParams>();
    const stepKey = iStepKey || match.params.step;
    const { t } = useI18n('purchase_process');

    const steps = React.useMemo(() => {
        const s = [{ title: t('summary-step'), key: 'summary' }];
        if (showPassengersStep) {
            s.push({ title: t('passengers-step'), key: 'passengers' });
        }
        if (showExtraStep) {
            s.push({ title: t('extra-step'), key: 'extra' });
        }
        if (showPaymentStep) {
            s.push({ title: t('payment-step'), key: 'payment' });
        }
        return s;
    }, [showPassengersStep, showExtraStep, showPaymentStep, t]);

    const activeStepIndex = findIndex(steps, item =>
        item ? item.key === stepKey : false
    );

    return (
        <div className="Steps">
            {steps.map((step, stepIndex) => (
                <React.Fragment key={stepIndex}>
                    {stepIndex !== 0 && (
                        <StepDivider isActive={activeStepIndex === stepIndex} />
                    )}
                    <Link
                        to={`/purchase/${step.key}`}
                        style={{
                            cursor:
                                activeStepIndex > stepIndex
                                    ? 'pointer'
                                    : 'default',
                            textDecoration: 'none',
                        }}
                        className={cx('Steps__Item', {
                            'Steps__Item--active':
                                activeStepIndex === stepIndex,
                            'Steps__Item--done': activeStepIndex > stepIndex,
                        })}
                        onClick={e => {
                            if (activeStepIndex < stepIndex) e.preventDefault();
                        }}
                    >
                        <div className="Steps__Check">
                            <Icon name={ICON_NAME.CHECK} />
                        </div>
                        <span className="Steps__Number">{stepIndex + 1}</span>
                        {step.title}
                    </Link>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Steps;
