import TripSmall from '@slkit/TripSmall';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import * as React from 'react';
import { ReactComponent as TIMES } from '../assets/svgs/times.svg';
import './index.scss';

interface IProps {
    entry: IBasketEntry;
    onDelete?: () => void;
    showBasketNumber?: boolean;
    showNumber?: boolean;
}

const BasketEntry = ({
    entry,
    onDelete,
    showBasketNumber,
    showNumber,
}: IProps) => (
    <div className="BasketEntry">
        <div>
            <TripSmall
                tripData={entry.outboundTripData}
                showNumber={showNumber}
                showBasketNumber={showBasketNumber}
            />
            {entry.inboundTripData && (
                <TripSmall
                    tripData={entry.inboundTripData}
                    showNumber={showNumber}
                    showBasketNumber={showBasketNumber}
                    returnTrip
                />
            )}
        </div>
        {onDelete && (
            <div className="BasketEntry__DeleteButton">
                <button onClick={onDelete}>
                    <TIMES />
                </button>
            </div>
        )}
    </div>
);

export default BasketEntry;
