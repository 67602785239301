import { TabType } from '@slkit/Tabs';
import * as cx from 'classnames';
import AppConfirm from 'components/AppConfirm';
import { IBusStopOption, IValue } from 'components/BusStopSelect/BusStopSelect';
import useBusStopsOptions from 'components/BusStopSelect/useBusStopsOptions';
import ErrorMessage from 'components/ErrorMessage';
import { IFareClassesValue } from 'components/FareClassesSelect/FareClassesSelect';
import JourneyPresets from 'components/JourneyPresets';
import { buildVariables } from 'components/SearchResult/helpers';
import {
    IParsedQueryParams,
    serializeParams,
} from 'components/SearchResult/useQueryParams';
import { TTravelPass } from 'components/TravelPassSelectModal';
import Maybe from 'graphql/tsutils/Maybe';
import {
    ApplicationDataContext,
    IApplicationDataContext,
} from 'lib/applicationDataContext';
import { formatDate, joinPath } from 'lib/helpers';
import {
    areStopsForTravelPass,
    travelPassSearchFormValues,
} from 'lib/helpers/travelPassHelpers';
import useCurrentLanguage from 'lib/hooks/useCurrentLanguage';
import { buildSearchString, fbpLogSearch } from 'lib/hooks/useFBPixel';
import useI18n from 'lib/hooks/useI18n';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import * as React from 'react';
import {
    CreateBasketWithProductsMutationVariables,
    TravelPassCategoryEnum,
    useCreateBasketWithProductsMutation,
    useTravelAccountTravelPassesQuery,
} from '../../../graphql';
import TravelPassBadge from './TravelPassBadge';
import TripsSearchForm, { ITripSearchFormOptions } from './TripsSearchForm';
import { useCustomSearchFormStyles } from './useCustomSearchFormStyles';
import { getAvailableDates } from 'lib/helpers/getAvailableDates';

declare global {
    interface Window {
        resetForm?: () => void;
    }
}

export interface ITripSearchFormState {
    availableDates?: string[];
    dateBack: string | null;
    dateThere: string | null;
    fareClasses: IFareClassesValue;
    stopFrom?: IValue;
    stopTo?: IValue;
    travelPassNumber?: Maybe<string>;
}

interface IProps {
    onChange?: (data: IParsedQueryParams, queryString: string) => void;
    onSubmit?: (
        data: IParsedQueryParams,
        queryString: string
    ) => Promise<any> | void;
    initialValue?: IParsedQueryParams;
    options?: ITripSearchFormOptions;
    travelPassNumber?: string | null;
}

const INITIAL_FARE_CLASSES = {
    'BONUS_SCHEME_GROUP.ADULT': 1,
};

const buildState = (
    queryParams: IParsedQueryParams | undefined,
    options: ITripSearchFormOptions | undefined
): ITripSearchFormState => {
    const syncDates = options && options.syncDates;

    if (!queryParams) {
        const date = formatDate(new Date());
        return {
            dateBack: syncDates ? date : null,
            dateThere: date,
            fareClasses: INITIAL_FARE_CLASSES,
        };
    } else {
        const dateThere = formatDate(queryParams.there);
        return {
            dateBack: queryParams.back
                ? formatDate(queryParams.back)
                : syncDates
                ? dateThere
                : null,
            dateThere,
            fareClasses: queryParams.fareClasses,
            stopFrom: queryParams.from,
            stopTo: queryParams.to,
            travelPassNumber: queryParams.travelPassNumber,
        };
    }
};

const ensureValidDates = (
    stops: IBusStopOption[],
    state: ITripSearchFormState
): ITripSearchFormState => {
    const newDates = getAvailableDates(stops, [state.stopFrom, state.stopTo]);

    // If dates would be equal, return former state as no change is needed
    if (isEqual(state.availableDates, newDates)) return state;
    const s = { ...state, availableDates: newDates };

    // If there are no dates, there is no need to check if selected dates are valid
    // so return new state early
    if (!s.availableDates) return s;

    // If available dates should change, ensure that selected dates are available or
    // change them to available dates

    // Only update if value is selected and that selected value is not available
    // Direct mutation of s is ok here, since it was created above
    if (s.dateThere && s.availableDates.indexOf(s.dateThere) === -1) {
        // If date there is not available use first available after current today or null
        const today = formatDate(new Date());
        s.dateThere = s.availableDates[0] || null;
        if (s.dateThere && s.dateThere < today) {
            s.dateThere = s.availableDates.find(d => d > today) || null;
        }
    }
    if (s.dateBack && s.availableDates.indexOf(s.dateBack) === -1) {
        // If date back is not available use last available or null
        s.dateBack = null;
    }

    return s;
};

const TripsSearchFormContainer: React.FunctionComponent<IProps> = ({
    onChange,
    onSubmit,
    options,
    initialValue,
    travelPassNumber: iTravelPassNumber,
}) => {
    const { t } = useI18n('travelpass_select');
    // Stuff that has to do with fast basket creation
    const [createBasket, { error }] = useCreateBasketWithProductsMutation();
    const { basketId, setBasketId } = React.useContext(
        ApplicationDataContext
    ) as IApplicationDataContext;

    const stops = useBusStopsOptions(options && options.stopsTag);
    const [elementId, customStyles] = useCustomSearchFormStyles(
        options && options.color
    );

    const { lang } = useCurrentLanguage();
    const initialState = buildState(initialValue, options);
    const [state, setState] = React.useState<ITripSearchFormState>(
        initialState
    );

    // Make sure initial value is only used if state is undefined
    // null is valid value for local state and should override initial value
    const travelPassNumber =
        state.travelPassNumber === undefined
            ? iTravelPassNumber
            : state.travelPassNumber;

    const { data: travelPassData } = useTravelAccountTravelPassesQuery({
        skip: !travelPassNumber,
        variables: {
            category: TravelPassCategoryEnum.CURRENT,
        },
    });

    const resetForm = React.useCallback(() => setState(initialState), [
        setState,
    ]);

    React.useEffect(() => {
        window.resetForm = resetForm;
        return () => {
            window.resetForm = undefined;
        };
    });

    const handleChange = (key: keyof ITripSearchFormState) => (
        value: any
    ): Promise<boolean> => {
        const update: React.SetStateAction<ITripSearchFormState> = s => {
            let newState = { ...s, [key]: value };
            // If stop is changing, also update available dates
            if (key === 'stopFrom' || key === 'stopTo') {
                newState = ensureValidDates(stops, { ...s, [key]: value });
            }

            // If syncDates is enabled and departure date is changing
            // also change return date. Date sync is only departure -> return
            if (
                options &&
                options.syncDates &&
                newState.dateThere !== s.dateThere
            ) {
                newState.dateBack = newState.dateThere;
            }

            return newState;
        };

        // If there is no travelpass number update directly
        if (!travelPassNumber) {
            setState(update);
            return Promise.resolve(true);
        }

        // Lookup travelpass for travelpass number
        const activeTP =
            travelPassData &&
            travelPassData.me &&
            travelPassData.me.travelPasses.find(
                tp => tp.number === travelPassNumber
            );

        // Do not validate if there is no travelpass or departure date is changing
        if (!activeTP || key === 'dateThere') {
            setState(update);
            return Promise.resolve(true);
        }

        const newStop = value as IValue;

        // If both stops are available for selected travelpass, set new stops
        if (
            key !== 'fareClasses' && // if fareclasses are not chaning
            key !== 'dateBack' && // and date is not changing
            // and stops are valid for TP
            areStopsForTravelPass(
                activeTP,
                key === 'stopFrom' ? newStop : state.stopFrom,
                key === 'stopTo' ? newStop : state.stopTo,
                stops
            )
        ) {
            // Update
            setState(update);
            return Promise.resolve(true);
        } else {
            // Otherwise, prompt user to remove travelpass from search
            const messageKey = (() => {
                switch (key) {
                    case 'fareClasses':
                        return 'cancel_travelpass_search.message.fare-classes';
                    case 'dateBack':
                        return 'cancel_travelpass_search.message.date-back';
                    default:
                        return 'cancel_travelpass_search.message.stops';
                }
            })();

            // otherwise prompt user to disable travelpass
            return AppConfirm(t(messageKey), {
                confirmTitle: t('cancel_travelpass_search.confirm'),
                rejectTitle: t('cancel_travelpass_search.reject'),
                title: t('cancel_travelpass_search.title'),
            }).then(res => {
                if (res) {
                    setState(s => ({
                        ...update(s),
                        travelPassNumber: undefined,
                    }));
                    return true;
                } else {
                    // If stop was not changed, reset value to previously selected stop (from initial value)
                    // or from default value for travelpass
                    if (key === 'stopFrom' || key === 'stopTo') {
                        setState(s => {
                            const values = travelPassSearchFormValues(
                                activeTP,
                                s,
                                stops
                            );
                            return {
                                ...s,
                                stopFrom:
                                    initialState.stopFrom ||
                                    (values && values.stopFrom),
                                stopTo:
                                    initialState.stopTo ||
                                    (values && values.stopTo),
                            };
                        });
                    }
                    return false;
                }
            });
        }
    };

    const handleSwitch = () => {
        setState({
            ...state,
            stopFrom: state.stopTo,
            stopTo: state.stopFrom,
        });
    };

    // When stops change or load, ensure available dates are correct
    React.useEffect(() => {
        setState(s => {
            let newState = { ...s };
            newState = ensureValidDates(stops, s);
            if (options && options.syncDates) {
                newState.dateBack = newState.dateThere;
            }
            return newState;
        });
    }, [stops]);

    React.useEffect(() => {
        // If initial values change, update local state in single update
        if (!initialValue) return;

        // This update needs to be atomic so onChange is not triggered.
        let needUpdate = false;
        const update: { [key: string]: any } = {};

        if (initialValue.from !== state.stopFrom) {
            update.stopFrom = initialValue.from;
            needUpdate = true;
        }
        if (initialValue.to !== state.stopTo) {
            update.stopTo = initialValue.to;
            needUpdate = true;
        }
        if (initialValue.fareClasses !== state.fareClasses) {
            update.fareClasses =
                initialValue.fareClasses || INITIAL_FARE_CLASSES;
            needUpdate = true;
        }
        if (formatDate(initialValue.there) !== state.dateThere) {
            update.dateThere =
                formatDate(initialValue.there) || formatDate(new Date());
            needUpdate = true;
        }
        if (
            initialValue.back &&
            formatDate(initialValue.back) !== state.dateBack
        ) {
            update.dateBack = formatDate(initialValue.back) || null;
            needUpdate = true;
        }

        if (needUpdate) {
            setState({ ...state, ...update });
        }
    }, [initialValue]);

    const canSwitch = !!state.stopFrom && !!state.stopTo;
    const totalTickets = reduce(state.fareClasses, (v, acc) => acc + v, 0);
    const canSubmit =
        !!state.stopFrom &&
        !!state.stopTo &&
        !!state.dateThere &&
        totalTickets > 0;

    const buildData = (
        affiliateId?: string,
        promoCode?: string
    ): [IParsedQueryParams, string] => {
        const data: IParsedQueryParams = {
            affiliateId,
            back: state.dateBack ? formatDate(state.dateBack) : undefined,
            fareClasses: state.fareClasses,
            from: state.stopFrom as IValue,
            lang,
            promoCode,
            there: formatDate(state.dateThere as string),
            to: state.stopTo as IValue,
            travelPassNumber: state.travelPassNumber
                ? state.travelPassNumber
                : undefined,
        };
        const queryString = serializeParams(data);
        return [data, queryString];
    };

    React.useEffect(() => {
        if (!onChange || !canSubmit) return;

        const [data, queryString] = buildData(
            initialValue && initialValue.affiliateId,
            initialValue && initialValue.promoCode
        );

        if (isEqual(initialValue, data)) return;

        onChange(data, queryString);
    }, [state]);

    const handleSubmit = (): Promise<any> | void => {
        const [data, queryString] = buildData(
            initialValue && initialValue.affiliateId,
            initialValue && initialValue.promoCode
        );

        const searchString = buildSearchString(data, stops);

        // If fast basket should be user, do not call onSubmit handler
        // just redirect to target url
        if (options && options.fastBasket) {
            const variables: CreateBasketWithProductsMutationVariables = {
                outboundInput: buildVariables(data, TabType.OUTBOUND).query,
            };

            if (data.back) {
                variables.inboundInput = buildVariables(
                    data,
                    TabType.INBOUND
                ).query;
            }

            if (basketId) {
                variables.basketId = basketId;
            } else {
                variables.createBasketInput = {
                    affiliateId: data.affiliateId,
                    isTravelAccountRelationCreated: true,
                };
            }

            return fbpLogSearch(searchString)
                .then(() =>
                    createBasket({
                        variables,
                    })
                )
                .then(res => {
                    const newBasketId =
                        res.data && res.data.createBasketWithProducts.id;
                    if (newBasketId) {
                        setBasketId(newBasketId);
                        const href = joinPath(
                            options.destinationUrl ||
                                'https://online.slovaklines.sk',
                            '/purchase/summary'
                        );
                        window.location.href = href;
                    }
                });
        }

        if (onSubmit) {
            return fbpLogSearch(searchString).then(() =>
                onSubmit(data, queryString)
            );
        } else {
            fbpLogSearch(searchString).then(() => {
                window.location.href = `/search?${queryString}`;
            });
        }
    };

    const handleSearchByPass = (travelPass?: TTravelPass | null) => {
        if (!travelPass) {
            // Clear travel pass number
            setState(s => ({ ...s, travelPassNumber: null }));
            return;
        }

        setState(s => {
            const values = travelPassSearchFormValues(travelPass, s, stops);
            if (!values) return s;

            return {
                ...s,
                ...values,
                travelPassNumber: travelPass.number!,
            };
        });
    };

    // Protection agains using travel pass that is not available for current user
    React.useEffect(() => {
        if (!travelPassNumber || !travelPassData) return;
        const activeTP =
            travelPassData.me &&
            travelPassData.me.travelPasses.find(
                tp => tp.number === travelPassNumber
            );

        // If there is travelPassNumber and travelPassData is loaded and
        // there is not activeTP, that means, travelPassNumber is not in users travel passes
        if (!activeTP) {
            // In that case, set local state travel pass number to null, to prevent user from using this number
            handleSearchByPass(null);
        } else {
            // If there is travel pass, call handleSearch so stops and other things are preffiled from travel pass
            handleSearchByPass(activeTP);
        }
    }, [travelPassData, travelPassNumber]);

    return (
        <div className={cx('TripsSearchFormContainer', elementId)}>
            {options && options.showTravelPass && (
                <TravelPassBadge
                    number={travelPassNumber}
                    onChange={handleSearchByPass}
                />
            )}
            <TripsSearchForm
                availableDates={state.availableDates}
                canSubmit={canSubmit}
                canSwitch={canSwitch}
                changeOnUpdate={!onChange}
                handleChange={handleChange}
                handleSwitch={handleSwitch}
                options={options}
                onSubmit={handleSubmit}
                state={state}
            />
            {options && options.showJourneyPresets && (
                <JourneyPresets
                    onSelect={preset => {
                        const from: IValue = {
                            id: preset.fromBusStop.id,
                            type: 'STOP',
                        };
                        const to: IValue = {
                            id: preset.toBusStop.id,
                            type: 'STOP',
                        };
                        handleChange('stopFrom')(from);
                        handleChange('stopTo')(to);
                    }}
                />
            )}
            <ErrorMessage noPortal error={error} />
            {customStyles}
        </div>
    );
};

export default TripsSearchFormContainer;
