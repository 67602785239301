import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface IAppPromptComponentProps {
    onCancel: () => void;
    onConfirm: () => void;
}

type AppPromptComponent = (
    props: IAppPromptComponentProps
) => React.ReactElement;

export const appPrompt = (componenent: AppPromptComponent): Promise<boolean> =>
    new Promise(resolve => {
        const wrapper = document.body.appendChild(
            document.createElement('div')
        );

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(wrapper);
            if (wrapper.parentNode !== null) {
                wrapper.parentNode.removeChild(wrapper);
            }
        };

        const onConfirm = () => {
            cleanup();
            resolve(true);
        };

        const onCancel = () => {
            cleanup();
            resolve(false);
        };

        ReactDOM.render(componenent({ onCancel, onConfirm }), wrapper);
    });

export default appPrompt;
