import cx from 'classnames';
import * as React from 'react';
import { COUNTRY_ALPHA } from './CountryAlphaEnum';
import './index.scss';

interface IProps {
    countryAlpha2?: COUNTRY_ALPHA;
    squared?: boolean;
    round?: boolean;
    className?: string;
}

const Flag = ({ countryAlpha2, squared, className, round }: IProps) => (
    <span
        style={
            round
                ? {
                      borderRadius: '50%',
                      height: '1em',
                  }
                : {}
        }
        className={cx('flag-icon', className, {
            [`flag-icon-${countryAlpha2}`]: countryAlpha2,
            'flag-icon-squared': squared || round,
        })}
    />
);

export default Flag;
