import NarrowButton from '@slkit/Narrow/NarrowButton';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import Tabs, { TabType } from '@slkit/Tabs';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { formatPrice, hasValue } from 'lib/helpers';
import useBasketContent from 'lib/hooks/useBasketContent';
import useBasketEntries, { IBasketEntry } from 'lib/hooks/useBasketEntries';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { useHistory } from 'react-router';
import EntrySeatsContainer from '../Seats/EntrySeatsContainer';
import JourneyPicker from '../Seats/JourneyPicker';
import { useCheckoutStep } from '../useCheckoutStep';
import AddonsContainer from './AddonsContainer';

const nextParams = (
    entries: IBasketEntry[],
    idx: number,
    tab: TabType
): [number | undefined, TabType | undefined] => {
    if (entries.length === 0) return [undefined, undefined];

    const entry = entries[idx];
    if (!entry) return [undefined, undefined];

    if (entry.inboundTripData && tab === TabType.OUTBOUND) {
        // If current entry has inbound trip and we are on outbound
        // switch to inbound
        return [idx, TabType.INBOUND];
    } else if (entries.length > idx + 1) {
        // If there is another entry
        // switch to that entry and outbound tab
        return [idx + 1, TabType.OUTBOUND];
    }

    return [undefined, undefined];
};

const ExtraContainer = () => {
    const { t } = useI18n('extra');
    const { t: tt } = useI18n('seats_reservation');
    const history = useHistory();

    const { data: basket } = useBasketContent();
    const { entries, error, loading } = useBasketEntries();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [activeTabState, setActiveTab] = React.useState<TabType>(
        TabType.OUTBOUND
    );

    useCheckoutStep(2);

    const entry = entries[activeIndex] as IBasketEntry | undefined;

    // If activeTabState is inbound but selected entry does not have inbound, force outbound
    // This should never happen, bude just in case
    let activeTab = activeTabState;
    if (activeTab === TabType.INBOUND && !(entry && entry.inboundTripData)) {
        activeTab = TabType.OUTBOUND;
    }

    // Determine what will be displayed when next button is pressed
    // If at the end, entry is undefined, that means we are on last tab
    const [nextIndex, nextTab] = nextParams(entries, activeIndex, activeTab);

    // When changin entry, select outbound tab
    const handleActiveIndexChange = (idx: number) => {
        setActiveIndex(idx);
        setActiveTab(TabType.OUTBOUND);
    };

    const handleNext = () => {
        if (nextIndex !== undefined && nextTab !== undefined) {
            setActiveIndex(nextIndex);
            setActiveTab(nextTab);
            window.scrollTo(0, 0);
        } else {
            history.push('/purchase/payment');
        }
    };

    const actionTitle =
        nextTab === TabType.INBOUND
            ? 'next-button-inbound'
            : nextIndex === undefined
            ? 'next-button-payment'
            : 'next-button-journey';

    if (loading) return <LoadingIndicator />;
    if (error) return <ErrorMessage error={error} />;

    const basketId = basket && basket.basket.id;
    const currency = basket && basket.basket.content.currency;
    const totalPrice = basket && basket.basket.content.totalPrice;

    return (
        <React.Fragment>
            <NarrowTitle
                accessory={
                    hasValue(totalPrice) &&
                    hasValue(currency) && (
                        <span>{formatPrice(totalPrice, currency)}</span>
                    )
                }
            >
                {t('title')}
            </NarrowTitle>

            {entries.length > 1 && (
                <JourneyPicker
                    activeIndex={activeIndex}
                    entries={entries}
                    onSelect={handleActiveIndexChange}
                />
            )}

            {entry && entry.inboundTripData && (
                <Tabs
                    small
                    dateOutbound={entry.outboundTripData.departureDateTime}
                    dateInbound={entry.inboundTripData.departureDateTime}
                    onTabSelect={setActiveTab}
                    activeTab={activeTab}
                />
            )}

            {basketId && currency && entry && (
                <AddonsContainer
                    activeTab={activeTab}
                    basketId={basketId}
                    currency={currency}
                    entry={entry}
                />
            )}

            {entry && (
                <EntrySeatsContainer
                    activeTab={activeTab}
                    currency={basket && basket.basket.content.currency}
                    entry={entry}
                >
                    <NarrowButton onClick={handleNext}>
                        {tt(actionTitle)}
                    </NarrowButton>
                </EntrySeatsContainer>
            )}
        </React.Fragment>
    );
};

export default ExtraContainer;
