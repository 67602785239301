import AddonOption from '@slkit/AddonOption';
import Grid, { GridColumn } from '@slkit/Grid';
import { ICON_NAME } from '@slkit/Icon';
import NarrowSubtitle from '@slkit/Narrow/NarrowSubtitle';
import NarrowText from '@slkit/Narrow/NarrowText';
import { TabType } from '@slkit/Tabs';
import { ApolloError } from 'apollo-client';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useBasketContent from 'lib/hooks/useBasketContent';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import { fbpLogAddToCart } from 'lib/hooks/useFBPixel';
import { gtmLogAddToCart, gtmLogRemoveFromCart } from 'lib/hooks/useGTM';
import useI18n from 'lib/hooks/useI18n';
import useLoading from 'lib/hooks/useLoading';
import keys from 'lodash/keys';
import * as React from 'react';
import {
    BusSeatFragment,
    CurrencyEnum,
    PaymentInfoDocument,
    useChangeReservedSeatMutation,
} from '../../../../graphql';
import BusContainer from './BusContainer';
import Seats from './Seats';
import useBusSeats, {
    IJourneyPassengerSeatInfo,
    IJourneyPassengerSeatsInfo,
} from './useBusSeats';

interface IProps {
    activeTab: TabType;
    currency?: CurrencyEnum | null;
    children?: React.ReactNode;
    entry: IBasketEntry | null;
}

const EntrySeatsContainer = ({
    activeTab,
    children,
    currency,
    entry,
}: IProps) => {
    if (entry === null) return null;

    const [changeReservedSeat] = useChangeReservedSeatMutation();
    const { basketId } = React.useContext(ApplicationDataContext);

    const { t } = useI18n('seats_reservation');
    const [submitError, setSubmitError] = React.useState<ApolloError | null>(
        null
    );
    const [
        selectedPassengerId,
        setSelectedPassengerId,
    ] = React.useState<string>();
    const { loading: stateLoading, beginLoading, endLoading } = useLoading();

    const { reload: reloadBasket } = useBasketContent();
    const {
        loading: seatsLoading,
        error,
        inbound,
        outbound,
        reload,
    } = useBusSeats(entry.products);

    const passengers: IJourneyPassengerSeatsInfo =
        activeTab === TabType.INBOUND ? inbound || {} : outbound || {};

    const passengerIds = keys(passengers);

    const canSelect = passengerIds.reduce(
        (acc, id) => acc || passengers[id].canChooseSeatNumber,
        false
    );

    const selectedPassenger:
        | IJourneyPassengerSeatInfo
        | undefined = selectedPassengerId
        ? passengers[selectedPassengerId]
        : passengers[passengerIds[0]];

    const handleError = (e: ApolloError | null) => {
        endLoading();
        setSubmitError(e);
    };

    const handleSeatSelect = React.useCallback(
        (newSeat: BusSeatFragment) => {
            if (!selectedPassenger) return;
            const seat = selectedPassenger.seat;

            if (
                basketId &&
                seat.ticketNumber &&
                selectedPassenger.seatPlan &&
                selectedPassenger.seatPlan.tripBusProfileId
            ) {
                const newSeatInfo = {
                    seatFloorNumber: 1,
                    seatNumber: newSeat.seatNumber,
                    seatNumberLetter: null,
                    ticketNumber: seat.ticketNumber,
                };

                beginLoading();
                changeReservedSeat!({
                    refetchQueries: [
                        {
                            query: PaymentInfoDocument,
                            variables: { id: basketId },
                        },
                    ],
                    variables: {
                        basketId,
                        reservedSeatRequest: {
                            currencyXc: currency,
                            tripBusProfiles: [
                                {
                                    seatInfo: [newSeatInfo],
                                    tripBusProfileId:
                                        selectedPassenger.seatPlan
                                            .tripBusProfileId,
                                },
                            ],
                        },
                    },
                })
                    .then(res => {
                        console.log('Seat reserved', res);
                        if (res.data?.basket?.changeReservedSeat) {
                            gtmLogRemoveFromCart([seat], 1);
                            gtmLogAddToCart([newSeat], 1);
                            fbpLogAddToCart([newSeat], 1);
                        }
                    })
                    .catch(handleError)
                    .then(() => (reload ? reload() : Promise.resolve()))
                    .then(() =>
                        reloadBasket ? reloadBasket() : Promise.resolve()
                    )
                    .then(endLoading);
            }
        },
        [basketId, currency, passengers, selectedPassenger]
    );

    return (
        <>
            <NarrowSubtitle>{t('title')}</NarrowSubtitle>
            {!seatsLoading && stateLoading && <LoadingIndicator />}
            {seatsLoading ? (
                <LoadingIndicator />
            ) : canSelect ? (
                <>
                    <NarrowText>{t('description')}</NarrowText>
                    <Grid>
                        <GridColumn gridWidth={11}>
                            {error && <ErrorMessage error={error} fixed />}

                            <Seats
                                passengers={passengers}
                                selected={selectedPassenger.seat.productId}
                                setSelected={setSelectedPassengerId}
                            />

                            {submitError && (
                                <ErrorMessage
                                    fixed
                                    error={submitError}
                                    onClose={() => setSubmitError(null)}
                                />
                            )}
                            {children}
                        </GridColumn>
                        <GridColumn gridWidth={8} hasContentRight>
                            {selectedPassenger.seatPlan && (
                                <BusContainer
                                    seatPlan={selectedPassenger.seatPlan}
                                    onSelect={handleSeatSelect}
                                />
                            )}
                        </GridColumn>
                    </Grid>
                </>
            ) : (
                <>
                    <AddonOption
                        title={t('not-available-title')}
                        description={t('not-available-description')}
                        icon={ICON_NAME.NO_SEATS_AVAILABLE}
                        muted
                    />
                    {error && <ErrorMessage error={error} fixed />}
                    {children}
                </>
            )}
        </>
    );
};

export default EntrySeatsContainer;
