import * as cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    as?: string;
    flex?: boolean;
}

const NarrowSubtitle = ({
    as: Component = 'h3',
    flex,
    ...props
}: IProps & React.HTMLAttributes<HTMLHeadingElement>) => (
    <Component
        className={cx('Narrow__Subtitle', {
            'Narrow__Subtitle--flex': flex,
        })}
        {...props}
    />
);

export default NarrowSubtitle;
