import React from 'react';
import cx from 'classnames';
import './index.scss';

interface IOption<T> {
    id: T;
    title: React.ReactNode;
}

interface IProps<T> {
    options: IOption<T>[];
    activeOptionId: T;
    onChange: (id: T, idx: number) => void;
    variant?: 'default' | 'dense';
}

const SegmentedControl = <T extends string>({
    options,
    activeOptionId,
    onChange,
    variant = 'default',
}: IProps<T>) => (
    <div
        className={cx('SegmentedControl', {
            'SegmentedControl--dense': variant === 'dense',
        })}
    >
        {options.map((option, n) => (
            <div
                key={option.id}
                className={cx('SegmentedControl_Option', {
                    'SegmentedControl_Option-active':
                        activeOptionId === option.id,
                })}
                onClick={() => onChange(option.id, n)}
            >
                <div>{option.title}</div>
            </div>
        ))}
    </div>
);

export default SegmentedControl;
