import React from 'react';
import ReactDOM from 'react-dom';

import AppConfirmDialog, { IAppConfirmDialogOptions } from './AppConfirmDialog';

const AppConfirm = (
    message: React.ReactNode,
    options: IAppConfirmDialogOptions
): Promise<boolean> => {
    const wrapper = document.body.appendChild(document.createElement('div'));
    const cleanup = res => {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => wrapper.remove());
        return res;
    };

    const dialogRef = React.createRef<AppConfirmDialog>();

    return new Promise<boolean>(resolve => {
        ReactDOM.render(
            <AppConfirmDialog
                ref={dialogRef}
                message={message}
                options={options}
            />,
            wrapper,
            () => {
                if (
                    !dialogRef ||
                    !dialogRef.current ||
                    !dialogRef.current.promise
                ) {
                    return;
                }

                resolve(dialogRef.current.promise.then(cleanup));
            }
        );
    });
};

export default AppConfirm;
