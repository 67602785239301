import cx from 'classnames';
import { formatDate } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { TabType } from '.';
import './index.scss';

interface IProps {
    tab: TabType;
    date?: string;
    active: boolean;
    small: boolean;
}

const Tab = ({
    tab,
    date,
    active,
    small,
    ...rest
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
    const { t } = useI18n('journey_tab');
    const baseCName = small ? 'TabsSmall' : 'Tabs';
    const tabCName = baseCName + '__Tab';
    const directionText =
        tab === TabType.INBOUND ? t('tab.inbound') : t('tab.outbound');
    const dateText = date ? ' ' + formatDate(date, 'MMMM Do') : null;
    return (
        <div
            className={cx(tabCName, {
                [tabCName + '--active']: active,
            })}
            {...rest}
        >
            <span className={baseCName + '__Name'}>{directionText}</span>
            {dateText}
        </div>
    );
};

export default Tab;
