import { PurchasedTravelPassSelector } from '@slkit/PurchasedTravelPassSelector';
import useCurrentUser from 'components/Login/useCurrentUser';
import TravelPassSelectModal, {
    TTravelPass,
} from 'components/TravelPassSelectModal';
import Maybe from 'graphql/tsutils/Maybe';
import * as React from 'react';

interface IProps {
    number: Maybe<string>;
    onChange: (pass?: TTravelPass) => void;
}

const TravelPassBadge = ({ number, onChange }: IProps) => {
    const u = useCurrentUser();
    const [showModal, setShowModal] = React.useState(false);

    // Do not show travel pass badge if user is not logged in
    if (!u) return null;

    return (
        <>
            <PurchasedTravelPassSelector
                activeTravelPass={number}
                onClear={() => onChange()}
                onSelect={() => setShowModal(true)}
            />
            {showModal && (
                <TravelPassSelectModal
                    onClose={() => setShowModal(false)}
                    onSelect={p => {
                        setShowModal(false);
                        onChange(p);
                    }}
                />
            )}
        </>
    );
};

export default TravelPassBadge;
