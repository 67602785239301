import Empty from '@slkit/Empty';
import PopupContent from '@slkit/Popup/PopupContent';
import PopupHeader from '@slkit/Popup/PopupHeader';
import PopupItem from '@slkit/Popup/PopupItem';
import FormattedText from 'components/FormattedText';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

const EmptyBasket = () => {
    const { t } = useI18n('basket.empty');
    return (
        <>
            <PopupHeader>{t('header')}</PopupHeader>
            <PopupContent>
                <Empty />
                <PopupItem empty>
                    <FormattedText>{t('message')}</FormattedText>
                </PopupItem>
            </PopupContent>
        </>
    );
};

export default EmptyBasket;
