import * as React from 'react';

interface IProps {
    city?: string;
    stop?: string;
}

const StopName = ({ city, stop }: IProps) => {
    if (city && stop) {
        return (
            <>
                <strong>{city}</strong>, {stop}
            </>
        );
    } else {
        return <strong>{city || stop}</strong>;
    }
};

export default StopName;
