import PurchaseSuccessContainer from 'components/PurchaseSuccess/PurchaseSuccessContainer';
import Layout from 'modules/application/Layout';
import * as React from 'react';

const PurchaseSuccessPage = () => (
    <Layout>
        <div className="container">
            <div className="Page">
                <PurchaseSuccessContainer />
            </div>
        </div>
    </Layout>
);

export default PurchaseSuccessPage;
