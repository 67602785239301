import TicketList from '@slkit/TicketList';
import TravelPass from '@slkit/TravelPass';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { useHistory } from 'react-router';

import { IBasketServiceEntry } from '../Basket/BasketServiceEntry';

interface IProps {
    entries: IBasketServiceEntry[];
}
const ServicesContainer = ({ entries }: IProps) => {
    const { t } = useI18n('passengers_info');
    const { t: tb } = useI18n('purchase_success');
    const history = useHistory();

    return (
        <>
            {entries.map((entry, n) => (
                <TicketList key={n}>
                    <TravelPass
                        name={entry.name!}
                        price={entry.price!}
                        from={entry.from}
                        to={entry.to}
                        fareClass={
                            entry.fareClassId ? t(entry.fareClassId) : undefined
                        }
                        onSearch={() => history.push(`/?pass=${entry.number}`)}
                        searchTitle={tb('travelpass-search')}
                    />
                </TicketList>
            ))}
        </>
    );
};

export default ServicesContainer;
