import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Json: any,
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any,
  Date: any,
};

export type AddLegAddonResult = {
   __typename?: 'AddLegAddonResult',
  basket?: Maybe<Basket>,
  tripLegAddons?: Maybe<TripLegsResponse>,
};


export type AddLegAddonResultBasketArgs = {
  id: Scalars['String']
};


export type AddLegAddonResultTripLegAddonsArgs = {
  basketId?: Maybe<Scalars['String']>,
  currency: CurrencyEnum,
  productIds?: Maybe<Array<Scalars['String']>>
};

export type Addon = {
   __typename?: 'Addon',
  id?: Maybe<Scalars['Int']>,
  isOutboundJourney?: Maybe<Scalars['Boolean']>,
  journeyRelation?: Maybe<Scalars['String']>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  unitPrice?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type AddOnInfo = {
   __typename?: 'AddOnInfo',
  code?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  detailsDescription?: Maybe<Scalars['String']>,
  detailsDescriptionIconSvg?: Maybe<Scalars['String']>,
  detailsFullDescription?: Maybe<Scalars['String']>,
  detailsGroupKey?: Maybe<Scalars['String']>,
  detailsGroupPosition?: Maybe<Scalars['Int']>,
  detailsIconSvg?: Maybe<Scalars['String']>,
  detailsIsSingleton?: Maybe<Scalars['Boolean']>,
  detailsKey?: Maybe<Scalars['String']>,
  detailsMaxQuantity?: Maybe<Scalars['Int']>,
  detailsTitle?: Maybe<Scalars['String']>,
  discount?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['String']>,
  journeyRelation?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  unitPrice?: Maybe<Scalars['Float']>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type Address = {
   __typename?: 'Address',
  cityName?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  zipCode?: Maybe<Scalars['String']>,
};

export type AddTravelPassInput = {
  conditionId: Scalars['String'],
  travelServiceId: Scalars['String'],
  zonePairPriceId: Scalars['String'],
};

export type AdyenAmountInput = {
  currency: Scalars['String'],
  value: Scalars['Int'],
};

export type AdyenBrowserInfoInput = {
  acceptHeader?: Maybe<Scalars['String']>,
  userAgent?: Maybe<Scalars['String']>,
};

export enum AdyenChannelEnum {
  ANDROID = 'Android',
  WEB = 'Web',
  IOS = 'iOS'
}

export type AdyenCreateSessionInput = {
  basketId?: Maybe<Scalars['String']>,
  channel: AdyenChannelEnum,
  countryCode: Scalars['String'],
  returnUrl: Scalars['String'],
  shopperLocale: Scalars['String'],
  token: Scalars['String'],
};

export type AdyenMutation = {
   __typename?: 'AdyenMutation',
  /** Use me.addCard instead */
  addCard: AdyenPaymentResponse,
  completeCardPayment: Basket,
  createSession: Scalars['String'],
  /** Use me.deleteSavedRecurringDetail instead */
  deleteSavedRecurringDetail: Scalars['Boolean'],
  payWithApple: AdyenPaymentResponse,
  /** Please note that adyenPaymentInput.type is ignored and defaults to \"scheme\". */
  payWithCard: AdyenPaymentResponse,
  payWithGoogle: AdyenPaymentResponse,
  payWithSavedMethod: AdyenPaymentResponse,
  payWithSavedMethodWithOneClick: AdyenPaymentResponse,
  performCapture: Scalars['String'],
  /** Accepts JSON which is directly passed to Adyen "payments/details" API request's body AND completes payment. */
  submitDetails: AdyenPaymentResponse,
};


export type AdyenMutationAddCardArgs = {
  adyenPaymentInput: AdyenPaymentInput,
  browserInfo?: Maybe<AdyenBrowserInfoInput>
};


export type AdyenMutationCompleteCardPaymentArgs = {
  paymentId: Scalars['String'],
  uri: Scalars['String']
};


export type AdyenMutationCreateSessionArgs = {
  attributes: AdyenCreateSessionInput
};


export type AdyenMutationDeleteSavedRecurringDetailArgs = {
  recurringDetailReference?: Maybe<Scalars['String']>
};


export type AdyenMutationPayWithAppleArgs = {
  adyenPaymentInput: AdyenPaymentTokenInput,
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>
};


export type AdyenMutationPayWithCardArgs = {
  adyenPaymentInput: AdyenPaymentInput,
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>
};


export type AdyenMutationPayWithGoogleArgs = {
  adyenPaymentInput: AdyenPaymentTokenInput,
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>
};


export type AdyenMutationPayWithSavedMethodArgs = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  recurringDetailReference: Scalars['String'],
  returnUrl?: Maybe<Scalars['String']>
};


export type AdyenMutationPayWithSavedMethodWithOneClickArgs = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  encryptedSecurityCode: Scalars['String'],
  recurringDetailReference: Scalars['String'],
  returnUrl?: Maybe<Scalars['String']>
};


export type AdyenMutationPerformCaptureArgs = {
  basketId: Scalars['String'],
  reference: Scalars['String'],
  referenceType: AdyenPaymentMethodReferenceEnum
};


export type AdyenMutationSubmitDetailsArgs = {
  paymentId: Scalars['String'],
  request: Scalars['Json']
};

export type AdyenPaymentInput = {
  allow3ds2?: Maybe<Scalars['Boolean']>,
  channel?: Maybe<AdyenChannelEnum>,
  encryptedCardNumber: Scalars['String'],
  encryptedExpiryMonth: Scalars['String'],
  encryptedExpiryYear: Scalars['String'],
  encryptedSecurityCode: Scalars['String'],
  makeDefault?: Maybe<Scalars['Boolean']>,
  remember?: Maybe<Scalars['Boolean']>,
  returnUrl?: Maybe<Scalars['String']>,
  type: PaymentMethodEnum,
};

export enum AdyenPaymentMethodReferenceEnum {
  PAYLOAD = 'payload',
  PSPREFERENCE = 'pspReference'
}

export type AdyenPaymentMethodsInput = {
  amount: AdyenAmountInput,
  channel?: Maybe<AdyenChannelEnum>,
  countryCode: Scalars['String'],
};

export type AdyenPaymentResponse = {
   __typename?: 'AdyenPaymentResponse',
  captureResult?: Maybe<Scalars['String']>,
  paymentId: Scalars['String'],
  rawResponse: Scalars['Json'],
  redirect?: Maybe<AdyenRedirect>,
  resultCode: AdyenPaymentResultCodeEnum,
};

export enum AdyenPaymentResultCodeEnum {
  AUTHENTICATIONFINISHED = 'AuthenticationFinished',
  AUTHORISED = 'Authorised',
  CANCELLED = 'Cancelled',
  CHALLENGESHOPPER = 'ChallengeShopper',
  ERROR = 'Error',
  IDENTIFYSHOPPER = 'IdentifyShopper',
  PENDING = 'Pending',
  PRESENTTOSHOPPER = 'PresentToShopper',
  RECEIVED = 'Received',
  REDIRECTSHOPPER = 'RedirectShopper',
  REFUSED = 'Refused'
}

export type AdyenPaymentTokenInput = {
  makeDefault?: Maybe<Scalars['Boolean']>,
  remember?: Maybe<Scalars['Boolean']>,
  returnUrl?: Maybe<Scalars['String']>,
  token: Scalars['String'],
};

export type AdyenQuery = {
   __typename?: 'AdyenQuery',
  paymentDetails: Scalars['Json'],
  /** 
 * [DEPRECATED, use adyen.submitDetails instead] Accepts JSON which is directly
   * passed to Adyen "payments/details" API request's body
 **/
  paymentDetailsRaw: Scalars['Json'],
  paymentMethods: Scalars['Json'],
  /** Please use me.savedRecurringDetails instead; Requires logged in user */
  savedRecurringDetails: Array<AdyenRecurringDetail>,
};


export type AdyenQueryPaymentDetailsArgs = {
  payload: Scalars['String']
};


export type AdyenQueryPaymentDetailsRawArgs = {
  request: Scalars['Json']
};


export type AdyenQueryPaymentMethodsArgs = {
  input: AdyenPaymentMethodsInput
};


export type AdyenQuerySavedRecurringDetailsArgs = {
  recurring?: Maybe<AdyenRecurringEnum>
};

export type AdyenRecurringDetail = {
   __typename?: 'AdyenRecurringDetail',
  additionalData?: Maybe<Scalars['Json']>,
  alias?: Maybe<Scalars['String']>,
  aliasType?: Maybe<Scalars['String']>,
  card?: Maybe<Card>,
  contractTypes?: Maybe<Array<AdyenRecurringEnum>>,
  creationDate?: Maybe<Scalars['ISO8601DateTime']>,
  firstPspReference?: Maybe<Scalars['String']>,
  isDefault: Scalars['Boolean'],
  paymentMethodVariant?: Maybe<Scalars['String']>,
  recurringDetailReference: Scalars['String'],
  shopperReference?: Maybe<Scalars['String']>,
  variant?: Maybe<Scalars['String']>,
};

export enum AdyenRecurringEnum {
  ONECLICK = 'ONECLICK',
  RECURRING = 'RECURRING'
}

export type AdyenRedirect = {
   __typename?: 'AdyenRedirect',
  data?: Maybe<Scalars['Json']>,
  httpMethod: AdyenRedirectMethodEnum,
  url: Scalars['String'],
};

export enum AdyenRedirectMethodEnum {
  GET = 'GET',
  POST = 'POST'
}

export type Alias = {
   __typename?: 'Alias',
  alias: Scalars['String'],
};

export type AppleMutation = {
   __typename?: 'AppleMutation',
  startSession: Scalars['Json'],
};


export type AppleMutationStartSessionArgs = {
  validationUrl: Scalars['String']
};

export type Basket = {
   __typename?: 'Basket',
  buyerInfo: BuyerInfo,
  /** This will be deprecated. Please use query `me { isConnectedToClientContract }` instead. */
  checkCorporateClientContractTravelAccount: Scalars['Boolean'],
  content: BasketContent,
  customerInfoResponse: CustomerInfoResponse,
  id: Scalars['String'],
  legacyTickets: Array<LegacyTicket>,
  number: Scalars['String'],
  /** Deprecated */
  passengersDataFields: Array<PassengerDataField>,
  paymentInfo: PaymentInfo,
  printoutUrl: Scalars['String'],
  productGroupResponse: ProductGroupResponse,
  productsResponse: ProductsResponse,
  seatPlan: Array<SeatPlanLeg>,
  state: BasketState,
  travelPassesByGuid: Array<TravelPassByGuid>,
  validUntil: Scalars['String'],
  validateSeats: Array<PaxCategoryNotSeatedTogetherButShould>,
};


export type BasketCheckCorporateClientContractTravelAccountArgs = {
  currency?: Maybe<CurrencyEnum>
};


export type BasketCustomerInfoResponseArgs = {
  productId: Scalars['String']
};


export type BasketPassengersDataFieldsArgs = {
  productId: Scalars['String']
};


export type BasketProductsResponseArgs = {
  currency?: Maybe<CurrencyEnum>
};


export type BasketSeatPlanArgs = {
  currency: CurrencyEnum,
  productIds?: Maybe<Array<Scalars['String']>>
};


export type BasketValidateSeatsArgs = {
  ticketsToIgnore?: Maybe<Array<Scalars['String']>>
};

export type BasketAddon = {
   __typename?: 'BasketAddon',
  addonName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  unitPrice?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type BasketContent = {
   __typename?: 'BasketContent',
  currency?: Maybe<CurrencyEnum>,
  passengers?: Maybe<Array<PassengerJourneys>>,
  services?: Maybe<Array<ServiceInfo>>,
  shoppingBasketNumber?: Maybe<Scalars['String']>,
  totalPrice?: Maybe<Scalars['Float']>,
  travelPasses?: Maybe<Array<BasketTravelPass>>,
};

export type BasketIdByCodeInput = {
  email?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  shoppingBasketCode: Scalars['String'],
};

export type BasketLevelAddon = {
   __typename?: 'BasketLevelAddon',
  finalPrice?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['Int']>,
  isSmsService?: Maybe<Scalars['Boolean']>,
  quantity?: Maybe<Scalars['Int']>,
  serviceName?: Maybe<Scalars['String']>,
  unitPrice?: Maybe<Scalars['Float']>,
};

export type BasketMutation = {
   __typename?: 'BasketMutation',
  addCampaignCode: Scalars['Boolean'],
  addJourney: Scalars['Boolean'],
  addLegAddons: AddLegAddonResult,
  /** Works when current user is logged-in */
  addTravelAccount: Scalars['Boolean'],
  /** Travel account must be logged in to be able to add travel pass to basket. */
  addTravelPass: Scalars['Boolean'],
  changeReservedSeat: Scalars['Boolean'],
  delete: Scalars['Boolean'],
  deletePayment: Scalars['Boolean'],
  deleteProduct: Scalars['Boolean'],
  deleteProducts: Scalars['Boolean'],
  devPay: Scalars['Boolean'],
  /** Calls UpdateCorporateClientPrice & PayWithCorporateClientContract */
  payWithCorporateClientContract: Scalars['Boolean'],
  /** Works when current user is logged-in */
  payWithCorporateClientContractTravelAccount: Scalars['Boolean'],
  payWithVoucher: PayWithVoucherResult,
  payWithZeroPayment: Scalars['Boolean'],
  returnCalculate: ReturnCalculate,
  sendToEmail: Scalars['Boolean'],
  startExternalPayment: Scalars['Boolean'],
  updateBuyerInfo: Scalars['Boolean'],
  updateCorporateClientPrice: Scalars['Boolean'],
  updateCustomerInfo: Scalars['Boolean'],
};


export type BasketMutationAddCampaignCodeArgs = {
  campaignCodeInput: CampaignCodeInput
};


export type BasketMutationAddJourneyArgs = {
  journeysInput: JourneysInput
};


export type BasketMutationAddLegAddonsArgs = {
  addons: Array<LegAddonInput>,
  productId: Scalars['String']
};


export type BasketMutationAddTravelPassArgs = {
  addTravelPassInput: Array<AddTravelPassInput>
};


export type BasketMutationChangeReservedSeatArgs = {
  reservedSeatRequest: ReservedSeatRequestInput
};


export type BasketMutationDeletePaymentArgs = {
  paymentId: Scalars['String']
};


export type BasketMutationDeleteProductArgs = {
  productId: Scalars['String']
};


export type BasketMutationDeleteProductsArgs = {
  deleteProductsInput: DeleteProductsInput
};


export type BasketMutationPayWithCorporateClientContractArgs = {
  identification?: Maybe<Scalars['String']>,
  input: CorporateClientContractInput
};


export type BasketMutationPayWithCorporateClientContractTravelAccountArgs = {
  identification?: Maybe<Scalars['String']>
};


export type BasketMutationPayWithVoucherArgs = {
  voucherInput: VoucherInput
};


export type BasketMutationPayWithZeroPaymentArgs = {
  currency: CurrencyEnum
};


export type BasketMutationReturnCalculateArgs = {
  input: ReturnCalculateInput
};


export type BasketMutationUpdateBuyerInfoArgs = {
  buyerInfoInput: BuyerInfoInput
};


export type BasketMutationUpdateCorporateClientPriceArgs = {
  input: CorporateClientPriceInput
};


export type BasketMutationUpdateCustomerInfoArgs = {
  customerInfoInput: CustomerInfoInput,
  productId: Scalars['String']
};

export type BasketSalesFee = {
   __typename?: 'BasketSalesFee',
  price?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type BasketState = {
   __typename?: 'BasketState',
  isRelatedToTravelPass?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
};

export type BasketTravelPass = {
   __typename?: 'BasketTravelPass',
  travelPassNumber?: Maybe<Scalars['String']>,
};

export type Brand = {
   __typename?: 'Brand',
  name?: Maybe<Scalars['String']>,
};

export type BusEquipment = {
   __typename?: 'BusEquipment',
  code?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  displayName?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type BusLine = {
   __typename?: 'BusLine',
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
};

export type BusStop = {
   __typename?: 'BusStop',
  address?: Maybe<Address>,
  aliases: Array<Alias>,
  availableDates?: Maybe<Array<Scalars['Date']>>,
  busStopExternalCode?: Maybe<Scalars['String']>,
  code: Scalars['String'],
  comment?: Maybe<Scalars['String']>,
  coordinates?: Maybe<Coordinates>,
  countyName?: Maybe<Scalars['String']>,
  hasPriority?: Maybe<Scalars['Boolean']>,
  hasValidTrips: Scalars['Boolean'],
  icon?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  isAirportBusStop?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  relatedStops?: Maybe<Array<BusStop>>,
  stopType: StopTypeEnum,
  tags: Array<Scalars['String']>,
};

export type BusStopsInput = {
  excludeTag?: Maybe<Scalars['String']>,
  onlyWithValidTrips: Scalars['Boolean'],
  tag?: Maybe<Scalars['String']>,
};

export type BuyerInfo = {
   __typename?: 'BuyerInfo',
  city?: Maybe<Scalars['String']>,
  companyName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  cultureXc?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderEnum>,
  lastName?: Maybe<Scalars['String']>,
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetHouseNo?: Maybe<Scalars['String']>,
};

export type BuyerInfoInput = {
  city?: Maybe<Scalars['String']>,
  companyName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  cultureXc?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderEnum>,
  lastName?: Maybe<Scalars['String']>,
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetHouseNo?: Maybe<Scalars['String']>,
};

export type CampaignCodeInput = {
  campaignCode: Scalars['String'],
};

export type Card = {
   __typename?: 'Card',
  expiryMonth: Scalars['Int'],
  expiryYear: Scalars['Int'],
  holderName?: Maybe<Scalars['String']>,
  number: Scalars['String'],
};

export type CardPurchase = {
   __typename?: 'CardPurchase',
  cardNumber?: Maybe<Scalars['String']>,
  cardUsers: Array<CardUser>,
};

export type CardUser = {
   __typename?: 'CardUser',
  cardUser?: Maybe<Scalars['String']>,
  purchases: Array<Purchase>,
};

export type ChangePasswordInput = {
  password: Scalars['String'],
  resetHash: Scalars['String'],
};

export type City = {
   __typename?: 'City',
  busStops: Array<BusStop>,
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Condition = {
   __typename?: 'Condition',
  allowedDepartureTimeIntervals: Array<TimeInterval>,
  allowedForDeparturesOnFriday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnMonday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSaturday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSunday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnThursday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnTuesday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnWednesday?: Maybe<Scalars['Boolean']>,
  conditionId?: Maybe<Scalars['String']>,
  currency?: Maybe<CurrencyEnum>,
  passengerType?: Maybe<Scalars['String']>,
  passengerTypeId?: Maybe<Scalars['String']>,
  zonePairPrices: Array<ZonePairPrice>,
};

export type Coordinates = {
   __typename?: 'Coordinates',
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
};

export type CorporateClientContractInput = {
  currency?: Maybe<CurrencyEnum>,
  password: Scalars['String'],
  userName: Scalars['String'],
};

export type CorporateClientHistory = {
   __typename?: 'CorporateClientHistory',
  cardPurchases: Array<CardPurchase>,
  contractPurchases: Array<Purchase>,
};

export type CorporateClientHistoryInput = {
  journeyDepartureDateFrom?: Maybe<Scalars['String']>,
  journeyDepartureDateTo?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  purchaseDateFrom: Scalars['String'],
  purchaseDateTo?: Maybe<Scalars['String']>,
  username: Scalars['String'],
};

export type CorporateClientPriceInput = {
  loyalityLevel?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  username: Scalars['String'],
};

export type CountryWithCities = {
   __typename?: 'CountryWithCities',
  cities: Array<City>,
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Coupon = {
   __typename?: 'Coupon',
  busLineNumber?: Maybe<Scalars['String']>,
  couponNumber?: Maybe<Scalars['String']>,
  departureStop?: Maybe<Scalars['String']>,
  departureTime?: Maybe<Scalars['ISO8601DateTime']>,
  destinationStop?: Maybe<Scalars['String']>,
  discount?: Maybe<Scalars['String']>,
  passengerName?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  returnedAmount?: Maybe<Scalars['Float']>,
  seatNumber?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type CreateBasketInput = {
  affiliateId?: Maybe<Scalars['String']>,
  isTravelAccountRelationCreated: Scalars['Boolean'],
};

export enum CurrencyEnum {
  EUR = 'EUR'
}

export type CustomerInfo = {
   __typename?: 'CustomerInfo',
  citizenshipId?: Maybe<Scalars['Int']>,
  comment?: Maybe<Scalars['String']>,
  corporateCard?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['String']>,
  documentNumber?: Maybe<Scalars['String']>,
  documentType?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  isVisaChecked?: Maybe<Scalars['Boolean']>,
  loyalityCardNumber?: Maybe<Scalars['String']>,
  nationalityId?: Maybe<Scalars['Int']>,
  passengerFatherName?: Maybe<Scalars['String']>,
  passengerFirstName?: Maybe<Scalars['String']>,
  passengerLastName?: Maybe<Scalars['String']>,
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  postalCodeCountryId?: Maybe<Scalars['Int']>,
};

export type CustomerInfoInput = {
  citizenshipId?: Maybe<Scalars['Int']>,
  comment?: Maybe<Scalars['String']>,
  corporateCard?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['String']>,
  documentNumber?: Maybe<Scalars['String']>,
  documentType?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emergencyContact?: Maybe<EmergencyContactInput>,
  gender?: Maybe<GenderEnum>,
  isVisaChecked?: Maybe<Scalars['Boolean']>,
  loyalityCardNumber?: Maybe<Scalars['String']>,
  nationalityId?: Maybe<Scalars['Int']>,
  passengerFatherName?: Maybe<Scalars['String']>,
  passengerFirstName?: Maybe<Scalars['String']>,
  passengerLastName?: Maybe<Scalars['String']>,
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  postalCodeCountryId?: Maybe<Scalars['Int']>,
};

export type CustomerInfoResponse = {
   __typename?: 'CustomerInfoResponse',
  customer?: Maybe<CustomerInfo>,
  emergencyContact?: Maybe<EmergencyContact>,
};


export type DeleteProductsInput = {
  productIds: Array<Scalars['String']>,
};

export type EmergencyContact = {
   __typename?: 'EmergencyContact',
  additionalPhoneNumber?: Maybe<PhoneNumber>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<PhoneNumber>,
  relationshipType?: Maybe<Scalars['String']>,
};

export type EmergencyContactInput = {
  additionalPhoneNumber?: Maybe<PhoneNumberInput>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<PhoneNumberInput>,
  relationshipType?: Maybe<Scalars['String']>,
};

export type Equipment = {
   __typename?: 'Equipment',
  code?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type FareClassInput = {
  count: Scalars['Int'],
  id: Scalars['String'],
};

export type FareClassItem = {
   __typename?: 'FareClassItem',
  comment?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  name: Scalars['String'],
};

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type Insurance = {
   __typename?: 'Insurance',
  name: Scalars['String'],
  price: Scalars['Float'],
  vatRate: Scalars['Float'],
};

export type InsuranceInfo = {
   __typename?: 'InsuranceInfo',
  code?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  unitPrice?: Maybe<Scalars['Float']>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type IntermediateStop = {
   __typename?: 'IntermediateStop',
  arrivalDateTime?: Maybe<Scalars['ISO8601DateTime']>,
  arrivalDateTimeZone?: Maybe<Scalars['String']>,
  departureDateTime?: Maybe<Scalars['ISO8601DateTime']>,
  departureDateTimeZone?: Maybe<Scalars['String']>,
  isRequestDropOffStop?: Maybe<Scalars['Boolean']>,
  isRequestPickUpStop?: Maybe<Scalars['Boolean']>,
  isTransfer?: Maybe<Scalars['Boolean']>,
  platform?: Maybe<Scalars['String']>,
  stopLocation?: Maybe<StopLocation>,
  stopName?: Maybe<Scalars['String']>,
  stopsOnSignalOnly?: Maybe<Scalars['Boolean']>,
  zone?: Maybe<Scalars['String']>,
};


export type Journey = {
   __typename?: 'Journey',
  arrivalDateTime: Scalars['ISO8601DateTime'],
  arrivalDateTimeTimeZone: Scalars['String'],
  availableBusinessCampaignSeats?: Maybe<Scalars['Int']>,
  availableBusinessClassSeats?: Maybe<Scalars['Int']>,
  availableCampaignSeats?: Maybe<Scalars['Int']>,
  availableRegularSeats: Scalars['Int'],
  businessBusPrice?: Maybe<Scalars['Float']>,
  businessCampaignPrice?: Maybe<Scalars['Float']>,
  businessClassPrice?: Maybe<Scalars['Float']>,
  campaignPrice?: Maybe<Scalars['Float']>,
  currency: CurrencyEnum,
  departureDateTime: Scalars['ISO8601DateTime'],
  departureDateTimeTimeZone: Scalars['String'],
  destinationCityName: Scalars['String'],
  destinationStopId: Scalars['Int'],
  destinationStopName: Scalars['String'],
  duration?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  isAllergicPassengerOnBus?: Maybe<Scalars['Boolean']>,
  isChangeable?: Maybe<Scalars['Boolean']>,
  isForSale?: Maybe<Scalars['Boolean']>,
  isPetOnBus?: Maybe<Scalars['Boolean']>,
  isRefundable?: Maybe<Scalars['Boolean']>,
  isReinforcement: Scalars['Boolean'],
  isWheelchairAreaAvailable?: Maybe<Scalars['Boolean']>,
  legs: Array<Leg>,
  originCityName: Scalars['String'],
  originStopId: Scalars['Int'],
  originStopName: Scalars['String'],
  plannedArrivalDateTime: Scalars['ISO8601DateTime'],
  plannedDepartureDateTime: Scalars['ISO8601DateTime'],
  priceClasses: Array<PriceClassItem>,
  regularBusPrice?: Maybe<Scalars['Float']>,
  regularPrice?: Maybe<Scalars['Float']>,
  salesFees?: Maybe<SalesFee>,
  soldLaterBusinessCampaignSeats?: Maybe<Scalars['Int']>,
  soldLaterBusinessClassSeats?: Maybe<Scalars['Int']>,
  soldLaterCampaignSeats?: Maybe<Scalars['Int']>,
  soldLaterRegularSeats: Scalars['Int'],
  soldOutWithinLegTripGroup: Scalars['Boolean'],
  source: JourneySourceEnum,
  timetablePdfUrl?: Maybe<Scalars['String']>,
  tripLegGroupId: Scalars['String'],
};

export enum JourneyDirectionEnum {
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound'
}

export type JourneyInfo = {
   __typename?: 'JourneyInfo',
  addOns?: Maybe<Array<AddOnInfo>>,
  legs?: Maybe<Array<LegInfo>>,
  totalPrice?: Maybe<Scalars['Float']>,
};

export type JourneyInput = {
  journeyId: Scalars['String'],
  priceClass: PriceClassEnum,
};

export type JourneyLeg = {
   __typename?: 'JourneyLeg',
  arrivalDateTime: Scalars['String'],
  brandName?: Maybe<Scalars['String']>,
  busCompanyName: Scalars['String'],
  busLineNumber?: Maybe<Scalars['String']>,
  departureDateTime: Scalars['String'],
  departureStopName: Scalars['String'],
  destinationStopName: Scalars['String'],
  isBusinessClass: Scalars['Boolean'],
  orderNumber: Scalars['Int'],
  price: Scalars['Float'],
  seatInfo: Array<SeatInfo>,
  seatSelectionFee?: Maybe<SeatSelectionFee>,
  ticketNumber: Scalars['String'],
  vatRate: Scalars['Float'],
};

export type JourneyPreset = {
   __typename?: 'JourneyPreset',
  fromBusStop: BusStop,
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  position: Scalars['Int'],
  toBusStop: BusStop,
};

export type JourneyProduct = {
   __typename?: 'JourneyProduct',
  addons: Array<Addon>,
  cancellationInsurance?: Maybe<Insurance>,
  inboundJourney?: Maybe<JourneyWay>,
  modificationInsurance?: Maybe<Insurance>,
  outboundJourney: JourneyWay,
  passengerName?: Maybe<Scalars['String']>,
  productId: Scalars['String'],
  requestedFareClass: Scalars['String'],
  salesFee?: Maybe<SalesFee>,
  totalPrice: Scalars['Float'],
  travelInsurance?: Maybe<TravelInsurance>,
  vatAmount: Scalars['Float'],
};

export enum JourneyRouteEnum {
  FULL = 'FULL',
  USED = 'USED'
}

export type JourneySearchResponse = {
   __typename?: 'JourneySearchResponse',
  journeys: Array<Journey>,
  responseValidUntilUtc: Scalars['ISO8601DateTime'],
  resultsSource?: Maybe<JourneySourceEnum>,
};

export type JourneysInput = {
  inboundJourney?: Maybe<JourneyInput>,
  outboundJourney?: Maybe<JourneyInput>,
};

export enum JourneySourceEnum {
  CRWS = 'CRWS',
  TURNIT = 'TURNIT'
}

export type JourneysSearchInput = {
  checkPaxSoldTogetherRules?: Maybe<Scalars['Boolean']>,
  currency: CurrencyEnum,
  departureDate: Scalars['Date'],
  destinationBusStopId?: Maybe<Scalars['Int']>,
  destinationCityId?: Maybe<Scalars['Int']>,
  fareClasses: Array<FareClassInput>,
  includeBusPrice?: Maybe<Scalars['Boolean']>,
  isAllergic?: Maybe<Scalars['Boolean']>,
  isPartOfRoundTrip?: Maybe<Scalars['Boolean']>,
  isWheelchair?: Maybe<Scalars['Boolean']>,
  locale?: Maybe<Scalars['String']>,
  originBusStopId?: Maybe<Scalars['Int']>,
  originCityId?: Maybe<Scalars['Int']>,
  promoCode?: Maybe<Scalars['String']>,
  travelPassNumber?: Maybe<Scalars['String']>,
};

export type JourneyWay = {
   __typename?: 'JourneyWay',
  legs: Array<JourneyLeg>,
  totalPrice: Scalars['Float'],
  vatAmount: Scalars['Float'],
};


export type Leg = {
   __typename?: 'Leg',
  availableEquipment?: Maybe<Array<Equipment>>,
  brandName?: Maybe<Scalars['String']>,
  brandShortName?: Maybe<Scalars['String']>,
  busCompanyName: Scalars['String'],
  isReinforcement: Scalars['Boolean'],
  lineIdentifier1?: Maybe<LineIdentifier>,
  lineIdentifier2?: Maybe<LineIdentifier>,
  lineIdentifier3?: Maybe<LineIdentifier>,
  orderNumber: Scalars['Int'],
  passengers: Array<Passenger>,
};

export type LegacyTicket = {
   __typename?: 'LegacyTicket',
  arrivalDateTime: Scalars['ISO8601DateTime'],
  basketCode: Scalars['String'],
  basketId: Scalars['String'],
  brandName?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['ISO8601DateTime']>,
  departureDateTime: Scalars['ISO8601DateTime'],
  direction: Scalars['Int'],
  email?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  isCorporateClientContractPurchase?: Maybe<Scalars['Boolean']>,
  isReinforcement: Scalars['Boolean'],
  journey: LegacyTicketJourney,
  legAddOns: Array<LegacyTicketLegAddOn>,
  pdfUrl: Scalars['String'],
  pkpassUrl: Scalars['String'],
  platform?: Maybe<Scalars['String']>,
  productCode: Scalars['String'],
  qrCodeImage: Scalars['String'],
  seatNumber?: Maybe<Scalars['String']>,
  tariff: LegacyTicketTariff,
};

export type LegacyTicketJourney = {
   __typename?: 'LegacyTicketJourney',
  stopFrom: LegacyTicketStop,
  stopTo: LegacyTicketStop,
};

export type LegacyTicketLegAddOn = {
   __typename?: 'LegacyTicketLegAddOn',
  description?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type LegacyTicketStop = {
   __typename?: 'LegacyTicketStop',
  city: Scalars['String'],
  code: Scalars['String'],
  coordinates: Coordinates,
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type LegacyTicketTariff = {
   __typename?: 'LegacyTicketTariff',
  name: Scalars['String'],
  price: Scalars['Float'],
};

export type LegAddonInput = {
  addonCode?: Maybe<Scalars['Int']>,
  addonId?: Maybe<Scalars['String']>,
  journeyDirection?: Maybe<JourneyDirectionEnum>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type LegInfo = {
   __typename?: 'LegInfo',
  actualArrivalDateTime?: Maybe<Scalars['ISO8601DateTime']>,
  actualDepartureDateTime?: Maybe<Scalars['ISO8601DateTime']>,
  addOns: Array<AddOnInfo>,
  arrivalDateTime?: Maybe<Scalars['ISO8601DateTime']>,
  basicDiscountName?: Maybe<Scalars['String']>,
  brandName?: Maybe<Scalars['String']>,
  busCompanyName?: Maybe<Scalars['String']>,
  busLineNumber?: Maybe<Scalars['String']>,
  departureDateTime?: Maybe<Scalars['ISO8601DateTime']>,
  departurePlatform?: Maybe<Scalars['String']>,
  departureStopCityName?: Maybe<Scalars['String']>,
  departureStopId?: Maybe<Scalars['Int']>,
  departureStopName?: Maybe<Scalars['String']>,
  destinationStopCityName?: Maybe<Scalars['String']>,
  destinationStopId?: Maybe<Scalars['Int']>,
  destinationStopName?: Maybe<Scalars['String']>,
  discount?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['String']>,
  isChoosingSeatEnabled?: Maybe<Scalars['Boolean']>,
  isReinforcement: Scalars['Boolean'],
  orderNumber?: Maybe<Scalars['Int']>,
  plannedArrivalDateTimeToDepartureStop?: Maybe<Scalars['ISO8601DateTime']>,
  price?: Maybe<Scalars['Float']>,
  productCode?: Maybe<Scalars['String']>,
  productId?: Maybe<Scalars['String']>,
  qrCodeUrl?: Maybe<Scalars['String']>,
  seats: Array<Seat>,
  subContractorName?: Maybe<Scalars['String']>,
  tripLegGroupId: Scalars['String'],
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type LegServices = {
   __typename?: 'LegServices',
  addOns: Array<ServiceWithSalesLimit>,
  isOutbound?: Maybe<Scalars['Boolean']>,
  legOrderNo?: Maybe<Scalars['Int']>,
  productId?: Maybe<Scalars['String']>,
};

export type LineIdentifier = {
   __typename?: 'LineIdentifier',
  name?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type LoginResult = {
   __typename?: 'LoginResult',
  accessToken: Scalars['String'],
  refreshToken: Scalars['String'],
  success: Scalars['Boolean'],
};

export type MeMutation = {
   __typename?: 'MeMutation',
  addCard: AdyenPaymentResponse,
  changePassword: Scalars['Boolean'],
  /** 
 * Careful! This is destructive and irreversible operation:
   *   1. Deletes Turnit Travel Account.
   *   2. Disables all Adyen recurring details.
   *   3. Creates new Delete User Request to be resolved in admin sections.
 **/
  delete: Scalars['Boolean'],
  deleteSavedRecurringDetail: Scalars['Boolean'],
  /** Issues short-term JWT token to be used with login API endpoint */
  issueOneTimeJwt: Scalars['String'],
  update: Scalars['Boolean'],
  updateDefaultPaymentMethod: User,
};


export type MeMutationAddCardArgs = {
  adyenPaymentInput: AdyenPaymentInput,
  browserInfo?: Maybe<AdyenBrowserInfoInput>
};


export type MeMutationChangePasswordArgs = {
  password: Scalars['String']
};


export type MeMutationDeleteSavedRecurringDetailArgs = {
  recurringDetailReference?: Maybe<Scalars['String']>
};


export type MeMutationUpdateArgs = {
  updateUserInput: UpdateUserInput
};


export type MeMutationUpdateDefaultPaymentMethodArgs = {
  reference: Scalars['String'],
  referenceType: AdyenPaymentMethodReferenceEnum
};

export type NormalizedTravelService = {
   __typename?: 'NormalizedTravelService',
  activationEvent?: Maybe<Scalars['String']>,
  activationPeriodMeasurementUnit?: Maybe<Scalars['String']>,
  activationPeriodValue?: Maybe<Scalars['Int']>,
  allowedDepartureTimeIntervals: Array<TimeInterval>,
  allowedForDeparturesOnFriday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnMonday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSaturday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSunday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnThursday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnTuesday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnWednesday?: Maybe<Scalars['Boolean']>,
  allowedSalesPointTypes: Array<SalesPoint>,
  conditionId?: Maybe<Scalars['String']>,
  currency?: Maybe<CurrencyEnum>,
  description?: Maybe<Scalars['String']>,
  firstZoneName?: Maybe<Scalars['String']>,
  firstZoneStopIds?: Maybe<Array<Scalars['Int']>>,
  firstZoneStops: Array<BusStop>,
  name?: Maybe<Scalars['String']>,
  numberOfUsages?: Maybe<Scalars['Int']>,
  ownerName?: Maybe<Scalars['String']>,
  passengerType?: Maybe<Scalars['String']>,
  passengerTypeId?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  routeRestrictions?: Maybe<RouteRestriction>,
  secondZoneName?: Maybe<Scalars['String']>,
  secondZoneStopIds?: Maybe<Array<Scalars['Int']>>,
  secondZoneStops: Array<BusStop>,
  staticTravelServiceId?: Maybe<Scalars['Int']>,
  travelServiceId?: Maybe<Scalars['String']>,
  validationPeriodMeasurementUnit?: Maybe<Scalars['String']>,
  validationPeriodValue?: Maybe<Scalars['Int']>,
  vatRate?: Maybe<Scalars['Float']>,
  zonePairPriceId?: Maybe<Scalars['String']>,
  zoneStopsId: Scalars['String'],
};

export type Passenger = {
   __typename?: 'Passenger',
  basicDiscountName: Scalars['String'],
  basicDiscountPrice: Scalars['Float'],
  finalPrice: Scalars['Float'],
  requestedFareClass: Scalars['String'],
  seatPricings: Array<SeatPricing>,
  unitPrice: Scalars['Float'],
  vatPercentage: Scalars['Float'],
};

export type PassengerDataField = {
   __typename?: 'PassengerDataField',
  displayType?: Maybe<Scalars['String']>,
  field?: Maybe<Scalars['String']>,
};

export type PassengerJourneys = {
   __typename?: 'PassengerJourneys',
  cancellationInsurance?: Maybe<InsuranceInfo>,
  fareClass?: Maybe<Scalars['String']>,
  fareClassId?: Maybe<Scalars['String']>,
  inboundJourney?: Maybe<JourneyInfo>,
  modificationInsurance?: Maybe<InsuranceInfo>,
  outboundJourney?: Maybe<JourneyInfo>,
  passengerName?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['String']>,
  productId?: Maybe<Scalars['String']>,
  totalPrice?: Maybe<Scalars['Float']>,
};

export type PatternValidation = {
   __typename?: 'PatternValidation',
  name: Scalars['String'],
  pattern: Scalars['String'],
};

export type PaxCategoryNotSeatedTogetherButShould = {
   __typename?: 'PaxCategoryNotSeatedTogetherButShould',
  passengerType?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['String']>,
  productId?: Maybe<Scalars['String']>,
  seatNumber?: Maybe<Scalars['Int']>,
  seatNumberLetter?: Maybe<Scalars['String']>,
  seatSelectionDeclineReason?: Maybe<Scalars['String']>,
  seatShouldBeNextTo: Array<Scalars['String']>,
  ticketNumber?: Maybe<Scalars['String']>,
  tripBusProfileId?: Maybe<Scalars['Int']>,
};

export type PaymentDetail = {
   __typename?: 'PaymentDetail',
  currency?: Maybe<Scalars['String']>,
  paidAmount?: Maybe<Scalars['Float']>,
  paymentMethodIdentificator?: Maybe<Scalars['String']>,
  paymentType?: Maybe<Scalars['String']>,
  referenceNumber?: Maybe<Scalars['String']>,
};

export type PaymentInfo = {
   __typename?: 'PaymentInfo',
  basketCost?: Maybe<Scalars['Float']>,
  currency?: Maybe<CurrencyEnum>,
  id: Scalars['String'],
  payableAmount?: Maybe<Scalars['Float']>,
  payableWithVoucherAmount?: Maybe<Scalars['Float']>,
  paymentInfo?: Maybe<Scalars['String']>,
  paymentProcessor?: Maybe<Scalars['String']>,
  payments?: Maybe<Array<PaymentInfoPayment>>,
};

export type PaymentInfoPayment = {
   __typename?: 'PaymentInfoPayment',
  currency?: Maybe<CurrencyEnum>,
  paidAmount?: Maybe<Scalars['Float']>,
  paymentId?: Maybe<Scalars['String']>,
  paymentTime?: Maybe<Scalars['String']>,
  paymentType?: Maybe<Scalars['String']>,
  voucherCode?: Maybe<Scalars['String']>,
};

export enum PaymentMethodEnum {
  CARD = 'card'
}

export type PayWithVoucherResult = {
   __typename?: 'PayWithVoucherResult',
  paymentInfo: PaymentInfo,
  paymentResult: VoucherResult,
  state: BasketState,
};

export type PhoneAreaCode = {
   __typename?: 'PhoneAreaCode',
  areaCode: Scalars['String'],
  countryAlpha2?: Maybe<Scalars['String']>,
  countryName: Scalars['String'],
  displayName: Scalars['String'],
};

export type PhoneNumber = {
   __typename?: 'PhoneNumber',
  areaCode?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
};

export type PhoneNumberInput = {
  areaCode: Scalars['String'],
  number: Scalars['String'],
};

export enum PriceClassEnum {
  BUSINESS = 'Business',
  CAMPAIGN = 'Campaign',
  CAMPAIGNBUSINESS = 'CampaignBusiness',
  REGULAR = 'Regular'
}

export type PriceClassItem = {
   __typename?: 'PriceClassItem',
  numberOfSeats?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  priceClassName?: Maybe<Scalars['String']>,
};

export type ProductGroupAddOnDetailInfo = {
   __typename?: 'ProductGroupAddOnDetailInfo',
  finalPrice?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['Int']>,
  journeyLegOrderNumber?: Maybe<Scalars['Int']>,
  quantity?: Maybe<Scalars['Int']>,
  serviceName?: Maybe<Scalars['String']>,
  unitPrice?: Maybe<Scalars['Float']>,
};

export type ProductGroupJourneyLeg = {
   __typename?: 'ProductGroupJourneyLeg',
  arrivalDateTime: Scalars['String'],
  brandName?: Maybe<Scalars['String']>,
  busCompanyName: Scalars['String'],
  departureDateTime: Scalars['String'],
  departureStopName: Scalars['String'],
  destinationStopName: Scalars['String'],
  orderNumber: Scalars['Int'],
  plannedArrivalDateTime: Scalars['String'],
  plannedArrivalDatetimeToDepartureStop: Scalars['String'],
  plannedDepartureDateTime: Scalars['String'],
  seatInfo: Array<ProductGroupSeatInfo>,
  subcontractorName?: Maybe<Scalars['String']>,
};

export type ProductGroupJourneyProduct = {
   __typename?: 'ProductGroupJourneyProduct',
  addOnDetails?: Maybe<Array<ProductGroupAddOnDetailInfo>>,
  assignedFareClass: Scalars['String'],
  cancellationInsurance?: Maybe<Insurance>,
  modificationInsurance?: Maybe<Insurance>,
  passengerName?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['String']>,
  productId: Scalars['String'],
  requestedFareClass: Scalars['String'],
  salesFee?: Maybe<ProductGroupSalesFee>,
  totalPrice: Scalars['Float'],
  travelInsurance?: Maybe<TravelInsurance>,
};

export type ProductGroupJourneyWay = {
   __typename?: 'ProductGroupJourneyWay',
  destinationStopName: Scalars['String'],
  legs: Array<ProductGroupJourneyLeg>,
  originStopName: Scalars['String'],
  products: Array<ProductGroupJourneyProduct>,
  totalPrice: Scalars['Float'],
};

export type ProductGroupResponse = {
   __typename?: 'ProductGroupResponse',
  basketLevelAddons: Array<BasketLevelAddon>,
  basketSalesFee?: Maybe<BasketSalesFee>,
  currency?: Maybe<CurrencyEnum>,
  inboundJourneys: Array<ProductGroupJourneyWay>,
  outboundJourneys: Array<ProductGroupJourneyWay>,
  totalPrice: Scalars['Float'],
  transfers: Array<TransferInfo>,
  travelPass: Array<TravelPass>,
};

export type ProductGroupSalesFee = {
   __typename?: 'ProductGroupSalesFee',
  price?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type ProductGroupSeatInfo = {
   __typename?: 'ProductGroupSeatInfo',
  departureRouteStopName?: Maybe<Scalars['String']>,
  destinationRouteStopName?: Maybe<Scalars['String']>,
  isAutomaticallyAssigned?: Maybe<Scalars['Boolean']>,
  isBusinessClass?: Maybe<Scalars['Boolean']>,
  productId?: Maybe<Scalars['String']>,
  seatFloorNumber?: Maybe<Scalars['Int']>,
  seatNumber?: Maybe<Scalars['Int']>,
  seatNumberLetter?: Maybe<Scalars['String']>,
  seatSelectionFee?: Maybe<ProductGroupSeatSelectionFee>,
  ticketNumber?: Maybe<Scalars['String']>,
  tripBusProfileId?: Maybe<Scalars['Int']>,
};

export type ProductGroupSeatSelectionFee = {
   __typename?: 'ProductGroupSeatSelectionFee',
  amount?: Maybe<Scalars['Float']>,
  currency?: Maybe<CurrencyEnum>,
};

export type ProductReturnCalculate = {
   __typename?: 'ProductReturnCalculate',
  currency?: Maybe<CurrencyEnum>,
  refundAmountToClient?: Maybe<Scalars['Float']>,
  refundPaymentMethod?: Maybe<Scalars['String']>,
  refundableAmount?: Maybe<Scalars['Float']>,
  serviceFeeTotal?: Maybe<Scalars['Float']>,
  totalPrice?: Maybe<Scalars['Float']>,
};

export type ProductReturnCommit = {
   __typename?: 'ProductReturnCommit',
  currency?: Maybe<CurrencyEnum>,
  productPriceBeforeReturn?: Maybe<Scalars['Float']>,
  refundPaymentMethod?: Maybe<Scalars['String']>,
  returnedAmount?: Maybe<Scalars['Float']>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatPercentage?: Maybe<Scalars['Float']>,
  voucherCode?: Maybe<Scalars['String']>,
};

export type Products = {
   __typename?: 'Products',
  /** Deprecated. If email is NULL, it attempts to use email from logged-in travel account */
  returnCalculate?: Maybe<ProductReturnCalculate>,
};


export type ProductsReturnCalculateArgs = {
  email?: Maybe<Scalars['String']>
};

export type ProductsMutation = {
   __typename?: 'ProductsMutation',
  /** Deprecated */
  returnCommit?: Maybe<ProductReturnCommit>,
};


export type ProductsMutationReturnCommitArgs = {
  currency: CurrencyEnum,
  email?: Maybe<Scalars['String']>
};

export type ProductsResponse = {
   __typename?: 'ProductsResponse',
  basketAddons: Array<BasketAddon>,
  basketSalesFee?: Maybe<BasketSalesFee>,
  currency?: Maybe<CurrencyEnum>,
  journeyProducts: Array<JourneyProduct>,
  smsFee?: Maybe<SmsFee>,
  totalPrice: Scalars['Float'],
  transfers: Array<TransferInfo>,
  travelPasses: Array<TravelPass>,
};

export type Purchase = {
   __typename?: 'Purchase',
  couponInfo: Array<Coupon>,
  currency?: Maybe<CurrencyEnum>,
  purchaseDate?: Maybe<Scalars['ISO8601DateTime']>,
  ticketNumber?: Maybe<Scalars['String']>,
};

export type RefundableProduct = {
   __typename?: 'RefundableProduct',
  productCode?: Maybe<Scalars['String']>,
  refundingScopes: Array<RefundingScope>,
};

export type RefundableProductInput = {
  productCode?: Maybe<Scalars['String']>,
  returningScopeXc?: Maybe<RefundingScopeEnum>,
};

export type RefundingScope = {
   __typename?: 'RefundingScope',
  refundAmountWithServiceFee?: Maybe<Scalars['Float']>,
  refundableAmount?: Maybe<Scalars['Float']>,
  refundingScope?: Maybe<Scalars['String']>,
  refundingScopeXc?: Maybe<RefundingScopeEnum>,
  serviceFee?: Maybe<Scalars['Float']>,
  totalPrice?: Maybe<Scalars['Float']>,
};

export enum RefundingScopeEnum {
  INBOUND_JOURNEY_WAY = 'INBOUND_JOURNEY_WAY',
  OUTBOUND_JOURNEY_WAY = 'OUTBOUND_JOURNEY_WAY',
  WHOLE_PRODUCT = 'WHOLE_PRODUCT'
}

export type RegisterUserInput = {
  email: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  isSendingInfoAllowed?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
};

export type ReservedSeatRequestInput = {
  campaignCode?: Maybe<Scalars['String']>,
  currencyXc?: Maybe<CurrencyEnum>,
  tripBusProfiles: Array<TripBusProfileItemInput>,
};

export type ResetUserPasswordInput = {
  email: Scalars['String'],
};

export type ReturnCalculate = {
   __typename?: 'ReturnCalculate',
  id?: Maybe<Scalars['String']>,
  paymentDetails: Array<PaymentDetail>,
  refundPaymentMethod?: Maybe<Scalars['String']>,
  refundableProducts: Array<RefundableProduct>,
  returningAllowed?: Maybe<Scalars['Boolean']>,
};

export type ReturnCalculateInput = {
  productCodes: Array<Scalars['String']>,
  refundPaymentMethod?: Maybe<Scalars['String']>,
};

export type ReturnCommit = {
   __typename?: 'ReturnCommit',
  currency?: Maybe<Scalars['String']>,
  refundPaymentMethod?: Maybe<Scalars['String']>,
  returnedAmount?: Maybe<Scalars['Float']>,
  voucherCode?: Maybe<Scalars['String']>,
};

export type ReturnCommitInput = {
  id: Scalars['String'],
  refundableProducts: Array<RefundableProductInput>,
};

export type RootMutation = {
   __typename?: 'RootMutation',
  adyen: AdyenMutation,
  apple: AppleMutation,
  basket?: Maybe<BasketMutation>,
  changePassword: Scalars['Boolean'],
  createBasket: Basket,
  /** 
 * Fast basket order:
   * 1) Uses existing basket_id or creates new one with createBasketInput.
   * 2) Searches for outbound journey and adds first result with free seats.
   *   - If time is specified, it adds first result after given time.
   * 3) Optionally searches for inbound journey and adds first result with free seats.
   *   - If time is specified, it adds first result after given time.
   * 4) returns basket id with filled in products.
 **/
  createBasketWithProducts: Basket,
  loginAppleUser: LoginResult,
  loginFacebookUser: LoginResult,
  loginUser: LoginResult,
  me?: Maybe<MeMutation>,
  products: ProductsMutation,
  refreshAccessToken: LoginResult,
  registerUser: LoginResult,
  resetUserPassword?: Maybe<Scalars['Boolean']>,
  returnCommit: ReturnCommit,
};


export type RootMutationBasketArgs = {
  id: Scalars['String']
};


export type RootMutationChangePasswordArgs = {
  input: ChangePasswordInput
};


export type RootMutationCreateBasketArgs = {
  affiliateId?: Maybe<Scalars['String']>,
  isTravelAccountRelationCreated: Scalars['Boolean']
};


export type RootMutationCreateBasketWithProductsArgs = {
  basketId?: Maybe<Scalars['String']>,
  createBasketInput?: Maybe<CreateBasketInput>,
  inboundInput?: Maybe<JourneysSearchInput>,
  inboundTime?: Maybe<Scalars['String']>,
  outboundInput: JourneysSearchInput,
  outboundTime?: Maybe<Scalars['String']>
};


export type RootMutationLoginAppleUserArgs = {
  basketId?: Maybe<Scalars['String']>,
  idToken: Scalars['String']
};


export type RootMutationLoginFacebookUserArgs = {
  basketId?: Maybe<Scalars['String']>,
  idToken: Scalars['String']
};


export type RootMutationLoginUserArgs = {
  basketId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  password: Scalars['String']
};


export type RootMutationProductsArgs = {
  id: Scalars['String']
};


export type RootMutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']
};


export type RootMutationRegisterUserArgs = {
  registerUserInput: RegisterUserInput
};


export type RootMutationResetUserPasswordArgs = {
  resetUserPasswordInput: ResetUserPasswordInput
};


export type RootMutationReturnCommitArgs = {
  email: Scalars['String'],
  input: ReturnCommitInput
};

export type RootQuery = {
   __typename?: 'RootQuery',
  adyen: AdyenQuery,
  basket: Basket,
  basketIdByCode: Scalars['String'],
  busStops: Array<CountryWithCities>,
  corporateClientHistory: CorporateClientHistory,
  fareClasses: Array<FareClassItem>,
  /** Journey Presets sorted by position asc */
  journeyPresets: Array<JourneyPreset>,
  journeyRoute: Array<Route>,
  /** 
 * Based on bus stops returns journey search results either from Turnit or CRWS.
   * You can customize Turnit result:
   *   skipReinforcementFilter - Defaults to false. Skips filtering of results by reinforcement:
   *     When result contains tripLegGroup with multiple records, it returns only
   * one bus from this group which is not sold out,
   *     and a list of sold out buses which were sold out prior to this one.
   *     Each tripLegGroup is sorted by isReinforcement DESC, brandName ASC.
 **/
  journeysSearch: JourneySearchResponse,
  me?: Maybe<User>,
  /** Same data as travelServices, but flattened to service->conditions->zonePairPrices level. */
  normalizedTravelServices: Array<NormalizedTravelService>,
  passwordValidations: Array<PatternValidation>,
  phoneAreaCodes: Array<PhoneAreaCode>,
  products: Products,
  travelPassesByGuid: Array<TravelPassByGuid>,
  travelServices: Array<TravelService>,
  /** Cached list of travelServices */
  travelServicesList: Array<TravelServicesListItem>,
  tripLegAddons: TripLegsResponse,
  voucher: Voucher,
};


export type RootQueryBasketArgs = {
  id: Scalars['String']
};


export type RootQueryBasketIdByCodeArgs = {
  input: BasketIdByCodeInput
};


export type RootQueryBusStopsArgs = {
  busStopsInput?: Maybe<BusStopsInput>
};


export type RootQueryCorporateClientHistoryArgs = {
  input: CorporateClientHistoryInput
};


export type RootQueryJourneyRouteArgs = {
  includeIntermediateStops?: Maybe<Scalars['Boolean']>,
  journeyId: Scalars['String'],
  route?: Maybe<JourneyRouteEnum>,
  source: JourneySourceEnum
};


export type RootQueryJourneysSearchArgs = {
  journeysSearchInput: JourneysSearchInput,
  skipReinforcementFilter?: Maybe<Scalars['Boolean']>
};


export type RootQueryNormalizedTravelServicesArgs = {
  travelServicesInput?: Maybe<TravelServicesInput>
};


export type RootQueryProductsArgs = {
  id: Scalars['String']
};


export type RootQueryTravelPassesByGuidArgs = {
  input: TravelPassesByGuidInput
};


export type RootQueryTravelServicesArgs = {
  travelServicesInput?: Maybe<TravelServicesInput>
};


export type RootQueryTravelServicesListArgs = {
  travelServicesInput?: Maybe<TravelServicesInput>
};


export type RootQueryTripLegAddonsArgs = {
  basketId?: Maybe<Scalars['String']>,
  currency: CurrencyEnum,
  productIds?: Maybe<Array<Scalars['String']>>
};


export type RootQueryVoucherArgs = {
  code: Scalars['String'],
  voucherInput?: Maybe<VoucherInput>
};

export type Route = {
   __typename?: 'Route',
  arrivalDatetime: Scalars['ISO8601DateTime'],
  arrivalTimezone: Scalars['String'],
  brandName?: Maybe<Scalars['String']>,
  busCompanyName: Scalars['String'],
  busEquipment: Array<BusEquipment>,
  busLineNumber: Scalars['String'],
  departureCityName: Scalars['String'],
  departureDatetime: Scalars['ISO8601DateTime'],
  departurePlatform?: Maybe<Scalars['String']>,
  departureStopCode: Scalars['String'],
  departureStopId: Scalars['Int'],
  departureStopLocation: StopLocation,
  departureStopName: Scalars['String'],
  departureTimezone: Scalars['String'],
  departureZone?: Maybe<Scalars['String']>,
  destinationCityName: Scalars['String'],
  destinationPlatform?: Maybe<Scalars['String']>,
  destinationStopCode: Scalars['String'],
  destinationStopId: Scalars['Int'],
  destinationStopLocation: StopLocation,
  destinationStopName: Scalars['String'],
  destinationZone?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['String']>,
  interchangeDuration?: Maybe<Scalars['String']>,
  intermediateStops: Array<IntermediateStop>,
  orderNumber: Scalars['Int'],
  plannedArrivalDateTime: Scalars['String'],
  plannedArrivalTimezone: Scalars['String'],
  plannedDepartureDateTime: Scalars['String'],
  plannedDepartureTimezone: Scalars['String'],
  serviceCode?: Maybe<Scalars['String']>,
  subcontractor?: Maybe<Scalars['String']>,
  tripName: Scalars['String'],
};

export type RouteRestriction = {
   __typename?: 'RouteRestriction',
  brands: Array<Brand>,
  busLines: Array<BusLine>,
  isBrandRestrictionInclusive?: Maybe<Scalars['Boolean']>,
  isLineRestrictionInclusive?: Maybe<Scalars['Boolean']>,
};

export type SalesFee = {
   __typename?: 'SalesFee',
  totalBusinessClassCampaignFee?: Maybe<Scalars['Float']>,
  totalBusinessClassSalesFee?: Maybe<Scalars['Float']>,
  totalCampaignSalesFee?: Maybe<Scalars['Float']>,
  totalRegularSalesFee?: Maybe<Scalars['Float']>,
};

export type SalesPoint = {
   __typename?: 'SalesPoint',
  name?: Maybe<Scalars['String']>,
};

export type Seat = {
   __typename?: 'Seat',
  departureStopName?: Maybe<Scalars['String']>,
  destinationStopName?: Maybe<Scalars['String']>,
  discount?: Maybe<Scalars['Float']>,
  isAutomaticSelected?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
  seatClass?: Maybe<Scalars['String']>,
  seatFloorNumber?: Maybe<Scalars['Int']>,
  seatLetter?: Maybe<Scalars['String']>,
  seatNumber?: Maybe<Scalars['Int']>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type SeatInfo = {
   __typename?: 'SeatInfo',
  departureBusStopId?: Maybe<Scalars['Int']>,
  departureBusStopName?: Maybe<Scalars['String']>,
  destinationBusStopId?: Maybe<Scalars['Int']>,
  destinationBusStopName?: Maybe<Scalars['String']>,
  seatFloorNumber?: Maybe<Scalars['Int']>,
  seatNumber?: Maybe<Scalars['Int']>,
  seatNumberLetter?: Maybe<Scalars['String']>,
  tripBusProfileId?: Maybe<Scalars['Int']>,
};

export type SeatInfoItemInput = {
  loyaltyLevelName?: Maybe<Scalars['String']>,
  seatFloorNumber?: Maybe<Scalars['Int']>,
  seatNumber?: Maybe<Scalars['Int']>,
  seatNumberLetter?: Maybe<Scalars['String']>,
  ticketNumber: Scalars['String'],
};

export type SeatPlanAdditionalSeat = {
   __typename?: 'SeatPlanAdditionalSeat',
  changeStopId?: Maybe<Scalars['Int']>,
  seatNumber?: Maybe<Scalars['Int']>,
  seatNumberLetter?: Maybe<Scalars['String']>,
};

export type SeatPlanBus = {
   __typename?: 'SeatPlanBus',
  availableSeatCount?: Maybe<Scalars['Int']>,
  canChooseSeatNumber?: Maybe<Scalars['Boolean']>,
  floors: Array<SeatPlanFloor>,
  name?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  steeringWheelLocation?: Maybe<Scalars['String']>,
  tripBusProfileId?: Maybe<Scalars['Int']>,
};

export type SeatPlanFloor = {
   __typename?: 'SeatPlanFloor',
  floorNumber?: Maybe<Scalars['Int']>,
  seatRows: Array<SeatPlanSeatRow>,
};

export type SeatPlanLeg = {
   __typename?: 'SeatPlanLeg',
  buses: Array<SeatPlanBus>,
  isOutbound?: Maybe<Scalars['Boolean']>,
  orderNumber?: Maybe<Scalars['Int']>,
};

export type SeatPlanPrice = {
   __typename?: 'SeatPlanPrice',
  amount?: Maybe<Scalars['Float']>,
  currency?: Maybe<CurrencyEnum>,
};

export type SeatPlanSeat = {
   __typename?: 'SeatPlanSeat',
  additionalSeat?: Maybe<SeatPlanAdditionalSeat>,
  columnNumber?: Maybe<Scalars['Int']>,
  hasElectricity?: Maybe<Scalars['Boolean']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  isChildSeat?: Maybe<Scalars['Boolean']>,
  isEmptyArea?: Maybe<Scalars['Boolean']>,
  isForwardSeating?: Maybe<Scalars['Boolean']>,
  isPetSeat?: Maybe<Scalars['Boolean']>,
  isSoldLater?: Maybe<Scalars['Boolean']>,
  isStairs?: Maybe<Scalars['Boolean']>,
  isToilet?: Maybe<Scalars['Boolean']>,
  isWheelChairSeat?: Maybe<Scalars['Boolean']>,
  productGuid?: Maybe<Scalars['String']>,
  seatClass?: Maybe<Scalars['String']>,
  seatClassCategory?: Maybe<Scalars['String']>,
  seatClassCategoryName?: Maybe<Scalars['String']>,
  seatClassName?: Maybe<Scalars['String']>,
  seatNumber?: Maybe<Scalars['Int']>,
  seatNumberLetter?: Maybe<Scalars['String']>,
  seatPrice?: Maybe<SeatPlanPrice>,
  seatRank?: Maybe<Scalars['String']>,
  ticketPrice: Array<SeatPlanTicketPrice>,
};

export type SeatPlanSeatRow = {
   __typename?: 'SeatPlanSeatRow',
  rowNumber?: Maybe<Scalars['Int']>,
  seats: Array<SeatPlanSeat>,
};

export type SeatPlanTicketPrice = {
   __typename?: 'SeatPlanTicketPrice',
  amount?: Maybe<Scalars['Float']>,
  productId?: Maybe<Scalars['String']>,
};

export type SeatPricing = {
   __typename?: 'SeatPricing',
  basicDiscountPrice: Scalars['Float'],
  finalPrice: Scalars['Float'],
  isBusPrice?: Maybe<Scalars['Boolean']>,
  isBusinessClass: Scalars['Boolean'],
  isCampaignPrice: Scalars['Boolean'],
  isCampaignTicketChangeable: Scalars['Boolean'],
  isCampaignTicketRefundable: Scalars['Boolean'],
  seatClassCategory: Scalars['String'],
  seatClassName?: Maybe<Scalars['String']>,
  unitPrice: Scalars['Float'],
  vatPercentage: Scalars['Float'],
};

export type SeatSelectionFee = {
   __typename?: 'SeatSelectionFee',
  price?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type ServiceInfo = {
   __typename?: 'ServiceInfo',
  code?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  discount?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type ServiceProviderInfo = {
   __typename?: 'ServiceProviderInfo',
  companyCode?: Maybe<Scalars['String']>,
  companyName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
};

export type ServiceWithSalesLimit = {
   __typename?: 'ServiceWithSalesLimit',
  addonGuid?: Maybe<Scalars['String']>,
  addonId?: Maybe<Scalars['Int']>,
  currencyXc?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  detailsDescription?: Maybe<Scalars['String']>,
  detailsDescriptionIconSvg?: Maybe<Scalars['String']>,
  detailsDoNotDisable?: Maybe<Scalars['Boolean']>,
  detailsFullDescription?: Maybe<Scalars['String']>,
  detailsGroupKey?: Maybe<Scalars['String']>,
  detailsGroupPosition?: Maybe<Scalars['Int']>,
  detailsIconSvg?: Maybe<Scalars['String']>,
  detailsIsSingleton?: Maybe<Scalars['Boolean']>,
  detailsKey?: Maybe<Scalars['String']>,
  detailsMaxQuantity?: Maybe<Scalars['Int']>,
  detailsTitle?: Maybe<Scalars['String']>,
  salesLimit?: Maybe<Scalars['Int']>,
  serviceName?: Maybe<Scalars['String']>,
  unitPrice?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type SmsFee = {
   __typename?: 'SmsFee',
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type SmsFeelInfo = {
   __typename?: 'SmsFeelInfo',
  price?: Maybe<Scalars['Float']>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type StopLocation = {
   __typename?: 'StopLocation',
  cityName?: Maybe<Scalars['String']>,
  countryName?: Maybe<Scalars['String']>,
  countyName?: Maybe<Scalars['String']>,
};

export enum StopTypeEnum {
  AIRPORT = 'AIRPORT',
  BUS_STATION = 'BUS_STATION',
  BUS_STOP = 'BUS_STOP'
}

export type TimeInterval = {
   __typename?: 'TimeInterval',
  timeFrom?: Maybe<Scalars['String']>,
  timeTo?: Maybe<Scalars['String']>,
};

export type TransferInfo = {
   __typename?: 'TransferInfo',
  price?: Maybe<Scalars['Float']>,
  productId?: Maybe<Scalars['String']>,
  transferDepartureTime?: Maybe<Scalars['ISO8601DateTime']>,
  transferDepartureTimeTimeZone?: Maybe<Scalars['String']>,
  transferName?: Maybe<Scalars['String']>,
  transferStopName?: Maybe<Scalars['String']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type TravelAccountTravelPass = {
   __typename?: 'TravelAccountTravelPass',
  activatedOn?: Maybe<Scalars['ISO8601DateTime']>,
  allowedDepartureTimeIntervals: Array<TimeInterval>,
  allowedForDeparturesOnFriday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnMonday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSaturday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSunday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnThursday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnTuesday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnWednesday?: Maybe<Scalars['Boolean']>,
  bonusSchemeGroupId?: Maybe<Scalars['String']>,
  bonusSchemeGroupName?: Maybe<Scalars['String']>,
  canBeActivatedUntil?: Maybe<Scalars['ISO8601DateTime']>,
  category: TravelPassCategoryEnum,
  description?: Maybe<Scalars['String']>,
  isActivated?: Maybe<Scalars['Boolean']>,
  isAirportTravelPass?: Maybe<Scalars['Boolean']>,
  isPeriodicCard?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  numberOfUsagesLeft?: Maybe<Scalars['Int']>,
  qrCode?: Maybe<Scalars['String']>,
  qrCodeImage?: Maybe<Scalars['String']>,
  travelServiceId?: Maybe<Scalars['Int']>,
  validUntil?: Maybe<Scalars['ISO8601DateTime']>,
  validityZonePair?: Maybe<ValidityZonePair>,
};

export type TravelAccountVoucher = {
   __typename?: 'TravelAccountVoucher',
  amount?: Maybe<Scalars['Float']>,
  code?: Maybe<Scalars['String']>,
  createdOn?: Maybe<Scalars['ISO8601DateTime']>,
  currency?: Maybe<CurrencyEnum>,
  initialAmount?: Maybe<Scalars['Float']>,
  pin?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  type?: Maybe<Scalars['String']>,
  validUntil?: Maybe<Scalars['ISO8601DateTime']>,
};

export type TravelInsurance = {
   __typename?: 'TravelInsurance',
  currency: CurrencyEnum,
  name: Scalars['String'],
  price: Scalars['Float'],
  vatRate: Scalars['Float'],
};

export type TravelPass = {
   __typename?: 'TravelPass',
  description?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  unitPrice?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type TravelPassBusStop = {
   __typename?: 'TravelPassBusStop',
  busStop?: Maybe<BusStop>,
  busStopId?: Maybe<Scalars['Int']>,
  isMainStop?: Maybe<Scalars['Boolean']>,
};

export type TravelPassByGuid = {
   __typename?: 'TravelPassByGuid',
  activatedOn?: Maybe<Scalars['ISO8601DateTime']>,
  allowedDepartureTimeIntervals: Array<TimeInterval>,
  allowedForDeparturesOnFriday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnMonday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSaturday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnSunday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnThursday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnTuesday?: Maybe<Scalars['Boolean']>,
  allowedForDeparturesOnWednesday?: Maybe<Scalars['Boolean']>,
  bonusSchemeGroupId?: Maybe<Scalars['String']>,
  bonusSchemeGroupName?: Maybe<Scalars['String']>,
  canBeActivatedUntil?: Maybe<Scalars['ISO8601DateTime']>,
  category: TravelPassCategoryEnum,
  description?: Maybe<Scalars['String']>,
  isActivated?: Maybe<Scalars['Boolean']>,
  isAirportTravelPass?: Maybe<Scalars['Boolean']>,
  isPeriodicCard?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  numberOfUsagesLeft?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  qrCode?: Maybe<Scalars['String']>,
  qrCodeImage?: Maybe<Scalars['String']>,
  serviceProviderInfo?: Maybe<ServiceProviderInfo>,
  smsFeelInfo?: Maybe<SmsFeelInfo>,
  travelServiceId?: Maybe<Scalars['Int']>,
  validUntil?: Maybe<Scalars['ISO8601DateTime']>,
  validityZonePair?: Maybe<ValidityZonePair>,
  vatAmount?: Maybe<Scalars['Float']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export enum TravelPassCategoryEnum {
  CURRENT = 'current',
  SPENT = 'spent'
}

export type TravelPassesByGuidInput = {
  shoppingBasketGuid?: Maybe<Scalars['String']>,
  travelPassGuid?: Maybe<Scalars['String']>,
};

export type TravelService = {
   __typename?: 'TravelService',
  activationEvent?: Maybe<Scalars['String']>,
  activationPeriodMeasurementUnit?: Maybe<Scalars['String']>,
  activationPeriodValue?: Maybe<Scalars['Int']>,
  allowedSalesPointTypes: Array<SalesPoint>,
  conditions: Array<Condition>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  numberOfUsages?: Maybe<Scalars['Int']>,
  ownerName?: Maybe<Scalars['String']>,
  routeRestrictions?: Maybe<RouteRestriction>,
  travelServiceId?: Maybe<Scalars['String']>,
  validationPeriodMeasurementUnit?: Maybe<Scalars['String']>,
  validationPeriodValue?: Maybe<Scalars['Int']>,
  vatRate?: Maybe<Scalars['Float']>,
};

export type TravelServicesInput = {
  currency?: Maybe<CurrencyEnum>,
  ignoreTravelProductOwner?: Maybe<Scalars['Boolean']>,
  zoneId?: Maybe<Scalars['Int']>,
};

export type TravelServicesListItem = {
   __typename?: 'TravelServicesListItem',
  from?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  price?: Maybe<Scalars['Float']>,
  to?: Maybe<Scalars['String']>,
};

export type TripBusProfileItemInput = {
  seatInfo?: Maybe<Array<SeatInfoItemInput>>,
  tripBusProfileId: Scalars['Int'],
};

export type TripLegsResponse = {
   __typename?: 'TripLegsResponse',
  legAddOns: Array<LegServices>,
  responseValidUntil: Scalars['ISO8601DateTime'],
};

export type UpdateUserInput = {
  birthDate?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  countryId?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  genderXc?: Maybe<GenderEnum>,
  isSendingInfoAllowed?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
};

export type User = {
   __typename?: 'User',
  birthDate?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderEnum>,
  id: Scalars['String'],
  isConnectedToClientContract?: Maybe<Scalars['Boolean']>,
  isSendingInfoAllowed?: Maybe<Scalars['Boolean']>,
  journeyWays: Array<UserJourneyWaysWay>,
  journeys: Array<UserJourneysWay>,
  lastName?: Maybe<Scalars['String']>,
  /** 
 * Please note that 'Legacyticket.journey' stops can have id 0 if we were unable
   * to map them by code (code was changed or deleted externally in Turnit).
 **/
  legacyTickets: Array<LegacyTicket>,
  phoneAreaCode?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  savedRecurringDetails: Array<AdyenRecurringDetail>,
  streetAddress?: Maybe<Scalars['String']>,
  travelPasses: Array<TravelAccountTravelPass>,
  /** Note that "spent" category no longer works, because Turnit no longer returns 0 amount vouchers */
  vouchers: Array<TravelAccountVoucher>,
};


export type UserJourneyWaysArgs = {
  userJourneyWaysInput: UserJourneyWaysInput
};


export type UserJourneysArgs = {
  userJourneysInput: UserJourneysInput
};


export type UserLegacyTicketsArgs = {
  userJourneysInput: UserJourneysInput
};


export type UserSavedRecurringDetailsArgs = {
  recurring?: Maybe<AdyenRecurringEnum>
};


export type UserTravelPassesArgs = {
  category?: Maybe<TravelPassCategoryEnum>
};


export type UserVouchersArgs = {
  category?: Maybe<VoucherCategoryEnum>
};

export type UserJourneysBusEquipment = {
   __typename?: 'UserJourneysBusEquipment',
  code?: Maybe<Scalars['String']>,
  displayName?: Maybe<Scalars['String']>,
};

export type UserJourneysInput = {
  daysInThePast: Scalars['Int'],
};

export type UserJourneysJourneyAddon = {
   __typename?: 'UserJourneysJourneyAddon',
  addonName?: Maybe<Scalars['String']>,
  currency?: Maybe<CurrencyEnum>,
  description?: Maybe<Scalars['String']>,
  journeyRelation?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type UserJourneysLeg = {
   __typename?: 'UserJourneysLeg',
  arrivalTime?: Maybe<Scalars['String']>,
  brandName?: Maybe<Scalars['String']>,
  busEquipment: Array<UserJourneysBusEquipment>,
  departureCityName?: Maybe<Scalars['String']>,
  departureStop?: Maybe<UserJourneysStop>,
  departureTime?: Maybe<Scalars['String']>,
  destinationCityName?: Maybe<Scalars['String']>,
  destinationStop?: Maybe<UserJourneysStop>,
  duration?: Maybe<Scalars['String']>,
  lineNumber?: Maybe<Scalars['String']>,
  orderNumber?: Maybe<Scalars['Int']>,
  serviceCode?: Maybe<Scalars['String']>,
};

export type UserJourneysLegAddon = {
   __typename?: 'UserJourneysLegAddon',
  addonName?: Maybe<Scalars['String']>,
  currency?: Maybe<CurrencyEnum>,
  description?: Maybe<Scalars['String']>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type UserJourneysPassenger = {
   __typename?: 'UserJourneysPassenger',
  email?: Maybe<Scalars['String']>,
  isBasketPayedByTravelAccount?: Maybe<Scalars['Boolean']>,
  isChangeable?: Maybe<Scalars['Boolean']>,
  isRefundable?: Maybe<Scalars['Boolean']>,
  journeyAddons: Array<UserJourneysJourneyAddon>,
  legAddons: Array<UserJourneysLegAddon>,
  passengerCategory?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['String']>,
  seats: Array<UserJourneysSeat>,
  tickets: Array<UserJourneysTicket>,
};

export type UserJourneysSeat = {
   __typename?: 'UserJourneysSeat',
  currency?: Maybe<CurrencyEnum>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  seatChangeStop?: Maybe<Scalars['String']>,
  seatClass?: Maybe<Scalars['String']>,
  seatFloorNumber?: Maybe<Scalars['String']>,
  seatNumber?: Maybe<Scalars['String']>,
  seatOrderNumber?: Maybe<Scalars['Int']>,
};

export type UserJourneysStop = {
   __typename?: 'UserJourneysStop',
  code?: Maybe<Scalars['String']>,
  coordinates?: Maybe<Coordinates>,
  name?: Maybe<Scalars['String']>,
};

export type UserJourneysTicket = {
   __typename?: 'UserJourneysTicket',
  currency?: Maybe<CurrencyEnum>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  qrCode?: Maybe<Scalars['String']>,
};

export type UserJourneysWay = {
   __typename?: 'UserJourneysWay',
  arrivalTime?: Maybe<Scalars['String']>,
  departureCityName?: Maybe<Scalars['String']>,
  departureStop?: Maybe<UserJourneysStop>,
  departureTime?: Maybe<Scalars['String']>,
  destinationCityName?: Maybe<Scalars['String']>,
  destinationStop?: Maybe<UserJourneysStop>,
  duration?: Maybe<Scalars['Boolean']>,
  isOutbound?: Maybe<Scalars['Boolean']>,
  isReinforcement: Scalars['Boolean'],
  legs: Array<UserJourneysLeg>,
  passengers: Array<UserJourneysPassenger>,
  shoppingBasketCode?: Maybe<Scalars['String']>,
  shoppingBasketId?: Maybe<Scalars['String']>,
};

export type UserJourneyWaysInput = {
  basketGuid?: Maybe<Scalars['String']>,
  displayPassed: Scalars['Boolean'],
  limit: Scalars['Int'],
};

export type UserJourneyWaysJourneyAddon = {
   __typename?: 'UserJourneyWaysJourneyAddon',
  addonName?: Maybe<Scalars['String']>,
  currency?: Maybe<CurrencyEnum>,
  description?: Maybe<Scalars['String']>,
  journeyRelation?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type UserJourneyWaysLeg = {
   __typename?: 'UserJourneyWaysLeg',
  arrivalTime?: Maybe<Scalars['String']>,
  brandName?: Maybe<Scalars['String']>,
  departureCityName?: Maybe<Scalars['String']>,
  departureStopName?: Maybe<Scalars['String']>,
  departureTime?: Maybe<Scalars['String']>,
  destinationCityName?: Maybe<Scalars['String']>,
  destinationStopName?: Maybe<Scalars['String']>,
  lineNumber?: Maybe<Scalars['String']>,
  orderNumber?: Maybe<Scalars['Int']>,
  plannedArrivalTime?: Maybe<Scalars['String']>,
  plannedDepartureTime?: Maybe<Scalars['String']>,
  subcontractorName?: Maybe<Scalars['String']>,
};

export type UserJourneyWaysLegAddon = {
   __typename?: 'UserJourneyWaysLegAddon',
  addonName?: Maybe<Scalars['String']>,
  currency?: Maybe<CurrencyEnum>,
  description?: Maybe<Scalars['String']>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type UserJourneyWaysPassenger = {
   __typename?: 'UserJourneyWaysPassenger',
  journeyAddons: Array<UserJourneyWaysJourneyAddon>,
  legAddons: Array<UserJourneyWaysLegAddon>,
  passengerCategory?: Maybe<Scalars['String']>,
  productCode?: Maybe<Scalars['String']>,
  seats: Array<UserJourneyWaysSeat>,
  tickets: Array<UserJourneyWaysTicket>,
};

export type UserJourneyWaysSeat = {
   __typename?: 'UserJourneyWaysSeat',
  currency?: Maybe<CurrencyEnum>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
  seatChangeStop?: Maybe<Scalars['String']>,
  seatClass?: Maybe<Scalars['String']>,
  seatFloorNumber?: Maybe<Scalars['String']>,
  seatNumber?: Maybe<Scalars['String']>,
  seatOrderNumber?: Maybe<Scalars['Int']>,
};

export type UserJourneyWaysTicket = {
   __typename?: 'UserJourneyWaysTicket',
  currency?: Maybe<CurrencyEnum>,
  legOrderNumber?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Float']>,
};

export type UserJourneyWaysWay = {
   __typename?: 'UserJourneyWaysWay',
  arrivalTime?: Maybe<Scalars['String']>,
  departureCityName?: Maybe<Scalars['String']>,
  departureStopName?: Maybe<Scalars['String']>,
  departureTime?: Maybe<Scalars['String']>,
  destinationCityName?: Maybe<Scalars['String']>,
  destinationStopName?: Maybe<Scalars['String']>,
  isCorporateClientContractPurchase?: Maybe<Scalars['Boolean']>,
  isOutbound?: Maybe<Scalars['Boolean']>,
  legs: Array<UserJourneyWaysLeg>,
  passengers: Array<UserJourneyWaysPassenger>,
  shoppingBasketCode?: Maybe<Scalars['String']>,
  shoppingBasketId?: Maybe<Scalars['String']>,
};

export type ValidityZonePair = {
   __typename?: 'ValidityZonePair',
  firstZoneBusStops: Array<TravelPassBusStop>,
  firstZoneName?: Maybe<Scalars['String']>,
  secondZoneBusStops: Array<TravelPassBusStop>,
  secondZoneName?: Maybe<Scalars['String']>,
};

export type Voucher = {
   __typename?: 'Voucher',
  amount?: Maybe<Scalars['Float']>,
  code?: Maybe<Scalars['String']>,
  createdOn?: Maybe<Scalars['ISO8601DateTime']>,
  currency?: Maybe<CurrencyEnum>,
  initialAmount?: Maybe<Scalars['Float']>,
  isValid?: Maybe<Scalars['Boolean']>,
  pdfUrl: Scalars['String'],
  pin?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  type?: Maybe<Scalars['String']>,
  validUntil?: Maybe<Scalars['ISO8601DateTime']>,
};

export enum VoucherCategoryEnum {
  AVAILABLE = 'available',
  SPENT = 'spent'
}

export type VoucherInput = {
  currency: CurrencyEnum,
  paidAmount?: Maybe<Scalars['Float']>,
  voucherCode: Scalars['String'],
  voucherPin?: Maybe<Scalars['String']>,
};

export type VoucherResult = {
   __typename?: 'VoucherResult',
  isVoucherUsed?: Maybe<Scalars['Boolean']>,
  paidAmount?: Maybe<Scalars['Float']>,
  voucherCurrency?: Maybe<CurrencyEnum>,
  voucherRemainingValue?: Maybe<Scalars['Float']>,
};

export type ZonePairPrice = {
   __typename?: 'ZonePairPrice',
  firstZoneName?: Maybe<Scalars['String']>,
  firstZoneStopIds?: Maybe<Array<Scalars['Int']>>,
  firstZoneStops: Array<BusStop>,
  price?: Maybe<Scalars['Float']>,
  secondZoneName?: Maybe<Scalars['String']>,
  secondZoneStopIds?: Maybe<Array<Scalars['Int']>>,
  secondZoneStops: Array<BusStop>,
  zonePairPriceId?: Maybe<Scalars['String']>,
  zoneStopsId: Scalars['String'],
};

export type JourneyFragmentFragment = (
  { __typename?: 'Journey' }
  & Pick<Journey, 'arrivalDateTime' | 'arrivalDateTimeTimeZone' | 'availableBusinessCampaignSeats' | 'availableBusinessClassSeats' | 'availableCampaignSeats' | 'availableRegularSeats' | 'businessBusPrice' | 'businessCampaignPrice' | 'businessClassPrice' | 'campaignPrice' | 'currency' | 'departureDateTime' | 'departureDateTimeTimeZone' | 'destinationCityName' | 'destinationStopId' | 'destinationStopName' | 'duration' | 'id' | 'isAllergicPassengerOnBus' | 'isChangeable' | 'isForSale' | 'isPetOnBus' | 'isRefundable' | 'isReinforcement' | 'isWheelchairAreaAvailable' | 'originCityName' | 'originStopId' | 'originStopName' | 'plannedArrivalDateTime' | 'plannedDepartureDateTime' | 'regularBusPrice' | 'regularPrice' | 'soldLaterBusinessCampaignSeats' | 'soldLaterBusinessClassSeats' | 'soldLaterCampaignSeats' | 'soldLaterRegularSeats' | 'soldOutWithinLegTripGroup' | 'source' | 'timetablePdfUrl'>
  & { legs: Array<(
    { __typename?: 'Leg' }
    & Pick<Leg, 'orderNumber' | 'brandName'>
    & { lineIdentifier1: Maybe<(
      { __typename?: 'LineIdentifier' }
      & Pick<LineIdentifier, 'name' | 'value'>
    )>, lineIdentifier2: Maybe<(
      { __typename?: 'LineIdentifier' }
      & Pick<LineIdentifier, 'name' | 'value'>
    )>, lineIdentifier3: Maybe<(
      { __typename?: 'LineIdentifier' }
      & Pick<LineIdentifier, 'name' | 'value'>
    )>, passengers: Array<(
      { __typename?: 'Passenger' }
      & Pick<Passenger, 'basicDiscountName' | 'finalPrice'>
    )> }
  )>, priceClasses: Array<(
    { __typename?: 'PriceClassItem' }
    & Pick<PriceClassItem, 'numberOfSeats' | 'price' | 'priceClassName'>
  )>, salesFees: Maybe<(
    { __typename?: 'SalesFee' }
    & Pick<SalesFee, 'totalBusinessClassCampaignFee' | 'totalBusinessClassSalesFee' | 'totalCampaignSalesFee' | 'totalRegularSalesFee'>
  )> }
);

export type PayWithResponseFragment = (
  { __typename?: 'AdyenPaymentResponse' }
  & Pick<AdyenPaymentResponse, 'captureResult' | 'resultCode'>
  & { redirect: Maybe<(
    { __typename?: 'AdyenRedirect' }
    & Pick<AdyenRedirect, 'data' | 'httpMethod' | 'url'>
  )> }
);

export type PaymentInfoFragmentFragment = (
  { __typename?: 'PaymentInfo' }
  & Pick<PaymentInfo, 'id' | 'basketCost' | 'currency' | 'payableAmount' | 'payableWithVoucherAmount' | 'paymentInfo' | 'paymentProcessor'>
  & { payments: Maybe<Array<(
    { __typename?: 'PaymentInfoPayment' }
    & Pick<PaymentInfoPayment, 'currency' | 'paidAmount' | 'paymentId' | 'paymentTime' | 'paymentType' | 'voucherCode'>
  )>> }
);

export type StopLocationFragmentFragment = (
  { __typename?: 'StopLocation' }
  & Pick<StopLocation, 'cityName' | 'countryName' | 'countyName'>
);

export type AddBasketJourneyMutationVariables = {
  basketId: Scalars['String'],
  journeysInput: JourneysInput
};


export type AddBasketJourneyMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'addJourney'>
  )> }
);

export type AddBasketTravelPassMutationVariables = {
  basketId: Scalars['String'],
  input: Array<AddTravelPassInput>
};


export type AddBasketTravelPassMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'addTravelPass'>
  )> }
);

export type AddCampaignCodeMutationVariables = {
  basketId: Scalars['String'],
  campaignCode: Scalars['String']
};


export type AddCampaignCodeMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'addCampaignCode'>
  )> }
);

export type AddCardMutationVariables = {
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  adyenPaymentInput: AdyenPaymentInput
};


export type AddCardMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & { addCard: (
      { __typename?: 'AdyenPaymentResponse' }
      & Pick<AdyenPaymentResponse, 'captureResult' | 'resultCode'>
      & { redirect: Maybe<(
        { __typename?: 'AdyenRedirect' }
        & Pick<AdyenRedirect, 'data' | 'httpMethod' | 'url'>
      )> }
    ) }
  ) }
);

export type AppleStartSessionMutationVariables = {
  validationUrl: Scalars['String']
};


export type AppleStartSessionMutation = (
  { __typename?: 'RootMutation' }
  & { apple: (
    { __typename?: 'AppleMutation' }
    & Pick<AppleMutation, 'startSession'>
  ) }
);

export type ChangePasswordMutationVariables = {
  password: Scalars['String']
};


export type ChangePasswordMutation = (
  { __typename?: 'RootMutation' }
  & { me: Maybe<(
    { __typename?: 'MeMutation' }
    & Pick<MeMutation, 'changePassword'>
  )> }
);

export type ChangeReservedSeatMutationVariables = {
  basketId: Scalars['String'],
  reservedSeatRequest: ReservedSeatRequestInput
};


export type ChangeReservedSeatMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'changeReservedSeat'>
  )> }
);

export type CreateBasketMutationVariables = {
  affiliateId?: Maybe<Scalars['String']>
};


export type CreateBasketMutation = (
  { __typename?: 'RootMutation' }
  & { createBasket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
  ) }
);

export type CreateBasketWithProductsMutationVariables = {
  basketId?: Maybe<Scalars['String']>,
  createBasketInput?: Maybe<CreateBasketInput>,
  inboundInput?: Maybe<JourneysSearchInput>,
  inboundTime?: Maybe<Scalars['String']>,
  outboundInput: JourneysSearchInput,
  outboundTime?: Maybe<Scalars['String']>
};


export type CreateBasketWithProductsMutation = (
  { __typename?: 'RootMutation' }
  & { createBasketWithProducts: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
  ) }
);

export type DeleteBasketMutationVariables = {
  id: Scalars['String']
};


export type DeleteBasketMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'delete'>
  )> }
);

export type DeleteBasketProductsMutationVariables = {
  id: Scalars['String'],
  productIds: Array<Scalars['String']>
};


export type DeleteBasketProductsMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'deleteProducts'>
  )> }
);

export type DeletePaymentMutationVariables = {
  basketId: Scalars['String'],
  paymentId: Scalars['String']
};


export type DeletePaymentMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'deletePayment'>
  )> }
);

export type DeleteSavedRecurringDetailMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeleteSavedRecurringDetailMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & Pick<AdyenMutation, 'deleteSavedRecurringDetail'>
  ) }
);

export type DevPaymentMutationVariables = {
  id: Scalars['String']
};


export type DevPaymentMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'devPay'>
  )> }
);

export type PayWithAppleMutationVariables = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  adyenPaymentInput: AdyenPaymentTokenInput
};


export type PayWithAppleMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & { payWithApple: (
      { __typename?: 'AdyenPaymentResponse' }
      & PayWithResponseFragment
    ) }
  ) }
);

export type PayWithCardMutationVariables = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  adyenPaymentInput: AdyenPaymentInput
};


export type PayWithCardMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & { payWithCard: (
      { __typename?: 'AdyenPaymentResponse' }
      & PayWithResponseFragment
    ) }
  ) }
);

export type PayWithCorporateClientContractTravelAccountMutationVariables = {
  basketId: Scalars['String'],
  identification?: Maybe<Scalars['String']>
};


export type PayWithCorporateClientContractTravelAccountMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'payWithCorporateClientContractTravelAccount'>
  )> }
);

export type PayWithCorporateClientCredentialsMutationVariables = {
  basketId: Scalars['String'],
  username: Scalars['String'],
  password: Scalars['String'],
  identification?: Maybe<Scalars['String']>
};


export type PayWithCorporateClientCredentialsMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'payWithCorporateClientContract'>
  )> }
);

export type PayWithGoogleMutationVariables = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  adyenPaymentInput: AdyenPaymentTokenInput
};


export type PayWithGoogleMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & { payWithGoogle: (
      { __typename?: 'AdyenPaymentResponse' }
      & PayWithResponseFragment
    ) }
  ) }
);

export type PayWithSavedMethodMutationVariables = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  recurringDetailReference: Scalars['String']
};


export type PayWithSavedMethodMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & { payWithSavedMethod: (
      { __typename?: 'AdyenPaymentResponse' }
      & PayWithResponseFragment
    ) }
  ) }
);

export type PayWithSavedMethodWithOneClickMutationVariables = {
  basketId: Scalars['String'],
  browserInfo?: Maybe<AdyenBrowserInfoInput>,
  encryptedSecurityCode: Scalars['String'],
  recurringDetailReference: Scalars['String']
};


export type PayWithSavedMethodWithOneClickMutation = (
  { __typename?: 'RootMutation' }
  & { adyen: (
    { __typename?: 'AdyenMutation' }
    & { payWithSavedMethodWithOneClick: (
      { __typename?: 'AdyenPaymentResponse' }
      & PayWithResponseFragment
    ) }
  ) }
);

export type PayWithVoucherMutationVariables = {
  basketId: Scalars['String'],
  code: Scalars['String'],
  paidAmount: Scalars['Float']
};


export type PayWithVoucherMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & { payWithVoucher: (
      { __typename?: 'PayWithVoucherResult' }
      & { paymentInfo: (
        { __typename?: 'PaymentInfo' }
        & PaymentInfoFragmentFragment
      ), paymentResult: (
        { __typename?: 'VoucherResult' }
        & Pick<VoucherResult, 'isVoucherUsed' | 'paidAmount' | 'voucherCurrency' | 'voucherRemainingValue'>
      ) }
    ) }
  )> }
);

export type PayWithZeroPaymentMutationVariables = {
  basketId: Scalars['String']
};


export type PayWithZeroPaymentMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'payWithZeroPayment'>
  )> }
);

export type RegisterUserMutationVariables = {
  registerUserInput: RegisterUserInput
};


export type RegisterUserMutation = (
  { __typename?: 'RootMutation' }
  & { registerUser: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'accessToken'>
  ) }
);

export type RefundingScopeFragment = (
  { __typename?: 'RefundingScope' }
  & Pick<RefundingScope, 'refundableAmount' | 'refundAmountWithServiceFee' | 'refundingScopeXc'>
);

export type RefundableProductFragment = (
  { __typename?: 'RefundableProduct' }
  & Pick<RefundableProduct, 'productCode'>
  & { refundingScopes: Array<(
    { __typename?: 'RefundingScope' }
    & RefundingScopeFragment
  )> }
);

export type ReturnCalculateMutationVariables = {
  basketId: Scalars['String'],
  paymentMethod: Scalars['String'],
  productCodes: Array<Scalars['String']>
};


export type ReturnCalculateMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & { returnCalculate: (
      { __typename?: 'ReturnCalculate' }
      & Pick<ReturnCalculate, 'id' | 'returningAllowed'>
      & { refundableProducts: Array<(
        { __typename?: 'RefundableProduct' }
        & RefundableProductFragment
      )>, paymentDetails: Array<(
        { __typename?: 'PaymentDetail' }
        & Pick<PaymentDetail, 'paymentType'>
      )> }
    ) }
  )> }
);

export type ReturnCommitMutationVariables = {
  id: Scalars['String'],
  email: Scalars['String'],
  refundableProducts: Array<RefundableProductInput>
};


export type ReturnCommitMutation = (
  { __typename?: 'RootMutation' }
  & { returnCommit: (
    { __typename?: 'ReturnCommit' }
    & Pick<ReturnCommit, 'currency' | 'refundPaymentMethod' | 'returnedAmount' | 'voucherCode'>
  ) }
);

export type UpdateBuyerInfoMutationVariables = {
  basketId: Scalars['String'],
  buyerInfoInput: BuyerInfoInput
};


export type UpdateBuyerInfoMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'updateBuyerInfo'>
  )> }
);

export type UpdateCorporateClientPriceMutationVariables = {
  basketId: Scalars['String'],
  username: Scalars['String'],
  password: Scalars['String']
};


export type UpdateCorporateClientPriceMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'updateCorporateClientPrice'>
  )> }
);

export type UpdateCustomerInfoMutationVariables = {
  basketId: Scalars['String'],
  productId: Scalars['String'],
  customerInfoInput: CustomerInfoInput
};


export type UpdateCustomerInfoMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & Pick<BasketMutation, 'updateCustomerInfo'>
  )> }
);

export type UpdateDefaultPaymentMethodMutationVariables = {
  reference: Scalars['String']
};


export type UpdateDefaultPaymentMethodMutation = (
  { __typename?: 'RootMutation' }
  & { me: Maybe<(
    { __typename?: 'MeMutation' }
    & { updateDefaultPaymentMethod: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )> }
);

export type AddLegAddonsMutationVariables = {
  basketId: Scalars['String'],
  currency: CurrencyEnum,
  productId: Scalars['String'],
  addons: Array<LegAddonInput>
};


export type AddLegAddonsMutation = (
  { __typename?: 'RootMutation' }
  & { basket: Maybe<(
    { __typename?: 'BasketMutation' }
    & { addLegAddons: (
      { __typename?: 'AddLegAddonResult' }
      & { basket: Maybe<(
        { __typename?: 'Basket' }
        & Pick<Basket, 'id'>
        & BasketContentFragment
      )>, tripLegAddons: Maybe<(
        { __typename?: 'TripLegsResponse' }
        & Pick<TripLegsResponse, 'responseValidUntil'>
        & { legAddOns: Array<(
          { __typename?: 'LegServices' }
          & Pick<LegServices, 'isOutbound' | 'legOrderNo' | 'productId'>
          & { addOns: Array<(
            { __typename?: 'ServiceWithSalesLimit' }
            & LegAddonFragment
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type AddonFragment = (
  { __typename?: 'AddOnInfo' }
  & Pick<AddOnInfo, 'code' | 'description' | 'detailsDescription' | 'detailsKey' | 'detailsTitle' | 'discount' | 'id' | 'name' | 'price' | 'quantity' | 'unitPrice'>
);

export type LegInfoFragment = (
  { __typename?: 'LegInfo' }
  & Pick<LegInfo, 'arrivalDateTime' | 'brandName' | 'busLineNumber' | 'departureDateTime' | 'departureStopCityName' | 'departureStopName' | 'destinationStopCityName' | 'destinationStopName' | 'discount' | 'orderNumber' | 'price' | 'id' | 'isChoosingSeatEnabled' | 'isReinforcement' | 'qrCodeUrl'>
  & { addOns: Array<(
    { __typename?: 'AddOnInfo' }
    & AddonFragment
  )> }
);

export type JourneyInfoFragment = (
  { __typename?: 'JourneyInfo' }
  & Pick<JourneyInfo, 'totalPrice'>
  & { legs: Maybe<Array<(
    { __typename?: 'LegInfo' }
    & LegInfoFragment
  )>> }
);

export type PassengerJourneysFragment = (
  { __typename?: 'PassengerJourneys' }
  & Pick<PassengerJourneys, 'fareClass' | 'fareClassId' | 'passengerName' | 'productId' | 'productCode' | 'totalPrice'>
  & { inboundJourney: Maybe<(
    { __typename?: 'JourneyInfo' }
    & JourneyInfoFragment
  )>, outboundJourney: Maybe<(
    { __typename?: 'JourneyInfo' }
    & JourneyInfoFragment
  )> }
);

export type BasketContentFragment = (
  { __typename?: 'Basket' }
  & Pick<Basket, 'id'>
  & { content: (
    { __typename?: 'BasketContent' }
    & Pick<BasketContent, 'currency' | 'totalPrice' | 'shoppingBasketNumber'>
    & { passengers: Maybe<Array<(
      { __typename?: 'PassengerJourneys' }
      & PassengerJourneysFragment
    )>>, services: Maybe<Array<(
      { __typename?: 'ServiceInfo' }
      & Pick<ServiceInfo, 'discount' | 'description' | 'id' | 'code' | 'name' | 'price' | 'quantity' | 'vatRate' | 'vatAmount'>
    )>> }
  ) }
);

export type BasketContentQueryVariables = {
  id: Scalars['String']
};


export type BasketContentQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id' | 'number' | 'printoutUrl'>
    & { travelPassesByGuid: Array<(
      { __typename?: 'TravelPassByGuid' }
      & Pick<TravelPassByGuid, 'bonusSchemeGroupId' | 'bonusSchemeGroupName' | 'description' | 'name' | 'number' | 'price' | 'qrCodeImage'>
      & { validityZonePair: Maybe<(
        { __typename?: 'ValidityZonePair' }
        & Pick<ValidityZonePair, 'firstZoneName' | 'secondZoneName'>
      )> }
    )> }
    & BasketContentFragment
  ) }
);

export type BasketStateQueryVariables = {
  id: Scalars['String']
};


export type BasketStateQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
    & { state: (
      { __typename?: 'BasketState' }
      & Pick<BasketState, 'isRelatedToTravelPass' | 'state'>
    ) }
  ) }
);

export type BasketValidUntilQueryVariables = {
  id: Scalars['String']
};


export type BasketValidUntilQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id' | 'validUntil'>
  ) }
);

export type BuyerInfoQueryVariables = {
  id: Scalars['String']
};


export type BuyerInfoQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
    & { buyerInfo: (
      { __typename?: 'BuyerInfo' }
      & Pick<BuyerInfo, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'country' | 'phoneAreaCode'>
    ) }
  ) }
);

export type CancelBasketQueryVariables = {
  id: Scalars['String']
};


export type CancelBasketQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
    & BasketContentFragment
  ) }
);

export type CheckCorporateClientContractTravelAccountQueryVariables = {};


export type CheckCorporateClientContractTravelAccountQuery = (
  { __typename?: 'RootQuery' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isConnectedToClientContract'>
  )> }
);

export type FareClassesQueryVariables = {};


export type FareClassesQuery = (
  { __typename?: 'RootQuery' }
  & { fareClasses: Array<(
    { __typename?: 'FareClassItem' }
    & Pick<FareClassItem, 'id' | 'name'>
  )> }
);

export type FindBasketQueryVariables = {
  email: Scalars['String'],
  shoppingBasketCode: Scalars['String']
};


export type FindBasketQuery = (
  { __typename?: 'RootQuery' }
  & Pick<RootQuery, 'basketIdByCode'>
);

export type JourneyPresetFragment = (
  { __typename?: 'JourneyPreset' }
  & Pick<JourneyPreset, 'id' | 'name' | 'position'>
  & { fromBusStop: (
    { __typename?: 'BusStop' }
    & Pick<BusStop, 'id' | 'name'>
  ), toBusStop: (
    { __typename?: 'BusStop' }
    & Pick<BusStop, 'id' | 'name'>
  ) }
);

export type JourneyPresetsQueryVariables = {};


export type JourneyPresetsQuery = (
  { __typename?: 'RootQuery' }
  & { journeyPresets: Array<(
    { __typename?: 'JourneyPreset' }
    & JourneyPresetFragment
  )> }
);

export type JourneyRouteFragment = (
  { __typename?: 'Route' }
  & Pick<Route, 'arrivalDatetime' | 'arrivalTimezone' | 'busCompanyName' | 'busLineNumber' | 'departureCityName' | 'departureDatetime' | 'departurePlatform' | 'departureStopName' | 'departureTimezone' | 'destinationCityName' | 'destinationStopName'>
  & { busEquipment: Array<(
    { __typename?: 'BusEquipment' }
    & Pick<BusEquipment, 'code' | 'displayName'>
  )>, intermediateStops: Array<(
    { __typename?: 'IntermediateStop' }
    & Pick<IntermediateStop, 'departureDateTime' | 'departureDateTimeZone' | 'isTransfer' | 'stopName'>
    & { stopLocation: Maybe<(
      { __typename?: 'StopLocation' }
      & StopLocationFragmentFragment
    )> }
  )> }
);

export type JourneyRouteQueryVariables = {
  id: Scalars['String'],
  includeIntermediateStops?: Maybe<Scalars['Boolean']>,
  source: JourneySourceEnum
};


export type JourneyRouteQuery = (
  { __typename?: 'RootQuery' }
  & { journeyRoute: Array<(
    { __typename?: 'Route' }
    & JourneyRouteFragment
  )> }
);

export type JourneysSearchQueryVariables = {
  query: JourneysSearchInput
};


export type JourneysSearchQuery = (
  { __typename?: 'RootQuery' }
  & { journeysSearch: (
    { __typename?: 'JourneySearchResponse' }
    & Pick<JourneySearchResponse, 'responseValidUntilUtc' | 'resultsSource'>
    & { journeys: Array<(
      { __typename?: 'Journey' }
      & JourneyFragmentFragment
    )> }
  ) }
);

export type MyIdQueryVariables = {};


export type MyIdQuery = (
  { __typename?: 'RootQuery' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type NormalizedTravelPassesQueryVariables = {
  query: TravelServicesInput
};


export type NormalizedTravelPassesQuery = (
  { __typename?: 'RootQuery' }
  & { normalizedTravelServices: Array<(
    { __typename?: 'NormalizedTravelService' }
    & Pick<NormalizedTravelService, 'staticTravelServiceId' | 'travelServiceId' | 'name' | 'description' | 'conditionId' | 'passengerType' | 'passengerTypeId' | 'zonePairPriceId' | 'firstZoneName' | 'zoneStopsId' | 'secondZoneName' | 'price'>
    & { firstZoneStops: Array<(
      { __typename?: 'BusStop' }
      & Pick<BusStop, 'name'>
    )>, secondZoneStops: Array<(
      { __typename?: 'BusStop' }
      & Pick<BusStop, 'name'>
    )> }
  )> }
);

export type PasswordValidationsQueryVariables = {};


export type PasswordValidationsQuery = (
  { __typename?: 'RootQuery' }
  & { passwordValidations: Array<(
    { __typename?: 'PatternValidation' }
    & Pick<PatternValidation, 'name' | 'pattern'>
  )> }
);

export type PaymentInfoQueryVariables = {
  id: Scalars['String']
};


export type PaymentInfoQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
    & { state: (
      { __typename?: 'BasketState' }
      & Pick<BasketState, 'isRelatedToTravelPass' | 'state'>
    ), paymentInfo: (
      { __typename?: 'PaymentInfo' }
      & PaymentInfoFragmentFragment
    ) }
  ) }
);

export type PaymentMethodsQueryVariables = {
  input: AdyenPaymentMethodsInput
};


export type PaymentMethodsQuery = (
  { __typename?: 'RootQuery' }
  & { adyen: (
    { __typename?: 'AdyenQuery' }
    & Pick<AdyenQuery, 'paymentMethods'>
  ) }
);

export type PhoneAreaCodesQueryVariables = {};


export type PhoneAreaCodesQuery = (
  { __typename?: 'RootQuery' }
  & { phoneAreaCodes: Array<(
    { __typename?: 'PhoneAreaCode' }
    & Pick<PhoneAreaCode, 'areaCode' | 'countryAlpha2' | 'countryName'>
  )> }
);

export type ProductsQueryVariables = {
  productCode: Scalars['String'],
  email?: Maybe<Scalars['String']>
};


export type ProductsQuery = (
  { __typename?: 'RootQuery' }
  & { products: (
    { __typename?: 'Products' }
    & { returnCalculate: Maybe<(
      { __typename?: 'ProductReturnCalculate' }
      & Pick<ProductReturnCalculate, 'currency' | 'refundAmountToClient' | 'refundPaymentMethod' | 'refundableAmount' | 'serviceFeeTotal' | 'totalPrice'>
    )> }
  ) }
);

export type SavedRecurringDetailsQueryVariables = {};


export type SavedRecurringDetailsQuery = (
  { __typename?: 'RootQuery' }
  & { adyen: (
    { __typename?: 'AdyenQuery' }
    & { savedRecurringDetails: Array<(
      { __typename?: 'AdyenRecurringDetail' }
      & Pick<AdyenRecurringDetail, 'isDefault' | 'recurringDetailReference' | 'variant' | 'contractTypes'>
      & { card: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'expiryMonth' | 'expiryYear' | 'number'>
      )> }
    )> }
  ) }
);

export type BusSeatFragment = (
  { __typename?: 'SeatPlanSeat' }
  & Pick<SeatPlanSeat, 'seatNumber' | 'isEmptyArea' | 'isAvailable' | 'columnNumber' | 'productGuid' | 'seatRank' | 'isStairs' | 'isToilet'>
  & { seatPrice: Maybe<(
    { __typename?: 'SeatPlanPrice' }
    & Pick<SeatPlanPrice, 'amount' | 'currency'>
  )> }
);

export type BusSeatPlanFragment = (
  { __typename?: 'SeatPlanBus' }
  & Pick<SeatPlanBus, 'canChooseSeatNumber' | 'tripBusProfileId'>
  & { floors: Array<(
    { __typename?: 'SeatPlanFloor' }
    & { seatRows: Array<(
      { __typename?: 'SeatPlanSeatRow' }
      & { seats: Array<(
        { __typename?: 'SeatPlanSeat' }
        & BusSeatFragment
      )> }
    )> }
  )> }
);

export type SeatPlanQueryVariables = {
  id: Scalars['String'],
  currency: CurrencyEnum,
  productIds?: Maybe<Array<Scalars['String']>>
};


export type SeatPlanQuery = (
  { __typename?: 'RootQuery' }
  & { basket: (
    { __typename?: 'Basket' }
    & Pick<Basket, 'id'>
    & { seatPlan: Array<(
      { __typename?: 'SeatPlanLeg' }
      & Pick<SeatPlanLeg, 'isOutbound'>
      & { buses: Array<(
        { __typename?: 'SeatPlanBus' }
        & BusSeatPlanFragment
      )> }
    )> }
  ) }
);

export type StopsQueryVariables = {
  tag?: Maybe<Scalars['String']>,
  excludeTag?: Maybe<Scalars['String']>
};


export type StopsQuery = (
  { __typename?: 'RootQuery' }
  & { busStops: Array<(
    { __typename?: 'CountryWithCities' }
    & Pick<CountryWithCities, 'id' | 'name'>
    & { cities: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
      & { busStops: Array<(
        { __typename?: 'BusStop' }
        & Pick<BusStop, 'hasPriority' | 'id' | 'name' | 'code' | 'availableDates' | 'stopType' | 'comment'>
        & { aliases: Array<(
          { __typename?: 'Alias' }
          & Pick<Alias, 'alias'>
        )> }
      )> }
    )> }
  )> }
);

export type TravelAccountTravelPassesQueryVariables = {
  category?: Maybe<TravelPassCategoryEnum>
};


export type TravelAccountTravelPassesQuery = (
  { __typename?: 'RootQuery' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { travelPasses: Array<(
      { __typename?: 'TravelAccountTravelPass' }
      & Pick<TravelAccountTravelPass, 'name' | 'number' | 'description' | 'bonusSchemeGroupId' | 'bonusSchemeGroupName'>
      & { validityZonePair: Maybe<(
        { __typename?: 'ValidityZonePair' }
        & Pick<ValidityZonePair, 'firstZoneName' | 'secondZoneName'>
        & { firstZoneBusStops: Array<(
          { __typename?: 'TravelPassBusStop' }
          & Pick<TravelPassBusStop, 'busStopId' | 'isMainStop'>
        )>, secondZoneBusStops: Array<(
          { __typename?: 'TravelPassBusStop' }
          & Pick<TravelPassBusStop, 'busStopId' | 'isMainStop'>
        )> }
      )> }
    )> }
  )> }
);

export type TravelPassQueryVariables = {
  guid: Scalars['String']
};


export type TravelPassQuery = (
  { __typename?: 'RootQuery' }
  & { travelPassesByGuid: Array<(
    { __typename?: 'TravelPassByGuid' }
    & Pick<TravelPassByGuid, 'number'>
  )> }
);

export type TravelPassesListQueryVariables = {
  query: TravelServicesInput
};


export type TravelPassesListQuery = (
  { __typename?: 'RootQuery' }
  & { travelServicesList: Array<(
    { __typename?: 'TravelServicesListItem' }
    & Pick<TravelServicesListItem, 'id' | 'from' | 'to' | 'price'>
  )> }
);

export type LegAddonFragment = (
  { __typename?: 'ServiceWithSalesLimit' }
  & Pick<ServiceWithSalesLimit, 'addonGuid' | 'addonId' | 'currencyXc' | 'description' | 'detailsDescription' | 'detailsDescriptionIconSvg' | 'detailsIconSvg' | 'detailsIsSingleton' | 'detailsKey' | 'detailsTitle' | 'detailsMaxQuantity' | 'detailsDoNotDisable' | 'detailsGroupKey' | 'detailsGroupPosition' | 'salesLimit' | 'serviceName' | 'unitPrice'>
);

export type TripLegAddonsQueryVariables = {
  id: Scalars['String'],
  currency: CurrencyEnum,
  productIds?: Maybe<Array<Scalars['String']>>
};


export type TripLegAddonsQuery = (
  { __typename?: 'RootQuery' }
  & { tripLegAddons: (
    { __typename?: 'TripLegsResponse' }
    & Pick<TripLegsResponse, 'responseValidUntil'>
    & { legAddOns: Array<(
      { __typename?: 'LegServices' }
      & Pick<LegServices, 'isOutbound' | 'legOrderNo' | 'productId'>
      & { addOns: Array<(
        { __typename?: 'ServiceWithSalesLimit' }
        & LegAddonFragment
      )> }
    )> }
  ) }
);

export type VoucherQueryVariables = {
  code: Scalars['String']
};


export type VoucherQuery = (
  { __typename?: 'RootQuery' }
  & { voucher: (
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'amount' | 'currency' | 'initialAmount' | 'isValid' | 'pin' | 'validUntil'>
  ) }
);

export const JourneyFragmentFragmentDoc = gql`
    fragment journeyFragment on Journey {
  arrivalDateTime
  arrivalDateTimeTimeZone
  availableBusinessCampaignSeats
  availableBusinessClassSeats
  availableCampaignSeats
  availableRegularSeats
  businessBusPrice
  businessCampaignPrice
  businessClassPrice
  campaignPrice
  currency
  departureDateTime
  departureDateTimeTimeZone
  destinationCityName
  destinationStopId
  destinationStopName
  duration
  id
  isAllergicPassengerOnBus
  isChangeable
  isForSale
  isPetOnBus
  isRefundable
  isReinforcement
  isWheelchairAreaAvailable
  legs {
    lineIdentifier1 {
      name
      value
    }
    lineIdentifier2 {
      name
      value
    }
    lineIdentifier3 {
      name
      value
    }
    orderNumber
    passengers {
      basicDiscountName
      finalPrice
    }
    brandName
  }
  originCityName
  originStopId
  originStopName
  plannedArrivalDateTime
  plannedDepartureDateTime
  priceClasses {
    numberOfSeats
    price
    priceClassName
  }
  regularBusPrice
  regularPrice
  salesFees {
    totalBusinessClassCampaignFee
    totalBusinessClassSalesFee
    totalCampaignSalesFee
    totalRegularSalesFee
  }
  soldLaterBusinessCampaignSeats
  soldLaterBusinessClassSeats
  soldLaterCampaignSeats
  soldLaterRegularSeats
  soldOutWithinLegTripGroup
  source
  timetablePdfUrl
}
    `;
export const PayWithResponseFragmentDoc = gql`
    fragment PayWithResponse on AdyenPaymentResponse {
  captureResult
  redirect {
    data
    httpMethod
    url
  }
  resultCode
}
    `;
export const PaymentInfoFragmentFragmentDoc = gql`
    fragment paymentInfoFragment on PaymentInfo {
  id
  basketCost
  currency
  payableAmount
  payableWithVoucherAmount
  paymentInfo
  paymentProcessor
  payments {
    currency
    paidAmount
    paymentId
    paymentTime
    paymentType
    voucherCode
  }
}
    `;
export const RefundingScopeFragmentDoc = gql`
    fragment RefundingScope on RefundingScope {
  refundableAmount
  refundAmountWithServiceFee
  refundingScopeXc
}
    `;
export const RefundableProductFragmentDoc = gql`
    fragment RefundableProduct on RefundableProduct {
  productCode
  refundingScopes {
    ...RefundingScope
  }
}
    ${RefundingScopeFragmentDoc}`;
export const AddonFragmentDoc = gql`
    fragment Addon on AddOnInfo {
  code
  description
  detailsDescription
  detailsKey
  detailsTitle
  discount
  id
  name
  price
  quantity
  unitPrice
}
    `;
export const LegInfoFragmentDoc = gql`
    fragment LegInfo on LegInfo {
  arrivalDateTime
  brandName
  busLineNumber
  departureDateTime
  departureStopCityName
  departureStopName
  destinationStopCityName
  destinationStopName
  discount
  orderNumber
  price
  id
  isChoosingSeatEnabled
  isReinforcement
  qrCodeUrl
  addOns {
    ...Addon
  }
}
    ${AddonFragmentDoc}`;
export const JourneyInfoFragmentDoc = gql`
    fragment JourneyInfo on JourneyInfo {
  totalPrice
  legs {
    ...LegInfo
  }
}
    ${LegInfoFragmentDoc}`;
export const PassengerJourneysFragmentDoc = gql`
    fragment PassengerJourneys on PassengerJourneys {
  fareClass
  fareClassId
  passengerName
  productId
  productCode
  totalPrice
  inboundJourney {
    ...JourneyInfo
  }
  outboundJourney {
    ...JourneyInfo
  }
}
    ${JourneyInfoFragmentDoc}`;
export const BasketContentFragmentDoc = gql`
    fragment BasketContent on Basket {
  id
  content {
    currency
    totalPrice
    shoppingBasketNumber
    passengers {
      ...PassengerJourneys
    }
    services {
      discount
      description
      id
      code
      name
      price
      quantity
      vatRate
      vatAmount
    }
  }
}
    ${PassengerJourneysFragmentDoc}`;
export const JourneyPresetFragmentDoc = gql`
    fragment JourneyPreset on JourneyPreset {
  id
  name
  fromBusStop {
    id
    name
  }
  toBusStop {
    id
    name
  }
  position
}
    `;
export const StopLocationFragmentFragmentDoc = gql`
    fragment stopLocationFragment on StopLocation {
  cityName
  countryName
  countyName
}
    `;
export const JourneyRouteFragmentDoc = gql`
    fragment JourneyRoute on Route {
  arrivalDatetime
  arrivalTimezone
  busCompanyName
  busEquipment {
    code
    displayName
  }
  busLineNumber
  departureCityName
  departureDatetime
  departurePlatform
  departureStopName
  departureTimezone
  destinationCityName
  destinationStopName
  intermediateStops {
    departureDateTime
    departureDateTimeZone
    isTransfer
    stopLocation {
      ...stopLocationFragment
    }
    stopName
  }
}
    ${StopLocationFragmentFragmentDoc}`;
export const BusSeatFragmentDoc = gql`
    fragment BusSeat on SeatPlanSeat {
  seatNumber
  isEmptyArea
  isAvailable
  columnNumber
  productGuid
  seatRank
  isStairs
  isToilet
  seatPrice {
    amount
    currency
  }
}
    `;
export const BusSeatPlanFragmentDoc = gql`
    fragment BusSeatPlan on SeatPlanBus {
  canChooseSeatNumber
  tripBusProfileId
  floors {
    seatRows {
      seats {
        ...BusSeat
      }
    }
  }
}
    ${BusSeatFragmentDoc}`;
export const LegAddonFragmentDoc = gql`
    fragment LegAddon on ServiceWithSalesLimit {
  addonGuid
  addonId
  currencyXc
  description
  detailsDescription
  detailsDescriptionIconSvg
  detailsIconSvg
  detailsIsSingleton
  detailsKey
  detailsTitle
  detailsMaxQuantity
  detailsDoNotDisable
  detailsGroupKey
  detailsGroupPosition
  salesLimit
  serviceName
  unitPrice
}
    `;
export const AddBasketJourneyDocument = gql`
    mutation AddBasketJourney($basketId: String!, $journeysInput: JourneysInput!) {
  basket(id: $basketId) {
    addJourney(journeysInput: $journeysInput)
  }
}
    `;
export type AddBasketJourneyMutationFn = ApolloReactCommon.MutationFunction<AddBasketJourneyMutation, AddBasketJourneyMutationVariables>;

/**
 * __useAddBasketJourneyMutation__
 *
 * To run a mutation, you first call `useAddBasketJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBasketJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBasketJourneyMutation, { data, loading, error }] = useAddBasketJourneyMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      journeysInput: // value for 'journeysInput'
 *   },
 * });
 */
export function useAddBasketJourneyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBasketJourneyMutation, AddBasketJourneyMutationVariables>) {
        return ApolloReactHooks.useMutation<AddBasketJourneyMutation, AddBasketJourneyMutationVariables>(AddBasketJourneyDocument, baseOptions);
      }
export type AddBasketJourneyMutationHookResult = ReturnType<typeof useAddBasketJourneyMutation>;
export type AddBasketJourneyMutationResult = ApolloReactCommon.MutationResult<AddBasketJourneyMutation>;
export type AddBasketJourneyMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBasketJourneyMutation, AddBasketJourneyMutationVariables>;
export const AddBasketTravelPassDocument = gql`
    mutation AddBasketTravelPass($basketId: String!, $input: [AddTravelPassInput!]!) {
  basket(id: $basketId) {
    addTravelPass(addTravelPassInput: $input)
  }
}
    `;
export type AddBasketTravelPassMutationFn = ApolloReactCommon.MutationFunction<AddBasketTravelPassMutation, AddBasketTravelPassMutationVariables>;

/**
 * __useAddBasketTravelPassMutation__
 *
 * To run a mutation, you first call `useAddBasketTravelPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBasketTravelPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBasketTravelPassMutation, { data, loading, error }] = useAddBasketTravelPassMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBasketTravelPassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBasketTravelPassMutation, AddBasketTravelPassMutationVariables>) {
        return ApolloReactHooks.useMutation<AddBasketTravelPassMutation, AddBasketTravelPassMutationVariables>(AddBasketTravelPassDocument, baseOptions);
      }
export type AddBasketTravelPassMutationHookResult = ReturnType<typeof useAddBasketTravelPassMutation>;
export type AddBasketTravelPassMutationResult = ApolloReactCommon.MutationResult<AddBasketTravelPassMutation>;
export type AddBasketTravelPassMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBasketTravelPassMutation, AddBasketTravelPassMutationVariables>;
export const AddCampaignCodeDocument = gql`
    mutation AddCampaignCode($basketId: String!, $campaignCode: String!) {
  basket(id: $basketId) {
    addCampaignCode(campaignCodeInput: {campaignCode: $campaignCode})
  }
}
    `;
export type AddCampaignCodeMutationFn = ApolloReactCommon.MutationFunction<AddCampaignCodeMutation, AddCampaignCodeMutationVariables>;

/**
 * __useAddCampaignCodeMutation__
 *
 * To run a mutation, you first call `useAddCampaignCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCampaignCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCampaignCodeMutation, { data, loading, error }] = useAddCampaignCodeMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      campaignCode: // value for 'campaignCode'
 *   },
 * });
 */
export function useAddCampaignCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCampaignCodeMutation, AddCampaignCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCampaignCodeMutation, AddCampaignCodeMutationVariables>(AddCampaignCodeDocument, baseOptions);
      }
export type AddCampaignCodeMutationHookResult = ReturnType<typeof useAddCampaignCodeMutation>;
export type AddCampaignCodeMutationResult = ApolloReactCommon.MutationResult<AddCampaignCodeMutation>;
export type AddCampaignCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCampaignCodeMutation, AddCampaignCodeMutationVariables>;
export const AddCardDocument = gql`
    mutation AddCard($browserInfo: AdyenBrowserInfoInput, $adyenPaymentInput: AdyenPaymentInput!) {
  adyen {
    addCard(browserInfo: $browserInfo, adyenPaymentInput: $adyenPaymentInput) {
      captureResult
      redirect {
        data
        httpMethod
        url
      }
      resultCode
    }
  }
}
    `;
export type AddCardMutationFn = ApolloReactCommon.MutationFunction<AddCardMutation, AddCardMutationVariables>;

/**
 * __useAddCardMutation__
 *
 * To run a mutation, you first call `useAddCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardMutation, { data, loading, error }] = useAddCardMutation({
 *   variables: {
 *      browserInfo: // value for 'browserInfo'
 *      adyenPaymentInput: // value for 'adyenPaymentInput'
 *   },
 * });
 */
export function useAddCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCardMutation, AddCardMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCardMutation, AddCardMutationVariables>(AddCardDocument, baseOptions);
      }
export type AddCardMutationHookResult = ReturnType<typeof useAddCardMutation>;
export type AddCardMutationResult = ApolloReactCommon.MutationResult<AddCardMutation>;
export type AddCardMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCardMutation, AddCardMutationVariables>;
export const AppleStartSessionDocument = gql`
    mutation AppleStartSession($validationUrl: String!) {
  apple {
    startSession(validationUrl: $validationUrl)
  }
}
    `;
export type AppleStartSessionMutationFn = ApolloReactCommon.MutationFunction<AppleStartSessionMutation, AppleStartSessionMutationVariables>;

/**
 * __useAppleStartSessionMutation__
 *
 * To run a mutation, you first call `useAppleStartSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppleStartSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appleStartSessionMutation, { data, loading, error }] = useAppleStartSessionMutation({
 *   variables: {
 *      validationUrl: // value for 'validationUrl'
 *   },
 * });
 */
export function useAppleStartSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppleStartSessionMutation, AppleStartSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<AppleStartSessionMutation, AppleStartSessionMutationVariables>(AppleStartSessionDocument, baseOptions);
      }
export type AppleStartSessionMutationHookResult = ReturnType<typeof useAppleStartSessionMutation>;
export type AppleStartSessionMutationResult = ApolloReactCommon.MutationResult<AppleStartSessionMutation>;
export type AppleStartSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<AppleStartSessionMutation, AppleStartSessionMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($password: String!) {
  me {
    changePassword(password: $password)
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeReservedSeatDocument = gql`
    mutation ChangeReservedSeat($basketId: String!, $reservedSeatRequest: ReservedSeatRequestInput!) {
  basket(id: $basketId) {
    changeReservedSeat(reservedSeatRequest: $reservedSeatRequest)
  }
}
    `;
export type ChangeReservedSeatMutationFn = ApolloReactCommon.MutationFunction<ChangeReservedSeatMutation, ChangeReservedSeatMutationVariables>;

/**
 * __useChangeReservedSeatMutation__
 *
 * To run a mutation, you first call `useChangeReservedSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReservedSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReservedSeatMutation, { data, loading, error }] = useChangeReservedSeatMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      reservedSeatRequest: // value for 'reservedSeatRequest'
 *   },
 * });
 */
export function useChangeReservedSeatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeReservedSeatMutation, ChangeReservedSeatMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeReservedSeatMutation, ChangeReservedSeatMutationVariables>(ChangeReservedSeatDocument, baseOptions);
      }
export type ChangeReservedSeatMutationHookResult = ReturnType<typeof useChangeReservedSeatMutation>;
export type ChangeReservedSeatMutationResult = ApolloReactCommon.MutationResult<ChangeReservedSeatMutation>;
export type ChangeReservedSeatMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeReservedSeatMutation, ChangeReservedSeatMutationVariables>;
export const CreateBasketDocument = gql`
    mutation CreateBasket($affiliateId: String) {
  createBasket(isTravelAccountRelationCreated: true, affiliateId: $affiliateId) {
    id
  }
}
    `;
export type CreateBasketMutationFn = ApolloReactCommon.MutationFunction<CreateBasketMutation, CreateBasketMutationVariables>;

/**
 * __useCreateBasketMutation__
 *
 * To run a mutation, you first call `useCreateBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBasketMutation, { data, loading, error }] = useCreateBasketMutation({
 *   variables: {
 *      affiliateId: // value for 'affiliateId'
 *   },
 * });
 */
export function useCreateBasketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBasketMutation, CreateBasketMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBasketMutation, CreateBasketMutationVariables>(CreateBasketDocument, baseOptions);
      }
export type CreateBasketMutationHookResult = ReturnType<typeof useCreateBasketMutation>;
export type CreateBasketMutationResult = ApolloReactCommon.MutationResult<CreateBasketMutation>;
export type CreateBasketMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBasketMutation, CreateBasketMutationVariables>;
export const CreateBasketWithProductsDocument = gql`
    mutation CreateBasketWithProducts($basketId: String, $createBasketInput: CreateBasketInput, $inboundInput: JourneysSearchInput, $inboundTime: String, $outboundInput: JourneysSearchInput!, $outboundTime: String) {
  createBasketWithProducts(basketId: $basketId, createBasketInput: $createBasketInput, inboundInput: $inboundInput, inboundTime: $inboundTime, outboundInput: $outboundInput, outboundTime: $outboundTime) {
    id
  }
}
    `;
export type CreateBasketWithProductsMutationFn = ApolloReactCommon.MutationFunction<CreateBasketWithProductsMutation, CreateBasketWithProductsMutationVariables>;

/**
 * __useCreateBasketWithProductsMutation__
 *
 * To run a mutation, you first call `useCreateBasketWithProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBasketWithProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBasketWithProductsMutation, { data, loading, error }] = useCreateBasketWithProductsMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      createBasketInput: // value for 'createBasketInput'
 *      inboundInput: // value for 'inboundInput'
 *      inboundTime: // value for 'inboundTime'
 *      outboundInput: // value for 'outboundInput'
 *      outboundTime: // value for 'outboundTime'
 *   },
 * });
 */
export function useCreateBasketWithProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBasketWithProductsMutation, CreateBasketWithProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBasketWithProductsMutation, CreateBasketWithProductsMutationVariables>(CreateBasketWithProductsDocument, baseOptions);
      }
export type CreateBasketWithProductsMutationHookResult = ReturnType<typeof useCreateBasketWithProductsMutation>;
export type CreateBasketWithProductsMutationResult = ApolloReactCommon.MutationResult<CreateBasketWithProductsMutation>;
export type CreateBasketWithProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBasketWithProductsMutation, CreateBasketWithProductsMutationVariables>;
export const DeleteBasketDocument = gql`
    mutation DeleteBasket($id: String!) {
  basket(id: $id) {
    delete
  }
}
    `;
export type DeleteBasketMutationFn = ApolloReactCommon.MutationFunction<DeleteBasketMutation, DeleteBasketMutationVariables>;

/**
 * __useDeleteBasketMutation__
 *
 * To run a mutation, you first call `useDeleteBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBasketMutation, { data, loading, error }] = useDeleteBasketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBasketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBasketMutation, DeleteBasketMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBasketMutation, DeleteBasketMutationVariables>(DeleteBasketDocument, baseOptions);
      }
export type DeleteBasketMutationHookResult = ReturnType<typeof useDeleteBasketMutation>;
export type DeleteBasketMutationResult = ApolloReactCommon.MutationResult<DeleteBasketMutation>;
export type DeleteBasketMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBasketMutation, DeleteBasketMutationVariables>;
export const DeleteBasketProductsDocument = gql`
    mutation DeleteBasketProducts($id: String!, $productIds: [String!]!) {
  basket(id: $id) {
    deleteProducts(deleteProductsInput: {productIds: $productIds})
  }
}
    `;
export type DeleteBasketProductsMutationFn = ApolloReactCommon.MutationFunction<DeleteBasketProductsMutation, DeleteBasketProductsMutationVariables>;

/**
 * __useDeleteBasketProductsMutation__
 *
 * To run a mutation, you first call `useDeleteBasketProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBasketProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBasketProductsMutation, { data, loading, error }] = useDeleteBasketProductsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useDeleteBasketProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBasketProductsMutation, DeleteBasketProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBasketProductsMutation, DeleteBasketProductsMutationVariables>(DeleteBasketProductsDocument, baseOptions);
      }
export type DeleteBasketProductsMutationHookResult = ReturnType<typeof useDeleteBasketProductsMutation>;
export type DeleteBasketProductsMutationResult = ApolloReactCommon.MutationResult<DeleteBasketProductsMutation>;
export type DeleteBasketProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBasketProductsMutation, DeleteBasketProductsMutationVariables>;
export const DeletePaymentDocument = gql`
    mutation DeletePayment($basketId: String!, $paymentId: String!) {
  basket(id: $basketId) {
    deletePayment(paymentId: $paymentId)
  }
}
    `;
export type DeletePaymentMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentMutation, DeletePaymentMutationVariables>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useDeletePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, baseOptions);
      }
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = ApolloReactCommon.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentMutation, DeletePaymentMutationVariables>;
export const DeleteSavedRecurringDetailDocument = gql`
    mutation DeleteSavedRecurringDetail($id: String) {
  adyen {
    deleteSavedRecurringDetail(recurringDetailReference: $id)
  }
}
    `;
export type DeleteSavedRecurringDetailMutationFn = ApolloReactCommon.MutationFunction<DeleteSavedRecurringDetailMutation, DeleteSavedRecurringDetailMutationVariables>;

/**
 * __useDeleteSavedRecurringDetailMutation__
 *
 * To run a mutation, you first call `useDeleteSavedRecurringDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedRecurringDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedRecurringDetailMutation, { data, loading, error }] = useDeleteSavedRecurringDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedRecurringDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSavedRecurringDetailMutation, DeleteSavedRecurringDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSavedRecurringDetailMutation, DeleteSavedRecurringDetailMutationVariables>(DeleteSavedRecurringDetailDocument, baseOptions);
      }
export type DeleteSavedRecurringDetailMutationHookResult = ReturnType<typeof useDeleteSavedRecurringDetailMutation>;
export type DeleteSavedRecurringDetailMutationResult = ApolloReactCommon.MutationResult<DeleteSavedRecurringDetailMutation>;
export type DeleteSavedRecurringDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSavedRecurringDetailMutation, DeleteSavedRecurringDetailMutationVariables>;
export const DevPaymentDocument = gql`
    mutation DevPayment($id: String!) {
  basket(id: $id) {
    devPay
  }
}
    `;
export type DevPaymentMutationFn = ApolloReactCommon.MutationFunction<DevPaymentMutation, DevPaymentMutationVariables>;

/**
 * __useDevPaymentMutation__
 *
 * To run a mutation, you first call `useDevPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devPaymentMutation, { data, loading, error }] = useDevPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDevPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DevPaymentMutation, DevPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DevPaymentMutation, DevPaymentMutationVariables>(DevPaymentDocument, baseOptions);
      }
export type DevPaymentMutationHookResult = ReturnType<typeof useDevPaymentMutation>;
export type DevPaymentMutationResult = ApolloReactCommon.MutationResult<DevPaymentMutation>;
export type DevPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DevPaymentMutation, DevPaymentMutationVariables>;
export const PayWithAppleDocument = gql`
    mutation PayWithApple($basketId: String!, $browserInfo: AdyenBrowserInfoInput, $adyenPaymentInput: AdyenPaymentTokenInput!) {
  adyen {
    payWithApple(basketId: $basketId, browserInfo: $browserInfo, adyenPaymentInput: $adyenPaymentInput) {
      ...PayWithResponse
    }
  }
}
    ${PayWithResponseFragmentDoc}`;
export type PayWithAppleMutationFn = ApolloReactCommon.MutationFunction<PayWithAppleMutation, PayWithAppleMutationVariables>;

/**
 * __usePayWithAppleMutation__
 *
 * To run a mutation, you first call `usePayWithAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithAppleMutation, { data, loading, error }] = usePayWithAppleMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      browserInfo: // value for 'browserInfo'
 *      adyenPaymentInput: // value for 'adyenPaymentInput'
 *   },
 * });
 */
export function usePayWithAppleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithAppleMutation, PayWithAppleMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithAppleMutation, PayWithAppleMutationVariables>(PayWithAppleDocument, baseOptions);
      }
export type PayWithAppleMutationHookResult = ReturnType<typeof usePayWithAppleMutation>;
export type PayWithAppleMutationResult = ApolloReactCommon.MutationResult<PayWithAppleMutation>;
export type PayWithAppleMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithAppleMutation, PayWithAppleMutationVariables>;
export const PayWithCardDocument = gql`
    mutation PayWithCard($basketId: String!, $browserInfo: AdyenBrowserInfoInput, $adyenPaymentInput: AdyenPaymentInput!) {
  adyen {
    payWithCard(basketId: $basketId, browserInfo: $browserInfo, adyenPaymentInput: $adyenPaymentInput) {
      ...PayWithResponse
    }
  }
}
    ${PayWithResponseFragmentDoc}`;
export type PayWithCardMutationFn = ApolloReactCommon.MutationFunction<PayWithCardMutation, PayWithCardMutationVariables>;

/**
 * __usePayWithCardMutation__
 *
 * To run a mutation, you first call `usePayWithCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithCardMutation, { data, loading, error }] = usePayWithCardMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      browserInfo: // value for 'browserInfo'
 *      adyenPaymentInput: // value for 'adyenPaymentInput'
 *   },
 * });
 */
export function usePayWithCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithCardMutation, PayWithCardMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithCardMutation, PayWithCardMutationVariables>(PayWithCardDocument, baseOptions);
      }
export type PayWithCardMutationHookResult = ReturnType<typeof usePayWithCardMutation>;
export type PayWithCardMutationResult = ApolloReactCommon.MutationResult<PayWithCardMutation>;
export type PayWithCardMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithCardMutation, PayWithCardMutationVariables>;
export const PayWithCorporateClientContractTravelAccountDocument = gql`
    mutation PayWithCorporateClientContractTravelAccount($basketId: String!, $identification: String) {
  basket(id: $basketId) {
    payWithCorporateClientContractTravelAccount(identification: $identification)
  }
}
    `;
export type PayWithCorporateClientContractTravelAccountMutationFn = ApolloReactCommon.MutationFunction<PayWithCorporateClientContractTravelAccountMutation, PayWithCorporateClientContractTravelAccountMutationVariables>;

/**
 * __usePayWithCorporateClientContractTravelAccountMutation__
 *
 * To run a mutation, you first call `usePayWithCorporateClientContractTravelAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithCorporateClientContractTravelAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithCorporateClientContractTravelAccountMutation, { data, loading, error }] = usePayWithCorporateClientContractTravelAccountMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      identification: // value for 'identification'
 *   },
 * });
 */
export function usePayWithCorporateClientContractTravelAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithCorporateClientContractTravelAccountMutation, PayWithCorporateClientContractTravelAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithCorporateClientContractTravelAccountMutation, PayWithCorporateClientContractTravelAccountMutationVariables>(PayWithCorporateClientContractTravelAccountDocument, baseOptions);
      }
export type PayWithCorporateClientContractTravelAccountMutationHookResult = ReturnType<typeof usePayWithCorporateClientContractTravelAccountMutation>;
export type PayWithCorporateClientContractTravelAccountMutationResult = ApolloReactCommon.MutationResult<PayWithCorporateClientContractTravelAccountMutation>;
export type PayWithCorporateClientContractTravelAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithCorporateClientContractTravelAccountMutation, PayWithCorporateClientContractTravelAccountMutationVariables>;
export const PayWithCorporateClientCredentialsDocument = gql`
    mutation PayWithCorporateClientCredentials($basketId: String!, $username: String!, $password: String!, $identification: String) {
  basket(id: $basketId) {
    payWithCorporateClientContract(input: {password: $password, userName: $username, currency: EUR}, identification: $identification)
  }
}
    `;
export type PayWithCorporateClientCredentialsMutationFn = ApolloReactCommon.MutationFunction<PayWithCorporateClientCredentialsMutation, PayWithCorporateClientCredentialsMutationVariables>;

/**
 * __usePayWithCorporateClientCredentialsMutation__
 *
 * To run a mutation, you first call `usePayWithCorporateClientCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithCorporateClientCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithCorporateClientCredentialsMutation, { data, loading, error }] = usePayWithCorporateClientCredentialsMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      identification: // value for 'identification'
 *   },
 * });
 */
export function usePayWithCorporateClientCredentialsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithCorporateClientCredentialsMutation, PayWithCorporateClientCredentialsMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithCorporateClientCredentialsMutation, PayWithCorporateClientCredentialsMutationVariables>(PayWithCorporateClientCredentialsDocument, baseOptions);
      }
export type PayWithCorporateClientCredentialsMutationHookResult = ReturnType<typeof usePayWithCorporateClientCredentialsMutation>;
export type PayWithCorporateClientCredentialsMutationResult = ApolloReactCommon.MutationResult<PayWithCorporateClientCredentialsMutation>;
export type PayWithCorporateClientCredentialsMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithCorporateClientCredentialsMutation, PayWithCorporateClientCredentialsMutationVariables>;
export const PayWithGoogleDocument = gql`
    mutation PayWithGoogle($basketId: String!, $browserInfo: AdyenBrowserInfoInput, $adyenPaymentInput: AdyenPaymentTokenInput!) {
  adyen {
    payWithGoogle(basketId: $basketId, browserInfo: $browserInfo, adyenPaymentInput: $adyenPaymentInput) {
      ...PayWithResponse
    }
  }
}
    ${PayWithResponseFragmentDoc}`;
export type PayWithGoogleMutationFn = ApolloReactCommon.MutationFunction<PayWithGoogleMutation, PayWithGoogleMutationVariables>;

/**
 * __usePayWithGoogleMutation__
 *
 * To run a mutation, you first call `usePayWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithGoogleMutation, { data, loading, error }] = usePayWithGoogleMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      browserInfo: // value for 'browserInfo'
 *      adyenPaymentInput: // value for 'adyenPaymentInput'
 *   },
 * });
 */
export function usePayWithGoogleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithGoogleMutation, PayWithGoogleMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithGoogleMutation, PayWithGoogleMutationVariables>(PayWithGoogleDocument, baseOptions);
      }
export type PayWithGoogleMutationHookResult = ReturnType<typeof usePayWithGoogleMutation>;
export type PayWithGoogleMutationResult = ApolloReactCommon.MutationResult<PayWithGoogleMutation>;
export type PayWithGoogleMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithGoogleMutation, PayWithGoogleMutationVariables>;
export const PayWithSavedMethodDocument = gql`
    mutation PayWithSavedMethod($basketId: String!, $browserInfo: AdyenBrowserInfoInput, $recurringDetailReference: String!) {
  adyen {
    payWithSavedMethod(basketId: $basketId, browserInfo: $browserInfo, recurringDetailReference: $recurringDetailReference) {
      ...PayWithResponse
    }
  }
}
    ${PayWithResponseFragmentDoc}`;
export type PayWithSavedMethodMutationFn = ApolloReactCommon.MutationFunction<PayWithSavedMethodMutation, PayWithSavedMethodMutationVariables>;

/**
 * __usePayWithSavedMethodMutation__
 *
 * To run a mutation, you first call `usePayWithSavedMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithSavedMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithSavedMethodMutation, { data, loading, error }] = usePayWithSavedMethodMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      browserInfo: // value for 'browserInfo'
 *      recurringDetailReference: // value for 'recurringDetailReference'
 *   },
 * });
 */
export function usePayWithSavedMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithSavedMethodMutation, PayWithSavedMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithSavedMethodMutation, PayWithSavedMethodMutationVariables>(PayWithSavedMethodDocument, baseOptions);
      }
export type PayWithSavedMethodMutationHookResult = ReturnType<typeof usePayWithSavedMethodMutation>;
export type PayWithSavedMethodMutationResult = ApolloReactCommon.MutationResult<PayWithSavedMethodMutation>;
export type PayWithSavedMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithSavedMethodMutation, PayWithSavedMethodMutationVariables>;
export const PayWithSavedMethodWithOneClickDocument = gql`
    mutation PayWithSavedMethodWithOneClick($basketId: String!, $browserInfo: AdyenBrowserInfoInput, $encryptedSecurityCode: String!, $recurringDetailReference: String!) {
  adyen {
    payWithSavedMethodWithOneClick(basketId: $basketId, browserInfo: $browserInfo, encryptedSecurityCode: $encryptedSecurityCode, recurringDetailReference: $recurringDetailReference) {
      ...PayWithResponse
    }
  }
}
    ${PayWithResponseFragmentDoc}`;
export type PayWithSavedMethodWithOneClickMutationFn = ApolloReactCommon.MutationFunction<PayWithSavedMethodWithOneClickMutation, PayWithSavedMethodWithOneClickMutationVariables>;

/**
 * __usePayWithSavedMethodWithOneClickMutation__
 *
 * To run a mutation, you first call `usePayWithSavedMethodWithOneClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithSavedMethodWithOneClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithSavedMethodWithOneClickMutation, { data, loading, error }] = usePayWithSavedMethodWithOneClickMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      browserInfo: // value for 'browserInfo'
 *      encryptedSecurityCode: // value for 'encryptedSecurityCode'
 *      recurringDetailReference: // value for 'recurringDetailReference'
 *   },
 * });
 */
export function usePayWithSavedMethodWithOneClickMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithSavedMethodWithOneClickMutation, PayWithSavedMethodWithOneClickMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithSavedMethodWithOneClickMutation, PayWithSavedMethodWithOneClickMutationVariables>(PayWithSavedMethodWithOneClickDocument, baseOptions);
      }
export type PayWithSavedMethodWithOneClickMutationHookResult = ReturnType<typeof usePayWithSavedMethodWithOneClickMutation>;
export type PayWithSavedMethodWithOneClickMutationResult = ApolloReactCommon.MutationResult<PayWithSavedMethodWithOneClickMutation>;
export type PayWithSavedMethodWithOneClickMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithSavedMethodWithOneClickMutation, PayWithSavedMethodWithOneClickMutationVariables>;
export const PayWithVoucherDocument = gql`
    mutation PayWithVoucher($basketId: String!, $code: String!, $paidAmount: Float!) {
  basket(id: $basketId) {
    payWithVoucher(voucherInput: {voucherCode: $code, currency: EUR, paidAmount: $paidAmount}) {
      paymentInfo {
        ...paymentInfoFragment
      }
      paymentResult {
        isVoucherUsed
        paidAmount
        voucherCurrency
        voucherRemainingValue
      }
    }
  }
}
    ${PaymentInfoFragmentFragmentDoc}`;
export type PayWithVoucherMutationFn = ApolloReactCommon.MutationFunction<PayWithVoucherMutation, PayWithVoucherMutationVariables>;

/**
 * __usePayWithVoucherMutation__
 *
 * To run a mutation, you first call `usePayWithVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithVoucherMutation, { data, loading, error }] = usePayWithVoucherMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      code: // value for 'code'
 *      paidAmount: // value for 'paidAmount'
 *   },
 * });
 */
export function usePayWithVoucherMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithVoucherMutation, PayWithVoucherMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithVoucherMutation, PayWithVoucherMutationVariables>(PayWithVoucherDocument, baseOptions);
      }
export type PayWithVoucherMutationHookResult = ReturnType<typeof usePayWithVoucherMutation>;
export type PayWithVoucherMutationResult = ApolloReactCommon.MutationResult<PayWithVoucherMutation>;
export type PayWithVoucherMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithVoucherMutation, PayWithVoucherMutationVariables>;
export const PayWithZeroPaymentDocument = gql`
    mutation PayWithZeroPayment($basketId: String!) {
  basket(id: $basketId) {
    payWithZeroPayment(currency: EUR)
  }
}
    `;
export type PayWithZeroPaymentMutationFn = ApolloReactCommon.MutationFunction<PayWithZeroPaymentMutation, PayWithZeroPaymentMutationVariables>;

/**
 * __usePayWithZeroPaymentMutation__
 *
 * To run a mutation, you first call `usePayWithZeroPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithZeroPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithZeroPaymentMutation, { data, loading, error }] = usePayWithZeroPaymentMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *   },
 * });
 */
export function usePayWithZeroPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayWithZeroPaymentMutation, PayWithZeroPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<PayWithZeroPaymentMutation, PayWithZeroPaymentMutationVariables>(PayWithZeroPaymentDocument, baseOptions);
      }
export type PayWithZeroPaymentMutationHookResult = ReturnType<typeof usePayWithZeroPaymentMutation>;
export type PayWithZeroPaymentMutationResult = ApolloReactCommon.MutationResult<PayWithZeroPaymentMutation>;
export type PayWithZeroPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<PayWithZeroPaymentMutation, PayWithZeroPaymentMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($registerUserInput: RegisterUserInput!) {
  registerUser(registerUserInput: $registerUserInput) {
    accessToken
  }
}
    `;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      registerUserInput: // value for 'registerUserInput'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const ReturnCalculateDocument = gql`
    mutation ReturnCalculate($basketId: String!, $paymentMethod: String!, $productCodes: [String!]!) {
  basket(id: $basketId) {
    returnCalculate(input: {refundPaymentMethod: $paymentMethod, productCodes: $productCodes}) {
      id
      refundableProducts {
        ...RefundableProduct
      }
      paymentDetails {
        paymentType
      }
      returningAllowed
    }
  }
}
    ${RefundableProductFragmentDoc}`;
export type ReturnCalculateMutationFn = ApolloReactCommon.MutationFunction<ReturnCalculateMutation, ReturnCalculateMutationVariables>;

/**
 * __useReturnCalculateMutation__
 *
 * To run a mutation, you first call `useReturnCalculateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnCalculateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnCalculateMutation, { data, loading, error }] = useReturnCalculateMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      paymentMethod: // value for 'paymentMethod'
 *      productCodes: // value for 'productCodes'
 *   },
 * });
 */
export function useReturnCalculateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReturnCalculateMutation, ReturnCalculateMutationVariables>) {
        return ApolloReactHooks.useMutation<ReturnCalculateMutation, ReturnCalculateMutationVariables>(ReturnCalculateDocument, baseOptions);
      }
export type ReturnCalculateMutationHookResult = ReturnType<typeof useReturnCalculateMutation>;
export type ReturnCalculateMutationResult = ApolloReactCommon.MutationResult<ReturnCalculateMutation>;
export type ReturnCalculateMutationOptions = ApolloReactCommon.BaseMutationOptions<ReturnCalculateMutation, ReturnCalculateMutationVariables>;
export const ReturnCommitDocument = gql`
    mutation ReturnCommit($id: String!, $email: String!, $refundableProducts: [RefundableProductInput!]!) {
  returnCommit(input: {id: $id, refundableProducts: $refundableProducts}, email: $email) {
    currency
    refundPaymentMethod
    returnedAmount
    voucherCode
  }
}
    `;
export type ReturnCommitMutationFn = ApolloReactCommon.MutationFunction<ReturnCommitMutation, ReturnCommitMutationVariables>;

/**
 * __useReturnCommitMutation__
 *
 * To run a mutation, you first call `useReturnCommitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnCommitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnCommitMutation, { data, loading, error }] = useReturnCommitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      refundableProducts: // value for 'refundableProducts'
 *   },
 * });
 */
export function useReturnCommitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReturnCommitMutation, ReturnCommitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReturnCommitMutation, ReturnCommitMutationVariables>(ReturnCommitDocument, baseOptions);
      }
export type ReturnCommitMutationHookResult = ReturnType<typeof useReturnCommitMutation>;
export type ReturnCommitMutationResult = ApolloReactCommon.MutationResult<ReturnCommitMutation>;
export type ReturnCommitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReturnCommitMutation, ReturnCommitMutationVariables>;
export const UpdateBuyerInfoDocument = gql`
    mutation UpdateBuyerInfo($basketId: String!, $buyerInfoInput: BuyerInfoInput!) {
  basket(id: $basketId) {
    updateBuyerInfo(buyerInfoInput: $buyerInfoInput)
  }
}
    `;
export type UpdateBuyerInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateBuyerInfoMutation, UpdateBuyerInfoMutationVariables>;

/**
 * __useUpdateBuyerInfoMutation__
 *
 * To run a mutation, you first call `useUpdateBuyerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuyerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuyerInfoMutation, { data, loading, error }] = useUpdateBuyerInfoMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      buyerInfoInput: // value for 'buyerInfoInput'
 *   },
 * });
 */
export function useUpdateBuyerInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBuyerInfoMutation, UpdateBuyerInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBuyerInfoMutation, UpdateBuyerInfoMutationVariables>(UpdateBuyerInfoDocument, baseOptions);
      }
export type UpdateBuyerInfoMutationHookResult = ReturnType<typeof useUpdateBuyerInfoMutation>;
export type UpdateBuyerInfoMutationResult = ApolloReactCommon.MutationResult<UpdateBuyerInfoMutation>;
export type UpdateBuyerInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBuyerInfoMutation, UpdateBuyerInfoMutationVariables>;
export const UpdateCorporateClientPriceDocument = gql`
    mutation UpdateCorporateClientPrice($basketId: String!, $username: String!, $password: String!) {
  basket(id: $basketId) {
    updateCorporateClientPrice(input: {password: $password, username: $username})
  }
}
    `;
export type UpdateCorporateClientPriceMutationFn = ApolloReactCommon.MutationFunction<UpdateCorporateClientPriceMutation, UpdateCorporateClientPriceMutationVariables>;

/**
 * __useUpdateCorporateClientPriceMutation__
 *
 * To run a mutation, you first call `useUpdateCorporateClientPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCorporateClientPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCorporateClientPriceMutation, { data, loading, error }] = useUpdateCorporateClientPriceMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateCorporateClientPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCorporateClientPriceMutation, UpdateCorporateClientPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCorporateClientPriceMutation, UpdateCorporateClientPriceMutationVariables>(UpdateCorporateClientPriceDocument, baseOptions);
      }
export type UpdateCorporateClientPriceMutationHookResult = ReturnType<typeof useUpdateCorporateClientPriceMutation>;
export type UpdateCorporateClientPriceMutationResult = ApolloReactCommon.MutationResult<UpdateCorporateClientPriceMutation>;
export type UpdateCorporateClientPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCorporateClientPriceMutation, UpdateCorporateClientPriceMutationVariables>;
export const UpdateCustomerInfoDocument = gql`
    mutation UpdateCustomerInfo($basketId: String!, $productId: String!, $customerInfoInput: CustomerInfoInput!) {
  basket(id: $basketId) {
    updateCustomerInfo(productId: $productId, customerInfoInput: $customerInfoInput)
  }
}
    `;
export type UpdateCustomerInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerInfoMutation, UpdateCustomerInfoMutationVariables>;

/**
 * __useUpdateCustomerInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerInfoMutation, { data, loading, error }] = useUpdateCustomerInfoMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      productId: // value for 'productId'
 *      customerInfoInput: // value for 'customerInfoInput'
 *   },
 * });
 */
export function useUpdateCustomerInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerInfoMutation, UpdateCustomerInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerInfoMutation, UpdateCustomerInfoMutationVariables>(UpdateCustomerInfoDocument, baseOptions);
      }
export type UpdateCustomerInfoMutationHookResult = ReturnType<typeof useUpdateCustomerInfoMutation>;
export type UpdateCustomerInfoMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerInfoMutation>;
export type UpdateCustomerInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerInfoMutation, UpdateCustomerInfoMutationVariables>;
export const UpdateDefaultPaymentMethodDocument = gql`
    mutation UpdateDefaultPaymentMethod($reference: String!) {
  me {
    updateDefaultPaymentMethod(referenceType: pspReference, reference: $reference) {
      id
    }
  }
}
    `;
export type UpdateDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>;

/**
 * __useUpdateDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultPaymentMethodMutation, { data, loading, error }] = useUpdateDefaultPaymentMethodMutation({
 *   variables: {
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useUpdateDefaultPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>(UpdateDefaultPaymentMethodDocument, baseOptions);
      }
export type UpdateDefaultPaymentMethodMutationHookResult = ReturnType<typeof useUpdateDefaultPaymentMethodMutation>;
export type UpdateDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateDefaultPaymentMethodMutation>;
export type UpdateDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>;
export const AddLegAddonsDocument = gql`
    mutation AddLegAddons($basketId: String!, $currency: CurrencyEnum!, $productId: String!, $addons: [LegAddonInput!]!) {
  basket(id: $basketId) {
    addLegAddons(productId: $productId, addons: $addons) {
      basket(id: $basketId) {
        id
        ...BasketContent
      }
      tripLegAddons(basketId: $basketId, currency: $currency, productIds: [$productId]) {
        responseValidUntil
        legAddOns {
          addOns {
            ...LegAddon
          }
          isOutbound
          legOrderNo
          productId
        }
      }
    }
  }
}
    ${BasketContentFragmentDoc}
${LegAddonFragmentDoc}`;
export type AddLegAddonsMutationFn = ApolloReactCommon.MutationFunction<AddLegAddonsMutation, AddLegAddonsMutationVariables>;

/**
 * __useAddLegAddonsMutation__
 *
 * To run a mutation, you first call `useAddLegAddonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLegAddonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLegAddonsMutation, { data, loading, error }] = useAddLegAddonsMutation({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      currency: // value for 'currency'
 *      productId: // value for 'productId'
 *      addons: // value for 'addons'
 *   },
 * });
 */
export function useAddLegAddonsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLegAddonsMutation, AddLegAddonsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddLegAddonsMutation, AddLegAddonsMutationVariables>(AddLegAddonsDocument, baseOptions);
      }
export type AddLegAddonsMutationHookResult = ReturnType<typeof useAddLegAddonsMutation>;
export type AddLegAddonsMutationResult = ApolloReactCommon.MutationResult<AddLegAddonsMutation>;
export type AddLegAddonsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLegAddonsMutation, AddLegAddonsMutationVariables>;
export const BasketContentDocument = gql`
    query BasketContent($id: String!) {
  basket(id: $id) {
    id
    number
    ...BasketContent
    printoutUrl
    travelPassesByGuid {
      bonusSchemeGroupId
      bonusSchemeGroupName
      description
      name
      number
      price
      qrCodeImage
      validityZonePair {
        firstZoneName
        secondZoneName
      }
    }
  }
}
    ${BasketContentFragmentDoc}`;

/**
 * __useBasketContentQuery__
 *
 * To run a query within a React component, call `useBasketContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketContentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasketContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BasketContentQuery, BasketContentQueryVariables>) {
        return ApolloReactHooks.useQuery<BasketContentQuery, BasketContentQueryVariables>(BasketContentDocument, baseOptions);
      }
export function useBasketContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasketContentQuery, BasketContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BasketContentQuery, BasketContentQueryVariables>(BasketContentDocument, baseOptions);
        }
export type BasketContentQueryHookResult = ReturnType<typeof useBasketContentQuery>;
export type BasketContentLazyQueryHookResult = ReturnType<typeof useBasketContentLazyQuery>;
export type BasketContentQueryResult = ApolloReactCommon.QueryResult<BasketContentQuery, BasketContentQueryVariables>;
export const BasketStateDocument = gql`
    query BasketState($id: String!) {
  basket(id: $id) {
    id
    state {
      isRelatedToTravelPass
      state
    }
  }
}
    `;

/**
 * __useBasketStateQuery__
 *
 * To run a query within a React component, call `useBasketStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketStateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasketStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BasketStateQuery, BasketStateQueryVariables>) {
        return ApolloReactHooks.useQuery<BasketStateQuery, BasketStateQueryVariables>(BasketStateDocument, baseOptions);
      }
export function useBasketStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasketStateQuery, BasketStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BasketStateQuery, BasketStateQueryVariables>(BasketStateDocument, baseOptions);
        }
export type BasketStateQueryHookResult = ReturnType<typeof useBasketStateQuery>;
export type BasketStateLazyQueryHookResult = ReturnType<typeof useBasketStateLazyQuery>;
export type BasketStateQueryResult = ApolloReactCommon.QueryResult<BasketStateQuery, BasketStateQueryVariables>;
export const BasketValidUntilDocument = gql`
    query BasketValidUntil($id: String!) {
  basket(id: $id) {
    id
    validUntil
  }
}
    `;

/**
 * __useBasketValidUntilQuery__
 *
 * To run a query within a React component, call `useBasketValidUntilQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketValidUntilQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketValidUntilQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasketValidUntilQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BasketValidUntilQuery, BasketValidUntilQueryVariables>) {
        return ApolloReactHooks.useQuery<BasketValidUntilQuery, BasketValidUntilQueryVariables>(BasketValidUntilDocument, baseOptions);
      }
export function useBasketValidUntilLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasketValidUntilQuery, BasketValidUntilQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BasketValidUntilQuery, BasketValidUntilQueryVariables>(BasketValidUntilDocument, baseOptions);
        }
export type BasketValidUntilQueryHookResult = ReturnType<typeof useBasketValidUntilQuery>;
export type BasketValidUntilLazyQueryHookResult = ReturnType<typeof useBasketValidUntilLazyQuery>;
export type BasketValidUntilQueryResult = ApolloReactCommon.QueryResult<BasketValidUntilQuery, BasketValidUntilQueryVariables>;
export const BuyerInfoDocument = gql`
    query BuyerInfo($id: String!) {
  basket(id: $id) {
    id
    buyerInfo {
      email
      firstName
      lastName
      phoneNumber
      country
      phoneAreaCode
    }
  }
}
    `;

/**
 * __useBuyerInfoQuery__
 *
 * To run a query within a React component, call `useBuyerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyerInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BuyerInfoQuery, BuyerInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<BuyerInfoQuery, BuyerInfoQueryVariables>(BuyerInfoDocument, baseOptions);
      }
export function useBuyerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BuyerInfoQuery, BuyerInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BuyerInfoQuery, BuyerInfoQueryVariables>(BuyerInfoDocument, baseOptions);
        }
export type BuyerInfoQueryHookResult = ReturnType<typeof useBuyerInfoQuery>;
export type BuyerInfoLazyQueryHookResult = ReturnType<typeof useBuyerInfoLazyQuery>;
export type BuyerInfoQueryResult = ApolloReactCommon.QueryResult<BuyerInfoQuery, BuyerInfoQueryVariables>;
export const CancelBasketDocument = gql`
    query CancelBasket($id: String!) {
  basket(id: $id) {
    id
    ...BasketContent
  }
}
    ${BasketContentFragmentDoc}`;

/**
 * __useCancelBasketQuery__
 *
 * To run a query within a React component, call `useCancelBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelBasketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelBasketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CancelBasketQuery, CancelBasketQueryVariables>) {
        return ApolloReactHooks.useQuery<CancelBasketQuery, CancelBasketQueryVariables>(CancelBasketDocument, baseOptions);
      }
export function useCancelBasketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CancelBasketQuery, CancelBasketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CancelBasketQuery, CancelBasketQueryVariables>(CancelBasketDocument, baseOptions);
        }
export type CancelBasketQueryHookResult = ReturnType<typeof useCancelBasketQuery>;
export type CancelBasketLazyQueryHookResult = ReturnType<typeof useCancelBasketLazyQuery>;
export type CancelBasketQueryResult = ApolloReactCommon.QueryResult<CancelBasketQuery, CancelBasketQueryVariables>;
export const CheckCorporateClientContractTravelAccountDocument = gql`
    query CheckCorporateClientContractTravelAccount {
  me {
    id
    isConnectedToClientContract
  }
}
    `;

/**
 * __useCheckCorporateClientContractTravelAccountQuery__
 *
 * To run a query within a React component, call `useCheckCorporateClientContractTravelAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCorporateClientContractTravelAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCorporateClientContractTravelAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckCorporateClientContractTravelAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckCorporateClientContractTravelAccountQuery, CheckCorporateClientContractTravelAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckCorporateClientContractTravelAccountQuery, CheckCorporateClientContractTravelAccountQueryVariables>(CheckCorporateClientContractTravelAccountDocument, baseOptions);
      }
export function useCheckCorporateClientContractTravelAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckCorporateClientContractTravelAccountQuery, CheckCorporateClientContractTravelAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckCorporateClientContractTravelAccountQuery, CheckCorporateClientContractTravelAccountQueryVariables>(CheckCorporateClientContractTravelAccountDocument, baseOptions);
        }
export type CheckCorporateClientContractTravelAccountQueryHookResult = ReturnType<typeof useCheckCorporateClientContractTravelAccountQuery>;
export type CheckCorporateClientContractTravelAccountLazyQueryHookResult = ReturnType<typeof useCheckCorporateClientContractTravelAccountLazyQuery>;
export type CheckCorporateClientContractTravelAccountQueryResult = ApolloReactCommon.QueryResult<CheckCorporateClientContractTravelAccountQuery, CheckCorporateClientContractTravelAccountQueryVariables>;
export const FareClassesDocument = gql`
    query FareClasses {
  fareClasses {
    id
    name
  }
}
    `;

/**
 * __useFareClassesQuery__
 *
 * To run a query within a React component, call `useFareClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFareClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFareClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFareClassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FareClassesQuery, FareClassesQueryVariables>) {
        return ApolloReactHooks.useQuery<FareClassesQuery, FareClassesQueryVariables>(FareClassesDocument, baseOptions);
      }
export function useFareClassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FareClassesQuery, FareClassesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FareClassesQuery, FareClassesQueryVariables>(FareClassesDocument, baseOptions);
        }
export type FareClassesQueryHookResult = ReturnType<typeof useFareClassesQuery>;
export type FareClassesLazyQueryHookResult = ReturnType<typeof useFareClassesLazyQuery>;
export type FareClassesQueryResult = ApolloReactCommon.QueryResult<FareClassesQuery, FareClassesQueryVariables>;
export const FindBasketDocument = gql`
    query FindBasket($email: String!, $shoppingBasketCode: String!) {
  basketIdByCode(input: {email: $email, shoppingBasketCode: $shoppingBasketCode})
}
    `;

/**
 * __useFindBasketQuery__
 *
 * To run a query within a React component, call `useFindBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBasketQuery({
 *   variables: {
 *      email: // value for 'email'
 *      shoppingBasketCode: // value for 'shoppingBasketCode'
 *   },
 * });
 */
export function useFindBasketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindBasketQuery, FindBasketQueryVariables>) {
        return ApolloReactHooks.useQuery<FindBasketQuery, FindBasketQueryVariables>(FindBasketDocument, baseOptions);
      }
export function useFindBasketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindBasketQuery, FindBasketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindBasketQuery, FindBasketQueryVariables>(FindBasketDocument, baseOptions);
        }
export type FindBasketQueryHookResult = ReturnType<typeof useFindBasketQuery>;
export type FindBasketLazyQueryHookResult = ReturnType<typeof useFindBasketLazyQuery>;
export type FindBasketQueryResult = ApolloReactCommon.QueryResult<FindBasketQuery, FindBasketQueryVariables>;
export const JourneyPresetsDocument = gql`
    query JourneyPresets {
  journeyPresets {
    ...JourneyPreset
  }
}
    ${JourneyPresetFragmentDoc}`;

/**
 * __useJourneyPresetsQuery__
 *
 * To run a query within a React component, call `useJourneyPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyPresetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJourneyPresetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JourneyPresetsQuery, JourneyPresetsQueryVariables>) {
        return ApolloReactHooks.useQuery<JourneyPresetsQuery, JourneyPresetsQueryVariables>(JourneyPresetsDocument, baseOptions);
      }
export function useJourneyPresetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JourneyPresetsQuery, JourneyPresetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JourneyPresetsQuery, JourneyPresetsQueryVariables>(JourneyPresetsDocument, baseOptions);
        }
export type JourneyPresetsQueryHookResult = ReturnType<typeof useJourneyPresetsQuery>;
export type JourneyPresetsLazyQueryHookResult = ReturnType<typeof useJourneyPresetsLazyQuery>;
export type JourneyPresetsQueryResult = ApolloReactCommon.QueryResult<JourneyPresetsQuery, JourneyPresetsQueryVariables>;
export const JourneyRouteDocument = gql`
    query JourneyRoute($id: String!, $includeIntermediateStops: Boolean, $source: JourneySourceEnum!) {
  journeyRoute(journeyId: $id, includeIntermediateStops: $includeIntermediateStops, source: $source) {
    ...JourneyRoute
  }
}
    ${JourneyRouteFragmentDoc}`;

/**
 * __useJourneyRouteQuery__
 *
 * To run a query within a React component, call `useJourneyRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeIntermediateStops: // value for 'includeIntermediateStops'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useJourneyRouteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JourneyRouteQuery, JourneyRouteQueryVariables>) {
        return ApolloReactHooks.useQuery<JourneyRouteQuery, JourneyRouteQueryVariables>(JourneyRouteDocument, baseOptions);
      }
export function useJourneyRouteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JourneyRouteQuery, JourneyRouteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JourneyRouteQuery, JourneyRouteQueryVariables>(JourneyRouteDocument, baseOptions);
        }
export type JourneyRouteQueryHookResult = ReturnType<typeof useJourneyRouteQuery>;
export type JourneyRouteLazyQueryHookResult = ReturnType<typeof useJourneyRouteLazyQuery>;
export type JourneyRouteQueryResult = ApolloReactCommon.QueryResult<JourneyRouteQuery, JourneyRouteQueryVariables>;
export const JourneysSearchDocument = gql`
    query JourneysSearch($query: JourneysSearchInput!) {
  journeysSearch(journeysSearchInput: $query) {
    journeys {
      ...journeyFragment
    }
    responseValidUntilUtc
    resultsSource
  }
}
    ${JourneyFragmentFragmentDoc}`;

/**
 * __useJourneysSearchQuery__
 *
 * To run a query within a React component, call `useJourneysSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneysSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneysSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useJourneysSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JourneysSearchQuery, JourneysSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<JourneysSearchQuery, JourneysSearchQueryVariables>(JourneysSearchDocument, baseOptions);
      }
export function useJourneysSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JourneysSearchQuery, JourneysSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JourneysSearchQuery, JourneysSearchQueryVariables>(JourneysSearchDocument, baseOptions);
        }
export type JourneysSearchQueryHookResult = ReturnType<typeof useJourneysSearchQuery>;
export type JourneysSearchLazyQueryHookResult = ReturnType<typeof useJourneysSearchLazyQuery>;
export type JourneysSearchQueryResult = ApolloReactCommon.QueryResult<JourneysSearchQuery, JourneysSearchQueryVariables>;
export const MyIdDocument = gql`
    query MyId {
  me {
    id
  }
}
    `;

/**
 * __useMyIdQuery__
 *
 * To run a query within a React component, call `useMyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyIdQuery, MyIdQueryVariables>) {
        return ApolloReactHooks.useQuery<MyIdQuery, MyIdQueryVariables>(MyIdDocument, baseOptions);
      }
export function useMyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyIdQuery, MyIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyIdQuery, MyIdQueryVariables>(MyIdDocument, baseOptions);
        }
export type MyIdQueryHookResult = ReturnType<typeof useMyIdQuery>;
export type MyIdLazyQueryHookResult = ReturnType<typeof useMyIdLazyQuery>;
export type MyIdQueryResult = ApolloReactCommon.QueryResult<MyIdQuery, MyIdQueryVariables>;
export const NormalizedTravelPassesDocument = gql`
    query NormalizedTravelPasses($query: TravelServicesInput!) {
  normalizedTravelServices(travelServicesInput: $query) {
    staticTravelServiceId
    travelServiceId
    name
    description
    conditionId
    passengerType
    passengerTypeId
    zonePairPriceId
    firstZoneName
    zoneStopsId
    firstZoneStops {
      name
    }
    secondZoneName
    secondZoneStops {
      name
    }
    price
  }
}
    `;

/**
 * __useNormalizedTravelPassesQuery__
 *
 * To run a query within a React component, call `useNormalizedTravelPassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNormalizedTravelPassesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNormalizedTravelPassesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useNormalizedTravelPassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NormalizedTravelPassesQuery, NormalizedTravelPassesQueryVariables>) {
        return ApolloReactHooks.useQuery<NormalizedTravelPassesQuery, NormalizedTravelPassesQueryVariables>(NormalizedTravelPassesDocument, baseOptions);
      }
export function useNormalizedTravelPassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NormalizedTravelPassesQuery, NormalizedTravelPassesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NormalizedTravelPassesQuery, NormalizedTravelPassesQueryVariables>(NormalizedTravelPassesDocument, baseOptions);
        }
export type NormalizedTravelPassesQueryHookResult = ReturnType<typeof useNormalizedTravelPassesQuery>;
export type NormalizedTravelPassesLazyQueryHookResult = ReturnType<typeof useNormalizedTravelPassesLazyQuery>;
export type NormalizedTravelPassesQueryResult = ApolloReactCommon.QueryResult<NormalizedTravelPassesQuery, NormalizedTravelPassesQueryVariables>;
export const PasswordValidationsDocument = gql`
    query PasswordValidations {
  passwordValidations {
    name
    pattern
  }
}
    `;

/**
 * __usePasswordValidationsQuery__
 *
 * To run a query within a React component, call `usePasswordValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePasswordValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePasswordValidationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePasswordValidationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PasswordValidationsQuery, PasswordValidationsQueryVariables>) {
        return ApolloReactHooks.useQuery<PasswordValidationsQuery, PasswordValidationsQueryVariables>(PasswordValidationsDocument, baseOptions);
      }
export function usePasswordValidationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PasswordValidationsQuery, PasswordValidationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PasswordValidationsQuery, PasswordValidationsQueryVariables>(PasswordValidationsDocument, baseOptions);
        }
export type PasswordValidationsQueryHookResult = ReturnType<typeof usePasswordValidationsQuery>;
export type PasswordValidationsLazyQueryHookResult = ReturnType<typeof usePasswordValidationsLazyQuery>;
export type PasswordValidationsQueryResult = ApolloReactCommon.QueryResult<PasswordValidationsQuery, PasswordValidationsQueryVariables>;
export const PaymentInfoDocument = gql`
    query PaymentInfo($id: String!) {
  basket(id: $id) {
    id
    state {
      isRelatedToTravelPass
      state
    }
    paymentInfo {
      ...paymentInfoFragment
    }
  }
}
    ${PaymentInfoFragmentFragmentDoc}`;

/**
 * __usePaymentInfoQuery__
 *
 * To run a query within a React component, call `usePaymentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentInfoQuery, PaymentInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentInfoQuery, PaymentInfoQueryVariables>(PaymentInfoDocument, baseOptions);
      }
export function usePaymentInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentInfoQuery, PaymentInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentInfoQuery, PaymentInfoQueryVariables>(PaymentInfoDocument, baseOptions);
        }
export type PaymentInfoQueryHookResult = ReturnType<typeof usePaymentInfoQuery>;
export type PaymentInfoLazyQueryHookResult = ReturnType<typeof usePaymentInfoLazyQuery>;
export type PaymentInfoQueryResult = ApolloReactCommon.QueryResult<PaymentInfoQuery, PaymentInfoQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods($input: AdyenPaymentMethodsInput!) {
  adyen {
    paymentMethods(input: $input)
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, baseOptions);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, baseOptions);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = ApolloReactCommon.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const PhoneAreaCodesDocument = gql`
    query PhoneAreaCodes {
  phoneAreaCodes {
    areaCode
    countryAlpha2
    countryName
  }
}
    `;

/**
 * __usePhoneAreaCodesQuery__
 *
 * To run a query within a React component, call `usePhoneAreaCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneAreaCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneAreaCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhoneAreaCodesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PhoneAreaCodesQuery, PhoneAreaCodesQueryVariables>) {
        return ApolloReactHooks.useQuery<PhoneAreaCodesQuery, PhoneAreaCodesQueryVariables>(PhoneAreaCodesDocument, baseOptions);
      }
export function usePhoneAreaCodesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PhoneAreaCodesQuery, PhoneAreaCodesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PhoneAreaCodesQuery, PhoneAreaCodesQueryVariables>(PhoneAreaCodesDocument, baseOptions);
        }
export type PhoneAreaCodesQueryHookResult = ReturnType<typeof usePhoneAreaCodesQuery>;
export type PhoneAreaCodesLazyQueryHookResult = ReturnType<typeof usePhoneAreaCodesLazyQuery>;
export type PhoneAreaCodesQueryResult = ApolloReactCommon.QueryResult<PhoneAreaCodesQuery, PhoneAreaCodesQueryVariables>;
export const ProductsDocument = gql`
    query Products($productCode: String!, $email: String) {
  products(id: $productCode) {
    returnCalculate(email: $email) {
      currency
      refundAmountToClient
      refundPaymentMethod
      refundableAmount
      serviceFeeTotal
      totalPrice
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const SavedRecurringDetailsDocument = gql`
    query SavedRecurringDetails {
  adyen {
    savedRecurringDetails {
      card {
        expiryMonth
        expiryYear
        number
      }
      isDefault
      recurringDetailReference
      variant
      contractTypes
    }
  }
}
    `;

/**
 * __useSavedRecurringDetailsQuery__
 *
 * To run a query within a React component, call `useSavedRecurringDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedRecurringDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedRecurringDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedRecurringDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SavedRecurringDetailsQuery, SavedRecurringDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<SavedRecurringDetailsQuery, SavedRecurringDetailsQueryVariables>(SavedRecurringDetailsDocument, baseOptions);
      }
export function useSavedRecurringDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SavedRecurringDetailsQuery, SavedRecurringDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SavedRecurringDetailsQuery, SavedRecurringDetailsQueryVariables>(SavedRecurringDetailsDocument, baseOptions);
        }
export type SavedRecurringDetailsQueryHookResult = ReturnType<typeof useSavedRecurringDetailsQuery>;
export type SavedRecurringDetailsLazyQueryHookResult = ReturnType<typeof useSavedRecurringDetailsLazyQuery>;
export type SavedRecurringDetailsQueryResult = ApolloReactCommon.QueryResult<SavedRecurringDetailsQuery, SavedRecurringDetailsQueryVariables>;
export const SeatPlanDocument = gql`
    query SeatPlan($id: String!, $currency: CurrencyEnum!, $productIds: [String!]) {
  basket(id: $id) {
    id
    seatPlan(currency: $currency, productIds: $productIds) {
      isOutbound
      buses {
        ...BusSeatPlan
      }
    }
  }
}
    ${BusSeatPlanFragmentDoc}`;

/**
 * __useSeatPlanQuery__
 *
 * To run a query within a React component, call `useSeatPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currency: // value for 'currency'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useSeatPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeatPlanQuery, SeatPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<SeatPlanQuery, SeatPlanQueryVariables>(SeatPlanDocument, baseOptions);
      }
export function useSeatPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeatPlanQuery, SeatPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeatPlanQuery, SeatPlanQueryVariables>(SeatPlanDocument, baseOptions);
        }
export type SeatPlanQueryHookResult = ReturnType<typeof useSeatPlanQuery>;
export type SeatPlanLazyQueryHookResult = ReturnType<typeof useSeatPlanLazyQuery>;
export type SeatPlanQueryResult = ApolloReactCommon.QueryResult<SeatPlanQuery, SeatPlanQueryVariables>;
export const StopsDocument = gql`
    query Stops($tag: String, $excludeTag: String) {
  busStops(busStopsInput: {onlyWithValidTrips: false, tag: $tag, excludeTag: $excludeTag}) {
    id
    name
    cities {
      id
      name
      busStops {
        hasPriority
        id
        name
        code
        aliases {
          alias
        }
        availableDates
        stopType
        comment
      }
    }
  }
}
    `;

/**
 * __useStopsQuery__
 *
 * To run a query within a React component, call `useStopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStopsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStopsQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *      excludeTag: // value for 'excludeTag'
 *   },
 * });
 */
export function useStopsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StopsQuery, StopsQueryVariables>) {
        return ApolloReactHooks.useQuery<StopsQuery, StopsQueryVariables>(StopsDocument, baseOptions);
      }
export function useStopsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StopsQuery, StopsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StopsQuery, StopsQueryVariables>(StopsDocument, baseOptions);
        }
export type StopsQueryHookResult = ReturnType<typeof useStopsQuery>;
export type StopsLazyQueryHookResult = ReturnType<typeof useStopsLazyQuery>;
export type StopsQueryResult = ApolloReactCommon.QueryResult<StopsQuery, StopsQueryVariables>;
export const TravelAccountTravelPassesDocument = gql`
    query TravelAccountTravelPasses($category: TravelPassCategoryEnum) {
  me {
    id
    travelPasses(category: $category) {
      name
      number
      description
      bonusSchemeGroupId
      bonusSchemeGroupName
      validityZonePair {
        firstZoneName
        firstZoneBusStops {
          busStopId
          isMainStop
        }
        secondZoneName
        secondZoneBusStops {
          busStopId
          isMainStop
        }
      }
    }
  }
}
    `;

/**
 * __useTravelAccountTravelPassesQuery__
 *
 * To run a query within a React component, call `useTravelAccountTravelPassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAccountTravelPassesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAccountTravelPassesQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useTravelAccountTravelPassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAccountTravelPassesQuery, TravelAccountTravelPassesQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAccountTravelPassesQuery, TravelAccountTravelPassesQueryVariables>(TravelAccountTravelPassesDocument, baseOptions);
      }
export function useTravelAccountTravelPassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAccountTravelPassesQuery, TravelAccountTravelPassesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAccountTravelPassesQuery, TravelAccountTravelPassesQueryVariables>(TravelAccountTravelPassesDocument, baseOptions);
        }
export type TravelAccountTravelPassesQueryHookResult = ReturnType<typeof useTravelAccountTravelPassesQuery>;
export type TravelAccountTravelPassesLazyQueryHookResult = ReturnType<typeof useTravelAccountTravelPassesLazyQuery>;
export type TravelAccountTravelPassesQueryResult = ApolloReactCommon.QueryResult<TravelAccountTravelPassesQuery, TravelAccountTravelPassesQueryVariables>;
export const TravelPassDocument = gql`
    query TravelPass($guid: String!) {
  travelPassesByGuid(input: {travelPassGuid: $guid}) {
    number
  }
}
    `;

/**
 * __useTravelPassQuery__
 *
 * To run a query within a React component, call `useTravelPassQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelPassQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelPassQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useTravelPassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelPassQuery, TravelPassQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelPassQuery, TravelPassQueryVariables>(TravelPassDocument, baseOptions);
      }
export function useTravelPassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelPassQuery, TravelPassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelPassQuery, TravelPassQueryVariables>(TravelPassDocument, baseOptions);
        }
export type TravelPassQueryHookResult = ReturnType<typeof useTravelPassQuery>;
export type TravelPassLazyQueryHookResult = ReturnType<typeof useTravelPassLazyQuery>;
export type TravelPassQueryResult = ApolloReactCommon.QueryResult<TravelPassQuery, TravelPassQueryVariables>;
export const TravelPassesListDocument = gql`
    query TravelPassesList($query: TravelServicesInput!) {
  travelServicesList(travelServicesInput: $query) {
    id
    from
    to
    price
  }
}
    `;

/**
 * __useTravelPassesListQuery__
 *
 * To run a query within a React component, call `useTravelPassesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelPassesListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelPassesListQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTravelPassesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelPassesListQuery, TravelPassesListQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelPassesListQuery, TravelPassesListQueryVariables>(TravelPassesListDocument, baseOptions);
      }
export function useTravelPassesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelPassesListQuery, TravelPassesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelPassesListQuery, TravelPassesListQueryVariables>(TravelPassesListDocument, baseOptions);
        }
export type TravelPassesListQueryHookResult = ReturnType<typeof useTravelPassesListQuery>;
export type TravelPassesListLazyQueryHookResult = ReturnType<typeof useTravelPassesListLazyQuery>;
export type TravelPassesListQueryResult = ApolloReactCommon.QueryResult<TravelPassesListQuery, TravelPassesListQueryVariables>;
export const TripLegAddonsDocument = gql`
    query TripLegAddons($id: String!, $currency: CurrencyEnum!, $productIds: [String!]) {
  tripLegAddons(currency: $currency, basketId: $id, productIds: $productIds) {
    responseValidUntil
    legAddOns {
      addOns {
        ...LegAddon
      }
      isOutbound
      legOrderNo
      productId
    }
  }
}
    ${LegAddonFragmentDoc}`;

/**
 * __useTripLegAddonsQuery__
 *
 * To run a query within a React component, call `useTripLegAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripLegAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripLegAddonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currency: // value for 'currency'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useTripLegAddonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TripLegAddonsQuery, TripLegAddonsQueryVariables>) {
        return ApolloReactHooks.useQuery<TripLegAddonsQuery, TripLegAddonsQueryVariables>(TripLegAddonsDocument, baseOptions);
      }
export function useTripLegAddonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TripLegAddonsQuery, TripLegAddonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TripLegAddonsQuery, TripLegAddonsQueryVariables>(TripLegAddonsDocument, baseOptions);
        }
export type TripLegAddonsQueryHookResult = ReturnType<typeof useTripLegAddonsQuery>;
export type TripLegAddonsLazyQueryHookResult = ReturnType<typeof useTripLegAddonsLazyQuery>;
export type TripLegAddonsQueryResult = ApolloReactCommon.QueryResult<TripLegAddonsQuery, TripLegAddonsQueryVariables>;
export const VoucherDocument = gql`
    query Voucher($code: String!) {
  voucher(code: $code) {
    amount
    currency
    initialAmount
    isValid
    pin
    validUntil
  }
}
    `;

/**
 * __useVoucherQuery__
 *
 * To run a query within a React component, call `useVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVoucherQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VoucherQuery, VoucherQueryVariables>) {
        return ApolloReactHooks.useQuery<VoucherQuery, VoucherQueryVariables>(VoucherDocument, baseOptions);
      }
export function useVoucherLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VoucherQuery, VoucherQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VoucherQuery, VoucherQueryVariables>(VoucherDocument, baseOptions);
        }
export type VoucherQueryHookResult = ReturnType<typeof useVoucherQuery>;
export type VoucherLazyQueryHookResult = ReturnType<typeof useVoucherLazyQuery>;
export type VoucherQueryResult = ApolloReactCommon.QueryResult<VoucherQuery, VoucherQueryVariables>;