import { getCookieDomain } from 'lib/helpers';
import { useCookies } from 'react-cookie';

const BASKET_ID_COOKIE = 'slo/basketId';

interface IBasketIdCookie {
    basketIdCookie?: string;
    setBasketIdCookie: (id?: string) => void;
}

const useBasketIdCookie = (): IBasketIdCookie => {
    const [cookies, setCookie, removeCookie] = useCookies([BASKET_ID_COOKIE]);
    const domain = getCookieDomain();

    return {
        basketIdCookie: cookies[BASKET_ID_COOKIE] as string | undefined,
        setBasketIdCookie: (id?: string) => {
            const options = {
                domain,
                maxAge: 1200,
                path: '/',
            };
            if (id === undefined) {
                removeCookie(BASKET_ID_COOKIE, options);
            } else {
                setCookie(BASKET_ID_COOKIE, id, options);
            }
        },
    };
};

export default useBasketIdCookie;
