import TravelPassRouteSelector from '@slkit/TravelPassRouteSelector';
import TravelPassTypeSelector from '@slkit/TravelPassTypeSelector';
import useCurrentUser from 'components/Login/useCurrentUser';
import useBuyerInfo from 'lib/hooks/useBuyerInfo';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import groupBy from 'lodash/groupBy';
import * as qs from 'query-string';
import * as React from 'react';

import {
    AddBasketTravelPassMutationVariables,
    NormalizedTravelPassesQuery,
    NormalizedTravelService,
    useAddBasketTravelPassMutation,
    useCreateBasketMutation,
    useNormalizedTravelPassesQuery,
} from '../../../graphql';
import { ApplicationDataContext } from '../../lib/applicationDataContext';
import useBasketContent from '../../lib/hooks/useBasketContent';
import useI18n from '../../lib/hooks/useI18n';
import useRouter from '../../lib/hooks/useRouter';
import ErrorMessage from '../ErrorMessage';
import LoadingIndicator from '../LoadingIndicator';
import TravelPassPassengerSection from './TravelPassPassengerSection';

export type TNormalizedTravelPass = NormalizedTravelPassesQuery['normalizedTravelServices'][0];

const TravelPassesContainer = () => {
    const user = useCurrentUser();
    if (!user) return null;

    // Code that checks for different travel pass in basket
    let hasOtherTravelPassInBasket: boolean = false;
    const { data: basketData, loading: basketLoading } = useBasketContent();
    if (basketData && basketData.basket.content) {
        const services = basketData.basket.content.services || [];
        hasOtherTravelPassInBasket = services.length > 0;
    }

    // Code that fetches current basket buyer name
    const { data: buyerInfoData, loading: buyerInfoLoading } = useBuyerInfo();
    let buyerInfoName: string | undefined;
    if (buyerInfoData && buyerInfoData.basket) {
        const { firstName, lastName } = buyerInfoData.basket.buyerInfo;
        buyerInfoName = `${firstName} ${lastName}`;
    }

    // Other
    const {
        data,
        loading: travelPassesLoading,
        error,
    } = useNormalizedTravelPassesQuery({
        variables: {
            query: {},
        },
    });
    const [
        createBasketMutation,
        { error: createBasketError },
    ] = useCreateBasketMutation({
        variables: { affiliateId: null },
    });
    const [
        addBasketTravelPassMutation,
        { error: addToBasketError },
    ] = useAddBasketTravelPassMutation();

    const { loading: mutationLoading, beginLoading, endLoading } = useLoading();
    const { basketId, setBasketId } = React.useContext(ApplicationDataContext);
    const [confirmPassengerState, setConfirmPassengerState] = React.useState(
        false
    );
    const [selected, setSelected] = React.useState<TNormalizedTravelPass>();
    const history = useHistory();
    const { location } = useRouter();
    const basket = useBasketContent();
    const { t } = useI18n('travel_passes');

    React.useEffect(() => {
        const services = data && data.normalizedTravelServices;

        if (services && services.length > 0 && !selected) {
            const queryParams = qs.parse(location.search);
            const idFromPath = queryParams.id;
            const fcFromPath = queryParams.fc;

            const filter = (tp: TNormalizedTravelPass): boolean => {
                if (!idFromPath && !fcFromPath) return false;

                if (idFromPath) {
                    if (
                        tp.zoneStopsId !== idFromPath &&
                        tp.staticTravelServiceId !== Number(idFromPath)
                    ) {
                        return false;
                    }
                }

                if (fcFromPath) {
                    if (tp.passengerTypeId !== fcFromPath) return false;
                }

                return true;
            };

            const defaultValue = services.find(filter) || services[0];
            setSelected(defaultValue);
        }
    }, [data, selected, setSelected]);

    const handleSelect = React.useCallback(
        (pass: NormalizedTravelService) => {
            if (pass !== selected) {
                setSelected(pass);
            }
        },
        [setSelected, selected]
    );

    const handleSubmit = () => {
        if (!selected) return;
        const { conditionId, travelServiceId, zonePairPriceId } = selected;
        if (!travelServiceId || !conditionId || !zonePairPriceId) return;

        beginLoading();

        const basketIdPromise = basketId
            ? Promise.resolve(basketId)
            : createBasketMutation().then(
                  result => result.data && result.data.createBasket.id
              );

        basketIdPromise
            .then(id => {
                if (!id) return;

                const variables: AddBasketTravelPassMutationVariables = {
                    basketId: id!,
                    input: [
                        {
                            conditionId,
                            travelServiceId,
                            zonePairPriceId,
                        },
                    ],
                };

                return addBasketTravelPassMutation({ variables })
                    .then(() => basket.reload && basket.reload())
                    .then(() => setBasketId(id))
                    .then(endLoading)
                    .then(() => history.push('/purchase/summary'));
            })
            .catch(endLoading);
    };

    const loading = travelPassesLoading || basketLoading || buyerInfoLoading;
    if (loading) return <LoadingIndicator fullscreen />;
    if (error) return <ErrorMessage error={error} fixed />;
    if (!data) return null;
    if (!selected) return null;
    const postSubmitError = createBasketError || addToBasketError;

    const groupedPasses = groupBy(
        data.normalizedTravelServices,
        (item: NormalizedTravelService) => item.zoneStopsId
    );
    const selectedZonePairPrices = data.normalizedTravelServices.filter(
        item => item.zoneStopsId === selected.zoneStopsId
    );
    const groupedZonePairPrices = groupBy(selectedZonePairPrices, 'name');

    return (
        <>
            {mutationLoading && <LoadingIndicator fullscreen />}
            <div className="Narrow">
                <h2 className="Narrow__Title">{t('title')}</h2>
                <TravelPassRouteSelector
                    data={groupedPasses}
                    selected={selected}
                    onSelect={handleSelect}
                />
                <h2 className="Narrow__Title">{t('travel-pass-type')}</h2>
                <TravelPassTypeSelector
                    data={groupedZonePairPrices}
                    selected={selected}
                    onSelect={handleSelect}
                />
                <TravelPassPassengerSection
                    onChange={setConfirmPassengerState}
                    value={confirmPassengerState}
                />
                <div className="Narrow__Buttons">
                    <button
                        disabled={!confirmPassengerState}
                        className="Button--inline Button"
                        onClick={() => handleSubmit()}
                    >
                        {t('button-title')}
                    </button>
                </div>
                {postSubmitError && (
                    <ErrorMessage error={postSubmitError} fixed />
                )}
            </div>
        </>
    );
};

export default TravelPassesContainer;
