import cx from 'classnames';
import * as React from 'react';

import { ReactComponent as APPLE } from '../assets/svgs/signin-logo-apple.svg';
import { ReactComponent as FACEBOOK } from '../assets/svgs/signin-logo-facebook.svg';

interface IProps {
    disabled?: boolean;
    full?: boolean;
    gpay?: boolean;
    marTop?: boolean;
    primary?: boolean;
    paypal?: boolean;
    secondary?: boolean;
    inline?: boolean;
    red?: boolean;
    download?: boolean;
    print?: boolean;
    apple?: boolean;
    facebook?: boolean;
    insurance?: boolean;
    account?: boolean;
}

const Button = ({
    className,
    disabled,
    full,
    gpay,
    marTop,
    primary,
    secondary,
    red,
    download,
    print,
    inline,
    paypal,
    apple,
    facebook,
    insurance,
    account,
    children,
    ...rest
}: IProps & React.HTMLAttributes<HTMLButtonElement>) => {
    let icon: React.ReactNode;

    if (apple) {
        icon = <APPLE style={{ position: 'absolute', left: '12px' }} />;
    }
    if (facebook) {
        icon = <FACEBOOK style={{ position: 'absolute', left: '12px' }} />;
    }

    return (
        <button
            className={cx('Button', className, {
                'Button--download': download,
                'Button--full': full,
                'Button--gpay': gpay,
                'Button--inline': inline,
                'Button--marTop': marTop,
                'Button--paypal': paypal,
                'Button--primary': primary,
                'Button--print': print,
                'Button--red': red,
                'Button--secondary': secondary,
                'Button--apple': apple,
                'Button--facebook': facebook,
                'Button--insurance': insurance,
                'Button--account': account,
            })}
            disabled={disabled}
            {...rest}
        >
            {icon}
            {children}
        </button>
    );
};

export default Button;
