import * as React from 'react';
import './index.scss';

import SearchResultPagination from 'components/SearchResult/SearchResultPagination';
import { ReactComponent as SVG } from '../assets/svgs/noresult.svg';

interface IProps {
    children?: React.ReactNode;
    date: string;
    onChange: (date: string) => void;
    title?: string;
}

const NoResult = ({ children, date, onChange, title }: IProps) => (
    <div className="Narrow">
        <div className="Noresult">
            <h2 className="Noresult__Title">{title}</h2>
            <p className="Noresult__Text">{children}</p>
            <SearchResultPagination date={date} onChange={onChange} />
            <SVG />
        </div>
    </div>
);

export default NoResult;
