import Icon, { ICON_NAME } from '@slkit/Icon';
import Loader from '@slkit/Loader';
import * as cx from 'classnames';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { IAdyenCheckoutCreateOptions } from './adyen';
import { ADYEN_INPUT_STYLES } from './AdyenCardPaymentMethod';
import { ICardFormFieldError } from './useAdyenCardFormReducer';
import useAdyenCheckout from './useAdyenCheckout';

interface IProps {
    checkoutType?: adyen.CheckoutType;
    onChange: (encryptedCVC?: string) => void;
}

const AdyenCVCForm = ({ checkoutType, onChange }: IProps) => {
    const { t } = useI18n('payment.card');
    const checkout = useAdyenCheckout();

    const [initialized, setInitialized] = React.useState(false);
    const [error, setError] = React.useState<ICardFormFieldError>();

    React.useEffect(() => {
        if (!checkout) return;

        const cfg: IAdyenCheckoutCreateOptions = {
            onConfigSuccess: () => setInitialized(true),
            onError: p => setError(p),
            onFieldValid: p => onChange(p.valid ? p.blob : undefined),
            placeholders: {
                encryptedSecurityCode: t('cvv-placeholder'),
            },
            styles: ADYEN_INPUT_STYLES,
            type: checkoutType || 'card',
        };

        try {
            checkout.create('securedfields', cfg).mount('.CVCForm');
        } catch (e) {
            console.log('Could not initialize card form');
            console.log(e);
        }
    }, [checkout]);

    const errorMessage = error && (error.i18n || error.error);

    return (
        <>
            {!initialized && <Loader small center />}
            <div
                className="CVCForm"
                style={{ display: initialized ? 'block' : 'none' }}
            >
                <div
                    className={cx('InputField', {
                        'InputField--invalid': errorMessage,
                    })}
                >
                    <div
                        className="InputField__Input"
                        data-cse="encryptedSecurityCode"
                    />
                    <div className="InputField__Info">
                        <Icon name={ICON_NAME.INFO} />
                    </div>
                    {errorMessage && (
                        <div className="InputField__Message">
                            {errorMessage}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AdyenCVCForm;
