import './index.scss';

import StopName from '@slkit/StopName';
import * as moment from 'moment';
import * as React from 'react';

interface IProps {
    time: moment.Moment;
    stopName: string;
    cityName: string;
}

const TimeBox = ({ cityName, stopName, time }: IProps) => (
    <div className="TimeBox">
        <div className="TimeBox__Time">{time.format('HH:mm')}</div>
        <div className="TimeBox__Location">
            <StopName city={cityName} stop={stopName} />
        </div>
    </div>
);

export default TimeBox;
