import './index.scss';

import * as React from 'react';

import LogoLink from './LogoLink';

interface IProps {
    children?: React.ReactNode;
    version?: string;
}

const Header = ({ children, version }: IProps) => (
    <div className="Header">
        <div className="Header__Container">
            <div className="Header__Logo">
                <h1 className="visually-hidden">Slovak Lines</h1>
                <LogoLink />
                {version && (
                    <span
                        style={{
                            color: 'rgba(43, 45, 66, 0.64)',
                            display: 'block',
                            fontSize: '12px',
                            position: 'absolute',
                            top: '60%',
                        }}
                    >
                        {version}
                    </span>
                )}
            </div>
            <div className="Header__Widgets" style={{ position: 'relative' }}>
                {children}
            </div>
        </div>
    </div>
);

export default Header;
