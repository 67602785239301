import { useEffect, useState } from 'react';

// This handle special case where component and whole DOM is rendered
// in one sweep. Since target renders in DOM, it is not available
// on first render. This rerenders component later - when target
// should be available.
const usePortalTarget = (elementId: string) => {
    // If element is already available, just return it
    const initial = document.getElementById(elementId);
    // Do not immediatly return, that breaks hooks

    // Prepare for retry
    const [backoff, setBackoff] = useState(10);
    // Initialize state value
    const [target, setTarget] = useState(initial);

    useEffect(() => {
        // When target is already found, do not search again
        if (target) return;

        // Lookup target
        const t = document.getElementById(elementId);

        if (t) {
            // If target is available, save it
            setTarget(t);
        } else {
            // If target is not available, plan retry
            setTimeout(() => {
                setBackoff(backoff * 10);
            }, backoff);
        }
    });

    return target;
};

export default usePortalTarget;
