import NoResult from '@slkit/NoResult';
import Trip from '@slkit/Trip';
import { JourneySourceEnum } from '__types__/globalTypes';
import ErrorMessage from 'components/ErrorMessage';
import FormattedText from 'components/FormattedText';
import LoadingIndicator from 'components/LoadingIndicator';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

import { JourneyFragmentFragment } from '../../../graphql';
import { buildTripDetail, isExpress, isSoldOut } from './helpers';
import SearchResultPagination from './SearchResultPagination';
import { useKioskContext } from 'components/Kiosk/KioskLayout';

interface IProps {
    date: string;
    loading?: boolean;
    error?: Error;
    journeys: JourneyFragmentFragment[];
    onDateChange: (date: string) => void;
    onJourneySelect: (journey: JourneyFragmentFragment) => void;
    onJourneyDetail: (journey: JourneyFragmentFragment) => void;
    selectedJourney?: JourneyFragmentFragment;
    travelPass?: boolean;
}

const lineType = (journey: JourneyFragmentFragment) =>
    isExpress(journey) ? 'Express' : undefined;

const SearchResult = ({
    date,
    loading,
    error,
    journeys,
    onDateChange,
    onJourneySelect,
    onJourneyDetail,
    selectedJourney,
    travelPass,
}: IProps) => {
    const { isKiosk } = useKioskContext();
    const { t } = useI18n('search_result');

    const lineSubType = React.useCallback(
        (journey: JourneyFragmentFragment): string | undefined => {
            if (journey.isReinforcement) return t('reinforcement');
            return undefined;
        },
        [t]
    );

    if (loading) return <LoadingIndicator />;
    if (error) return <ErrorMessage error={error} fixed />;

    return journeys.length > 0 ? (
        <div>
            {journeys.map(j => (
                <Trip
                    key={j.id}
                    trip={j}
                    details={buildTripDetail(j)}
                    lineType={lineType(j)}
                    onSelect={
                        j.source === JourneySourceEnum.TURNIT
                            ? onJourneySelect
                            : undefined
                    }
                    onDetail={onJourneyDetail}
                    selected={selectedJourney === j}
                    soldOut={isSoldOut(j)}
                    lineSubType={lineSubType(j)}
                    travelPass={travelPass}
                    variant={isKiosk ? 'kiosk' : 'default'}
                    brandName={j.legs[0].brandName}
                />
            ))}
            <SearchResultPagination date={date} onChange={onDateChange} />
        </div>
    ) : (
        <NoResult
            date={date}
            onChange={onDateChange}
            title={t(isKiosk ? 'kiosk-empty.title' : 'empty.title')}
        >
            <FormattedText>
                {t(isKiosk ? 'kiosk-empty.message' : 'empty.message')}
            </FormattedText>
        </NoResult>
    );
};

export default SearchResult;
