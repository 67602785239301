import { ApolloError } from 'apollo-client';
import { buildBasketServiceEntries } from 'components/Basket/helpers';
import { useMemo } from 'react';

import { IBasketServiceEntry } from '../../components/Basket/BasketServiceEntry';
import useBasketContent from './useBasketContent';

interface IBasketServiceEntriesResult {
    loading: boolean;
    error?: ApolloError;
    entries: IBasketServiceEntry[];
}

const NO_SERVICES = [];

const useBasketServiceEntries = (
    basketId?: string
): IBasketServiceEntriesResult => {
    const { data, loading, error } = useBasketContent(basketId);

    const entries = useMemo(
        () => (data ? buildBasketServiceEntries(data.basket) : NO_SERVICES),
        [data]
    );

    return {
        entries,
        error,
        loading,
    };
};

export default useBasketServiceEntries;
