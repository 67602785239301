import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

const AppPromo = () => {
    return null;
    const { t } = useI18n('purchase_success');
    return <div className="AppPromo">{t('app-promo')}</div>;
};

export default AppPromo;
