import Button from '@slkit/Button';
import Check from '@slkit/Check';
import Grid, { GridColumn } from '@slkit/Grid';
import InputField from '@slkit/InputField';
import PasswordInputField from '@slkit/InputField/PasswordInputField';
import Modal from '@slkit/Modal';
import NarrowSubtitle from '@slkit/Narrow/NarrowSubtitle';
import NarrowText from '@slkit/Narrow/NarrowText';
import PhoneNumber from '@slkit/PhoneNumber';
import CSRFFields from 'components/CSRFFields';
import ErrorMessage from 'components/ErrorMessage';
import FormattedText from 'components/FormattedText';
import LoadingIndicator from 'components/LoadingIndicator';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { parseNames } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

interface IProps {
    basketId?: string;
    buyerData?: {
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        phoneNumber: string | null;
        phoneAreaCode: string | null;
    };
}

const CreateAccount = ({ buyerData, basketId }: IProps) => {
    const { t } = useI18n();
    const formRef = React.useRef<HTMLFormElement | null>(null);
    const { registerUser } = React.useContext(ApplicationDataContext);
    const [isModalActive, setModalActive] = React.useState(false);
    const [name, setName] = React.useState<string | null>(null);
    const [email, setEmail] = React.useState<string | null>(null);
    const [password, setPassword] = React.useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = React.useState<string | null>(null);
    const [phoneAreaCode, setPhoneAreaCode] = React.useState<string | null>(
        null
    );
    const [agree, setAgree] = React.useState(false);
    const [isSendingInfoAllowed, setIsSendingInfoAllowed] = React.useState(
        false
    );
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (buyerData) {
            setName(
                `${buyerData.firstName}${
                    buyerData.lastName ? ' ' + buyerData.lastName : ''
                }`
            );
            setEmail(buyerData.email);
            setPhoneNumber(buyerData.phoneNumber);
            setPhoneAreaCode(buyerData.phoneAreaCode);
        }
    }, [buyerData]);
    const handleSubmit = () => {
        if (!registerUser) return;
        setLoading(true);
        const names = name && parseNames(name);
        registerUser({
            email: email || '',
            firstName: names && names[0],
            lastName: names && names[1],
            password: password || '',
            phoneAreaCode,
            phoneNumber,
            isSendingInfoAllowed,
        })
            .then(() => {
                setLoading(false);
                setModalActive(false);
                if (formRef && formRef.current) {
                    formRef.current.submit();
                }
            })
            .catch((e: Error) => {
                setLoading(false);
                setError(e);
            });
    };

    const buttons = (
        <div className="ButtonGroup">
            <button
                className="Button Button--secondary"
                onClick={() => setModalActive(false)}
            >
                {t('registration.cancel-button')}
            </button>

            <button
                className="Button"
                onClick={handleSubmit}
                disabled={!canSubmit || !agree}
            >
                {t('registration.submit-button')}
            </button>
        </div>
    );
    const handleStrengthChange = React.useCallback((strength: number) => {
        setCanSubmit(strength === 1);
    }, []);
    return (
        <>
            <NarrowSubtitle>
                {t('purchase_success.create-account-title')}
            </NarrowSubtitle>
            <NarrowText>{t('purchase_success.create-account-copy')}</NarrowText>
            <Grid>
                <GridColumn isPadded gridWidth={1}>
                    <Button
                        secondary
                        red
                        account
                        onClick={() => setModalActive(true)}
                    >
                        {t('purchase_success.create-password-button')}
                    </Button>
                </GridColumn>
                <GridColumn gridWidth={1} />
            </Grid>

            {isModalActive && (
                <Modal
                    isActive
                    title={t('registration.modal-title')}
                    buttons={buttons}
                    onClose={() => setModalActive(false)}
                >
                    <div className="Modal__Item">
                        {loading && <LoadingIndicator />}
                        <NarrowText isBig>
                            {t('registration.subtitle')}
                        </NarrowText>
                        <InputField
                            value={name || ''}
                            onChange={e => setName(e.target.value)}
                            placeholder={t('registration.name-placeholder')}
                        />

                        <InputField
                            value={email || ''}
                            onChange={e => setEmail(e.target.value)}
                            placeholder={t('registration.email-placeholder')}
                        />
                        <PhoneNumber
                            areaCode={phoneAreaCode || ''}
                            onAreaCodeChange={code =>
                                setPhoneAreaCode(code.areaCode)
                            }
                            placeholder={t(
                                'registration.phone-number-placeholder'
                            )}
                            value={phoneNumber || ''}
                            onChange={e => setPhoneNumber(e.target.value)}
                        />
                        <PasswordInputField
                            onChange={e => setPassword(e.target.value)}
                            placeholder={t('registration.password-placeholder')}
                            value={password || ''}
                            autoComplete="new-password"
                            hasHints={true}
                            onStrengthChange={handleStrengthChange}
                        />
                        <Check
                            isChecked={isSendingInfoAllowed}
                            label={
                                <FormattedText>
                                    {t('registration.is-sending-info-allowed')}
                                </FormattedText>
                            }
                            onChange={setIsSendingInfoAllowed}
                        />
                        <Check
                            isChecked={agree}
                            label={
                                <FormattedText>
                                    {t('registration.agreement')}
                                </FormattedText>
                            }
                            onChange={setAgree}
                        />
                        <ErrorMessage
                            error={error}
                            fixed
                            noPortal
                            onClose={() => setError(undefined)}
                        />
                    </div>
                </Modal>
            )}
            <form
                action="/users/sign_in"
                acceptCharset="UTF-8"
                method="post"
                ref={formRef}
            >
                <CSRFFields />
                <input type="hidden" name="user[email]" value={email || ''} />
                <input
                    type="hidden"
                    name="user[password]"
                    value={password || ''}
                />
                <input type="hidden" name="basket_id" value={basketId} />
            </form>
        </>
    );
};

export default CreateAccount;
