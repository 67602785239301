import FooterLink from '@slkit/Footer/FooterLink';
import Icon, { ICON_NAME } from '@slkit/Icon';
import * as cx from 'classnames';
import Flag from 'components/Flag';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

import LanguageOptions, { LanguageOptionsDirection } from './LanguageOptions';
import useLanguagePicker from './useLanguagePicker';

interface IProps {
    compact?: boolean;
    direction?: LanguageOptionsDirection;
}

const LanguagePicker = ({ compact, direction }: IProps) => {
    const {
        currentLanguage,
        handleClick,
        handleSelect,
        isOpen,
        languages,
        wrapperRef,
    } = useLanguagePicker();
    const { t } = useI18n('language_picker');
    return (
        <FooterLink
            className="Footer__Link"
            ref={wrapperRef}
            onClick={handleClick}
            style={{ position: 'relative' }}
            icon={
                !compact && (
                    <Flag
                        countryAlpha2={currentLanguage.countryAlpha2}
                        className="Icon"
                        round
                    />
                )
            }
        >
            {t(`options${compact ? '_short' : ''}.${currentLanguage.key}`)}
            <div
                className={cx('Footer__Arrow', {
                    'Footer__Arrow--down': direction === 'down',
                    'Footer__Arrow--up': direction === 'up',
                })}
            >
                <Icon name={ICON_NAME.SMALL_ARROW} />
            </div>
            <LanguageOptions
                active={isOpen}
                direction={direction}
                languages={languages}
                onSelect={handleSelect}
            />
        </FooterLink>
    );
};

export default LanguagePicker;
