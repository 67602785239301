import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import isEqual from 'lodash/isEqual';
import * as moment from 'moment';
import * as query from 'queries/JourneysSearch.graphql';
import * as React from 'react';

import {
    JourneysSearchQuery,
    JourneysSearchQueryVariables,
} from '../../../graphql';

interface ISearchResultsState {
    loading: boolean;
    data?: JourneysSearchQuery;
    error?: ApolloError;
    variables: JourneysSearchQueryVariables;
}

// Returns true if data are loaded and validUntil is in past
const isStale = (data?: JourneysSearchQuery) => {
    if (!data) return false;

    const validUntil = moment(
        data.journeysSearch.responseValidUntilUtc as string
    );
    const remaining = validUntil.diff(moment());

    return remaining < 0;
};

const useSearchResults = (iVariables: JourneysSearchQueryVariables) => {
    const variables = React.useRef<JourneysSearchQueryVariables | undefined>();
    const { apolloClient: client } = React.useContext(ApplicationDataContext);
    const [data, setData] = React.useState<ISearchResultsState>({
        loading: true,
        variables: iVariables,
    });

    // This effect is called when variables or data change
    // When variables change, query should always be executed. If result is already
    // loaded, apollo will return data from cache.
    //
    // When data change, validity of data is checked and if data are stale
    // query is run with 'network-only' policy, so data are loaded fresh from server.
    // This is called even if variables do not change.
    React.useEffect(() => {
        if (!client) return;

        const variablesChanged = !isEqual(variables.current, iVariables);
        const stale = isStale(data.data);

        // Do not trigger query if variables are same and data are not stale
        if (!variablesChanged && !stale) return;
        variables.current = iVariables;
        const fetchPolicy = stale ? 'network-only' : undefined;

        setData({
            data: undefined,
            loading: true,
            variables: variables.current,
        });
        client
            .query<JourneysSearchQuery, JourneysSearchQueryVariables>({
                fetchPolicy,
                query,
                variables: variables.current,
            })
            .then(res => {
                setData({
                    data: res.data,
                    loading: false,
                    variables: variables.current!,
                });
            })
            .catch((error: ApolloError) => {
                setData({
                    error,
                    loading: false,
                    variables: variables.current!,
                });
            });
    }, [client, data, iVariables]);

    return data;
};

export default useSearchResults;
