import BasketEntry from '@slkit/BasketEntry';
import PopupItem from '@slkit/Popup/PopupItem';
import Summary from '@slkit/Summary';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import * as React from 'react';

interface IProps {
    entries: IBasketEntry[];
}
const TicketsContainer = ({ entries }: IProps) => {
    return (
        <Summary>
            {entries.map((entry, n) => (
                <PopupItem key={n}>
                    <BasketEntry entry={entry} showBasketNumber />
                </PopupItem>
            ))}
        </Summary>
    );
};

export default TicketsContainer;
