import Check from '@slkit/Check';
import FormattedText from 'components/FormattedText';
import useCurrentUser from 'components/Login/useCurrentUser';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import CSRFFields from '../CSRFFields';

interface IProps {
    onChange: (state: boolean) => void;
    value: boolean;
}

const TravelPassPassengerSection = ({ onChange, value }: IProps) => {
    const user = useCurrentUser();
    const { t, i } = useI18n('travel_passes');

    const currentUserName = (user && user.name) || '';

    return (
        <div className="TravelPassPassengerSection">
            <h2 className="Narrow__Title">{t('custom-name-title')}</h2>
            <Check
                label={
                    <span>
                        <FormattedText>
                            {i(t('custom-name-checkbox-label'), {
                                name: currentUserName,
                            })}
                        </FormattedText>
                    </span>
                }
                isChecked={value}
                onChange={checked => onChange(checked)}
                variant="large"
            />
            {!value && (
                <>
                    <p className="Narrow__Text">
                        {t('owner-must-be-passenger-info')}
                    </p>
                    <form action="/users/sign_out" method="post">
                        <CSRFFields />
                        <input type="hidden" name="_method" value="delete" />
                        <button className="Link--small">
                            {t('owner-must-be-passenger-logout')}
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default TravelPassPassengerSection;
