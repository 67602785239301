import { ApolloError } from 'apollo-client';
import * as React from 'react';

import { ApplicationDataContext } from 'lib/applicationDataContext';
import { FareClasses } from 'queries/__types__/FareClasses';
import * as query from 'queries/FareClasses.graphql';

interface IFareClassesResult {
    loading: boolean;
    data?: FareClasses;
    error?: ApolloError;
}

export default function useFareClasses() {
    const { apolloClient: client } = React.useContext(ApplicationDataContext);
    const [data, setData] = React.useState<IFareClassesResult>({
        loading: true,
    });

    React.useEffect(() => {
        if (!client) return;

        client
            .query<FareClasses>({ query })
            .then(d => {
                setData({
                    data: d.data,
                    loading: false,
                });
            })
            .catch(error => {
                setData({
                    error: error as ApolloError,
                    loading: false,
                });
            });
    }, [client]);

    return data;
}
