import NotificationSmall from '@slkit/SmallNotification';
import { getErrorMessage } from 'lib/helpers';
import * as React from 'react';

interface IProps {
    error?: Error;
    fixed?: boolean;
    inline?: boolean;
    noPortal?: boolean;
    title?: string;
    onClose?: () => void;
}

const ErrorMessage = ({ error, ...props }: IProps) => {
    if (!error) return null;
    const messageProps = {
        [props.title ? 'text' : 'title']: getErrorMessage(error),
    };
    return <NotificationSmall {...messageProps} {...props} />;
};

export default ErrorMessage;
