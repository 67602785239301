import './index.scss';

import cx from 'classnames';
import * as React from 'react';

const Accordion = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('Accordion', className)} {...props} />
);

export default Accordion;
