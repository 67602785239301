import { DialogHeader } from '@slkit/Dialog';
import Icon, { ICON_NAME } from '@slkit/Icon';
import SearchFormBusStop, {
    SearchFormBusStopMode,
} from '@slkit/SearchForm/SearchFormBusStop';
import SearchFormCell from '@slkit/SearchForm/SearchFormCell';
import SearchFormSwitch from '@slkit/SearchForm/SearchFormSwitch';
import { selectItemAfter, selectItemBefore } from 'lib/helpers';
import useBusStops from 'lib/hooks/useBusStops';
import useFocusable from 'lib/hooks/useFocusable';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { StopsQuery } from '../../../graphql';
import BusStopSelectOptions from './BusStopSelectOptions';
import useBusStopsOptions from './useBusStopsOptions';
import useVisibleOptions from './useVisibleOptions';

export type BusStopOptionType = 'CITY' | 'STOP';

export interface IBusStopOption {
    availableDates?: string[];
    hasPriority: boolean;
    icon: ICON_NAME;
    id: number;
    label: string;
    searchString: string;
    stops: IBusStopOption[];
    tag?: string;
    text: string;
    type: BusStopOptionType;
    value?: StopsQuery['busStops'][0]['cities'][0]['busStops'][0];
}

export interface IValue {
    id: number;
    type: BusStopOptionType;
}

interface IProps {
    canSwitch?: boolean;
    except?: IValue;
    flattenStops?: boolean;
    mode?: SearchFormBusStopMode;
    onChange?: (value?: IValue) => void;
    onSwitch?: () => void;
    showAllStops?: boolean;
    subtitle?: string;
    stopsTag?: string;
    title?: string;
    value?: IValue;
}

const BusStopSelect = ({
    canSwitch,
    except,
    flattenStops,
    mode,
    onChange,
    onSwitch,
    showAllStops,
    subtitle,
    stopsTag,
    title,
    value,
}: IProps) => {
    const { t } = useI18n('search_form');
    // internal value is used for tracking changes from outside such as change of
    // value to undefined. Internal value is set sync so when component
    // trigger the change of value, we can behave correctly (ie. not reset filter)
    const internalValue = React.useRef<
        IValue | undefined
    >() as React.MutableRefObject<IValue | undefined>;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { fBlur, fFocus, isFocused, ref } = useFocusable(false);
    const [hovered, setHovered] = React.useState<IBusStopOption>();
    const [filter, setFilter] = React.useState('');

    const handleChange = (v?: IValue) => {
        internalValue.current = v;
        if (onChange) onChange(v);
    };

    React.useEffect(() => {
        // Only reset filter if value was changed from outside
        // If value is same, value change was triggered from inside
        if (internalValue.current !== value && value === undefined) {
            setFilter('');
        }

        if (internalValue.current !== value) {
            internalValue.current = value;
        }
    }, [value]);

    const { error, loading } = useBusStops(stopsTag);
    const options = useBusStopsOptions(stopsTag);

    // Find selected option
    const selectedOption =
        value && options.find(o => o.id === value.id && o.type === value.type);

    // Memoize filtered options
    const visibleOptions = useVisibleOptions(
        filter,
        options,
        except,
        showAllStops
    );
    const selectableOptions = visibleOptions;

    // Some edgecases:
    // - Do not change if selected value is not visible (hover -> change -> enter)
    // - Do not change to nothing if options are not shown (select -> tab)
    // - Do not change when input with selected value is focused and then blured
    // - Some weird stuff happened when values were selected and swapped, then input was
    //   focused and tabbed (select both -> swap -> focus -> tab)
    const handleSelect = React.useCallback(
        (opt?: IBusStopOption) => {
            if (opt && !selectableOptions.includes(opt)) return;

            // Always blur (hide options)
            fBlur();
            setHovered(undefined);

            if (opt) {
                setFilter(opt.label);
                handleChange({ id: opt.id, type: opt.type });
            }
        },
        [selectableOptions, inputRef.current, onChange]
    );

    const setKeyboardHovered = (
        e: React.KeyboardEvent<HTMLInputElement>,
        opt?: IBusStopOption
    ) => {
        setHovered(opt);
        e.preventDefault(); // prevent default to prevent cusrsor movement in input

        if (opt) {
            const element = document.getElementById(opt.type + '_' + opt.id);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.keyCode) {
            case 9: // tab
            case 13: // enter
                return handleSelect(hovered);
            case 38: // up
                return setKeyboardHovered(
                    e,
                    selectItemBefore(selectableOptions, hovered)
                );
            case 40: // up
                return setKeyboardHovered(
                    e,
                    selectItemAfter(selectableOptions, hovered)
                );
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(undefined);
        setFilter(e.target.value);
        if (!isFocused) fFocus();
    };

    const handleInputFocus = () => {
        if (selectedOption) {
            setFilter(selectedOption.label);
        }
        if (inputRef.current) {
            // [FVSD-991] Delay selecting text to prevent bug in EDGE
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.setSelectionRange(0, 999999);
                }
            }, 10);
        }
        fFocus();
    };

    const handleMouseLeave = React.useMemo(() => () => setHovered(undefined), [
        setHovered,
    ]);

    const handleClear = () => {
        handleChange(undefined);
        setFilter('');
    };

    const optionIcon = (option?: IBusStopOption): ICON_NAME => {
        if (!option) {
            return mode === SearchFormBusStopMode.DEPARTURE
                ? ICON_NAME.DESTINATIONS_SOURCE
                : ICON_NAME.DESTINATIONS_DESTINATION;
        }
        return option.icon;
    };

    return (
        <SearchFormCell
            // active -> red highlight
            active={isFocused}
            as="label"
            // filled -> black highlight
            // Focus / active should have priority so don't mark as filled if focused
            filled={!!value && !isFocused}
            disabled={loading || !!error}
            ref={ref}
            className="SearchForm__Cell--BusStop"
        >
            <DialogHeader onClose={fBlur} />
            <Icon name={optionIcon(selectedOption)} />
            <SearchFormBusStop
                error={error}
                inputValue={(selectedOption && selectedOption.label) || filter}
                loading={loading}
                mode={mode}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={title}
                text={selectedOption ? selectedOption.text : subtitle}
                ref={inputRef}
                onClear={handleClear}
            />
            <BusStopSelectOptions
                activeOption={hovered}
                enabled={isFocused && filter.trim().length > 0}
                flattenStops={flattenStops}
                onMouseEnter={setHovered}
                onMouseLeave={handleMouseLeave}
                onOptionClick={handleSelect}
                options={visibleOptions}
            />
            {onSwitch && (
                <SearchFormSwitch
                    disabled={!canSwitch || loading || !!error}
                    onClick={onSwitch}
                    title={t('switch-destination')}
                />
            )}
        </SearchFormCell>
    );
};

export default BusStopSelect;
