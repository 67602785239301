import Pager from '@slkit/Pager';
import PagerButton from '@slkit/Pager/PagerButton';
import { formatDate, now } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as moment from 'moment';
import * as React from 'react';

interface IProps {
    date: string;
    onChange: (date: string) => void;
}

const SearchResultPagination = ({ date, onChange }: IProps) => {
    const { t } = useI18n('search_results.pagination');

    const prevDate = moment(date).add(-1, 'days');
    const nextDate = moment(date).add(1, 'days');
    const isToday = date === now();

    return (
        <Pager>
            <PagerButton
                prev
                onClick={() => onChange(formatDate(prevDate.toDate()))}
                disabled={isToday}
            >
                {`${t('prev-button')} ${prevDate.format(
                    t('button-date-format')
                )}`}
            </PagerButton>
            <PagerButton
                next
                onClick={() => onChange(formatDate(nextDate.toDate()))}
            >
                {`${t('next-button')} ${nextDate.format(
                    t('button-date-format')
                )}`}
            </PagerButton>
        </Pager>
    );
};

export default SearchResultPagination;
