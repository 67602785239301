import Dropdown from '@slkit/Dropdown';
import FooterLink from '@slkit/Footer/FooterLink';
import Icon, { ICON_NAME } from '@slkit/Icon';
import SupportCenter from '@slkit/SupportCenter';
import useFocusable from 'lib/hooks/useFocusable';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

const defaultProps = {
    active: false,
    position: 'right',
};

const SupportCenterLink = () => {
    const { t } = useI18n('footer');

    const { buttonRef, fFocus, isFocused, ref } = useFocusable(false);

    return (
        <div style={{ position: 'relative' }}>
            <FooterLink
                className="Footer__Link--question"
                icon={<Icon name={ICON_NAME.QUESTION} />}
                ref={buttonRef}
                onClick={fFocus}
            >
                {t('questions')}
            </FooterLink>
            <Dropdown
                style={{
                    bottom: '0',
                    left: 'auto',
                    right: '0',
                    top: 'auto',
                }}
                ref={ref}
                active={isFocused}
            >
                <SupportCenter />
            </Dropdown>
        </div>
    );
};

SupportCenterLink.defaultProps = defaultProps;

export default SupportCenterLink;
