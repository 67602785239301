import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import useOnTargetClick from 'lib/hooks/useOnTargetClick';
import ModalBackdrop from './ModalBackdrop';
import ModalContent from './ModalContent';

interface IProps {
    buttons?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    hideCloseButton?: boolean;
    hideHeader?: boolean;
    isActive?: boolean;
    onClose?: () => void;
    title?: React.ReactNode;
}

const Modal = ({
    buttons,
    children,
    className,
    hideCloseButton = false,
    hideHeader = false,
    isActive,
    onClose,
    title,
}: IProps) => {
    const backdropProps = useOnTargetClick(onClose);

    React.useEffect(() => {
        const opts = {
            cancelable: false,
        };

        const eventName = isActive
            ? 'sl-modal-activated'
            : 'sl-modal-deactivated';
        window.dispatchEvent(new CustomEvent(eventName, opts));

        return () => {
            if (isActive) {
                window.dispatchEvent(
                    new CustomEvent('sl-modal-deactivated', opts)
                );
            }
        };
    }, [isActive]);

    const content = (
        <ModalBackdrop
            className={className}
            isActive={isActive}
            {...backdropProps}
        >
            <ModalContent>
                {!hideHeader && (
                    <div className="Modal__Header">
                        <h2 className="Modal__Headline">{title}</h2>
                    </div>
                )}
                {!hideCloseButton && onClose && (
                    <button className="Modal__Close" onClick={onClose}>
                        <Icon name={ICON_NAME.CLOSE2021} />
                    </button>
                )}
                {children}
                {buttons}
            </ModalContent>
        </ModalBackdrop>
    );

    const target = document.querySelector('[data-modal-container]');

    if (target) {
        return ReactDOM.createPortal(content, target);
    } else {
        return content;
    }
};

export default Modal;
