import DropdownOption from '@slkit/Dropdown/DropdownOption';
import DropdownSearch from '@slkit/DropdownSearch';
import Flag from 'components/Flag';
import { COUNTRY_ALPHA } from 'components/Flag/CountryAlphaEnum';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

interface IProps {
    active: boolean;
    onSelect: (phoneAreaCode: IPhoneAreaCode) => void;
    phoneAreaCodes?: IPhoneAreaCode[];
}

interface IOption extends IPhoneAreaCode {
    sanitizedFilterString: string;
}

const defaultProps = {
    active: false,
};

const AreaCodeSelect = ({ active, onSelect, phoneAreaCodes }: IProps) => {
    const { t } = useI18n('area_code_select');
    const [searchInputValue, setSearchInputValue] = React.useState<string>('');

    const options = React.useMemo(() => {
        if (!phoneAreaCodes) return null;

        return phoneAreaCodes.map((item: IPhoneAreaCode) => ({
            ...item,
            sanitizedFilterString: (
                item.areaCode +
                item.countryAlpha2 +
                item.countryName
            ).toLowerCase(),
        }));
    }, [phoneAreaCodes]);

    const filteredOptions = React.useMemo(() => {
        if (!options) return [];
        if (searchInputValue.length === 0) return options;

        return options.filter((item: IOption) => {
            if (!item || !item.sanitizedFilterString) return false;
            return item.sanitizedFilterString.includes(
                searchInputValue.toLowerCase()
            );
        });
    }, [searchInputValue, options]);

    return (
        <DropdownSearch
            placeholder={t('prompt')}
            active={active}
            inputValue={searchInputValue}
            onInputChange={setSearchInputValue}
        >
            {filteredOptions.map((l, i) => (
                <DropdownOption
                    key={i}
                    onClick={() => onSelect(l as IPhoneAreaCode)}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <div className="DropdownSearch__Item">
                        {l.countryAlpha2 && (
                            <Flag
                                countryAlpha2={
                                    l.countryAlpha2.toLowerCase() as COUNTRY_ALPHA
                                }
                                className="DropdownSearch__Flag"
                            />
                        )}

                        <div className="DropdownSearch__Number">
                            {l.areaCode}
                        </div>
                        <div className="DropdownSearch__Text">
                            {l.countryName}
                        </div>
                    </div>
                </DropdownOption>
            ))}
        </DropdownSearch>
    );
};

AreaCodeSelect.defaultProps = defaultProps;

export default AreaCodeSelect;
