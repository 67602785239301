import * as React from 'react';
import { PayWithResponseFragment } from '../../../graphql';

const AdyenRedirect = ({
    data,
    httpMethod,
    url,
}: NonNullable<PayWithResponseFragment['redirect']>) => {
    const form = React.useRef<HTMLFormElement>(null);

    React.useEffect(() => {
        if (httpMethod === 'GET') {
            window.location.href = url;
        } else {
            if (!form.current) return;
            form.current.submit();
        }
    }, [form.current]);

    return (
        <React.Fragment>
            <form
                style={{ display: 'none' }}
                action={url}
                method={httpMethod}
                ref={form}
            >
                {data &&
                    Object.keys(data).map(key => (
                        <input
                            key={key}
                            name={key}
                            value={data[key]}
                            readOnly
                        />
                    ))}
                <input type="submit" />
            </form>
        </React.Fragment>
    );
};

export default AdyenRedirect;
