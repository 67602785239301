import { IBasketServiceEntry } from 'components/Basket/BasketServiceEntry';
import { IJourneyPassengerSeatInfo } from 'components/PurchaseProcess/Seats/useBusSeats';
import forEach from 'lodash/forEach';
import * as moment from 'moment';
import {
    BusSeatFragment,
    JourneyFragmentFragment,
    LegAddonFragment,
    LegInfoFragment,
    PassengerJourneysFragment,
} from '../../../graphql';
import { IBasketEntry } from './useBasketEntries';

interface IGTMProductField {
    name?: string;
    id?: string;
    price?: string;
    brand?: string;
    category?: string;
    variant?: string;
    quantity: number;
}

export type TAnalyticsBusSeatFragment =
    | BusSeatFragment
    | IJourneyPassengerSeatInfo['seat'];

export type TAnalyticsProductInput =
    | JourneyFragmentFragment
    | IBasketEntry
    | IBasketServiceEntry
    | LegAddonFragment
    | TAnalyticsBusSeatFragment
    | null
    | undefined;

const gtmLogEvent = (event: any) => {
    console.log('[gtmLogEvent]', event);
    if (!window.dataLayer) {
        console.log('[gtmLogEvent] Initializing dataLayer');
        window.dataLayer = [];
    }
    window.dataLayer.push(event);
};

export const gtmLogDetail = (journeys: JourneyFragmentFragment[]) => {
    const products = buildProductsFromInput(journeys);
    console.log('[gtmLogDetail]', products);

    if (products.length === 0) return;

    // https://developers.google.com/tag-manager/enhanced-ecommerce#details
    gtmLogEvent({
        event: 'productDetailImpression',
        ecommerce: {
            detail: {
                products,
            },
        },
    });
};

export const gtmLogAddToCart = (
    iProducts: TAnalyticsProductInput[],
    quantity?: number
) => {
    const products = buildProductsFromInput(iProducts, quantity, true);
    console.log('[gtmLogAddToCart]', quantity, products);

    if (products.length === 0) return;

    // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
    gtmLogEvent({
        event: 'addToCart',
        ecommerce: {
            currencyCode: 'EUR',
            add: {
                products,
            },
        },
    });
};

export const gtmLogRemoveFromCart = (
    iProducts: TAnalyticsProductInput[],
    quantity?: number
) => {
    const products = buildProductsFromInput(iProducts, quantity, true);
    console.log('[gtmLogRemoveFromCart]', quantity, products);

    if (products.length === 0) return;

    // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
    gtmLogEvent({
        event: 'removeFromCart',
        ecommerce: {
            remove: {
                products,
            },
        },
    });
};

export const gtmLogCheckoutStep = (
    iProducts: TAnalyticsProductInput[],
    step: number
) => {
    const products = buildProductsFromInput(iProducts, undefined, true);
    console.log('[gtmLogCheckoutStep]', step, products);

    if (products.length === 0) return;

    // https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
    gtmLogEvent({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step },
                products,
            },
        },
    });
};

export const gtmLogPurchase = (
    iProducts: TAnalyticsProductInput[],
    purchaseInfo: {
        id: string;
        affiliation?: string;
        revenue: string;
        coupon?: string;
    }
) => {
    const products = buildProductsFromInput(iProducts, undefined, true);
    console.log('[gtmLogPurchase]', products, purchaseInfo);

    if (products.length === 0) return;

    // Do like this to prevent sending undefined for coupon or affiliation
    const actionField: Parameters<typeof gtmLogPurchase>[1] = {
        id: purchaseInfo.id,
        revenue: purchaseInfo.revenue,
    };

    if (purchaseInfo.affiliation) {
        actionField.affiliation = purchaseInfo.affiliation;
    }

    if (purchaseInfo.coupon) {
        actionField.coupon = purchaseInfo.coupon;
    }

    // https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
    gtmLogEvent({
        event: 'purchase',
        ecommerce: {
            purchase: {
                actionField,
                products,
            },
        },
    });
};

const buildProductsFromInput = (
    input: TAnalyticsProductInput[],
    quantity?: number, // used when adding / removing addons from basket
    individual?: boolean // used when logging basket to log each ticket separately
): IGTMProductField[] => {
    const res: IGTMProductField[] = [];

    console.log('[gtm] buildProductsFromInput', input, {
        quantity,
        individual,
    });

    forEach(input, item => {
        if (item === null || item === undefined) return;

        if (isJourneyFragment(item)) {
            // used when adding and removing from basket
            if (individual) {
                forEach(item.legs, leg => {
                    forEach(leg.passengers, passenger => {
                        res.push(buildJourneyProduct(item, passenger));
                    });
                });
            } else {
                res.push(buildJourneyProduct(item));
            }
        } else if (isBasketEntry(item)) {
            forEach(item.products, product => {
                const outLeg = product.outboundJourney?.legs?.[0];
                if (outLeg) {
                    res.push(buildBasketEntryProduct(product, outLeg));
                }
                const inLeg = product.inboundJourney?.legs?.[0];
                if (inLeg) {
                    res.push(buildBasketEntryProduct(product, inLeg));
                }
            });
        } else if (isBasketServiceEntry(item)) {
            res.push(buildBasketServiceEntryProduct(item));
        } else if (isLegAddon(item)) {
            res.push(buildLegAddonProduct(item, quantity));
        } else if (isBusSeatProduct(item)) {
            res.push(buildBusSeatProduct(item, quantity));
        }
    });

    return res;
};

export const isJourneyFragment = (
    item: any
): item is JourneyFragmentFragment => {
    // tslint:disable-next-line: no-unsafe-any
    return item.__typename === 'Journey';
};

const buildJourneyProduct = (
    j: JourneyFragmentFragment,
    p?: JourneyFragmentFragment['legs'][0]['passengers'][0]
): IGTMProductField => {
    const res: IGTMProductField = {
        name: `${j.originCityName}, ${j.originStopName} - ${j.destinationCityName}, ${j.destinationStopName}`,
        price: p
            ? p.finalPrice.toString()
            : (j.campaignPrice || j.regularPrice || 0).toString(),
        variant: moment
            .tz(j.departureDateTime, j.departureDateTimeTimeZone)
            .format('HH:mm'),
        quantity: 1,
    };

    if (j.legs[0]?.brandName) {
        res.brand = j.legs[0]?.brandName;
    }

    if (p?.basicDiscountName) {
        res.category = p.basicDiscountName;
    }

    return res;
};

export const isBasketEntry = (item: any): item is IBasketEntry => {
    // tslint:disable-next-line: no-unsafe-any
    return !!item.outboundTripData;
};

const buildBasketEntryProduct = (
    be: PassengerJourneysFragment,
    leg: LegInfoFragment
): IGTMProductField => {
    const res: IGTMProductField = {
        name: `${leg.departureStopCityName}, ${leg.departureStopName} - ${leg.destinationStopCityName}, ${leg.destinationStopName}`,
        price: be.totalPrice?.toString() || '0',
        variant: moment(leg.departureDateTime || '').format('HH:mm'),
        quantity: 1,
    };

    if (leg.brandName) {
        res.brand = leg.brandName;
    }

    if (be.fareClass) {
        res.category = be.fareClass;
    }

    return res;
};

export const isBasketServiceEntry = (
    item: any
): item is IBasketServiceEntry => {
    // tslint:disable-next-line: no-unsafe-any
    return !!item.name && !!item.from && !!item.to;
};

const buildBasketServiceEntryProduct = (
    be: IBasketServiceEntry
): IGTMProductField => ({
    name: be.name,
    price: be.priceValue?.toString() || '0',
    category: be.fareClass,
    variant: `${be.from} - ${be.to}`,
    quantity: 1,
});

export const isLegAddon = (item: any): item is LegAddonFragment => {
    // tslint:disable-next-line: no-unsafe-any
    return item.__typename === 'ServiceWithSalesLimit';
};

const buildLegAddonProduct = (
    la: LegAddonFragment,
    quantity = 1
): IGTMProductField => {
    const price = (la.unitPrice ?? 0) * (quantity ?? 1);
    return {
        name: la.serviceName || '',
        price: price.toString(),
        quantity,
    };
};

export const isBusSeatProduct = (
    item: any
): item is TAnalyticsBusSeatFragment => {
    return (
        item &&
        // tslint:disable-next-line: no-unsafe-any
        (item.__typename === 'SeatPlanSeat' ||
            // tslint:disable-next-line: no-unsafe-any
            (!!item.seatNumber && !!item.seatRank && !!item.seatPrice))
    );
};

const buildBusSeatProduct = (
    item: TAnalyticsBusSeatFragment,
    quantity = 1
): IGTMProductField => {
    const price = item.seatPrice?.amount || 0;
    const res: IGTMProductField = {
        name: `Seat ${item.seatRank} - ${item.seatNumber}`,
        price: price.toString(),
        quantity,
    };

    if (item.seatRank) {
        res.category = item.seatRank;
    }

    return res;
};
