import Button from '@slkit/Button';
import Icon, { ICON_NAME } from '@slkit/Icon';
import TripCTAButton from '@slkit/Trip/TripCTAButton';
import * as cx from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import { useKioskContext } from 'components/Kiosk/KioskLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import useI18n from 'lib/hooks/useI18n';
import { useScrollStatus } from 'lib/hooks/useScrollStatus';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { JourneySourceEnum } from '__types__/globalTypes';
import {
    JourneyFragmentFragment,
    useJourneyRouteQuery,
} from '../../../graphql';
import SearchTripDetailLineDetails from './SearchTripDetailLineDetails';
import SearchTripDetailSchedule from './SearchTripDetailSchedule';
import './styles.scss';

interface IProps {
    details?: string;
    trip: JourneyFragmentFragment;
    onClose: () => void;
    onSelect: () => void;
    travelPass?: boolean;
}

const SearchTripDetail = ({
    details,
    trip,
    onClose,
    onSelect,
    travelPass,
}: IProps) => {
    const { ref, atBottom, atTop } = useScrollStatus();
    const { isKiosk } = useKioskContext();
    const { t, i } = useI18n('trip_details');
    const { data, error, loading } = useJourneyRouteQuery({
        variables: {
            id: trip.id,
            source: trip.source,
            includeIntermediateStops: true,
        },
    });
    const isCrws = trip.source === JourneySourceEnum.CRWS;

    const time = moment.tz(
        trip.departureDateTime,
        trip.departureDateTimeTimeZone
    );

    const timeTableUrl = trip.timetablePdfUrl;

    const footerContent = (() => {
        if (isKiosk) {
            return (
                <Button full onClick={onClose}>
                    {t('close')}
                </Button>
            );
        }

        if (isCrws) {
            if (timeTableUrl) {
                return (
                    <a
                        href={timeTableUrl}
                        target="_blank"
                        className="Button Button--full Button--icon"
                    >
                        <Icon name={ICON_NAME.DOCUMENT} />
                        {t('show-timetable-pdf')}
                    </a>
                );
            } else {
                return null;
            }
        } else {
            return (
                <TripCTAButton
                    buttonClassName="Button--full Button--marTop Button--primary"
                    details={details}
                    selected={false}
                    travelPass={travelPass}
                    trip={trip}
                    onClick={onSelect}
                />
            );
        }
    })();

    return (
        <div className="SearchTripDetail">
            <div
                className={cx('SearchTripDetail__Header', {
                    'SearchTripDetail__Header--withShadow': !atTop,
                })}
            >
                <div className="SearchTripDetail__Title">
                    {time.format(t('title'))}
                </div>
                <div className="SearchTripDetail__Subtitle">
                    {i(t('subtitle'), {
                        from: trip.originCityName,
                        to: trip.destinationCityName,
                    })}
                </div>
            </div>
            <div className="SearchTripDetail__Content" ref={ref}>
                {(loading || error) && (
                    <div className="SearchTripDetail__ContentCenter">
                        {loading && (
                            <LoadingIndicator center fullscreen={false} />
                        )}
                        {error && <ErrorMessage error={error} fixed noPortal />}
                    </div>
                )}
                {data && (
                    <>
                        {trip.source === JourneySourceEnum.TURNIT && (
                            <>
                                <div className="SearchTripDetail__SectionTitle">
                                    {t('line-details')}
                                </div>
                                <SearchTripDetailLineDetails
                                    route={data.journeyRoute[0]}
                                    trip={trip}
                                />
                            </>
                        )}
                        <div className="SearchTripDetail__SectionTitle">
                            {t('line-route')}
                        </div>
                        <SearchTripDetailSchedule
                            route={data.journeyRoute[0]}
                        />
                    </>
                )}
            </div>

            {footerContent && (
                <div
                    className={cx('SearchTripDetail__Footer', {
                        'SearchTripDetail__Footer--withShadow': !atBottom,
                    })}
                >
                    {footerContent}
                </div>
            )}
        </div>
    );
};

export default SearchTripDetail;
