import Icon, { ICON_NAME } from '@slkit/Icon';
import IconBadge from '@slkit/IconBadge';
import InputField from '@slkit/InputField';
import PasswordInputField from '@slkit/InputField/PasswordInputField';
import Panel from '@slkit/Panel';
import PanelActions from '@slkit/Panel/PanelActions';
import PanelBody from '@slkit/Panel/PanelBody';
import LoadingIndicator from 'components/LoadingIndicator';
import useCurrentUser from 'components/Login/useCurrentUser';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

import { useCheckCorporateClientContractTravelAccountQuery } from '../../../../../graphql';
import PaymentMethodBadge from '@slkit/PaymentMethodBadge';

export interface IB2BMethodData {
    identification: string;
    password: string;
    useTravelAccount: boolean;
    username: string;
}

interface IProps {
    hasVoucher: boolean;
    onMethodChange: (data: IB2BMethodData) => void;
    onVoucherRemove: () => void;
}

const B2BPaymentMethod = ({
    hasVoucher,
    onMethodChange,
    onVoucherRemove,
}: IProps) => {
    const { t } = useI18n('payment.b2b');
    const currentUser = useCurrentUser();
    const { basketId } = React.useContext(ApplicationDataContext);
    if (!basketId) return null;

    const { data, loading } = useCheckCorporateClientContractTravelAccountQuery(
        {
            skip: !currentUser,
        }
    );
    const travelAccountAvailable =
        (data && data.me && data.me.isConnectedToClientContract) || false;

    const [formState, setFormState] = React.useState<IB2BMethodData>({
        identification: '',
        password: '',
        useTravelAccount: false,
        username: '',
    });

    const update = (u: Partial<IB2BMethodData>) => {
        const newState = { ...formState, ...u };
        setFormState(newState);
        onMethodChange(newState);
    };

    // Call update for initial value
    React.useEffect(() => {
        onMethodChange(formState);
    }, []);

    // Call update if travelAccountAvailable changes
    React.useEffect(() => {
        if (travelAccountAvailable) {
            // Call update with current identifier
            update({ password: '', useTravelAccount: true, username: '' });
        }
    }, [travelAccountAvailable]);

    return (
        <form id="b2b-payment-form" autoComplete="off">
            {loading ? (
                <LoadingIndicator fullscreen={false} />
            ) : (
                <>
                    <div className="PaymentMethod__Description">
                        {t(
                            travelAccountAvailable
                                ? 'b2b-payment-subtitle-travel-account'
                                : 'b2b-payment-subtitle'
                        )}
                        {!travelAccountAvailable && (
                            <div>
                                <a
                                    className="ExternalLink"
                                    href={t('b2b-payment-more-url')}
                                >
                                    {t('b2b-payment-more')}{' '}
                                    <Icon name={ICON_NAME.LINK} />
                                </a>
                            </div>
                        )}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                        {!travelAccountAvailable && (
                            <>
                                <InputField
                                    id="b2b-u"
                                    autoComplete="off"
                                    placeholder={t(
                                        'input.username.placeholder'
                                    )}
                                    name="b2b-u"
                                    value={formState.username}
                                    onChange={e =>
                                        update({ username: e.target.value })
                                    }
                                />
                                <PasswordInputField
                                    id="b2b-p"
                                    autoComplete="off"
                                    placeholder={t(
                                        'input.password.placeholder'
                                    )}
                                    name="b2b-p"
                                    value={formState.password}
                                    onChange={e =>
                                        update({ password: e.target.value })
                                    }
                                />
                            </>
                        )}
                        <InputField
                            id="b2b-i"
                            autoComplete="off"
                            placeholder={t('input.identifier.placeholder')}
                            value={formState.identification}
                            onChange={e =>
                                update({ identification: e.target.value })
                            }
                        />
                    </div>
                    {hasVoucher && (
                        <div style={{ marginTop: '1rem' }}>
                            <Panel>
                                <PanelBody
                                    style={{
                                        padding: '2.875rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    <span>{t('unavailable-with-voucher')}</span>
                                </PanelBody>
                                <PanelActions
                                    buttons={[
                                        {
                                            isPrimary: false,
                                            onClick: e => {
                                                onVoucherRemove();
                                                e.preventDefault();
                                                e.stopPropagation();
                                            },
                                            title: t('remove-voucher'),
                                        },
                                    ]}
                                ></PanelActions>
                            </Panel>
                        </div>
                    )}
                </>
            )}
        </form>
    );
};

export const B2BPaymentMethodHeader = ({ active }: { active: boolean }) => {
    const { t } = useI18n('payment_method');

    return (
        <div className="PaymentMethodTitle">
            <PaymentMethodBadge active={active} icon={ICON_NAME.B2B} />
            <div className="PaymentMethodTitle--methodName">{t('B2B')}</div>
        </div>
    );
};

export default B2BPaymentMethod;
