import { ApplicationDataContext } from 'lib/applicationDataContext';
import {
    Voucher,
    Voucher_voucher,
    VoucherVariables
} from 'queries/__types__/Voucher';
import * as query from 'queries/Voucher.graphql';
import * as React from 'react';

type GetVoucher = (code: string) => Promise<Voucher_voucher | undefined>;

export const useGetVoucher = (): GetVoucher => {
    const { apolloClient: client } = React.useContext(ApplicationDataContext);

    const getVoucher = (code: string): Promise<Voucher_voucher | undefined> => {
        if (!client || !code) return Promise.resolve(undefined);

        return client
            .query<Voucher, VoucherVariables>({
                fetchPolicy: 'network-only',
                query,
                variables: {
                    code,
                },
            })
            .then(res => {
                return res.data.voucher;
            });
    };

    return getVoucher;
};
