import * as cx from 'classnames';
import * as React from 'react';

interface IProps {
    children?: React.ReactNode;
    empty?: boolean;
}

const PopupItem = ({ children, empty }: IProps) => (
    <div className={cx('Popup__Item', { 'Popup__Item--empty': empty })}>
        {children}
    </div>
);

export default PopupItem;
