import Dialog from '@slkit/Dialog';
import Location from '@slkit/Location';
import LocationStop from '@slkit/Location/LocationStop';
import * as React from 'react';

import { IBusStopOption } from './BusStopSelect';

// Manually import style for dropdown to get Dropdown--scrollable
// This is required when used as component.
import 'lib/@slkit/Dropdown/index.scss';

interface IProps {
    activeOption?: IBusStopOption;
    enabled: boolean;
    flattenStops?: boolean;
    onMouseEnter: (option: IBusStopOption) => void;
    onMouseLeave: (option: IBusStopOption) => void;
    onOptionClick: (option: IBusStopOption) => void;
    options: IBusStopOption[];
}

const BusStopSelectOptions = ({
    activeOption,
    enabled,
    flattenStops,
    onMouseEnter,
    onMouseLeave,
    onOptionClick,
    options,
}: IProps) => {
    if (!enabled) return null;
    if (options.length === 0) return null;

    const cities = React.useMemo(() => options.filter(o => o.type === 'CITY'), [
        options,
    ]);

    return (
        <div className="SearchForm__Dialog">
            <Dialog>
                <div className="Dropdown--scrollable">
                    {cities.map(cityOption => (
                        <Location
                            hideLocation={flattenStops}
                            hovered={cityOption === activeOption}
                            key={cityOption.id}
                            item={cityOption}
                            onClick={onOptionClick}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            title={cityOption.label}
                        >
                            {cityOption.stops.map(stopOption => (
                                <LocationStop
                                    key={stopOption.id}
                                    hovered={stopOption === activeOption}
                                    item={stopOption}
                                    onClick={onOptionClick}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                />
                            ))}
                        </Location>
                    ))}
                </div>
            </Dialog>
        </div>
    );
};

export default BusStopSelectOptions;
