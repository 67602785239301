import Accordion from '@slkit/Accordion';
import AccordionTile from '@slkit/Accordion/AccordionTile';
import Button from '@slkit/Button';
import Check from '@slkit/Check';
import Icon, { ICON_NAME } from '@slkit/Icon';
import Message from '@slkit/Message';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import { PaymentType } from '@slkit/PaymentMethod';
import PaymentMethodSelector from '@slkit/PaymentMethodSelector';
import ErrorMessage from 'components/ErrorMessage';
import FormattedText from 'components/FormattedText';
import { ICardData } from 'lib/@adyen/AdyenCardPaymentMethod';
import { formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { CurrencyEnum, PaymentInfoQuery } from '../../../../graphql';
import ApplePayPaymentMethod, {
    ApplePayPaymentMethodButton,
    ApplePayPaymentMethodHeader,
} from './Methods/ApplePayPaymentMethod';
import B2BPaymentMethod, {
    B2BPaymentMethodHeader,
    IB2BMethodData,
} from './Methods/B2BPaymentMethod';
import CreditCardPaymentMethod, {
    CreditCardPaymentMethodHeader,
    ICardPaymentMethod,
} from './Methods/CreditCardPaymentMethod';
import GPayPaymentMethod, {
    GPayPaymentMethodButton,
    GPayPaymentMethodHeader,
} from './Methods/GPayPaymentMethod';
import { IExistingPaymentMethod } from './useExistingPaymentMethods';
import VoucherForm, { IVoucherData } from './VoucherForm';

interface IProps {
    error?: Error;
    onAddVoucher: (code: string) => Promise<any>;
    onApplePaySubmit?: () => void;
    onB2BPaymentMethodSubmit?: (data: IB2BMethodData) => Promise<any>;
    onCardSubmit: (cardData: ICardData) => void;
    onDevSubmit?: () => void;
    onGooglePaySubmit?: () => void;
    onZeroPaymentSubmit: () => void;
    onErrorClose: () => void;
    onExistingPaymentMethodSubmit: (
        method: IExistingPaymentMethod,
        cvc?: string
    ) => void;
    onTypeChange: (type: PaymentType) => void;
    onPaymentDelete: (paymentId: string) => Promise<any>;
    paymentInfo: PaymentInfoQuery['basket']['paymentInfo'];
    type: PaymentType;
}

const Payment = ({
    error: iError,
    onAddVoucher,
    onApplePaySubmit,
    onB2BPaymentMethodSubmit,
    onCardSubmit,
    onDevSubmit,
    onErrorClose,
    onExistingPaymentMethodSubmit,
    onGooglePaySubmit,
    onZeroPaymentSubmit,
    onTypeChange,
    onPaymentDelete,
    paymentInfo,
    type,
}: IProps) => {
    const { i, t } = useI18n('payment');
    const [cardMethod, setCardMethod] = React.useState<ICardPaymentMethod>({});
    const [b2BMethod, setB2BMethod] = React.useState<
        IB2BMethodData | undefined
    >();
    const [localError, setError] = React.useState<Error>();
    const [aggree, setAgree] = React.useState(false);

    const ACTIVE_METHODS = React.useMemo(() => {
        const methods: PaymentType[] = [PaymentType.CREDIT_CARD];

        if (onApplePaySubmit) methods.push(PaymentType.APAY);
        if (onGooglePaySubmit) methods.push(PaymentType.GPAY);

        if (onB2BPaymentMethodSubmit) methods.push(PaymentType.B2B);

        return methods;
    }, [onGooglePaySubmit]);

    const voucherData: IVoucherData[] = React.useMemo(() => {
        if (!paymentInfo || !paymentInfo.payments) return [];

        return paymentInfo.payments.map<IVoucherData>(pi => ({
            amount: pi.paidAmount as number,
            code: pi.voucherCode as string,
            currency: pi.currency as CurrencyEnum,
            id: pi.paymentId as string,
            type: pi.paymentType as string,
        }));
    }, [paymentInfo]);

    let canSubmit = false;

    switch (type) {
        case PaymentType.ZERO_PAYMENT:
            canSubmit = true;
            break;
        case PaymentType.CREDIT_CARD:
            if (cardMethod.existingPaymentMethod) {
                // If existing payment method is selected, check if that method requires cvc
                if (cardMethod.existingPaymentMethod.requiresCVC) {
                    // If cvc is required, check if cvc is present
                    canSubmit = !!cardMethod.existingPaymentMethodCVC;
                } else {
                    // If cvc is not required, method can be submitted
                    canSubmit = true;
                }
            } else {
                // If not trying to submit with existing payment method, card data is required
                canSubmit = !!cardMethod.cardData;
            }
            break;
        case PaymentType.B2B:
            canSubmit =
                !!b2BMethod &&
                ((!!b2BMethod.password && !!b2BMethod.username) ||
                    b2BMethod.useTravelAccount) &&
                voucherData.length === 0;
            break;
        default:
            break;
    }

    const handleSubmit = React.useCallback(() => {
        setError(undefined);
        if (!aggree) {
            setError(new Error(t('toc_required')));
            return;
        }

        if (type === PaymentType.ZERO_PAYMENT) {
            onZeroPaymentSubmit();
            return;
        }

        if (type === PaymentType.CREDIT_CARD) {
            if (cardMethod.cardData) {
                // Process with card data
                if (onCardSubmit) onCardSubmit(cardMethod.cardData);
                return;
            }

            if (cardMethod.existingPaymentMethod) {
                // Process with selected method
                if (onExistingPaymentMethodSubmit) {
                    onExistingPaymentMethodSubmit(
                        cardMethod.existingPaymentMethod,
                        cardMethod.existingPaymentMethodCVC
                    );
                }
            }
        }

        if (type === PaymentType.B2B) {
            if (b2BMethod) {
                if (onB2BPaymentMethodSubmit) {
                    onB2BPaymentMethodSubmit(b2BMethod);
                }
            }
        }

        if (type === PaymentType.GPAY) {
            if (onGooglePaySubmit) {
                onGooglePaySubmit();
            }
        }

        if (type === PaymentType.APAY) {
            if (onApplePaySubmit) {
                onApplePaySubmit();
            }
        }
    }, [
        aggree,
        b2BMethod,
        cardMethod,
        onCardSubmit,
        onExistingPaymentMethodSubmit,
        type,
    ]);

    const error = iError || localError;
    const paymentAmount = paymentInfo.payableAmount || 0;
    const voucherAmount = paymentInfo.payableWithVoucherAmount || 0;

    const accHeader = (paymentType: PaymentType, active: boolean) => {
        switch (paymentType) {
            case PaymentType.CREDIT_CARD:
                return <CreditCardPaymentMethodHeader active={active} />;

            case PaymentType.B2B:
                return <B2BPaymentMethodHeader active={active} />;

            case PaymentType.GPAY:
                return <GPayPaymentMethodHeader active={active} />;

            case PaymentType.APAY:
                return <ApplePayPaymentMethodHeader active={active} />;

            default:
                return null;
        }
    };

    const accTileContent = (paymentType: PaymentType, active: boolean) => {
        switch (paymentType) {
            case PaymentType.CREDIT_CARD:
                return (
                    <CreditCardPaymentMethod onMethodChange={setCardMethod} />
                );

            case PaymentType.B2B:
                return (
                    <B2BPaymentMethod
                        hasVoucher={voucherData.length > 0}
                        onMethodChange={setB2BMethod}
                        onVoucherRemove={() =>
                            onPaymentDelete(voucherData[0].id)
                        }
                    />
                );

            case PaymentType.GPAY:
                return <GPayPaymentMethod />;

            case PaymentType.APAY:
                return <ApplePayPaymentMethod />;

            default:
                return null;
        }
    };

    return (
        <>
            {onDevSubmit && (
                <>
                    <br />
                    <Button onClick={onDevSubmit} full>
                        DEVELOPMENT PAYMENT
                    </Button>
                    <br />
                </>
            )}
            {error && (
                <ErrorMessage
                    error={error}
                    fixed
                    noPortal
                    onClose={
                        localError ? () => setError(undefined) : onErrorClose
                    }
                />
            )}
            <NarrowTitle
                accessory={
                    <span>
                        {formatPrice(
                            paymentInfo.payableAmount!,
                            paymentInfo.currency!
                        )}
                    </span>
                }
            >
                {t('title')}
            </NarrowTitle>
            {paymentAmount === 0 && (
                <div
                    className="PaymentMethod__Description"
                    style={{ marginTop: '10px', marginBottom: '1rem' }}
                >
                    {t('zero-payment-message')}
                </div>
            )}
            {paymentAmount > 0 && (
                <Message className="mb-1" success>
                    <Icon name={ICON_NAME.SECURE} />
                    {t('encryption-message')}
                </Message>
            )}
            <PaymentMethodSelector>
                {paymentAmount > 0 && (
                    <Accordion>
                        {ACTIVE_METHODS.map(method => (
                            <AccordionTile
                                key={method}
                                active={type === method}
                                onClick={() => onTypeChange(method)}
                                header={accHeader(method, type === method)}
                            >
                                {accTileContent(method, type === method)}
                            </AccordionTile>
                        ))}
                    </Accordion>
                )}
                {voucherAmount > 0 && (
                    <VoucherForm
                        available={type !== PaymentType.B2B}
                        onDelete={pid => onPaymentDelete(pid)}
                        onSubmit={onAddVoucher}
                        voucherData={voucherData}
                    />
                )}

                <div style={{ padding: '10px 24px' }}>
                    <div style={{ marginTop: 10, marginBottom: '1rem' }}>
                        <Check
                            id="toc_checkbox"
                            isChecked={aggree}
                            label={
                                <FormattedText>
                                    {t('tos-agreement')}
                                </FormattedText>
                            }
                            onChange={setAgree}
                        />
                    </div>
                </div>

                {paymentInfo &&
                    type !== PaymentType.GPAY &&
                    type !== PaymentType.APAY && (
                        <Button
                            className="Narrow__Button"
                            style={{ margin: 0 }}
                            disabled={!canSubmit}
                            paypal={type === PaymentType.PAYPAL}
                            onClick={handleSubmit}
                        >
                            {i(t(`action.${type}`), {
                                price: formatPrice(
                                    paymentInfo.payableAmount!,
                                    paymentInfo.currency!
                                ),
                            })}
                        </Button>
                    )}
                {paymentInfo && type === PaymentType.APAY && (
                    <ApplePayPaymentMethodButton onClick={handleSubmit} />
                )}
                {paymentInfo && type === PaymentType.GPAY && (
                    <GPayPaymentMethodButton onClick={handleSubmit} />
                )}
            </PaymentMethodSelector>
        </>
    );
};

export default Payment;
