import Modal from '@slkit/Modal';
import React, { Component } from 'react';

export interface IAppConfirmDialogOptions {
    className?: string;
    confirmTitle: string;
    hideHeader?: boolean;
    rejectTitle: string;
    title?: React.ReactNode;
}

interface IProps {
    message: React.ReactNode;
    options?: IAppConfirmDialogOptions;
}

class AppConfirmDialog extends Component<IProps> {
    public promise?: Promise<boolean>;
    private resolve?: (res: boolean) => void;

    public componentDidMount() {
        this.promise = new Promise((resolve, _reject) => {
            this.resolve = resolve;
        });
    }

    public confirm = () => {
        this.resolve!(true);
    };

    public reject = () => {
        this.resolve!(false);
    };

    public render() {
        const { options, message } = this.props;
        const { confirmTitle, rejectTitle, ...rest } = options || {};

        return (
            <Modal
                {...rest}
                isActive
                onClose={this.reject}
                buttons={
                    <div className="ButtonGroup">
                        <button
                            className="Button Button--secondary"
                            onClick={this.reject}
                        >
                            {rejectTitle}
                        </button>

                        <button className="Button" onClick={this.confirm}>
                            {confirmTitle}
                        </button>
                    </div>
                }
            >
                <div className="Modal__Item">{message}</div>
            </Modal>
        );
    }
}

export default AppConfirmDialog;
