import AddonOption from '@slkit/AddonOption';
import { ICON_NAME } from '@slkit/Icon';
import NarrowSubtitle from '@slkit/Narrow/NarrowSubtitle';
import NarrowText from '@slkit/Narrow/NarrowText';
import { TabType } from '@slkit/Tabs';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import { fbpLogAddToCart } from 'lib/hooks/useFBPixel';
import { gtmLogAddToCart, gtmLogRemoveFromCart } from 'lib/hooks/useGTM';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import groupBy from 'lodash/groupBy';
import {
    CurrencyEnum,
    JourneyDirectionEnum,
    LegAddonFragment,
    TripLegAddonsDocument,
    useAddLegAddonsMutation,
    useTripLegAddonsQuery,
} from '../../../../graphql';
import { ADULT_FARE_CLASS } from '../Passengers/usePassengersForm';
import AddonOptionContainer from './AddonOptionContainer';
import useBasketContent from '../../../lib/hooks/useBasketContent';

interface IProps {
    activeTab: TabType;
    basketId: string;
    currency: CurrencyEnum;
    entry: IBasketEntry;
}

const AddonsContainer = ({ activeTab, basketId, currency, entry }: IProps) => {
    const { reload } = useBasketContent();

    const { t } = useI18n('extra.addons');
    let targetProduct = entry.products.find(
        e => e.fareClass === ADULT_FARE_CLASS
    );
    if (!targetProduct) targetProduct = entry.products[0];

    const tripLegAddonsVariables = {
        currency,
        id: basketId,
        productIds: [targetProduct.productId!],
    };

    const [addLegAddons, { error, loading }] = useAddLegAddonsMutation({
        update: (proxy, res) => {
            // Update previous result from useTripLegAddonsQuery
            // This is needed, because standard apollo cache matching on tripLegAddons
            // does not work, as addons are not uniqly identified between different legs
            // and addonID is different even on same leg / product combination between two updates
            if (
                !res.data ||
                !res.data.basket ||
                !res.data.basket.addLegAddons.tripLegAddons
            ) {
                return;
            }
            proxy.writeQuery({
                data: {
                    tripLegAddons: res.data.basket.addLegAddons.tripLegAddons,
                },
                query: TripLegAddonsDocument,
                variables: tripLegAddonsVariables,
            });
        },
    });
    const { data, error: addonsError } = useTripLegAddonsQuery({
        fetchPolicy: 'cache-and-network',
        variables: tripLegAddonsVariables,
    });

    // Addons available for selected direction (outbound / inbound)
    const availableAddons =
        data &&
        data.tripLegAddons.legAddOns.find(
            a => a.isOutbound === (activeTab === TabType.OUTBOUND)
        );

    const handleAddonCountChange = (
        addon: LegAddonFragment,
        quantity: number,
        oldQuantity: number
    ) => {
        if (!availableAddons || !currency) return Promise.resolve(false);
        const dQuantity = quantity - oldQuantity;

        return addLegAddons({
            variables: {
                addons: [
                    {
                        addonId: addon.addonGuid!,
                        addonCode: addon.addonId,

                        journeyDirection: availableAddons.isOutbound
                            ? JourneyDirectionEnum.OUTBOUND
                            : JourneyDirectionEnum.INBOUND,
                        legOrderNumber: availableAddons.legOrderNo!,
                        quantity,
                    },
                ],
                basketId,
                currency,
                productId: availableAddons.productId!,
            },
        })
            .then(() => {
                if (dQuantity > 0) {
                    gtmLogAddToCart([addon], dQuantity);
                    fbpLogAddToCart([addon], dQuantity);
                } else {
                    gtmLogRemoveFromCart([addon], -dQuantity);
                }
            })
            .then(() => true)
            .catch(() => {
                reload();
                return false;
            });
    };

    const addonCurrentCount = (addonCode: number): number => {
        if (!targetProduct) return 0;

        const journey =
            activeTab === TabType.OUTBOUND
                ? targetProduct.outboundJourney
                : targetProduct.inboundJourney;
        if (!journey) return 0;

        const leg = journey.legs && journey.legs[0];
        if (!leg) return 0;

        const addon = leg.addOns.find(a => a.code === addonCode);
        if (!addon) return 0;

        return addon.quantity || 0;
    };

    const sorted =
        availableAddons &&
        availableAddons.addOns.sort((a, b) => {
            const positionA = a.detailsGroupPosition || 1;
            const positionB = b.detailsGroupPosition || 1;
            return positionA - positionB;
        });

    const grouped = groupBy(sorted, 'detailsGroupKey');

    const notAvailable = availableAddons && availableAddons.addOns.length === 0;

    return (
        <React.Fragment>
            {loading && <LoadingIndicator fullscreen />}
            {error && <ErrorMessage error={error} fixed noPortal />}
            {addonsError && <ErrorMessage error={addonsError} inline />}
            {notAvailable ? (
                <>
                    <NarrowSubtitle>{t('title')}</NarrowSubtitle>
                    <AddonOption
                        title={t('not-available-title')}
                        description={t('not-available-description')}
                        icon={ICON_NAME.NO_EXTRA_AVAILABLE}
                        muted
                    />
                </>
            ) : (
                <>
                    {grouped &&
                        Object.keys(grouped).map(key => {
                            const group = grouped[key];
                            return (
                                <React.Fragment key={key}>
                                    <NarrowSubtitle>
                                        {t(
                                            key + '.' + 'title',
                                            1,
                                            key === 'default'
                                                ? t('title')
                                                : undefined
                                        )}
                                    </NarrowSubtitle>
                                    <NarrowText>
                                        {t(
                                            key + '.' + 'description',
                                            1,
                                            key === 'default'
                                                ? t('description')
                                                : undefined
                                        )}
                                    </NarrowText>
                                    {group.map((addon: LegAddonFragment) => {
                                        const addonId = addon.addonId || -1;
                                        const currentCount = addonCurrentCount(
                                            addonId
                                        );
                                        return (
                                            <AddonOptionContainer
                                                addon={addon}
                                                count={currentCount}
                                                currency={currency}
                                                key={addonId}
                                                onCountChange={c =>
                                                    handleAddonCountChange(
                                                        addon,
                                                        c,
                                                        currentCount
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                </>
            )}
        </React.Fragment>
    );
};

export default AddonsContainer;
