import Dropdown from '@slkit/Dropdown';
import DropdownOption from '@slkit/Dropdown/DropdownOption';
import Flag from 'components/Flag';
import { COUNTRY_ALPHA } from 'components/Flag/CountryAlphaEnum';
import { Language } from 'lib/hooks/useI18n';
import * as React from 'react';

export type LanguageOptionsDirection = 'down' | 'up';

export interface ILanguageOption {
    key: Language;
    title: string;
    countryAlpha2: COUNTRY_ALPHA;
}

interface IProps {
    active: boolean;
    direction?: LanguageOptionsDirection;
    languages: ILanguageOption[];
    onSelect: (option: ILanguageOption) => void;
    position?: 'left' | 'right';
}

const defaultProps = {
    active: false,
    position: 'right',
};

const LanguageOptions = ({
    active,
    direction = 'up',
    languages,
    onSelect,
    position,
}: IProps) => {
    return (
        <Dropdown
            style={{
                bottom: direction === 'up' ? '0' : 'auto',
                left: position === 'left' ? '0' : 'auto',
                right: position === 'left' ? 'auto' : '0',
                top: direction === 'up' ? 'auto' : '0',
            }}
            active={active}
        >
            {languages.map(l => (
                <DropdownOption key={l.key} onClick={() => onSelect(l)}>
                    <Flag countryAlpha2={l.countryAlpha2} round />
                    &nbsp;
                    {l.title}
                </DropdownOption>
            ))}
        </Dropdown>
    );
};

LanguageOptions.defaultProps = defaultProps;

export default LanguageOptions;
