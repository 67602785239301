import * as cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import * as React from 'react';
import './index.scss';

interface IProps {
    label: string | JSX.Element;
    variant?: 'centered';
}

const Radio = ({
    label,
    children,
    variant,
    ...rest
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
    const [componentId] = React.useState(uniqueId('radio_'));

    return (
        <div
            className={cx('Radio', {
                'Radio--centered': variant === 'centered',
            })}
        >
            <input type="radio" id={componentId} {...rest} />
            <label htmlFor={componentId}>{label}</label>
            <div className="Radio__Content">{children}</div>
        </div>
    );
};

export default Radio;
