import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import PasswordStrengthCheck from 'components/PasswordStrengthCheck';
import { attributesToProps } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

// Raw is used when field is used as standalone component
// directly rendered to wrapper
interface IProps {
    error?: string;
    placeholder?: string;
    raw?: NamedNodeMap | null;
    required?: boolean;
    hasHints?: boolean;
    onStrengthChange?: (strength: number) => void;
}

// attributes from html parsing
type TRawProps = React.InputHTMLAttributes<HTMLInputElement> & {
    autocomplete?: string;
    class?: string;
};

const PasswordInputField = ({
    error,
    placeholder,
    raw,
    required,
    onStrengthChange,
    hasHints,
    className,
    ...props
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
    const {
        autocomplete: autoComplete,
        class: rClassName,
        ...rawProps
    } = React.useMemo(() => {
        if (!raw) return {};
        return attributesToProps(raw);
    }, [raw]) as TRawProps;

    const { t } = useI18n('input_field');
    const containerClassName = cx(
        'InputField',
        'InputField--info',
        rClassName,
        {
            'InputField--disabled': props.disabled,
            'InputField--invalid': error,
            'InputField--required': required,
        }
    );

    const [masked, setMasked] = React.useState(true);
    const inputPlaceholder = placeholder || rawProps.placeholder;
    return (
        <>
            <div className={containerClassName}>
                <input
                    {...props}
                    {...rawProps}
                    className={className}
                    autoComplete={autoComplete}
                    type={masked ? 'password' : 'text'}
                    placeholder=" "
                />
                {inputPlaceholder && (
                    <div className="InputField__Placeholder">
                        {inputPlaceholder}
                    </div>
                )}
                {error && <div className="InputField__Message">{error}</div>}
                {required && (
                    <div className="InputField__Label">{t('required')}</div>
                )}
                <div
                    className="InputField__Password"
                    onClick={e => {
                        setMasked(!masked);
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <Icon
                        name={masked ? ICON_NAME.EYE : ICON_NAME.EYE_CLOSED}
                    />
                </div>
            </div>
            {hasHints && (
                <PasswordStrengthCheck
                    password={props.value as string}
                    onStrengthChange={onStrengthChange}
                />
            )}
        </>
    );
};

export default PasswordInputField;
