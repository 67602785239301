import {
    AdyenBrowserInfoInput,
    AdyenPaymentInput,
    BuyerInfoInput,
    CustomerInfoInput,
    JourneysInput,
    RegisterUserInput,
    ReservedSeatRequestInput,
} from '__types__/globalTypes';
import { ApolloClient } from 'apollo-client';
import { FetchResult } from 'apollo-link';

import {
    AddBasketJourney,
    AddBasketJourneyVariables,
} from './__types__/AddBasketJourney';
import {
    AddCampaignCode,
    AddCampaignCodeVariables,
} from './__types__/AddCampaignCode';
import { AddCard, AddCardVariables } from './__types__/AddCard';
import {
    ChangePassword,
    ChangePasswordVariables,
} from './__types__/ChangePassword';
import {
    ChangeReservedSeat,
    ChangeReservedSeatVariables,
} from './__types__/ChangeReservedSeat';
import { CreateBasket, CreateBasketVariables } from './__types__/CreateBasket';
import { DeleteBasket, DeleteBasketVariables } from './__types__/DeleteBasket';
import {
    DeleteBasketProducts,
    DeleteBasketProductsVariables,
} from './__types__/DeleteBasketProducts';
import {
    DeleteSavedRecurringDetail,
    DeleteSavedRecurringDetailVariables,
} from './__types__/DeleteSavedRecurringDetail';
import { DevPayment, DevPaymentVariables } from './__types__/DevPayment';
import { PayWithCard, PayWithCardVariables } from './__types__/PayWithCard';
import {
    PayWithSavedMethod,
    PayWithSavedMethodVariables,
} from './__types__/PayWithSavedMethod';
import {
    PayWithVoucher,
    PayWithVoucherVariables,
} from './__types__/PayWithVoucher';
import {
    PayWithZeroPayment,
    PayWithZeroPaymentVariables,
} from './__types__/PayWithZeroPayment';
import { RegisterUser, RegisterUserVariables } from './__types__/RegisterUser';
import {
    UpdateBuyerInfo,
    UpdateBuyerInfoVariables,
} from './__types__/UpdateBuyerInfo';
import {
    UpdateCustomerInfo,
    UpdateCustomerInfoVariables,
} from './__types__/UpdateCustomerInfo';
import {
    UpdateDefaultPaymentMethod,
    UpdateDefaultPaymentMethodVariables,
} from './__types__/UpdateDefaultPaymentMethod';
import * as addBasketJourneyMutation from './AddBasketJourney.graphql';
import * as addCampaignCodeMutation from './AddCampaignCode.graphql';
import * as addCardMutation from './AddCard.graphql';
import * as changePasswordMutation from './ChangePassword.graphql';
import * as changeReservedSeatMutation from './ChangeReservedSeat.graphql';
import * as createBasketMutation from './CreateBasket.graphql';
import * as deleteBasketMutation from './DeleteBasket.graphql';
import * as deleteBasketProductsMutation from './DeleteBasketProducts.graphql';
import * as deleteSavedRecurringDetailMutation from './DeleteSavedRecurringDetail.graphql';
import * as devPaymentMutation from './DevPayment.graphql';
import * as payWithCardMutation from './PayWithCard.graphql';
import * as payWithSavedMethodMutation from './PayWithSavedMethod.graphql';
import * as payWithVoucherMutation from './PayWithVoucher.graphql';
import * as payWithZeroPaymentMutation from './PayWithZeroPayment.graphql';
import * as registerUserMutation from './RegisterUser.graphql';
import * as updateBuyerInfoMutation from './UpdateBuyerInfo.graphql';
import * as updateCustomerInfoMutation from './UpdateCustomerInfo.graphql';
import * as updateDefaultPaymentMethodMutation from './UpdateDefaultPaymentMethod.graphql';

export type ICreateBasket = (
    affiliateId?: string
) => Promise<FetchResult<CreateBasket>>;

export const createBasket = (client: ApolloClient<any>): ICreateBasket => (
    affiliateId?: string
) =>
    client.mutate<CreateBasket, CreateBasketVariables>({
        mutation: createBasketMutation,
        variables: {
            affiliateId,
        },
    });

export type IAddBasketJourney = (
    basketId: string,
    journeysInput: JourneysInput
) => Promise<FetchResult<AddBasketJourney>>;

export const addBasketJourney = (
    client: ApolloClient<any>
): IAddBasketJourney => (basketId, journeysInput) =>
    client.mutate<AddBasketJourney, AddBasketJourneyVariables>({
        mutation: addBasketJourneyMutation,
        variables: {
            basketId,
            journeysInput,
        },
    });

export type IUpdateBuyerInfo = (
    basketId: string,
    buyerInfoInput: BuyerInfoInput
) => Promise<FetchResult<UpdateBuyerInfo>>;

export const updateBuyerInfo = (
    client: ApolloClient<any>
): IUpdateBuyerInfo => (basketId, buyerInfoInput) =>
    client.mutate<UpdateBuyerInfo, UpdateBuyerInfoVariables>({
        mutation: updateBuyerInfoMutation,
        variables: {
            basketId,
            buyerInfoInput,
        },
    });

export type IUpdateCustomerInfo = (
    basketId: string,
    productId: string,
    customerInfoInput: CustomerInfoInput
) => Promise<FetchResult<UpdateCustomerInfo>>;

export const updateCustomerInfo = (
    client: ApolloClient<any>
): IUpdateCustomerInfo => (basketId, productId, customerInfoInput) =>
    client.mutate<UpdateCustomerInfo, UpdateCustomerInfoVariables>({
        mutation: updateCustomerInfoMutation,
        variables: {
            basketId,
            customerInfoInput,
            productId,
        },
    });

export type IDeleteBasketProducts = (
    basketId: string,
    productIds: string[]
) => Promise<FetchResult<DeleteBasketProducts>>;

export const deleteBasketProducts = (
    client: ApolloClient<any>
): IDeleteBasketProducts => (basketId, productIds) =>
    client.mutate<DeleteBasketProducts, DeleteBasketProductsVariables>({
        mutation: deleteBasketProductsMutation,
        variables: {
            id: basketId,
            productIds,
        },
    });

export type IChangeReservedSeat = (
    basketId: string,
    reservedSeatRequest: ReservedSeatRequestInput
) => Promise<FetchResult<ChangeReservedSeat>>;

export const changeReservedSeat = (
    client: ApolloClient<any>
): IChangeReservedSeat => (basketId, reservedSeatRequest) =>
    client.mutate<ChangeReservedSeat, ChangeReservedSeatVariables>({
        mutation: changeReservedSeatMutation,
        variables: {
            basketId,
            reservedSeatRequest,
        },
    });

export type IAddCampaignCode = (
    basketId: string,
    campaignCode: string
) => Promise<FetchResult<AddCampaignCode>>;

export const addCampaignCode = (
    client: ApolloClient<any>
): IAddCampaignCode => (basketId, campaignCode) =>
    client.mutate<AddCampaignCode, AddCampaignCodeVariables>({
        mutation: addCampaignCodeMutation,
        variables: {
            basketId,
            campaignCode,
        },
    });

export type IChangePassword = (
    password: string
) => Promise<FetchResult<ChangePassword>>;

export const changePassword = (
    client: ApolloClient<any>
): IChangePassword => password =>
    client.mutate<ChangePassword, ChangePasswordVariables>({
        mutation: changePasswordMutation,
        variables: {
            password,
        },
    });

export type IRegisterUser = (
    registerUserInput: RegisterUserInput
) => Promise<FetchResult<RegisterUser>>;

export const registerUser = (
    client: ApolloClient<any>
): IRegisterUser => registerUserInput =>
    client.mutate<RegisterUser, RegisterUserVariables>({
        mutation: registerUserMutation,
        variables: {
            registerUserInput,
        },
    });

export type IAddCard = (
    adyenPaymentInput: AdyenPaymentInput,
    browserInfo?: AdyenBrowserInfoInput
) => Promise<FetchResult<AddCard>>;

export const addCard = (client: ApolloClient<any>): IAddCard => (
    adyenPaymentInput,
    browserInfo
) =>
    client.mutate<AddCard, AddCardVariables>({
        mutation: addCardMutation,
        variables: {
            adyenPaymentInput,
            browserInfo,
        },
    });

export type IPayWithCard = (
    basketId: string,
    adyenPaymentInput: AdyenPaymentInput,
    browserInfo?: AdyenBrowserInfoInput
) => Promise<FetchResult<PayWithCard>>;

export const payWithCard = (client: ApolloClient<any>): IPayWithCard => (
    basketId,
    adyenPaymentInput,
    browserInfo
) =>
    client.mutate<PayWithCard, PayWithCardVariables>({
        mutation: payWithCardMutation,
        variables: {
            adyenPaymentInput,
            basketId,
            browserInfo,
        },
    });

export type IPayWithZeroPayment = (
    basketId: string
) => Promise<FetchResult<PayWithZeroPayment>>;
export const payWithZeroPayment = (
    client: ApolloClient<any>
): IPayWithZeroPayment => basketId =>
    client.mutate<PayWithZeroPayment, PayWithZeroPaymentVariables>({
        mutation: payWithZeroPaymentMutation,
        variables: {
            basketId,
        },
    });

export type IPayWithSavedMethod = (
    basketId: string,
    recurringDetailReference: string,
    browserInfo?: AdyenBrowserInfoInput
) => Promise<FetchResult<PayWithSavedMethod>>;

export const payWithSavedMethod = (
    client: ApolloClient<any>
): IPayWithSavedMethod => (basketId, recurringDetailReference, browserInfo) =>
    client.mutate<PayWithSavedMethod, PayWithSavedMethodVariables>({
        mutation: payWithSavedMethodMutation,
        variables: {
            basketId,
            browserInfo,
            recurringDetailReference,
        },
    });

export type IDevPayment = (
    basketId: string
) => Promise<FetchResult<DevPayment>>;

export const devPayment = (
    client: ApolloClient<any>
): IDevPayment => basketId =>
    client.mutate<DevPayment, DevPaymentVariables>({
        mutation: devPaymentMutation,
        variables: {
            id: basketId,
        },
    });

export type IDeleteSavedRecurringDetail = (
    id: string
) => Promise<FetchResult<DeleteSavedRecurringDetail>>;

export const deleteSavedRecurringDetail = (
    client: ApolloClient<any>
): IDeleteSavedRecurringDetail => id =>
    client.mutate<
        DeleteSavedRecurringDetail,
        DeleteSavedRecurringDetailVariables
    >({
        mutation: deleteSavedRecurringDetailMutation,
        variables: {
            id,
        },
    });

export type IUpdateDefaultPaymentMethod = (
    reference: string
) => Promise<FetchResult<UpdateDefaultPaymentMethod>>;

export const updateDefaultPaymentMethod = (
    client: ApolloClient<any>
): IUpdateDefaultPaymentMethod => reference =>
    client.mutate<
        UpdateDefaultPaymentMethod,
        UpdateDefaultPaymentMethodVariables
    >({
        mutation: updateDefaultPaymentMethodMutation,
        variables: {
            reference,
        },
    });

export type IPayWithVoucher = (
    basketId: string,
    code: string,
    paidAmount: number
) => Promise<FetchResult<PayWithVoucher>>;

export const payWithVoucher = (client: ApolloClient<any>): IPayWithVoucher => (
    basketId,
    code,
    paidAmount
) =>
    client.mutate<PayWithVoucher, PayWithVoucherVariables>({
        mutation: payWithVoucherMutation,
        variables: {
            basketId,
            code,
            paidAmount,
        },
    });

export type IDeleteBasket = (id: string) => Promise<FetchResult<DeleteBasket>>;

export const deleteBasket = (client: ApolloClient<any>): IDeleteBasket => id =>
    client.mutate<DeleteBasket, DeleteBasketVariables>({
        mutation: deleteBasketMutation,
        variables: {
            id,
        },
    });
