import Icon, { ICON_NAME } from '@slkit/Icon';
import Panel from '@slkit/Panel';
import PanelBody from '@slkit/Panel/PanelBody';
import { formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import min from 'lodash/min';
import * as React from 'react';

import { CurrencyEnum } from '../../__types__/globalTypes';
import { TNormalizedTravelPass } from './TravelPassesContainer';

interface IProps {
    passes: TNormalizedTravelPass[];
    selected: TNormalizedTravelPass;
    onSelect: (travelPass: TNormalizedTravelPass) => void;
}

const TravelPass = ({ passes, selected, onSelect }: IProps) => {
    const { i, t } = useI18n('travel_pass');
    const minPrice = min(passes.map(item => item.price));
    const firstZoneName = passes[0].firstZoneName;
    const secondZoneName = passes[0].secondZoneName;
    const stops = [
        ...passes[0].firstZoneStops,
        ...passes[0].secondZoneStops,
    ].map(zone => zone.name);
    const isSelected = passes.indexOf(selected) >= 0;
    const selectedClass: string = isSelected ? 'TravelPassRoute-selected' : '';

    return (
        <div
            className={['TravelPassRoute', selectedClass].join(' ')}
            onClick={() => onSelect(passes[0])}
        >
            <div className="TravelPassRoute_Title">
                <b className="TravelPassRoute_TitleLeft">
                    <span>{firstZoneName}</span>
                    <Icon name={ICON_NAME.SWITCH} />
                    <span>{secondZoneName}</span>
                </b>
                <span className="TravelPassRoute_TitleRight">
                    {i(t('price-from'), {
                        price: formatPrice(
                            minPrice as number,
                            CurrencyEnum.EUR
                        ),
                    })}
                </span>
            </div>
            {isSelected && (
                <div className="TravelPassRoute_Notes">
                    <Panel>
                        <PanelBody>
                            {i(t('bus-stops'), { stops: stops.join(', ') })}
                        </PanelBody>
                    </Panel>
                </div>
            )}
        </div>
    );
};

export default TravelPass;
