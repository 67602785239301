import Icon, { ICON_NAME } from '@slkit/Icon';
import * as React from 'react';

interface IProps {
    badge?: number;
    icon: ICON_NAME;
}

type HeaderItemProps = IProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const HeaderItem = React.forwardRef<HTMLAnchorElement, HeaderItemProps>(
    ({ badge, icon, ...rest }: HeaderItemProps, ref) => (
        <a className="Header__Widgets-Item" ref={ref} {...rest}>
            <Icon badge={badge} name={icon} />
        </a>
    )
);

export default HeaderItem;
