import * as React from 'react';

interface IScript {
    error: boolean;
    loading: boolean;
}

// Hook
const cachedScripts: string[] = [];

const useScript = (src: string): IScript => {
    // Keeping track of script loading and error state
    const [state, setState] = React.useState<IScript>({
        error: false,
        loading: true,
    });

    React.useEffect(() => {
        // If cachedScripts array already includes src that means another instance ...
        // ... of this hook already loading this script, so no need to load again.
        if (cachedScripts.includes(src)) {
            setState({
                error: false,
                loading: false,
            });
        } else {
            cachedScripts.push(src);

            // Create script
            const script = document.createElement('script');
            script.src = src;
            script.async = true;

            // Script event listener callbacks for load and error
            const onScriptLoad = () => {
                setState({
                    error: false,
                    loading: false,
                });
            };

            const onScriptError = () => {
                // Remove from cachedScripts we can try loading again
                const index = cachedScripts.indexOf(src);
                if (index >= 0) cachedScripts.splice(index, 1);
                script.remove();

                setState({
                    error: true,
                    loading: false,
                });
            };

            script.addEventListener('load', onScriptLoad);
            script.addEventListener('error', onScriptError);

            // Add script to document body
            document.body.appendChild(script);

            // Remove event listeners on cleanup
            return () => {
                script.removeEventListener('load', onScriptLoad);
                script.removeEventListener('error', onScriptError);
            };
        }
    }, [src]); // Only re-run effect if script src changes

    return state;
};

export default useScript;
