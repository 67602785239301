import useI18n from 'lib/hooks/useI18n';
import { useMemo } from 'react';
import {
    JourneyFragmentFragment,
    JourneyRouteFragment,
} from '../../../graphql';

export const useTripDescription = (
    trip: JourneyFragmentFragment,
    route: JourneyRouteFragment
) => {
    const { i, t } = useI18n('trip_detail.line_details');

    return useMemo(() => {
        let description = '';

        if (trip.availableRegularSeats) {
            description +=
                i(t('line_detail.free-seats'), {
                    count: trip.availableRegularSeats,
                }) + ' ';
        }

        const equipmentStrings: string[] = [];

        route.busEquipment.forEach(equipment => {
            const equipmentText = t(
                `line-detail.${equipment.code}`,
                undefined,
                ''
            );

            if (equipmentText && equipmentText !== '') {
                equipmentStrings.push(equipmentText);
            }
        });

        if (equipmentStrings.length > 0) {
            description += i(t('line-detail.equipments'), {
                equipments: equipmentStrings.join(', '),
            });
        }

        description += `\n${i(t('line-detail.info'), {
            busLineNumber: route.busLineNumber,
            busCompanyName: route.busCompanyName,
        })}`;

        const ticketStrings: string[] = [];

        ['isChangeable', 'isRefundable'].forEach(attr => {
            if (trip[attr]) {
                ticketStrings.push(t(`line-detail.${attr}`));
            }
        });

        if (ticketStrings.length > 0) {
            description += `\n\n${i(t('line-detail.ticket'), {
                ticketFeatures: ticketStrings.join(', '),
            })}`;
        }

        return description;
    }, [trip, route, i, t]);
};
