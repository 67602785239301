import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    as?: 'div' | 'p';
    isBig?: boolean;
    isCenter?: boolean;
}

const NarrowText = ({
    as = 'p',
    className,
    isBig,
    isCenter,
    ...rest
}: IProps & React.HTMLAttributes<HTMLHeadingElement>) => {
    const Component = as;
    return (
        <Component
            className={cx('Narrow__Text', className, {
                'Narrow__Text--big': isBig,
                'Narrow__Text--center': isCenter,
            })}
            {...rest}
        />
    );
};

export default NarrowText;
