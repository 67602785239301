import useCSRF from 'lib/hooks/useCSRF';
import * as React from 'react';

const CSRFFields = () => {
    const hiddenFields = useCSRF();
    return (
        <>
            {hiddenFields.map(f => (
                <input key={f.name} type="hidden" {...f} />
            ))}
        </>
    );
};

export default CSRFFields;
