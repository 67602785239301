import * as React from 'react';

interface ISearchFormCellContentOwnProps {
    contentTitle: React.ReactNode;
    text: React.ReactNode;
}

export type SearchFormCellContentProps = ISearchFormCellContentOwnProps &
    React.HTMLProps<HTMLDivElement>;

const SearchFormCellContent = ({
    contentTitle: title,
    text,
    children,
    ...rest
}: SearchFormCellContentProps) => (
    <div className="SearchForm__Content" {...rest}>
        <div className="SearchForm__Title">{title}</div>
        <div className="SearchForm__Text">{text}</div>
        {children}
    </div>
);

export default SearchFormCellContent;
