import { IPaymentConfiguration } from 'components/PurchaseProcess/Payment/usePaymentConfiguration';
import useScript from 'lib/hooks/useScript';
import * as React from 'react';
import { PaymentMethodsQuery } from '../../../graphql';
import { IAdyenCheckout, IAdyenLanguageTranslations } from './adyen';
import useI18n from 'lib/hooks/useI18n';

type TAdyenCheckoutState = 'initializing' | 'ready' | 'error';

interface IAdyenCheckoutResult {
    state: TAdyenCheckoutState;
    checkout?: IAdyenCheckout;
    error?: Error;
}

const useAdyenCheckoutLib = (
    options: IPaymentConfiguration,
    paymentMethodsResponse?: PaymentMethodsQuery['adyen']['paymentMethods']
): IAdyenCheckoutResult => {
    const { t } = useI18n('payment.card');
    if (!options.sdkUrl || !options.originKey) {
        const missing = options.sdkUrl ? 'originKey' : 'sdkUrl';
        return {
            error: new Error(
                `Invalid Adyen configuration, ${missing} is missing`
            ),
            state: 'error',
        };
    }

    const environment = React.useMemo(() => {
        if (options.environment === 'test' || options.environment === 'live') {
            return options.environment;
        } else {
            console.warn(
                `Invalid Adyen environment "${options.environment}", using "test"`
            );
            return 'test';
        }
    }, []);

    const { loading, error } = useScript(options.sdkUrl);

    return React.useMemo((): IAdyenCheckoutResult => {
        if (loading) return { state: 'initializing' };
        if (error) {
            return {
                error: new Error('Could not load Adyen SDK javascript'),
                state: 'error',
            };
        }

        if (!window.AdyenCheckout) {
            return {
                error: new Error('Missing AdyenCheckout lib'),
                state: 'error',
            };
        }

        if (!options.originKey) {
            return {
                error: new Error(
                    'Invalid Adyen configuration, originKey is missing'
                ),
                state: 'error',
            };
        }

        const locale = 'custom';
        const translations: IAdyenLanguageTranslations = {
            'creditCard.expiryDateField.invalid': t('expiration-date-invalid'),
            'creditCard.numberField.invalid': t('card-number-invalid'),
            'creditCard.oneClickVerification.invalidInput.title': t(
                'cvv-invalid'
            ),
        };
        const checkout = new window.AdyenCheckout({
            environment,
            locale,
            originKey: options.originKey,
            paymentMethodsResponse,
            translations: {
                [locale]: translations,
            },
        });

        return { state: 'ready', checkout };
    }, [loading, error, paymentMethodsResponse, t]);
};

interface IAdyenCheckoutContextProviderProps {
    children: React.ReactNode;
    options: IPaymentConfiguration;
    paymentMethodsResponse?: PaymentMethodsQuery['adyen']['paymentMethods'];
}

const AdyenCheckoutContext = React.createContext<IAdyenCheckoutResult>({
    state: 'initializing',
});

export const AdyenCheckoutContextProvider = (
    props: IAdyenCheckoutContextProviderProps
) => {
    const ctx = useAdyenCheckoutLib(
        props.options,
        props.paymentMethodsResponse
    );
    return (
        <AdyenCheckoutContext.Provider value={ctx}>
            {props.children}
        </AdyenCheckoutContext.Provider>
    );
};

export const useAdyenCheckout = () => {
    const ctx = React.useContext(AdyenCheckoutContext);
    return ctx.checkout;
};

export default useAdyenCheckout;
