import Hero from '@slkit/Hero';
import Notice from 'components/Notice/Notice';
import { IParsedQueryParams } from 'components/SearchResult/useQueryParams';
import TripsSearchFormContainer from 'components/TripsSearchForm/TripsSearchFormContainer';
import useI18n from 'lib/hooks/useI18n';
import useQuery from 'lib/hooks/useQuery';
import { useHistory } from 'lib/hooks/useRouter';
import Layout from 'modules/application/Layout';
import * as React from 'react';

const SearchFormPage = () => {
    const { t } = useI18n('home');
    const params = useQuery<{ pass?: string }>();
    const history = useHistory();

    const handleChange = (data: IParsedQueryParams, queryString: string) => {
        // When travelpass number is set to undefined, clear query params to prevent
        // ?pass= overriding removed travel pass number
        if (params.pass !== data.travelPassNumber) {
            history.push('/');
        }
    };

    return (
        <Layout>
            <div className="container">
                <div className="Page">
                    <Hero>{t('hero')}</Hero>
                    <TripsSearchFormContainer
                        onChange={handleChange}
                        options={{
                            showJourneyPresets: true,
                            showTravelPass: true,
                        }}
                        travelPassNumber={params.pass}
                    />
                    <Notice />
                </div>
            </div>
        </Layout>
    );
};

export default SearchFormPage;
