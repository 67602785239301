import './index.scss';

import * as React from 'react';
import { matchPath } from 'react-router';

import PasswordInputField from '@slkit/InputField/PasswordInputField';
import CSRFFields from 'components/CSRFFields';
import FacebookLoginButton from 'components/FacebookLoginButton';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useI18n from 'lib/hooks/useI18n';
import useRouter from 'lib/hooks/useRouter';
import Dialog from '../Dialog';
import InputField from '../InputField';
import AppleLoginButton from 'components/AppleLoginButton';

const LoginDialog = () => {
    const { t } = useI18n('login');

    // gather basketId from route when we are on success screen
    let routeBasketId: string | undefined;
    const router = useRouter();
    if (router && router.location && router.location.pathname) {
        const match = matchPath<{ basketId: string }>(
            router.location.pathname,
            { path: '/purchase-success/:basketId' }
        );
        if (match) {
            routeBasketId = match.params.basketId;
        }
    }

    const { basketId: contextBasketId } = React.useContext(
        ApplicationDataContext
    );

    const basketId = contextBasketId || routeBasketId;

    return (
        <div className="LoginDialog">
            <Dialog>
                <form
                    action="/users/sign_in"
                    acceptCharset="UTF-8"
                    method="post"
                >
                    <div className="LoginDialog__Title">{t('title')}</div>
                    <CSRFFields />
                    <InputField
                        autoComplete="email"
                        name="user[email]"
                        placeholder={t('email-placeholder')}
                        type="email"
                    />
                    <PasswordInputField
                        autoComplete="current-password"
                        name="user[password]"
                        placeholder={t('password-placeholder')}
                        type="password"
                    />
                    <button
                        className="Button Button--full Button--marTop"
                        type="submit"
                    >
                        {t('login')}
                    </button>
                    <input type="hidden" name="basket_id" value={basketId} />
                </form>
                <div className="LoginDialog__Or">{t('or')}</div>

                <AppleLoginButton />
                <FacebookLoginButton className="mt-05" />

                <hr />

                <div className="flex">
                    <a
                        href="/new_password_reset"
                        className="Button Button--secondary flex-1"
                    >
                        {t('forgot-password')}
                    </a>
                    <a
                        href="/registration"
                        className="Button Button--secondary flex-1"
                    >
                        {t('register')}
                    </a>
                </div>
            </Dialog>
        </div>
    );
};

export default LoginDialog;
