import './index.scss';

import * as React from 'react';

import Counter from '../Counter';

interface IProps {
    count: number;
    title: string;
    onCountChange: (count: number) => void;
}

const TicketType = ({ title, count, onCountChange }: IProps) => (
    <div className="TicketType">
        <div className="TicketType__Content">
            <div className="TicketType__Title">{title}</div>
            <div className="TicketType__Counter">
                <Counter count={count} onChange={onCountChange} />
            </div>
        </div>
    </div>
);

export default TicketType;
