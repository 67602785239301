import { IBusStopOption } from 'components/BusStopSelect/BusStopSelect';
import useBusStopsOptions from 'components/BusStopSelect/useBusStopsOptions';
import { buildSearchString, fbpLogSearchResults } from 'lib/hooks/useFBPixel';
import { gtmLogDetail } from 'lib/hooks/useGTM';
import * as React from 'react';
import { JourneySourceEnum } from '__types__/globalTypes';
import {
    JourneyFragmentFragment,
    JourneysSearchQueryVariables,
} from '../../../graphql';
import { isCharter, isFestival, isInFuture, isSoldOut } from './helpers';
import useQueryParams, { IParsedQueryParams } from './useQueryParams';
import useSearchResults from './useSearchResults';

interface IChildProps {
    error?: Error;
    journeys: JourneyFragmentFragment[];
    loading: boolean;
}

interface IProps {
    children: (childProps: IChildProps) => JSX.Element;
    outboundJourney?: JourneyFragmentFragment;
    variables: JourneysSearchQueryVariables;
}

const getStop = (
    options: IBusStopOption[],
    stop: IParsedQueryParams['from']
): IBusStopOption | undefined =>
    options.find(o => o.type === stop.type && o.id === stop.id);

const SearchResultContainer = ({
    variables,
    children,
    outboundJourney,
}: IProps) => {
    const { data, error, loading, variables: dataVariables } = useSearchResults(
        variables
    );
    const { parsedQueryParams } = useQueryParams();

    const options = useBusStopsOptions();

    const journeys = React.useMemo(() => {
        if (loading || error || !data) return [];
        const allJourneys = data.journeysSearch.journeys;
        let filtered = allJourneys.filter(
            j =>
                j.source === JourneySourceEnum.CRWS ||
                (isInFuture(j) && (j.isForSale || isSoldOut(j)))
        );

        if (outboundJourney) {
            const isC = isCharter(outboundJourney);
            const isF = isFestival(outboundJourney);
            if (isC || isF) {
                filtered = filtered.filter(
                    j =>
                        // if outbound journey is charter, filter only charter journeys
                        // if outbound journey is festival, filter only festival journeys
                        (isC ? isCharter(j) : isFestival(j)) &&
                        j.destinationStopId === outboundJourney.originStopId &&
                        j.originStopId === outboundJourney.destinationStopId
                );
            }
        }

        return filtered;
    }, [data, outboundJourney]);

    React.useEffect(() => {
        if (data && options) {
            // Do not track if current data result is for different variables then
            // variables passed through props. This means data are from previous search
            // result and new are being loaded
            if (variables !== dataVariables) return;

            const origin = getStop(options, parsedQueryParams.from);
            const destination = getStop(options, parsedQueryParams.to);
            if (!origin || !destination) return;

            gtmLogDetail(journeys);
            fbpLogSearchResults(buildSearchString(parsedQueryParams, options));
        }
    }, [data, journeys, variables, dataVariables]);

    return children({ journeys, loading, error });
};

export default SearchResultContainer;
