import { ApolloError } from 'apollo-client';
import * as React from 'react';

import { ApplicationDataContext } from 'lib/applicationDataContext';
import { PhoneAreaCodes } from 'queries/__types__/PhoneAreaCodes';
import * as query from 'queries/PhoneAreaCodes.graphql';

interface IPhoneAreaCodes {
    loading: boolean;
    data?: PhoneAreaCodes;
    error?: ApolloError;
}

export default function usePhoneAreaCodes() {
    const { apolloClient: client } = React.useContext(ApplicationDataContext);
    const [data, setData] = React.useState<IPhoneAreaCodes>({
        loading: true,
    });

    React.useEffect(() => {
        if (!client) return;

        client
            .query<PhoneAreaCodes>({ query })
            .then(d => {
                setData({
                    data: d.data,
                    loading: false,
                });
            })
            .catch(error => {
                setData({
                    error: error as ApolloError,
                    loading: false,
                });
            });
    }, [client]);

    return data;
}
