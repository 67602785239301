import './index.scss';

import * as React from 'react';

interface IProps {
    children?: React.ReactNode;
    summaryTitle?: string;
    summaryPrice?: string;
}

const Summary = ({ children, summaryPrice, summaryTitle }: IProps) => {
    return (
        <div className="Summary">
            {children}
            {summaryPrice && summaryTitle && (
                <div className="Summary__Item">
                    <div>{summaryTitle}</div>
                    <div className="Summary__Price">{summaryPrice}</div>
                </div>
            )}
        </div>
    );
};

export default Summary;
