import { useCallback, useEffect, useRef, useState } from 'react';

interface IUseFocusable {
    buttonRef: React.MutableRefObject<null>;
    fBlur: (
        e?: Event | React.MouseEvent | React.FocusEvent<HTMLDivElement>
    ) => void;
    fFocus: (
        e?: Event | React.MouseEvent | React.FocusEvent<HTMLDivElement>
    ) => void;
    isFocused: boolean;
    ref: React.MutableRefObject<null>;
    setIsFocused: (focused: boolean) => void;
}

export default function useFocusable(focused: boolean): IUseFocusable {
    const [isFocused, setIsFocused] = useState<boolean>(focused);
    const ref = useRef(null);
    const buttonRef = useRef(null);

    const fFocus = useCallback(
        (e?: Event | React.MouseEvent | React.FocusEvent<HTMLDivElement>) => {
            setIsFocused(true);
            if (e && e.preventDefault) e.preventDefault();
        },
        []
    );
    const fBlur = useCallback(
        (e?: Event | React.MouseEvent | React.FocusEvent<HTMLDivElement>) => {
            setIsFocused(false);
            if (e && e.preventDefault) e.preventDefault();
        },
        []
    );

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (!isFocused) return;
            if (ref.current === null) return;
            const node = (ref.current as unknown) as HTMLElement;
            if (node.contains(event.target as Node)) return;

            setIsFocused(false);

            if (!buttonRef || !buttonRef.current) return;

            const buttonNode = (buttonRef.current as unknown) as HTMLElement;
            if (buttonNode.contains(event.target as Node)) {
                event.preventDefault();
                event.stopPropagation();
            }
        },
        [ref.current, isFocused, setIsFocused]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('touchstart', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener(
                'touchstart',
                handleClickOutside,
                true
            );
        };
    }, [handleClickOutside]);

    return {
        // buttonRef can be used for preventing some element from triggering focus
        // when trying to blur
        buttonRef,
        fBlur,
        fFocus,
        isFocused,
        ref,
        setIsFocused,
    };
}
