import Hero from '@slkit/Hero';
import KioskNavbar from '@slkit/KioskNavbar';
import KioskLayout from 'components/Kiosk/KioskLayout';
import TripsSearchFormContainer from 'components/TripsSearchForm/TripsSearchFormContainer';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { useHistory } from 'react-router';

const KioskFormPage = () => {
    const { t } = useI18n('kiosk.home');
    const history = useHistory();
    const handleSubmit = (_, queryString) => {
        history.push(`/kiosk/search?${queryString}`);
    };
    return (
        <KioskLayout>
            <div className="KioskContainer__Content">
                <KioskNavbar />
                <Hero>{t('hero')}</Hero>
                <TripsSearchFormContainer
                    options={{
                        showJourneyPresets: true,
                        showTravelPass: false,
                        hideApproach: true,
                        hideFareClasses: true,
                    }}
                    onSubmit={handleSubmit}
                />
            </div>
        </KioskLayout>
    );
};

export default KioskFormPage;
