import {
    ApplicationDataContext,
    IApplicationDataContext,
} from 'lib/applicationDataContext';
import { getStrings } from 'lib/SLStringsStore';
import * as React from 'react';
import { useContext } from 'react';
import { Language } from './useI18n';

export interface ILanguageData {
    [key: string]: string | { [key: string]: string };
}

const allStrings: { [key: string]: ILanguageData } = {
    [Language.DE]: require('src/locale/de.json') as ILanguageData,
    [Language.EN]: require('src/locale/en.json') as ILanguageData,
    [Language.SK]: require('src/locale/sk.json') as ILanguageData,
};

const LANG_PATH = (lang: Language) => `translations/${lang}.json`;

const isILanguageData = (
    value: ILanguageData | null | undefined
): value is ILanguageData => {
    return value !== undefined && value !== null;
};

export const useLanguageData = (lang: Language): ILanguageData => {
    // Make sure context is optional, as this can be called from outside of context
    const ctx = useContext(ApplicationDataContext) as
        | IApplicationDataContext
        | undefined;
    const apiUri = ctx && ctx.apiUri;

    const [loadedStrings, setLoadedStrings] = React.useState<{
        [key: string]: ILanguageData | null | undefined;
    }>({});
    const loadedLangData = loadedStrings[lang];

    // not null, null is when load failed or something
    if (loadedLangData === undefined) {
        // apiUri is either undefined or complete graphql uri, ie. https://online.slovaklines.sk/graphql
        const url = apiUri
            ? apiUri.replace('graphql', LANG_PATH(lang))
            : '/' + LANG_PATH(lang);

        getStrings(url).then(langData =>
            setLoadedStrings(data => ({ ...data, [lang]: langData }))
        );
    }

    return isILanguageData(loadedLangData) ? loadedLangData : allStrings[lang];
};
