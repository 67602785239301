import Icon, { ICON_NAME } from '@slkit/Icon';
import IconBadge from '@slkit/IconBadge';
import * as React from 'react';
import './index.scss';

export type CardIcon =
    | 'maestro'
    | 'mc'
    | 'visa'
    | 'discover'
    | 'amex'
    | 'diners';

export interface IProps {
    number: string;
    icon?: CardIcon;
}

const ICONS = {
    amex: ICON_NAME.AMEX,
    diners: ICON_NAME.DINERS,
    discover: ICON_NAME.DISCOVER,
    maestro: ICON_NAME.MAESTRO,
    mc: ICON_NAME.MASTERCARD,
    visa: ICON_NAME.VISA,
};

const CardLabel = ({ number, icon }: IProps) => {
    const iconName = !!icon && ICONS[icon];

    return (
        <div className="CardList__Card">
            <span className="CardList__Number">•••• {number}</span>
            {iconName && (
                <IconBadge variant="white">
                    <Icon name={iconName} rect />
                </IconBadge>
            )}
        </div>
    );
};

export default CardLabel;
