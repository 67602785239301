import Icon, { ICON_NAME } from '@slkit/Icon';
import LoadingIndicator from 'components/LoadingIndicator';
import { useIsMounted } from 'lib/hooks/useIsMounted';
import useLoading from 'lib/hooks/useLoading';
import * as React from 'react';

interface IProps {
    disabled?: boolean;
    onSubmit: () => Promise<any> | void;
    title?: string;
}

const TripsSearchFormSubmitButton = ({ disabled, onSubmit, title }: IProps) => {
    const { loading, beginLoading, endLoading } = useLoading();
    const isMounted = useIsMounted();

    const handleClick = () => {
        const prom = onSubmit();
        if (prom) {
            beginLoading();
            prom.finally(() => {
                if (isMounted.current) {
                    endLoading();
                }
            });
        }
    };

    return (
        <>
            <button
                className="SearchForm__Button"
                disabled={disabled || loading}
                onClick={handleClick}
            >
                {title || <Icon name={ICON_NAME.SEARCH} />}
            </button>
            {loading && <LoadingIndicator fullscreen />}
        </>
    );
};

export default TripsSearchFormSubmitButton;
