import cx from 'classnames';
import * as React from 'react';
import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';

export interface ICounterProps {
    count: number;
    max?: number;
    onChange: (count: number) => void;
}

const Counter = ({ count, max, onChange }: ICounterProps) => {
    const canAdd = max === undefined ? true : count < max;
    return (
        <div
            className={cx('Counter', {
                'Counter--active': count > 0,
            })}
        >
            <button
                className="Counter__Minus"
                onClick={() => onChange(count - 1)}
            >
                <Icon name={ICON_NAME.MINUS} />
            </button>
            <div className="Counter__Display">{count}</div>
            <button
                className="Counter__Plus"
                disabled={!canAdd}
                onClick={() => onChange(count + 1)}
            >
                <Icon name={ICON_NAME.PLUS} />
            </button>
        </div>
    );
};

export default Counter;
