import * as React from 'react';

interface ILogSearchEventProperties {
    origin: string;
    origin_code?: string;
    destination: string;
    destination_code?: string;
    first_route_timestamp: number;
    return_route_timestamp?: number;
    line_type: 'international' | 'regional';
    ticket_type: 'oneway' | 'return';
    ticket_tariff: Array<{ tariff: string; quantity: number }>;
    location: string;
    language: string;
    platform: 'web';
    result: boolean; // true - has results, false - no results
}

interface ITracking {
    identify: (email: string) => void;
    logSearch: (params: ILogSearchEventProperties) => void;
}

export const useTracking = (): ITracking => {
    const track = (event: string, params: any) => {
        // console.log('[EXPONEA]', 'track', event, params);
        if (window.exponea) window.exponea.track(event, params);
    };

    const identify = React.useCallback((email: string) => {
        if (!window.exponea) return;

        const id = email.toLowerCase().trim();
        // console.log('[EXPONEA]', 'identify', id);
        window.exponea.identify({ registered: id });
    }, []);

    const logSearch = React.useCallback((params: ILogSearchEventProperties) => {
        track('search_result', params);
    }, []);

    return {
        identify,
        logSearch,
    };
};
