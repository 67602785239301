import { ICON_NAME } from '@slkit/Icon';
import useBusStops from 'lib/hooks/useBusStops';
import * as React from 'react';
import { StopTypeEnum } from '../../../graphql';
import { IBusStopOption } from './BusStopSelect';

const stopsSort = (a: IBusStopOption, b: IBusStopOption): number => {
    if (a.hasPriority === b.hasPriority) {
        // If priority is same, sort A~Z
        return a.label.localeCompare(b.label);
    } else {
        // If priority is not same, sort ones with priority first
        return a.hasPriority ? -1 : 1;
    }
};

const stopIcon = (stopType: StopTypeEnum): ICON_NAME => {
    switch (stopType) {
        case StopTypeEnum.AIRPORT:
            return ICON_NAME.DESTINATIONS_AIRPORT;
        case StopTypeEnum.BUS_STATION:
            return ICON_NAME.DESTINATIONS_BUS;
        case StopTypeEnum.BUS_STOP:
            return ICON_NAME.DESTINATIONS_DESTINATION;
        default:
            return ICON_NAME.DESTINATIONS_DESTINATION;
    }
};

/**
 * Removes leading and trailing spaces
 * Removes accents from string
 * Downcases string
 */
export const normalizeSearchString = (input: string): string => {
    // See https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    // for more info
    return input
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};

// Memoize options
const useBusStopsOptions = (tag?: string) => {
    const { data: busStops } = useBusStops(tag);

    return React.useMemo(() => {
        let opts: IBusStopOption[] = [];

        if (!busStops) return opts;
        // OPT is used to mark start of any search string part
        // This allows for simple regex testing at "start of string" with multiple strings
        const searchStringify = (strings: string[]) =>
            strings.map(s => 'OPT' + normalizeSearchString(s)).join();

        busStops!.busStops.map(country => {
            country.cities.map(city => {
                const cityOption: IBusStopOption = {
                    hasPriority: false,
                    icon: ICON_NAME.DESTINATIONS_CITY,
                    id: city.id,
                    label: city.name,
                    searchString: searchStringify([city.name]),
                    stops: [],
                    text: city.name,
                    type: 'CITY',
                    value: undefined,
                };
                opts.push(cityOption);

                city.busStops.map(stop => {
                    const stopOption: IBusStopOption = {
                        availableDates: stop.availableDates as string[],
                        hasPriority: stop.hasPriority || false,
                        icon: stopIcon(stop.stopType),
                        id: stop.id,
                        label: stop.name,
                        searchString: searchStringify([
                            city.name,
                            stop.name,
                            ...stop.aliases.map(a => a.alias),
                        ]),
                        stops: [],
                        tag: stop.comment ? stop.comment : undefined,
                        text: city.name,
                        type: 'STOP',
                        value: stop,
                    };
                    cityOption.stops.push(stopOption);
                    return stopOption;
                });

                cityOption.stops = cityOption.stops.sort(stopsSort);
            });
        });

        opts = opts.sort(stopsSort);

        opts = opts.reduce(
            (all, cityOption) => [...all, cityOption, ...cityOption.stops],
            []
        );

        return opts;
    }, [busStops]);
};

export default useBusStopsOptions;
