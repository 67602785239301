import './index.scss';

import cx from 'classnames';
import * as React from 'react';

interface IProps {
    variant?: 'default' | 'white';
}

const IconBadge = ({
    className,
    variant = 'default',
    ...props
}: IProps & React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cx('IconBadge', className)}
        style={variant === 'white' ? { backgroundColor: 'white' } : undefined}
        {...props}
    />
);

export default IconBadge;
