import cx from 'classnames';
import * as React from 'react';
import { createPortal } from 'react-dom';
import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import useI18n from 'lib/hooks/useI18n';
import usePortalTarget from 'lib/hooks/usePortalTarget';

interface IProps {
    fixed?: boolean;
    inline?: boolean;
    noPortal?: boolean;
    title?: string;
    text?: string;
    onClose?: () => void;
}

const NotificationSmall = ({
    fixed,
    inline,
    noPortal,
    title,
    text,
    onClose,
}: IProps) => {
    const { t } = useI18n('form');

    const className = cx('NotificationSmall', {
        'NotificationSmall--fixed': fixed,
        'NotificationSmall--inline': inline,
    });

    const content = (
        <div className={className}>
            <div className="NotificationSmall__Content">
                <div className="NotificationSmall__Icon">
                    <Icon name={ICON_NAME.WARNING} />
                </div>
                <div className="NotificationSmall__Message">
                    <div className="NotificationSmall__Title">
                        {title || t('submit-error')}
                    </div>
                    <div className="NotificationSmall__Text">{text}</div>
                </div>
                {onClose && (
                    <button
                        className="NotificationSmall__Close"
                        onClick={onClose}
                    >
                        <Icon name={ICON_NAME.CLOSE} />
                    </button>
                )}
            </div>
        </div>
    );

    if (fixed && !noPortal) {
        const portalTarget = usePortalTarget('fixed-notifications-container');
        if (!portalTarget) return null;
        return createPortal(content, portalTarget);
    } else {
        return content;
    }
};

export default NotificationSmall;
