import * as React from 'react';
import * as cx from 'classnames';
import './index.scss';

interface IProps {
    image: React.ReactNode;
    className?: string;
    title?: string | undefined;
}

const ImageBadge = ({ className, image, title }: IProps) => (
    <div className={cx('ImageBadge', className)}>
        <div className="ImageBadge__Image">{image}</div>
        {title && <div className="ImageBadge__Title">{title}</div>}
    </div>
);

export default ImageBadge;
