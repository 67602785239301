import Bus from '@slkit/Bus';
import orderBy from 'lodash/orderBy';
import * as React from 'react';
import { BusSeatFragment, BusSeatPlanFragment } from '../../../../graphql';

interface IProps {
    seatPlan: BusSeatPlanFragment;
    onSelect: (seat: BusSeatFragment) => void;
}

const BusContainer = ({ seatPlan, onSelect }: IProps) => {
    const seats = React.useMemo(
        () =>
            seatPlan.floors[0].seatRows.reduce(
                (a, b) => [...a, ...orderBy(b.seats, ['columnNumber'])],
                []
            ),
        [seatPlan]
    );

    return <Bus seats={seats} onSelect={onSelect} />;
};

export default BusContainer;
