import './index.scss';

import cx from 'classnames';
import React from 'react';

import Icon, { ICON_NAME } from '.';

interface IProps {
    direction: 'up' | 'down' | 'left' | 'right';
}

export const IconArrow = ({ direction }: IProps) => (
    <Icon
        name={ICON_NAME.ARROW_DOWN}
        className={cx({
            'Icon--rotate-0': direction === 'right',
            'Icon--rotate-180': direction === 'left',
            'Icon--rotate-270': direction === 'up',
            'Icon--rotate-90': direction === 'down',
        })}
    />
);
