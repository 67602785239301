import { IBusStopOption, IValue } from 'components/BusStopSelect/BusStopSelect';

export const getAvailableDates = (
    stops: IBusStopOption[],
    selectedStops: Array<IValue | undefined>
): string[] | undefined =>
    selectedStops.reduce<string[] | undefined>((dates, stop) => {
        // If stop is undefined, return previous value
        if (!stop) return dates;

        // Find stop option for stop
        const stopOption = stops.find(
            s => s.id === stop.id && s.type === stop.type
        );

        // If stop is not found or stop does not have available dates, return previous value
        if (!stopOption || !stopOption.availableDates) return dates;
        const stopDates = stopOption.availableDates;

        // If previous value is undefined (no yet set), return dates from current stop
        if (dates === undefined) return stopDates;
        // otherwise return dates that are present in both arrays
        else return dates.filter(d => stopDates.indexOf(d) !== -1);
    }, undefined);
