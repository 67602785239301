import './index.scss';

import StopName from '@slkit/StopName';
import cx from 'classnames';
import * as moment from 'moment';
import * as React from 'react';

interface IProps {
    time: moment.Moment;
    showDate?: boolean;
    city: string;
    stop: string;
    details?: string;
    platform?: string | null;
    from?: boolean;
    to?: boolean;
}

const ScheduleItem = ({
    time,
    showDate = false,
    city,
    stop,
    details,
    platform,
    from = false,
    to = false,
}: IProps) => {
    const date = showDate ? time.format('D/MM') : null;

    return (
        <div
            className={cx('ScheduleItem', {
                'ScheduleItem--from': from,
                'ScheduleItem--to': to,
            })}
        >
            <div className="ScheduleItem__Datetime">
                <div className="ScheduleItem__Time">{time.format('HH:mm')}</div>
                {date}
            </div>
            <div className="ScheduleItem__Separator" />
            <div className="ScheduleItem__Stop">
                <div>
                    <StopName city={city} stop={stop} />
                </div>
                <div className="ScheduleItem__Details">
                    {details}
                    <br />
                    {platform}
                </div>
            </div>
        </div>
    );
};

export default ScheduleItem;
