import Narrow from '@slkit/Narrow';
import Steps from '@slkit/Steps';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useBasketContent from 'lib/hooks/useBasketContent';
import * as React from 'react';
import { Redirect, Route } from 'react-router';
import ExtraContainer from './Extra/ExtraContainer';
import PassengersInformationStep from './Passengers/PassengersInformationStep';
import PaymentContainer from './Payment';
import TripSummaryContainer from './TripSummary';

const PurchaseProcessContainer = () => {
    const { isTravelPassesOnly } = useBasketContent();
    const showPassengers = !isTravelPassesOnly;
    const showExtra = !isTravelPassesOnly;

    const { basketId } = React.useContext(ApplicationDataContext);
    if (!basketId) return <Redirect to="/" />;
    return (
        <Narrow>
            <Steps
                showExtraStep={showExtra}
                showPassengersStep={showPassengers}
            />
            <Route path="*/summary" component={TripSummaryContainer} />
            <Route path="*/passengers" component={PassengersInformationStep} />
            <Route path="*/extra" component={ExtraContainer} />
            <Route path="*/payment" component={PaymentContainer} />
        </Narrow>
    );
};

export default PurchaseProcessContainer;
