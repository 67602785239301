import Icon, { ICON_NAME } from '@slkit/Icon';
import Tooltip from '@slkit/Tooltip';
import cx from 'classnames';
import useFocusable from 'lib/hooks/useFocusable';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

interface IProps {
    required?: boolean;
    placeholder?: string;
    error?: string | null;
    info?: React.ReactNode;
}

const InputField = ({
    className,
    required,
    placeholder,
    error,
    type = 'text',
    info,
    ...rest
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
    const { t } = useI18n('input_field');
    const { fFocus, fBlur, isFocused, ref } = useFocusable(false);

    const containerClassName = cx('InputField', className, {
        'InputField--disabled': rest.disabled,
        'InputField--info': info,
        'InputField--invalid': error,
        'InputField--required': required,
    });

    return (
        <div className={containerClassName}>
            <input type={type} placeholder=" " {...rest} />
            {placeholder && (
                <div className="InputField__Placeholder">{placeholder}</div>
            )}
            {error && <div className="InputField__Message">{error}</div>}
            {required && (
                <div className="InputField__Label">{t('required')}</div>
            )}
            {info && (
                <button
                    className="InputField__Info"
                    ref={ref}
                    onMouseEnter={fFocus}
                    onMouseLeave={fBlur}
                >
                    <Icon name={ICON_NAME.INFO} />
                    <Tooltip active={isFocused}>{info}</Tooltip>
                </button>
            )}
        </div>
    );
};

export default InputField;
