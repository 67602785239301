import cx from 'classnames';
import * as React from 'react';
import './index.scss';

const Hero = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('Hero', className)} {...props} />
);

export default Hero;
