import NotificationSmall from '@slkit/SmallNotification';
import localStorage from 'lib/localStorage';
import * as React from 'react';

const Notice: React.FC = () => {
    const [notice, setNotice] = React.useState<string | undefined>();

    const clearNotice = () => {
        localStorage.setNotice(undefined);
        setNotice(undefined);
    };

    React.useEffect(() => {
        const checkNotice = () => {
            const n = localStorage.getNotice();
            if (n != null) {
                setNotice(n);
            }
        };

        checkNotice();
        const interval = setInterval(() => checkNotice(), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    if (notice == null || notice === '') {
        return null;
    }

    return (
        <NotificationSmall
            fixed
            noPortal
            title={notice}
            onClose={clearNotice}
        />
    );
};

export default Notice;
