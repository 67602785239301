import * as React from 'react';

interface IProps {
    children: React.ReactNode;
}

const PopupFooter = ({ children }: IProps) => (
    <div className="Popup__Footer">{children}</div>
);

export default PopupFooter;
