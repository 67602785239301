import { ICON_NAME } from '@slkit/Icon';
import PaymentMethodBadge from '@slkit/PaymentMethodBadge';
import useCurrentLanguage from 'lib/hooks/useCurrentLanguage';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

const ApplePayPaymentMethod = () => {
    const { t } = useI18n('payment.apay');
    return <div className="PaymentMethod__Description">{t('description')}</div>;
};

export const ApplePayPaymentMethodHeader = ({
    active,
}: {
    active: boolean;
}) => {
    const { t } = useI18n('payment_method');

    return (
        <div className="PaymentMethodTitle">
            <PaymentMethodBadge active={active} icon={ICON_NAME.APPLE_PAY} />
            <div className="PaymentMethodTitle--methodName">{t('APAY')}</div>
        </div>
    );
};

interface IApplePayPaymentMethodButtonProps {
    onClick: () => void;
}

export const ApplePayPaymentMethodButton = ({
    onClick,
}: IApplePayPaymentMethodButtonProps) => {
    const { lang } = useCurrentLanguage();
    return (
        <button onClick={onClick} className="apay-button" lang={lang}></button>
    );
};

export default ApplePayPaymentMethod;
