import KioskButton, { KioskLink } from '@slkit/KioskButton';
import LanguagePickerModal from 'components/LanguagePicker/LanguagePickerModal';
import useRouter from 'lib/hooks/useRouter';
import * as React from 'react';
import { ReactComponent as HOME } from '../assets/svgs/kiosk_home.svg';
import { ReactComponent as LANG } from '../assets/svgs/kiosk_lang.svg';
import { ReactComponent as LOGO } from '../assets/svgs/kiosk_logo.svg';
import './index.scss';

interface IProps {
    showHome?: boolean;
}

const KioskNavbar = ({ showHome = false }: IProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const router = useRouter();

    React.useEffect(() => {
        if (router?.location?.hash === '#language') {
            setIsOpen(true);
            router.history.replace({ hash: '' });
        }
    }, []);

    return (
        <>
            <div className="KioskNavbar">
                {showHome && (
                    <KioskLink to="/kiosk">
                        <HOME />
                    </KioskLink>
                )}
                <div>
                    <LOGO />
                </div>
                <KioskButton onClick={() => setIsOpen(!isOpen)} active={isOpen}>
                    <LANG />
                </KioskButton>
            </div>
            <LanguagePickerModal
                isActive={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
};

export default KioskNavbar;
