import * as React from 'react';

export interface ICurrentUser {
    email: string;
    id: string;
    name: string;
}

export const currentUserMetaValue = () => {
    const param = document.querySelector("meta[name='x-current-user']");
    if (!param) return undefined;
    return param.getAttribute('content');
};

const useCurrentUser = (): ICurrentUser | undefined => {
    const userData = currentUserMetaValue();

    if (userData) {
        return React.useMemo(() => JSON.parse(userData) as ICurrentUser, [
            userData,
        ]);
    }
};

export default useCurrentUser;
