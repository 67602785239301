import Icon, { ICON_NAME } from '@slkit/Icon';
import useI18n from 'lib/hooks/useI18n';
import capitalize from 'lodash/capitalize';
import 'moment/locale/sk';
import * as React from 'react';
import DayPicker, {
    DayModifiers,
    DayPickerProps,
    NavbarElementProps,
} from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import './index.scss';

const CalendarNavbar = (props: NavbarElementProps) => {
    const {
        month,
        onPreviousClick,
        onNextClick,
        className,
        localeUtils,
    } = props;
    const months = localeUtils.getMonths(props.locale);
    return (
        <div className={className}>
            <div className="DayPicker-NavButton DayPicker-NavButton--previous">
                {props.showPreviousButton && (
                    <span
                        tabIndex={0}
                        role="button"
                        aria-label="Previous Month"
                        className="DayPicker-NavButtonContent"
                        onClick={() => onPreviousClick()}
                    >
                        <Icon name={ICON_NAME.CHEVRON} />
                    </span>
                )}
            </div>
            <div className="DayPicker-Caption">
                <span className="DayPicker-CaptionMonth">
                    {capitalize(months[month.getMonth()])}
                </span>{' '}
                <span className="DayPicker-CaptionYear">
                    {month.getFullYear()}
                </span>
            </div>
            <div className="DayPicker-NavButton DayPicker-NavButton--next">
                {props.showNextButton && (
                    <span
                        tabIndex={0}
                        role="button"
                        aria-label="Next Month"
                        className="DayPicker-NavButtonContent"
                        onClick={() => onNextClick()}
                    >
                        <Icon name={ICON_NAME.CHEVRON} />
                    </span>
                )}
            </div>
        </div>
    );
};

const renderDay = (day: Date, modifiers: DayModifiers) => {
    const { end, start, selected, today } = modifiers;

    const showCap = selected && (start || end);
    const showCircle =
        (selected && !start && !end) ||
        (!selected && start && !end) ||
        (!selected && !start && end);

    return (
        <>
            {today && <span className="DayPicker-Day__today" />}

            {showCap && <span className="DayPicker-Day__cap" />}
            {showCircle && <span className="DayPicker-Day__circle" />}

            <span>
                {day
                    .getDate()
                    .toString()
                    .padStart(2, '0')}
            </span>
        </>
    );
};

const Calendar = (props: DayPickerProps) => {
    const { lang } = useI18n();
    return (
        <div className="Calendar">
            <DayPicker
                fromMonth={new Date()}
                localeUtils={MomentLocaleUtils}
                locale={lang}
                showOutsideDays
                renderDay={renderDay}
                navbarElement={CalendarNavbar}
                captionElement={() => null}
                {...props}
            />
        </div>
    );
};

export default Calendar;
