import * as React from 'react';
import usePaymentConfiguration from 'components/PurchaseProcess/Payment/usePaymentConfiguration';
import { GooglePayContextProvider } from './useGooglePay';

interface IProps {
    children?: React.ReactNode;
}

const GooglePayContainer = ({ children }: IProps) => {
    const config = usePaymentConfiguration();

    return (
        <GooglePayContextProvider options={config}>
            {children}
        </GooglePayContextProvider>
    );
};

export default GooglePayContainer;
