import Icon, { ICON_NAME } from '@slkit/Icon';
import IconBadge from '@slkit/IconBadge';
import * as React from 'react';

interface IProps {
    active?: boolean;
}

const SupportedCards = ({ active }: IProps) => {
    const supported: ICON_NAME[] = [
        ICON_NAME.VISA,
        ICON_NAME.MASTERCARD,
        // ICON_NAME.MAESTRO,
        // ICON_NAME.AMEX,
        // ICON_NAME.DISCOVER,
        // ICON_NAME.DINERS,
    ];
    return (
        <>
            {supported.map(name => (
                <IconBadge key={name} variant={active ? 'white' : undefined}>
                    <Icon name={name} rect />
                </IconBadge>
            ))}
        </>
    );
};

export default SupportedCards;
