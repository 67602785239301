import { ApolloError } from 'apollo-client';

import { StopsQuery, useStopsQuery } from '../../../graphql';

interface IBusStopsResult {
    loading: boolean;
    data?: StopsQuery;
    error?: ApolloError;
}

const useBusStops = (tag?: string): IBusStopsResult => {
    const excludeTag = tag ? undefined : 'no_online';
    return useStopsQuery({ variables: { tag, excludeTag } });
};

export default useBusStops;
