import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    center?: boolean;
    left?: boolean;
    padded?: boolean;
}

const Narrow = ({
    center,
    className,
    left,
    padded,
    ...rest
}: IProps & React.HTMLProps<HTMLDivElement>) => {
    return (
        <div
            className={cx(
                'Narrow',
                {
                    'Narrow--center': center,
                    'Narrow--left': left,
                    Narrow__Padded: padded,
                },
                className
            )}
            {...rest}
        />
    );
};

export default Narrow;
