import './index.scss';

import cx from 'classnames';
import * as React from 'react';

import { BusSeatFragment } from '../../../../graphql';
import { ReactComponent as SEAT } from '../assets/svgs/seat.svg';

export const PREMIUM_SEAT_RANK = 'SEAT_RANK.A';

interface IProps {
    seat: BusSeatFragment;
    onSelect: (seatId: BusSeatFragment) => void;
}

const BusSeat = ({ seat, onSelect }: IProps) => {
    const handleClick = () => {
        if (!seat.isAvailable) return;
        onSelect(seat);
    };

    const classNames = cx('Bus__Seat', {
        'Bus__Seat--premium':
            seat.seatRank && seat.seatRank === PREMIUM_SEAT_RANK,
        'Bus__Seat--reserved': !seat.isAvailable && !seat.productGuid,
        'Bus__Seat--selected': seat.productGuid,
        'Bus__Seat--space': seat.isEmptyArea || seat.isStairs || seat.isToilet,
    });

    return (
        <a className={classNames} onClick={handleClick}>
            <SEAT />
            {seat.seatNumber && (
                <span className="Bus__SeatNumber">{seat.seatNumber}</span>
            )}
        </a>
    );
};

export default BusSeat;
