import { ApolloProvider } from '@apollo/react-hooks';
import '@slkit/@base/global.scss';
import '@slkit/@base/Layout.scss';
import '@slkit/@base/tailwind.scss';
import { DefaultApplicationDataProvider } from 'lib/applicationDataContext';
import buildClient from 'lib/client';
import { customizeMoment } from 'lib/helpers/momentCustomization';
import history from 'lib/history';
import useCurrentLanguage from 'lib/hooks/useCurrentLanguage';
import { Language } from 'lib/hooks/useI18n';
import NotFoundPage from 'pages/NotFoundPage';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import { hot } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import Routes from './Routes';

declare global {
    interface Window {
        _slCurrentLang: Language;
    }
}

const Application = () => {
    const { lang } = useCurrentLanguage();
    window._slCurrentLang = lang;

    const [client] = React.useState(buildClient(undefined, lang));

    customizeMoment(lang);

    return (
        // This enables onClick handling on document on mobile safari
        // global onclick is needed for useFocusable hook
        <div className="SLApp" onClick={() => {}}>
            <Router history={history}>
                <CookiesProvider>
                    <ApolloProvider client={client}>
                        <DefaultApplicationDataProvider>
                            <Routes />
                        </DefaultApplicationDataProvider>
                    </ApolloProvider>
                </CookiesProvider>
            </Router>
        </div>
    );
};

Application.NotFound = () => <NotFoundPage />;

export default hot(module)(Application);
