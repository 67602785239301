import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import Maybe from 'graphql/tsutils/Maybe';
import useI18n from 'lib/hooks/useI18n';
import React from 'react';

interface IProps {
    activeTravelPass: Maybe<string>;
    onClear: () => void;
    onSelect: () => void;
}

export const PurchasedTravelPassSelector = ({
    activeTravelPass: travelPassNumber,
    onClear,
    onSelect,
}: IProps) => {
    const { i, t } = useI18n('travelpass_select.selector');

    return (
        <>
            <div
                className={cx('PurchasedTravelPassSelector', {
                    pointer: !travelPassNumber,
                })}
                onClick={travelPassNumber ? undefined : onSelect}
            >
                <div className="mr-1/2">
                    <div
                        className={cx('PurchasedTravelPassSelector_Tag', {
                            'PurchasedTravelPassSelector_Tag-active': !!travelPassNumber,
                        })}
                    >
                        <div className="PurchasedTravelPassSelector_Tag_Indicator" />
                        <span className="PurchasedTravelPassSelector_Tag_Label">
                            {t('travelpass-label')}
                        </span>
                    </div>
                </div>
                <span className="PurchasedTravelPassSelector_Label flex self-center text-sm">
                    {!travelPassNumber
                        ? t('prompt')
                        : i(t('active-travelpass'), { travelPassNumber })}
                </span>
                <div
                    className="PurchasedTravelPassSelector_Button"
                    onClick={travelPassNumber ? onClear : undefined}
                >
                    <Icon
                        name={
                            travelPassNumber
                                ? ICON_NAME.CLOSE
                                : ICON_NAME.SMALL_ARROW
                        }
                    />
                </div>
            </div>
        </>
    );
};
