import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    as?: 'div' | 'p';
    iconName?: ICON_NAME;
    noBorder?: boolean;
}

const NarrowIconItem = ({
    as = 'p',
    children,
    className,
    iconName,
    noBorder,
    ...rest
}: IProps & React.HTMLAttributes<HTMLHeadingElement>) => {
    const Component = as;
    return (
        <Component
            className={cx('Narrow__IconItem', className, {
                'Narrow__IconItem--noBorder': noBorder,
            })}
            {...rest}
        >
            {iconName != null && <Icon as="span" name={iconName} />}
            {children}
        </Component>
    );
};

export default NarrowIconItem;
