import * as React from 'react';

import useFareClasses from 'lib/hooks/useFareClasses';
import { IFareClassOption } from './FareClassesSelect';

// Memoize options
const useFareClassesOptions = () => {
    const { data } = useFareClasses();

    return React.useMemo(() => {
        const opts: IFareClassOption[] = [];

        if (!data) return opts;

        data.fareClasses.map(fareClass => {
            opts.push({
                id: fareClass.id,
                label: fareClass.name,
                value: fareClass,
            });
        });
        return opts;
    }, [data]);
};

export default useFareClassesOptions;
