import { TabType } from '@slkit/Tabs';
import {
    CurrencyEnum,
    FareClassInput,
    JourneySourceEnum,
} from '__types__/globalTypes';
import map from 'lodash/map';
import * as moment from 'moment';

import {
    JourneyFragmentFragment,
    JourneysSearchQueryVariables,
} from '../../../graphql';
import { IParsedQueryParams } from './useQueryParams';

export const buildVariables = (params: IParsedQueryParams, tab: TabType) => {
    const fareClasses: FareClassInput[] = map(
        params.fareClasses,
        (count, id) => ({ id, count } as FareClassInput)
    );

    const date = tab === TabType.OUTBOUND ? params.there : params.back;
    const from = tab === TabType.OUTBOUND ? params.from : params.to;
    const to = tab === TabType.OUTBOUND ? params.to : params.from;

    const variables: JourneysSearchQueryVariables = {
        query: {
            checkPaxSoldTogetherRules: true,
            currency: CurrencyEnum.EUR,
            departureDate: moment(date).format('YYYY-MM-DD'),
            destinationBusStopId: to.type === 'STOP' ? to.id : undefined,
            destinationCityId: to.type === 'CITY' ? to.id : undefined,
            fareClasses,
            isPartOfRoundTrip: !!params.back,
            originBusStopId: from.type === 'STOP' ? from.id : undefined,
            originCityId: from.type === 'CITY' ? from.id : undefined,
            promoCode: null,
            travelPassNumber: params.travelPassNumber,
        } as JourneysSearchQueryVariables['query'],
    };

    return variables;
};

export const buildTripDetail = (trip?: JourneyFragmentFragment): string => {
    if (trip && trip.legs && trip.legs.length > 0) {
        const leg = trip.legs[0];
        const counts: { [key: string]: number } = {};
        leg.passengers.forEach(p => {
            counts[p.basicDiscountName] = counts[p.basicDiscountName] || 0;
            counts[p.basicDiscountName] += 1;
        });

        const pts: string[] = [];
        for (const key in counts) {
            if (counts.hasOwnProperty(key)) {
                pts.push(`${counts[key]}x ${key}`);
            }
        }

        return pts.join(', ');
    }

    return '';
};

export const lineIdentifier = (
    journey: JourneyFragmentFragment,
    name: string
): string | undefined => {
    const leg = journey.legs[0];
    if (!leg) return;

    const identifiers: { [key: string]: string } = {};

    if (
        leg.lineIdentifier1 &&
        leg.lineIdentifier1.name &&
        leg.lineIdentifier1.value
    ) {
        identifiers[leg.lineIdentifier1.name] = leg.lineIdentifier1.value;
    }
    if (
        leg.lineIdentifier2 &&
        leg.lineIdentifier2.name &&
        leg.lineIdentifier2.value
    ) {
        identifiers[leg.lineIdentifier2.name] = leg.lineIdentifier2.value;
    }
    if (
        leg.lineIdentifier3 &&
        leg.lineIdentifier3.name &&
        leg.lineIdentifier3.value
    ) {
        identifiers[leg.lineIdentifier3.name] = leg.lineIdentifier3.value;
    }

    return identifiers[name];
};

export const isExpress = (journey: JourneyFragmentFragment) =>
    lineIdentifier(journey, 'Line Type') === 'Express';

export const isCharter = (journey: JourneyFragmentFragment) =>
    lineIdentifier(journey, 'Line Type') === 'Charter';

export const isFestival = (journey: JourneyFragmentFragment) =>
    lineIdentifier(journey, 'Line Type') === 'Festival';

export const isSoldOut = (journey: JourneyFragmentFragment) => {
    if (journey.source !== JourneySourceEnum.TURNIT) return false;

    const availableSeets =
        (journey.availableBusinessCampaignSeats || 0) +
        (journey.availableBusinessClassSeats || 0) +
        (journey.availableCampaignSeats || 0) +
        (journey.availableRegularSeats || 0);
    return availableSeets === 0 || journey.soldOutWithinLegTripGroup;
};

export const isInFuture = (journey: JourneyFragmentFragment) =>
    moment(journey.plannedDepartureDateTime as string) > moment();
