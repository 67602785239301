import { ApolloError, NetworkStatus } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import * as React from 'react';

import {
    BasketContentQuery,
    useBasketContentQuery,
    useBasketValidUntilQuery,
} from '../../../graphql';

interface IBasketContentResult {
    loading: boolean;
    data?: BasketContentQuery;
    error?: ApolloError;
    reload: () => Promise<any>;
    validUntil?: string;
    isChoosingSeatEnabled?: boolean;
    isTravelPassesOnly?: boolean;
}

const isChoosingSeatEnabled = (
    basket?: BasketContentQuery['basket']
): boolean => {
    if (!basket) return false;

    if (!basket.content.passengers) return false;

    for (const passenger of basket.content.passengers) {
        if (passenger.inboundJourney && passenger.inboundJourney.legs) {
            for (const leg of passenger.inboundJourney.legs) {
                if (leg.isChoosingSeatEnabled) {
                    return true;
                }
            }
        }

        if (passenger.outboundJourney && passenger.outboundJourney.legs) {
            for (const leg of passenger.outboundJourney.legs) {
                if (leg.isChoosingSeatEnabled) {
                    return true;
                }
            }
        }
    }

    return false;
};

const isTravelPassesOnly = (basket?: BasketContentQuery['basket']): boolean => {
    if (!basket) return false;

    const passengers = basket.content.passengers;
    if (passengers && passengers.length !== 0) return false;
    if (!basket.travelPassesByGuid || basket.travelPassesByGuid.length === 0) {
        return false;
    }
    return true;
};

const dummyReload = () => {
    console.warn('[useBasketContent] dummyReloadCalled');
    return Promise.resolve(false);
};

const useBasketContent = (basketId?: string): IBasketContentResult => {
    const prevNS = React.useRef<NetworkStatus>();
    const { basketId: contextBasketId } = React.useContext(
        ApplicationDataContext
    );

    const id = basketId || contextBasketId;

    const validUntilQuery = useBasketValidUntilQuery({
        skip: !id,
        variables: { id: id! },
    });

    const basketQuery = useBasketContentQuery({
        skip: !id,
        variables: { id: id! },
        notifyOnNetworkStatusChange: true,
    });

    // Refetch validUntil when basketQuery finishes, but only if it finishes from actuall loading state.
    // networkStatus is initially ready if data is already loaded
    React.useEffect(() => {
        if (
            prevNS.current &&
            prevNS.current !== basketQuery.networkStatus &&
            basketQuery.networkStatus === NetworkStatus.ready
        ) {
            validUntilQuery.refetch();
        }
        prevNS.current = basketQuery.networkStatus;
    }, [basketQuery.networkStatus]);

    if (!id) {
        return {
            loading: false,
            reload: dummyReload,
        } as IBasketContentResult;
    }

    const loading = basketQuery.loading || validUntilQuery.loading;
    const error = basketQuery.error || validUntilQuery.error;

    const data = basketQuery.data;

    return {
        data,
        validUntil: validUntilQuery.data?.basket.validUntil,
        error,
        loading,
        isChoosingSeatEnabled: data && isChoosingSeatEnabled(data.basket),
        isTravelPassesOnly: data && isTravelPassesOnly(data.basket),
        reload: basketQuery.refetch,
    };
};

export default useBasketContent;
