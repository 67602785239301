import { IconArrow } from '@slkit/Icon/IconArrow';
import cx from 'classnames';
import * as React from 'react';

interface IProps {
    header?: React.ReactNode;
    active?: boolean;
    onClick?: () => void;
}

const AccordionTile = ({
    active = false,
    className,
    header,
    onClick,
    ...props
}: IProps & React.HTMLAttributes<HTMLDivElement>) => {
    const toggleIconDirection = active ? 'up' : 'down';
    return (
        <div
            className={cx('Accordion__Tile', {
                'Accordion__Tile--active': active,
            })}
        >
            <div className="Accodion__TileHeader" onClick={onClick}>
                {header}
                <div
                    className={`Accordion__TileToggleIcon Accordion__TileToggleIcon--${toggleIconDirection}`}
                >
                    <IconArrow direction={toggleIconDirection} />
                </div>
            </div>
            {active && <div className="Accordion__TileBody" {...props} />}
        </div>
    );
};

export default AccordionTile;
