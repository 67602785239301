import { PaymentType } from '@slkit/PaymentMethod';
import LoadingIndicator from 'components/LoadingIndicator';
import { ICardData } from 'lib/@adyen/AdyenCardPaymentMethod';
import AdyenPaymentContainer from 'lib/@adyen/AdyenPaymentContainer';
import AdyenRedirect from 'lib/@adyen/AdyenRedirect';
import { useApplePay } from 'lib/@apple-pay/useApplePay';
import GooglePayContainer from 'lib/@google-pay/GooglePayContainer';
import useGooglePay from 'lib/@google-pay/useGooglePay';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import appPrompt from 'lib/appPrompt';
import useBasketContent from 'lib/hooks/useBasketContent';
import useBasketServiceEntries from 'lib/hooks/useBasketServiceEntries';
import useFeature, { Features } from 'lib/hooks/useFeature';
import useI18n from 'lib/hooks/useI18n';
import useLoading from 'lib/hooks/useLoading';
import useQuery from 'lib/hooks/useQuery';
import * as React from 'react';
import { Redirect } from 'react-router';
import {
    PaymentMethodEnum,
    PayWithCardMutationVariables,
    PayWithResponseFragment,
    useDeletePaymentMutation,
    usePayWithCardMutation,
    usePayWithCorporateClientContractTravelAccountMutation,
    usePayWithCorporateClientCredentialsMutation,
    usePayWithGoogleMutation,
    usePayWithSavedMethodMutation,
    usePayWithSavedMethodWithOneClickMutation,
} from '../../../../graphql';
import { useCheckoutStep } from '../useCheckoutStep';
import { useGetVoucher } from './getVoucher';
import { IB2BMethodData } from './Methods/B2BPaymentMethod';
import Payment from './Payment';
import useExistingPaymentMethods, {
    IExistingPaymentMethod,
} from './useExistingPaymentMethods';
import usePaymentInfo from './usePaymentInfo';
import VoucherPaymentPrompt from './VoucherPaymentPrompt';

type IAdyenPaymentResponse = PayWithResponseFragment;
type IAdyenPaymentResponseRedirect = IAdyenPaymentResponse['redirect'];

const PaymentContainer = () => {
    const { t } = useI18n('payment');
    const { error: queryError } = useQuery();
    const getVoucher = useGetVoucher();
    const [
        redirect,
        setRedirect,
    ] = React.useState<IAdyenPaymentResponseRedirect | null>(null);
    const {
        devPayment,
        payWithVoucher,
        payWithZeroPayment,
        basketId,
    } = React.useContext(ApplicationDataContext);
    // const [
    //     updateCorporateClientPrice,
    // ] = useUpdateCorporateClientPriceMutation();
    const {
        googlePay,
        isReadyToPay: isGooglePayReady,
        handlePayment,
    } = useGooglePay();
    const applePay = useApplePay();
    const [payWithCard] = usePayWithCardMutation();
    const [payWithCorporate] = usePayWithCorporateClientCredentialsMutation();
    const [payWithSavedMethod] = usePayWithSavedMethodMutation();
    const [
        payWithSavedMethodWithOneClick,
    ] = usePayWithSavedMethodWithOneClickMutation();
    const [
        payWithCorporateTravelAccount,
    ] = usePayWithCorporateClientContractTravelAccountMutation();
    const [payWithGoogle] = usePayWithGoogleMutation();
    const devPaymentEnabled = useFeature(Features.DevPayment);
    const [deletePayment] = useDeletePaymentMutation();
    const {
        data,
        loading: basketLoading,
        reload: reloadBasket,
    } = useBasketContent();
    const { entries } = useBasketServiceEntries();
    const {
        data: basketInfo,
        loading: paymentInfoLoading,
        reload: reloadPaymentInfo,
    } = usePaymentInfo();
    const { paymentInfo, state: basketState } = basketInfo || {
        paymentInfo: undefined,
        state: undefined,
    };
    const { loading: epLoading } = useExistingPaymentMethods();
    const [error, setError] = React.useState<Error>();
    const { loading: stateLoading, beginLoading, endLoading } = useLoading();
    const [paymentType, setPaymentType] = React.useState<PaymentType>(
        PaymentType.CREDIT_CARD
    );

    useCheckoutStep(3);

    const successPath = `/purchase-success/${basketId}`;

    const handleError = React.useCallback(
        (e: Error) => {
            endLoading();
            setError(e);
        },
        [endLoading, setError]
    );

    React.useEffect(() => {
        if (queryError) {
            setError(new Error(queryError));
        }
    }, [queryError]);

    const handleApplePaySubmit = React.useCallback(() => {
        if (!applePay.isAvailable || !basketId || !paymentInfo) return;

        const amountToPay = paymentInfo.payableAmount as number;
        const browserInfo = {
            userAgent: navigator.userAgent,
        };

        return applePay
            .handleSubmit(amountToPay, basketId, browserInfo)
            .then(handlePaymentResult)
            .then(reloadPaymentInfo)
            .catch(handleError);
    }, [applePay.isAvailable, basketId, paymentInfo]);

    const handleAddVoucher = React.useCallback(
        (code: string) => {
            setError(undefined);
            if (!basketId || !payWithVoucher || !paymentInfo) {
                return Promise.reject();
            }
            beginLoading();
            const amountToPay = paymentInfo.payableAmount as number;
            const voucherAmountToPay = paymentInfo.payableWithVoucherAmount as number;

            return getVoucher(code)
                .then(voucher => {
                    // If amount payable by voucher is not enough for whole basket, continue
                    if (voucherAmountToPay < amountToPay) return true;

                    // If amount on voucher is less then required for basket, continue
                    if (
                        voucher &&
                        voucher.amount !== null &&
                        voucher.amount < amountToPay
                    ) {
                        return true;
                    }

                    endLoading();
                    // Amount on voucher is unknown or larger then amount required to pay for basket
                    // We need to prompt user to confirm adding, since after adding voucher,
                    // basket will be payed and transaction will be done
                    return appPrompt(({ onCancel, onConfirm }) => (
                        <VoucherPaymentPrompt
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                        />
                    ));
                })
                .then(res => {
                    if (res) {
                        beginLoading();
                        return payWithVoucher(
                            basketId,
                            code,
                            voucherAmountToPay
                        )
                            .then(reloadPaymentInfo)
                            .then(endLoading);
                    } else {
                        return Promise.resolve(false);
                    }
                })
                .catch(handleError);
        },
        [basketId, paymentInfo, payWithVoucher, reloadPaymentInfo]
    );

    const handleDevSubmit = devPaymentEnabled
        ? React.useCallback(() => {
              setError(undefined);
              if (!basketId || !devPayment) return;

              beginLoading();
              devPayment(basketId)
                  .then(res => {
                      if (
                          res.data &&
                          res.data.basket &&
                          res.data.basket.devPay
                      ) {
                          return reloadPaymentInfo();
                      } else {
                          throw new Error('DEV PAYMENT FAILED');
                      }
                  })
                  .catch(handleError);
          }, [basketId, devPayment, reloadPaymentInfo])
        : undefined;

    const handlePaymentResult = (result?: IAdyenPaymentResponse | null) => {
        if (!result) {
            console.log('no result');
            throw new Error('Something went wrong');
        }

        if (result.resultCode === 'RedirectShopper') {
            if (!result.redirect) {
                console.log('result', result);
                throw new Error('redirect info is missing, cannot redirect');
            }

            console.log('redirect to', result.redirect);
            setRedirect(result.redirect);
            // When redirecting, return without anything. Reload will be called, but that is not important
            return;
        }

        if (result.resultCode === 'Authorised') {
            console.log('payment succesfull');
            // When all is OK, do nothing, just return so reload payment can be called
            return;
        }

        console.log('Result unhandled', result);
        throw new Error(t('payment-error'));
    };

    const handleExistingPaymentMethodSubmit = React.useCallback(
        (method: IExistingPaymentMethod, encryptedSecurityCode?: string) => {
            setError(undefined);
            if (!basketId || !payWithSavedMethod) return;
            beginLoading();

            const browserInfo = {
                userAgent: navigator.userAgent,
            };
            const variables = {
                basketId,
                browserInfo,
                recurringDetailReference: method.id,
            };

            const basePromise = encryptedSecurityCode
                ? payWithSavedMethodWithOneClick({
                      variables: {
                          ...variables,
                          encryptedSecurityCode,
                      },
                  }).then(
                      r => r.data && r.data.adyen.payWithSavedMethodWithOneClick
                  )
                : payWithSavedMethod({ variables }).then(
                      r => r.data && r.data.adyen.payWithSavedMethod
                  );

            return basePromise
                .then(handlePaymentResult)
                .then(reloadPaymentInfo)
                .catch(handleError);
        },
        [reloadPaymentInfo]
    );

    const handleCardSubmit = React.useCallback(
        (cardData: ICardData) => {
            setError(undefined);
            if (!basketId || !payWithCard) return;
            beginLoading();

            const adyenPaymentInput: PayWithCardMutationVariables['adyenPaymentInput'] = {
                ...cardData,
                type: PaymentMethodEnum.CARD,
            };

            const browserInfo = {
                userAgent: navigator.userAgent,
            };

            return payWithCard({
                variables: { adyenPaymentInput, basketId, browserInfo },
            })
                .then(r =>
                    handlePaymentResult(r.data && r.data.adyen.payWithCard)
                )
                .then(reloadPaymentInfo)
                .catch(handleError);
        },
        [basketId, payWithCard, reloadPaymentInfo]
    );

    const handleZeroPaymentSubmit = React.useCallback(() => {
        setError(undefined);
        if (!basketId || !payWithZeroPayment) return;
        beginLoading();

        return payWithZeroPayment(basketId)
            .then(res => {
                if (res) return reloadPaymentInfo();
                throw new Error(t('payment-error'));
            })
            .then(endLoading)
            .catch(handleError);
    }, [basketId, payWithZeroPayment, reloadPaymentInfo]);

    const handleB2BPaymentSubmit = React.useCallback(
        (methodData: IB2BMethodData) => {
            setError(undefined);
            if (!basketId) return Promise.resolve();
            beginLoading();

            let basePromise: Promise<boolean>;

            if (methodData.useTravelAccount) {
                basePromise = payWithCorporateTravelAccount({
                    variables: {
                        basketId,
                        identification: methodData.identification,
                    },
                }).then(res =>
                    Boolean(
                        res.data &&
                            res.data.basket &&
                            res.data.basket
                                .payWithCorporateClientContractTravelAccount
                    )
                );
            } else {
                // updateCorporateClientPrice({
                //     variables,
                // })
                //     .then(res => {
                //         if (!res) throw new Error(t('payment-error'));
                //     })
                // .then(() => payWithCorporate({ variables }))
                basePromise = payWithCorporate({
                    variables: {
                        basketId,
                        identification: methodData.identification,
                        password: methodData.password,
                        username: methodData.username,
                    },
                }).then(res =>
                    Boolean(
                        res.data &&
                            res.data.basket &&
                            res.data.basket.payWithCorporateClientContract
                    )
                );
            }

            return basePromise
                .then(res => {
                    if (!res) throw new Error(t('payment-error'));
                })
                .then(reloadPaymentInfo)
                .then(reloadBasket)
                .then(endLoading)
                .catch(handleError);
        },
        [
            basketId,
            payWithCorporate,
            reloadBasket,
            reloadPaymentInfo,
            // updateCorporateClientPrice,
        ]
    );

    const handleGooglePaySubmit = React.useCallback(() => {
        setError(undefined);
        if (!basketId || !isGooglePayReady || !handlePayment || !paymentInfo) {
            return;
        }
        beginLoading();

        const amountToPay = paymentInfo.payableAmount as number;
        const browserInfo = {
            userAgent: navigator.userAgent,
        };

        handlePayment({
            totalPrice: amountToPay.toFixed(2),
        })
            .then(({ token }) =>
                payWithGoogle({
                    variables: {
                        adyenPaymentInput: { token },
                        basketId,
                        browserInfo,
                    },
                })
            )
            .then(r =>
                handlePaymentResult(r.data && r.data.adyen.payWithGoogle)
            )
            .then(reloadPaymentInfo)
            .catch(e => {
                if (e && e.statusCode === 'CANCELED') {
                    endLoading();
                } else {
                    handleError(e);
                }
            });
    }, [basketId, isGooglePayReady, googlePay, paymentInfo]);

    const handlePaymentDelete = React.useCallback(
        (paymentId: string) => {
            if (!basketId || !paymentId) return Promise.resolve();
            beginLoading();
            return deletePayment({
                variables: { basketId, paymentId },
            })
                .then(reloadPaymentInfo)
                .then(endLoading)
                .catch(handleError);
        },
        [basketId, deletePayment, reloadPaymentInfo]
    );

    const loading = basketLoading || epLoading || paymentInfoLoading;

    if (loading) return <LoadingIndicator />;
    if (!data || !paymentInfo || !basketState) return null;

    if (redirect) {
        return <AdyenRedirect {...redirect} />;
    }

    // paid, payment_started, not_paid
    if (basketState.state === 'paid') {
        return <Redirect to={successPath} />;
    }

    const forceZeroPayment = paymentInfo.payableAmount === 0;
    const enableB2B = entries.length === 0;

    return (
        <AdyenPaymentContainer
            amount={paymentInfo.payableAmount!}
            currency={paymentInfo.currency!}
        >
            {stateLoading && <LoadingIndicator />}
            <Payment
                error={error}
                type={forceZeroPayment ? PaymentType.ZERO_PAYMENT : paymentType}
                onAddVoucher={handleAddVoucher}
                onApplePaySubmit={
                    applePay.isAvailable ? handleApplePaySubmit : undefined
                }
                onB2BPaymentMethodSubmit={
                    enableB2B ? handleB2BPaymentSubmit : undefined
                }
                onDevSubmit={handleDevSubmit}
                onCardSubmit={handleCardSubmit}
                onGooglePaySubmit={
                    isGooglePayReady ? handleGooglePaySubmit : undefined
                }
                onZeroPaymentSubmit={handleZeroPaymentSubmit}
                onErrorClose={() => setError(undefined)}
                onExistingPaymentMethodSubmit={
                    handleExistingPaymentMethodSubmit
                }
                onTypeChange={setPaymentType}
                onPaymentDelete={handlePaymentDelete}
                paymentInfo={paymentInfo}
            />
        </AdyenPaymentContainer>
    );
};

const WrappedPaymentContainer = () => (
    <GooglePayContainer>
        <PaymentContainer />
    </GooglePayContainer>
);

export default WrappedPaymentContainer;
