import { CardIcon } from '@slkit/CardList/CardLabel';
import * as React from 'react';
import {
    AdyenRecurringEnum,
    SavedRecurringDetailsQuery,
    useSavedRecurringDetailsQuery,
} from '../../../../graphql';

type TDetail = SavedRecurringDetailsQuery['adyen']['savedRecurringDetails'][0];

export interface IExistingPaymentMethod {
    icon?: CardIcon;
    id: string;
    isPrimary?: boolean;
    number: string;
    requiresCVC: boolean;
    checkoutType?: adyen.CheckoutType;
    detail: TDetail;
}

interface IExistingPaymentMethods {
    data: IExistingPaymentMethod[];
    loading: boolean;
}

const cardIcon = (variant: string | null | undefined): CardIcon | undefined => {
    switch (variant) {
        case 'mc':
        case 'visa':
        case 'amex':
        case 'diners':
        case 'discover':
        case 'maestro':
            return variant;
        default:
            return undefined;
    }
};

const requiresCVC = ({ contractTypes: ct }: TDetail): boolean => {
    if (!ct) return false;
    return ct.length === 1 && ct[0] === AdyenRecurringEnum.ONECLICK;
};

const buildMethod = (detail: TDetail): IExistingPaymentMethod => ({
    checkoutType: (detail.variant as adyen.CheckoutType) || undefined,
    detail,
    icon: cardIcon(detail.variant),
    id: detail.recurringDetailReference as string,
    isPrimary: detail.isDefault || false,
    number: ((detail.card && detail.card.number) as string) || '',
    requiresCVC: requiresCVC(detail),
});

const useExistingPaymentMethods: () => IExistingPaymentMethods = () => {
    const { data, loading } = useSavedRecurringDetailsQuery();
    const methods = React.useMemo(() => {
        if (!data) return [];
        const list = data.adyen.savedRecurringDetails.map(buildMethod);

        // If there is no primary, preselect first
        if (list.length > 0 && !list.find(m => !!m.isPrimary)) {
            list[0].isPrimary = true;
        }

        return list;
    }, [data]);

    return {
        data: methods,
        loading,
    };
};

export default useExistingPaymentMethods;
