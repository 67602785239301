import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import CSRFFields from 'components/CSRFFields';
import FormattedText from 'components/FormattedText';
import { ICurrentUser } from 'components/Login/useCurrentUser';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

import Dialog from '../Dialog';

interface IProps {
    currentUser: ICurrentUser;
    onLogout?: () => void;
}

const LoginDropdown = ({ currentUser, onLogout }: IProps) => {
    const { i, t } = useI18n('login');

    const name = (currentUser.name || '').trim();
    const titleKey = name ? 'menu.title.has-name' : 'menu.title.no-name';
    const titleText = i<{ name: string }>(t(titleKey), {
        name,
    });

    return (
        <div className="LoginDropdown">
            <Dialog>
                <div className="LoginDropdown__Header">
                    <FormattedText>{titleText}</FormattedText>
                </div>
                <div className="LoginDropdown__Body">
                    <ul className="LoginDropdown__List">
                        <li className={'LoginDropdown__Item'}>
                            <a
                                className="LoginDropdown__Link"
                                href="/travel_account/tickets"
                            >
                                <Icon name={ICON_NAME.TICKETS} />
                                {t('menu.tickets')}
                            </a>
                        </li>
                        <li className={'LoginDropdown__Item'}>
                            <a
                                className="LoginDropdown__Link"
                                href="/travel_account/passes"
                            >
                                <Icon name={ICON_NAME.KARNET} />
                                {t('menu.passes')}
                            </a>
                        </li>
                        <li className={'LoginDropdown__Item'}>
                            <a
                                className="LoginDropdown__Link"
                                href="/travel_account/profile"
                            >
                                <Icon name={ICON_NAME.PROFILE} />
                                {t('menu.profile')}
                            </a>
                        </li>
                        <li className={'LoginDropdown__Item'}>
                            <a
                                className="LoginDropdown__Link"
                                href="/travel_account/payments"
                            >
                                <Icon name={ICON_NAME.PAYMENT} />
                                {t('menu.payments')}
                            </a>
                        </li>
                        <li className={'LoginDropdown__Item'}>
                            <a
                                className="LoginDropdown__Link"
                                href="/travel_account/privacy"
                            >
                                <Icon name={ICON_NAME.PRIVACY} />
                                {t('menu.privacy')}
                            </a>
                        </li>
                        <li className={'LoginDropdown__Item'}>
                            <a
                                className="LoginDropdown__Link"
                                href="/travel_account/vouchers"
                            >
                                <Icon name={ICON_NAME.PERCENTAGE} />
                                {t('menu.vouchers')}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="LoginDropdown__Footer">
                    <form
                        action="/users/sign_out"
                        method="post"
                        onClick={() => {
                            if (onLogout) onLogout();
                        }}
                    >
                        <CSRFFields />
                        <input type="hidden" name="_method" value="delete" />
                        <button className="Button Button--primary Button--full">
                            {t('logout')}
                        </button>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default LoginDropdown;
