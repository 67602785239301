import { ICON_NAME } from '@slkit/Icon';
import PaymentMethodBadge from '@slkit/PaymentMethodBadge';
import useGooglePay from 'lib/@google-pay/useGooglePay';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

const GPayPaymentMethod = () => {
    const { t } = useI18n('payment.gpay');
    return <div className="PaymentMethod__Description">{t('description')}</div>;
};

export const GPayPaymentMethodHeader = ({ active }: { active: boolean }) => {
    const { t } = useI18n('payment_method');

    return (
        <div className="PaymentMethodTitle">
            <PaymentMethodBadge active={active} icon={ICON_NAME.GPAY} />
            <div className="PaymentMethodTitle--methodName">{t('GPAY')}</div>
        </div>
    );
};

interface IGPayPaymentMethodButtonProps {
    onClick: () => void;
}

export const GPayPaymentMethodButton = ({
    onClick,
}: IGPayPaymentMethodButtonProps) => {
    const { googlePay, isReadyToPay } = useGooglePay();
    const compRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<
        HTMLDivElement
    >;

    const button = React.useMemo(() => {
        if (!isReadyToPay || !googlePay) return null;
        return googlePay.createButton({
            buttonColor: 'black',
            buttonType: 'long',
            onClick,
        });
    }, [onClick]);

    React.useLayoutEffect(() => {
        if (!compRef.current || !button) return;
        compRef.current.appendChild(button);
        return () => {
            compRef.current.removeChild(button);
        };
    }, [compRef.current, button]);

    if (button === null) return null;

    return <div ref={compRef} />;
};

export default GPayPaymentMethod;
