import Modal from '@slkit/Modal';
import { useKioskContext } from 'components/Kiosk/KioskLayout';
import SearchTripDetail from 'components/SearchTripDetail/SearchTripDetail';
import * as React from 'react';
import { JourneyFragmentFragment } from '../../../graphql';
import './styles.scss';

interface IProps {
    details?: string;
    onClose: () => void;
    onSelect: () => void;
    trip?: JourneyFragmentFragment;
    travelPass?: boolean;
}

const TripDetailModal = ({
    details,
    onClose,
    onSelect,
    trip,
    travelPass,
}: IProps) => {
    const { isKiosk } = useKioskContext();
    return (
        <Modal
            className="TripDetailModal"
            isActive={!!trip}
            // modal should have close button so it can be closed
            // on mobile (where background cannot be clicked)
            hideCloseButton={isKiosk}
            hideHeader
            onClose={onClose}
        >
            {trip && (
                <SearchTripDetail
                    details={details}
                    trip={trip}
                    onClose={onClose}
                    onSelect={onSelect}
                    travelPass={travelPass}
                />
            )}
        </Modal>
    );
};

export default TripDetailModal;
