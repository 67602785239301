import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const LinkRenderer = ({ href = '', ...props }) => {
    const relative = href && href[0] === '/';
    if (relative) {
        return <Link to={href} className="Link" {...props} />;
    }

    return <a className="Link" href={href} target="_blank" {...props} />;
};

const ParagraphRenderer = ({ children }: { children: React.ReactNode }) => (
    // Do not render paragraphs in <p>
    <>{children}</>
);

interface IProps {
    children: string;
    wrapParagraphs?: boolean;
}

/**
 * Component can interpolate data in source string
 * @param {} param0
 */
const FormattedText = ({ children, wrapParagraphs = false }: IProps) => {
    const renderers: { [key: string]: React.ElementType<any> } = {
        link: LinkRenderer,
    };

    if (!wrapParagraphs) {
        renderers.paragraph = ParagraphRenderer;
    }

    return <ReactMarkdown renderers={renderers} source={children} />;
};

export default FormattedText;
