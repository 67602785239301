import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import { useMemo } from 'react';
import useCurrentLanguage from './useCurrentLanguage';
import { useLanguageData } from './useLanguageData';

export const enum Language {
    DE = 'de',
    EN = 'en',
    SK = 'sk',
}

enum PluralizationKey {
    ONE = 'one',
    FEW = 'few',
    MANY = 'many',
    OTHER = 'other',
}

export const validateLanguage = (key?: string): Language | undefined => {
    if (!key) return undefined;

    switch (key) {
        case Language.SK:
        case Language.EN:
        case Language.DE:
            return key as Language;
        default:
            console.warn(`Language "${key}" is supported`);
            return Language.SK;
    }
};

// See http://www.unicode.org/cldr/charts/29/supplemental/language_plural_rules.html for more info
const pluralizationKey = (lang: Language, count: number) => {
    switch (lang) {
        case Language.DE:
        case Language.EN:
            return count === 1 ? PluralizationKey.ONE : PluralizationKey.OTHER;
        case Language.SK:
            if (Math.round(count) !== count) return PluralizationKey.MANY;
            else if (count === 1) return PluralizationKey.ONE;
            else if (count < 5) return PluralizationKey.FEW;
            else return PluralizationKey.OTHER;
        default:
            return '';
    }
};

interface IInterpolationData {
    [key: string]: string | number | undefined;
}

interface II18n {
    /**
     * Pass text and data object to interpolate values from object into text.
     */
    i: <T extends IInterpolationData>(text: string, data: T) => string;
    /**
     * Current locale
     */
    lang: string;
    /**
     * Pass key to lookup string in localizations of current language.
     * - optional number count can be used for pluralization.
     * - optional string missing will be returned if key is not found.
     */
    t: (key: string, count?: number, missing?: string) => string;
}

const useI18n = (defaultScope?: string, language?: Language): II18n => {
    const { lang } = useCurrentLanguage();
    const languageData = useLanguageData(language || lang);

    const i = useMemo(
        () => <T extends IInterpolationData>(text: string, data: T) => {
            let res = text;
            forEach(data, (v, k) => {
                const value = v === undefined ? '' : v.toString();
                res = replace(res, `%${k}%`, value);
            });
            return res;
        },
        [languageData]
    );

    const t = useMemo(
        () => (key: string, count: number = 1, missing?: string) => {
            const p = `${defaultScope ? defaultScope + '.' : ''}${key}`;
            if (!languageData) {
                console.warn('language missing: ', lang);
                return '';
            }

            const lString = languageData[p];

            if (lString === undefined || lString === null) {
                if (missing === undefined) {
                    console.warn('translation missing: ', p);
                    return `{${p}}`;
                } else {
                    return missing;
                }
            } else if (
                typeof lString === 'object' &&
                lString[PluralizationKey.ONE]
            ) {
                const res = lString[pluralizationKey(lang, count)];
                if (!res) {
                    console.warn(
                        'pluralization missing: ',
                        lString,
                        pluralizationKey(lang, count)
                    );
                }
                return res || '';
            }

            return lString as string;
        },
        [defaultScope, languageData]
    );

    return { i, lang, t } as II18n;
};

export default useI18n;
