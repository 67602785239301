import update from 'immutability-helper';
import { useReducer } from 'react';

interface IPassengersInitializeAction {
    type: Action.INITIALIZE;
    payload: IPassengerData[];
}

interface IPassengerUpdateAction {
    type: Action.UPDATE_PASSENGER;
    payload: IPassengerDataAttributes;
}

interface IPassengerUpdateErrorsAction {
    type: Action.UPDATE_PASSENGER_ERRORS;
    payload: {
        id: string;
        errors?: IPassengerDataErrors;
    };
}

export enum Action {
    INITIALIZE = 'INITIALIZE',
    UPDATE_PASSENGER = 'UPDATE_PASSENGER',
    UPDATE_PASSENGER_ERRORS = 'UPDATE_PASSENGER_ERRORS',
}

const reducer = (
    state: IPassengerData[],
    action:
        | IPassengersInitializeAction
        | IPassengerUpdateAction
        | IPassengerUpdateErrorsAction
) => {
    switch (action.type) {
        case Action.INITIALIZE:
            return update(state, { $set: action.payload });

        case Action.UPDATE_PASSENGER: {
            const idx = state.findIndex(p => p.id === action.payload.id);
            const newPassenger = Object.assign({}, state[idx], action.payload, {
                errors: undefined,
            });
            return update(state, {
                [idx]: { $set: newPassenger },
            });
        }

        case Action.UPDATE_PASSENGER_ERRORS: {
            const idx = state.findIndex(p => p.id === action.payload.id);
            const newPassenger = Object.assign({}, state[idx], {
                errors: action.payload.errors,
            });
            return update(state, {
                [idx]: { $set: newPassenger },
            });
        }

        default:
            return state;
    }
};

const initialState: IPassengerData[] = [];

const usePassengersFormReducer = () => useReducer(reducer, initialState);

export default usePassengersFormReducer;
