import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import {
    BasketState,
    BasketState_basket_state,
    BasketStateVariables
} from 'queries/__types__/BasketState';
import * as query from 'queries/BasketState.graphql';
import * as React from 'react';

interface IBasketStateResult {
    loading: boolean;
    state?: BasketState_basket_state;
    error?: ApolloError;
    reload: () => Promise<any>;
}

const dummyReload = () => {
    console.warn('[useBasketContent] dummyReloadCalled');
    return Promise.resolve(false);
};

const useBasketState = (basketId?: string) => {
    const {
        apolloClient: client,
        basketId: contextBasketId,
    } = React.useContext(ApplicationDataContext);

    const [data, setData] = React.useState<IBasketStateResult>({
        loading: true,
        reload: dummyReload,
    });

    const id = basketId || contextBasketId;

    React.useEffect(() => {
        if (!id || !client) {
            setData({
                loading: false,
                reload: dummyReload,
            });
            return;
        }

        const variables = { id };
        const q = client.watchQuery<BasketState, BasketStateVariables>({
            query,
            variables,
        });

        q.subscribe({
            error: error => {
                setData({
                    error: error as ApolloError,
                    loading: false,
                    reload: dummyReload,
                });
            },
            next: d => {
                setData({
                    loading: false,
                    reload: () => {
                        console.log('reloading basket expiration');
                        return q.refetch(variables);
                    },
                    state: d.data.basket.state,
                });
            },
        });
    }, [id]);

    if (!id) {
        return {
            loading: false,
        } as IBasketStateResult;
    }

    return data;
};

export default useBasketState;
