import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    center?: boolean;
    info?: boolean;
    warning?: boolean;
    success?: boolean;
}

const Message = ({
    center = false,
    className,
    info = false,
    warning = false,
    success = false,
    ...props
}: IProps & React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cx(
            'Message',
            {
                'Message--center': center,
                'Message--info': info,
                'Message--success': success,
                'Message--warning': warning,
            },
            className
        )}
        {...props}
    />
);

export default Message;
