export enum Features {
    DevPayment = 'x-feature-enable-dev-pay',
}

const useFeature = (feature: Features): boolean => {
    const param = document.querySelector(`meta[name='${feature}']`);

    if (param) {
        return param.getAttribute('content') === 'true';
    } else {
        return false;
    }
};

export default useFeature;
