import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    color?: 'blue' | 'red' | 'black' | 'gray';
    secondary?: boolean;
    text: string;
}

const Tag = ({ color, secondary = false, text }: IProps) => {
    const className = cx('Tag', {
        'Tag--black': color === 'black',
        'Tag--blue': color === 'blue',
        'Tag--gray': color === 'gray',
        'Tag--red': color === 'red',
        'Tag--secondary': secondary,
    });
    return <div className={className}>{text}</div>;
};

export default Tag;
