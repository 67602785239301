import Dialog from '@slkit/Dialog';
import HeaderItem from '@slkit/Header/HeaderItem';
import { ICON_NAME } from '@slkit/Icon';
import Popup from '@slkit/Popup';
import * as cx from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { formatPrice } from 'lib/helpers';
import useBasketContent from 'lib/hooks/useBasketContent';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import useFocusable from 'lib/hooks/useFocusable';
import { gtmLogRemoveFromCart } from 'lib/hooks/useGTM';
import useI18n from 'lib/hooks/useI18n';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import * as moment from 'moment';
import * as React from 'react';
import Basket from './Basket';
import BasketHeader from './BasketHeader';
import { IBasketServiceEntry } from './BasketServiceEntry';
import EmptyBasket from './EmptyBasket';
import { buildBasketEntries, buildBasketServiceEntries } from './helpers';

const BasketContainer = () => {
    const {
        basketId,
        deleteBasket,
        deleteBasketProducts,
        setBasketId,
    } = React.useContext(ApplicationDataContext);
    const { loading, beginLoading, endLoading } = useLoading();
    const [error, setError] = React.useState<Error>();
    const { buttonRef, ref, isFocused, setIsFocused } = useFocusable(false);
    const { data, reload, validUntil } = useBasketContent();
    const history = useHistory();
    const { t } = useI18n('basket');

    let badge = 0;
    let actionTitle: string | undefined;
    let entries: IBasketEntry[] = [];
    let services: IBasketServiceEntry[] = [];

    if (data) {
        entries = buildBasketEntries(data.basket.content);
        services = buildBasketServiceEntries(data.basket);
        badge = entries.length + services.length;
        if (badge > 0) {
            actionTitle = `${t('action')} ${formatPrice(
                data.basket.content.totalPrice!,
                data.basket.content.currency!
            )}`;
        }
    }

    const handleAction = () => {
        history.push('/purchase/summary');
        setIsFocused(false);
    };

    const handleError = (e: Error | undefined) => {
        endLoading();
        setError(e);
    };

    const handleEntryDelete = (basketEntry: IBasketEntry) => {
        if (!basketId || !deleteBasket || !deleteBasketProducts) {
            return Promise.resolve();
        }

        beginLoading();

        if (entries.length === 1 && services.length === 0) {
            return deleteBasket(basketId)
                .then(() => {
                    gtmLogRemoveFromCart([basketEntry]);
                    setBasketId();
                    endLoading();
                })
                .catch(handleError);
        }

        const productIds = basketEntry.products.map(p => p.productId!);
        return deleteBasketProducts(basketId, productIds)
            .then(() => {
                gtmLogRemoveFromCart([basketEntry]);
                if (!reload) return Promise.resolve();
                return reload();
            })
            .then(endLoading)
            .catch(handleError);
    };

    const remaining = moment
        .duration(moment(validUntil).diff(moment()))
        .asMilliseconds();

    let basketVisible = !!data;
    if (remaining < 0) {
        badge = 0;
        basketVisible = false;
    }

    return (
        <>
            <HeaderItem
                badge={badge}
                icon={ICON_NAME.CART}
                onClick={() => setIsFocused(!isFocused)}
                ref={buttonRef}
            />
            <div ref={ref}>
                <Popup active={isFocused}>
                    <div
                        className={cx('BasketDialog', {
                            'BasketDialog--empty': !basketId,
                        })}
                    >
                        <Dialog>
                            <div className="Popup v2">
                                {basketVisible && basketId ? (
                                    <>
                                        {validUntil && (
                                            <BasketHeader
                                                validUntil={validUntil}
                                            />
                                        )}
                                        <Basket
                                            actionTitle={actionTitle}
                                            entries={entries}
                                            services={services}
                                            onAction={handleAction}
                                            onEntryDelete={handleEntryDelete}
                                        />
                                    </>
                                ) : (
                                    <EmptyBasket />
                                )}
                            </div>
                        </Dialog>
                    </div>
                </Popup>
            </div>
            <ErrorMessage
                error={error}
                fixed
                onClose={() => setError(undefined)}
            />
            {loading && <LoadingIndicator />}
        </>
    );
};

export default BasketContainer;
