import Calendar from '@slkit/Calendar';
import Dialog, { DialogHeader } from '@slkit/Dialog';
import Icon, { ICON_NAME } from '@slkit/Icon';
import SearchFormCell from '@slkit/SearchForm/SearchFormCell';
import SearchFormCellContent from '@slkit/SearchForm/SearchFormCellContent';
import { formatDate } from 'lib/helpers';
import useFocusable from 'lib/hooks/useFocusable';
import useI18n from 'lib/hooks/useI18n';
import * as moment from 'moment';
import * as React from 'react';
import { DayModifiers, Modifier, Modifiers } from 'react-day-picker';

export enum DateSelectMode {
    APPROACH = 'approach',
    DEPARTURE = 'departure',
}

interface IProps {
    after?: string | Date | null;
    availableDates?: string[];
    before?: string | Date | null;
    clearable?: boolean;
    disabled?: boolean;
    mode: DateSelectMode;
    onChange: (day: string | null) => void;
    subtitle?: string;
    title?: string;
    value: string | null;
}

const DateSelect = ({
    after,
    availableDates,
    before,
    clearable = false,
    disabled = false,
    mode,
    onChange,
    subtitle,
    title,
    value,
}: IProps) => {
    const { t } = useI18n('search_form');
    const { ref, isFocused, fBlur, fFocus } = useFocusable(false);
    const handleChange = (day: Date | null, mods?: DayModifiers) => {
        if (mods && mods.disabled) return;
        if (day === null) {
            onChange(null);
        } else {
            onChange(formatDate(day));
        }
        setTimeout(fBlur, 100);
    };
    let selectedDay: Date | undefined;
    let contentTitle = title || t(`${mode}-date.placeholder`);
    const valueText = subtitle || t(`${mode}-date.placeholder-short`);

    if (value) {
        const valueDate = moment(value);
        selectedDay = valueDate.toDate();
        contentTitle = valueDate.format(
            t('date.selected-value-subtitle-format', undefined, 'ddd, D.M.YYYY')
        );
    }

    const isDateDisabled = React.useCallback(
        (date: Date): boolean => {
            if (!availableDates) return false;
            const d = formatDate(date);
            return availableDates.indexOf(d) === -1;
        },
        [availableDates]
    );

    // Only be disabled if disabled is true and input does not have value or focus
    const hasValue = !!value;
    const isDisabled = !(!disabled || hasValue || isFocused);

    let disabledDays: Modifier | undefined;
    let selectedDays: Modifier | undefined = selectedDay;
    let modifiers: Partial<Modifiers> | undefined;
    let month = selectedDay;

    if (after) {
        const aValue = moment(after).toDate();
        if (mode === DateSelectMode.APPROACH) {
            selectedDays = {
                from: aValue,
                to: selectedDay!,
            };
            modifiers = { start: selectedDays.from, end: selectedDays.to };
            if (!selectedDay) {
                month = aValue;
            }
        }
        disabledDays = {
            before: aValue,
        };
    }

    if (before) {
        const bValue = moment(before).toDate();
        if (mode === DateSelectMode.DEPARTURE) {
            selectedDays = {
                from: selectedDay!,
                to: bValue,
            };
            modifiers = { start: selectedDays.from, end: selectedDays.to };
        }

        disabledDays = {
            ...disabledDays,
            after: bValue,
        };
    }

    if (mode === DateSelectMode.DEPARTURE && !before) {
        // "From" day picker
        selectedDays = selectedDay;
        modifiers = {
            oneSelected: selectedDay,
        };
    }

    const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        fBlur();
    };

    const accessory = isFocused ? (
        <div className="SearchForm__Dialog SearchForm__Dialog--Date">
            <div className="SearchForm__DialogBackdrop" onClick={handleClose} />
            <Dialog>
                <DialogHeader onClose={handleClose}>{valueText}</DialogHeader>
                <Calendar
                    disabledDays={[disabledDays, isDateDisabled]}
                    modifiers={modifiers}
                    month={month}
                    onDayClick={handleChange}
                    selectedDays={selectedDays}
                />
            </Dialog>
        </div>
    ) : null;

    return (
        <SearchFormCell
            active={isFocused}
            disabled={isDisabled}
            filled={!!value && !isFocused}
            ref={ref}
            tabIndex={0}
            onBlur={e => {
                if (!e.relatedTarget) return;
                const relTarget = e.relatedTarget as any;

                if (relTarget === ref.current) return;

                if (
                    relTarget.className &&
                    relTarget.className.startsWith &&
                    relTarget.className.startsWith('DayPicker')
                ) {
                    return;
                }

                fBlur();
            }}
            onFocus={fFocus}
            onClick={fFocus}
        >
            {accessory}
            <Icon name={ICON_NAME.SEARCH_CALENDAR} />
            <SearchFormCellContent contentTitle={contentTitle} text={valueText}>
                {hasValue && clearable && (
                    <div
                        className="SearchForm__Remove"
                        tabIndex={0}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleChange(null);
                        }}
                        onFocus={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Icon name={ICON_NAME.BTNS_DESTINATION_CANCEL} />
                    </div>
                )}
            </SearchFormCellContent>
        </SearchFormCell>
    );
};

export default DateSelect;
