import cx from 'classnames';
import * as React from 'react';

const PanelFooter = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('PanelFooter', className)} {...props} />
);

export default PanelFooter;
