import Modal from '@slkit/Modal';
import FormattedText from 'components/FormattedText';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

interface IProps {
    onCancel?: () => void;
    onConfirm?: () => void;
}

const VoucherPaymentPrompt = ({ onCancel, onConfirm }: IProps) => {
    const { t } = useI18n('voucher_form.confirm');

    return (
        <Modal
            isActive
            title={t('title')}
            onClose={() => onCancel && onCancel()}
        >
            <div className="Modal__Item">
                <FormattedText>{t('message')}</FormattedText>
            </div>
            <div className="ButtonGroup">
                <button className="Button Button--secondary" onClick={onCancel}>
                    {t('cancel')}
                </button>
                <button className="Button" onClick={onConfirm}>
                    {t('confirm')}
                </button>
            </div>
        </Modal>
    );
};

export default VoucherPaymentPrompt;
