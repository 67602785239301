import KioskNavbar from '@slkit/KioskNavbar';
import * as cx from 'classnames';
import KioskLayout from 'components/Kiosk/KioskLayout';
import SearchResultsContainer from 'components/SearchResult/SearchResultsContainer';
import SearchResultsTripsSearchFormContainer from 'components/SearchResult/SearchResultsTripsSearchFormContainer';
import { useScrollStatus } from 'lib/hooks/useScrollStatus';
import * as React from 'react';

const KioskResultsPage = () => {
    const { atTop } = useScrollStatus();
    return (
        <KioskLayout>
            <div
                className={cx('KioskContainer__Sticky', {
                    'KioskContainer__Sticky--active': !atTop,
                })}
            >
                <div className="KioskContainer__Content">
                    <KioskNavbar showHome />
                    <SearchResultsTripsSearchFormContainer />
                </div>
            </div>
            <div className="KioskContainer__Content">
                <SearchResultsContainer />
            </div>
        </KioskLayout>
    );
};

export default KioskResultsPage;
