import * as React from 'react';
import './index.scss';

interface IProps {
    active?: boolean;
    children?: React.ReactNode;
}

const Popup = ({ active, children }: IProps) => {
    if (!active) return null;
    return (
        <div
            style={{
                bottom: 'auto',
                display: active ? 'block' : 'none',
                left: 'auto',
                position: 'absolute',
                right: 0,
                top: '40px',
                zIndex: 10,
            }}
        >
            {children}
        </div>
    );
};

export default Popup;
