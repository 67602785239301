import cx from 'classnames';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './index.scss';

interface IProps {
    active?: boolean;
    className?: string;
}

const cname = ({ active, className }: IProps) =>
    cx('KioskButton', className, {
        'KioskButton--active': active,
    });

const KioskButton = ({
    active,
    className,
    ...props
}: React.HTMLAttributes<HTMLButtonElement> & IProps) => (
    <button className={cname({ active, className })} {...props} />
);

export const KioskLink = ({
    active,
    className,
    ...props
}: LinkProps & IProps) => (
    <Link className={cname({ active, className })} {...props} />
);

export default KioskButton;
