import SearchResultsContainer from 'components/SearchResult/SearchResultsContainer';
import SearchResultsTripsSearchFormContainer from 'components/SearchResult/SearchResultsTripsSearchFormContainer';
import Layout from 'modules/application/Layout';
import * as React from 'react';

const SearchResultsPage = () => (
    <Layout>
        <div className="container">
            <div className="Page">
                <SearchResultsTripsSearchFormContainer />
                <SearchResultsContainer />
            </div>
        </div>
    </Layout>
);

export default SearchResultsPage;
