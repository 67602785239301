import * as React from 'react';
import './index.scss';

interface IKioskContext {
    isKiosk: boolean;
}

const KioskContext = React.createContext<IKioskContext>({ isKiosk: false });

interface IProps {
    children: React.ReactNode;
}

const KioskLayout = (props: IProps) => {
    React.useEffect(() => {
        const handleContextMenu = (e: any) => {
            e?.stopPropagation?.();
            e?.preventDefault?.();
            return false;
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);
    return (
        <KioskContext.Provider value={{ isKiosk: true }}>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
    ::-webkit-scrollbar {
        display: none;
    }
    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .gdpr{display:none !important;}
                `,
                }}
            />
            <div className="KioskContainer" {...props} />
            <div data-modal-container />
        </KioskContext.Provider>
    );
};

export const useKioskContext = () => React.useContext(KioskContext);

export default KioskLayout;
