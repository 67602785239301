import './index.scss';

import React from 'react';

import TravelPass from '../../../components/TravelPasses/TravelPass';
import { TNormalizedTravelPass } from '../../../components/TravelPasses/TravelPassesContainer';

interface IProps {
    data: {
        [key: string]: TNormalizedTravelPass[];
    };
    selected: TNormalizedTravelPass;
    onSelect: (travelPass: TNormalizedTravelPass) => void;
}

const TravelPassRouteSelector: React.FC<IProps> = ({
    data,
    selected,
    onSelect,
}: IProps) => {
    return (
        <div className="TravelPassRouteSelector">
            {Object.keys(data).map(key => (
                <TravelPass
                    key={key}
                    passes={data[key]}
                    selected={selected}
                    onSelect={onSelect}
                />
            ))}
        </div>
    );
};

export default TravelPassRouteSelector;
