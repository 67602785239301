import Logo from '@slkit/Logo';
import cx from 'classnames';
import * as React from 'react';
import './index.scss';

const Footer = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('Footer', className)}>
        <div className="container">
            <div />
            <div className="Footer__Links" {...props} />
        </div>
    </div>
);

export default Footer;
