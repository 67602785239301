import * as React from 'react';

type onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

const useOnTargetClick = (
    action?: () => void
): { onClick: onClickHandler; ref: React.RefObject<HTMLDivElement> } => {
    const ref = React.useRef<HTMLDivElement>(null);

    const onClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (e.target === ref.current && action) {
                action();
            }
        },
        [action, ref.current]
    );

    return { onClick, ref };
};

export default useOnTargetClick;
