import ScheduleItem from '@slkit/ScheduleItem';
import useI18n from 'lib/hooks/useI18n';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { JourneyRouteFragment } from '../../../graphql';

interface IProps {
    route: JourneyRouteFragment;
}

const SearchTripDetailSchedule = ({ route }: IProps) => {
    const { i, t } = useI18n();
    const deptPlatform = route.departurePlatform
        ? i<{ platform: string }>(t('trip_detail.schedule.platform'), {
              platform: route.departurePlatform,
          })
        : null;

    return (
        <div>
            <ScheduleItem
                city={route.departureCityName}
                time={moment.tz(
                    route.departureDatetime,
                    route.departureTimezone
                )}
                stop={route.departureStopName}
                platform={deptPlatform}
                details={i<{ busLineNumber: string; busCompanyName: string }>(
                    t('trip_detail.schedule.line-number'),
                    {
                        busCompanyName: route.busCompanyName,
                        busLineNumber: route.busLineNumber,
                    }
                )}
                showDate
                from
            />
            {route.intermediateStops.map((s, n) => (
                <ScheduleItem
                    key={n}
                    from={s.isTransfer || false}
                    city={(s.stopLocation && s.stopLocation.cityName) || ''}
                    time={moment.tz(
                        s.departureDateTime,
                        s.departureDateTimeZone as string
                    )}
                    stop={s.stopName || ''}
                />
            ))}
            <ScheduleItem
                city={route.destinationCityName}
                time={moment.tz(route.arrivalDatetime, route.arrivalTimezone)}
                stop={route.destinationStopName}
                to
            />
        </div>
    );
};

export default SearchTripDetailSchedule;
