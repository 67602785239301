import Icon, { ICON_NAME } from '@slkit/Icon';
import * as React from 'react';
import './index.scss';

const Dialog = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>((props: React.HTMLAttributes<HTMLDivElement>, ref) => (
    <div className="Dialog2" {...props} ref={ref} />
));

interface IDialogHeaderProps {
    children?: React.ReactNode;
    onClose?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const DialogHeader = ({ children, onClose }: IDialogHeaderProps) => (
    <div className="Dialog2__Header">
        <span>{children}</span>
        {onClose && (
            <span className="Dialog2__HeaderClose" onClick={onClose}>
                <Icon name={ICON_NAME.CLOSE} />
            </span>
        )}
    </div>
);

export default Dialog;
