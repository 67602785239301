import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { formatPrice } from 'lib/helpers';
import useBasketContent from 'lib/hooks/useBasketContent';
import useBasketEntries, { IBasketEntry } from 'lib/hooks/useBasketEntries';
import { gtmLogRemoveFromCart } from 'lib/hooks/useGTM';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import localStorage from 'lib/localStorage';
import * as React from 'react';
import useBasketServiceEntries from '../../../lib/hooks/useBasketServiceEntries';
import { useCheckoutStep } from '../useCheckoutStep';
import TripSummary from './TripSummary';

const TripSummaryContainer = () => {
    const {
        basketId,
        addCampaignCode,
        deleteBasket,
        deleteBasketProducts,
        setBasketId,
    } = React.useContext(ApplicationDataContext);
    const [error, setError] = React.useState<Error | undefined>();
    const history = useHistory();
    const { data, reload, isTravelPassesOnly } = useBasketContent();
    const { entries, loading: basketLoading } = useBasketEntries();
    const { loading, beginLoading, endLoading } = useLoading();
    const {
        entries: services,
        loading: basketServiceLoading,
    } = useBasketServiceEntries();

    useCheckoutStep(0);

    const handleError = (e: Error | undefined) => {
        endLoading();
        setError(e);
    };

    const handleAddCampaignCode = React.useCallback(
        (code: string) => {
            if (!basketId || !code || !addCampaignCode) {
                return Promise.resolve(false);
            }

            beginLoading();
            return addCampaignCode(basketId, code)
                .then(reload)
                .then(endLoading)
                .then(() => (window.__trackingCoupon = code)) // store for tracking later
                .catch(e => endLoading(undefined, e));
        },
        [addCampaignCode, basketId, reload]
    );

    const handleBack = React.useCallback(() => {
        if (isTravelPassesOnly) {
            history.push('/travel-passes');
        } else {
            const query = localStorage.getLastSearchQuery() || '';
            history.push('/search' + query);
        }
    }, [history, isTravelPassesOnly]);

    const handleContinue = () => {
        const path = isTravelPassesOnly
            ? '/purchase/payment'
            : '/purchase/passengers';
        history.push(path);
    };

    const handleDeleteEntry = (basketEntry: IBasketEntry) => {
        if (!basketId || !deleteBasketProducts || !deleteBasket) {
            return Promise.resolve();
        }

        beginLoading();

        if (entries.length === 1 && services.length === 0) {
            return deleteBasket(basketId)
                .then(() => {
                    gtmLogRemoveFromCart([basketEntry]);
                    setBasketId();
                })
                .then(endLoading)
                .catch(handleError);
        }

        const productIds = basketEntry.products.map(p => p.productId!);
        return deleteBasketProducts(basketId, productIds)
            .then(() => gtmLogRemoveFromCart([basketEntry]))
            .then(reload)
            .then(endLoading)
            .catch(handleError);
    };

    if (basketLoading || basketServiceLoading) return <LoadingIndicator />;

    let summaryPrice: string | undefined;

    if (data) {
        const content = data.basket.content;
        if (content.totalPrice !== null && content.currency !== null) {
            summaryPrice = formatPrice(content.totalPrice, content.currency);
        }
    }

    return (
        <>
            {loading && <LoadingIndicator />}
            <TripSummary
                entries={entries}
                services={services}
                onBack={handleBack}
                onContinue={handleContinue}
                onDeleteEntry={handleDeleteEntry}
                onPromoCodeSubmit={handleAddCampaignCode}
                summaryPrice={summaryPrice}
                isTravelPassesOnly={isTravelPassesOnly}
            />
            <ErrorMessage
                error={error}
                fixed
                onClose={() => setError(undefined)}
            />
        </>
    );
};

export default TripSummaryContainer;
