import * as History from 'history';

const history = History.createBrowserHistory();

history.listen(() => {
    if (window.exponea && window.exponea.track) {
        window.exponea.track('page_visit');
    }
});

export default history;
