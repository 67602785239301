import CardList from '@slkit/CardList';
import Icon, { ICON_NAME } from '@slkit/Icon';
import PaymentMethodBadge from '@slkit/PaymentMethodBadge';
import SupportedCards from 'components/AddCreditCardDialog/SupportedCards';
import useCurrentUser from 'components/Login/useCurrentUser';
import AdyentCardPaymentMethod, {
    ICardData,
} from 'lib/@adyen/AdyenCardPaymentMethod';
import AdyenCVCForm from 'lib/@adyen/AdyenCVCForm';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import useExistingPaymentMethods, {
    IExistingPaymentMethod,
} from '../useExistingPaymentMethods';

export interface ICardPaymentMethod {
    existingPaymentMethod?: IExistingPaymentMethod;
    existingPaymentMethodCVC?: string;
    cardData?: ICardData;
}

interface IProps {
    onMethodChange: (method: ICardPaymentMethod) => void;
}

const CreditCardPaymentMethod = ({ onMethodChange }: IProps) => {
    const { t } = useI18n('payment');
    const currentUser = useCurrentUser();

    // This should never start with loading true, as loading is checked
    // in parent and this component is only rendered when loading is false
    const {
        data: existingPaymentMethods,
        loading,
    } = useExistingPaymentMethods();
    const initialCardFormVisible = existingPaymentMethods.length === 0;

    const [cardFormVisible, setCardFormVisible] = React.useState(
        initialCardFormVisible
    );

    // Card can either be selected from existing or new with card data
    const [method, setMethod] = React.useState<ICardPaymentMethod>({});

    // When existing methods are loaded, preselect one of them
    React.useEffect(() => {
        if (!existingPaymentMethods || existingPaymentMethods.length === 0) {
            return;
        }
        const primaryMethod = existingPaymentMethods.find(m => !!m.isPrimary);
        handleSelectedMethodChange(primaryMethod || existingPaymentMethods[0]);
    }, [existingPaymentMethods]);

    const cardListItems = React.useMemo(
        () =>
            existingPaymentMethods.map(m => ({
                ...m,
                // accessory: <pre>{JSON.stringify(m, null, 2)}</pre>,
                accessory: m.requiresCVC ? (
                    <div>
                        <AdyenCVCForm
                            checkoutType={m.checkoutType}
                            onChange={enc => handleSelectedMethodChange(m, enc)}
                        />
                        {/* <pre>{JSON.stringify(m, null, 2)}</pre> */}
                    </div>
                ) : (
                    undefined
                ),
            })),
        [existingPaymentMethods]
    );

    const handleSelectedMethodChange = (
        m: IExistingPaymentMethod,
        cvc?: string
    ) => {
        handleMethod({
            existingPaymentMethod: m,
            existingPaymentMethodCVC: cvc,
        });
        setCardFormVisible(false);
    };

    const handleCardDataChange = (cardData: ICardData) => {
        handleMethod({ cardData });
    };

    const handleMethod = (m: ICardPaymentMethod) => {
        setMethod(m);
        onMethodChange(m);
    };

    if (loading) return null;

    return (
        <>
            <div className="PaymentMethod__Description">
                {t('card.card-payment-subtitle')}
                <div className="CreditCardsStrip">
                    <SupportedCards active={true} />
                </div>
            </div>
            <div style={{ marginTop: 16 }}>
                {cardFormVisible ? (
                    <AdyentCardPaymentMethod
                        onCardData={handleCardDataChange}
                        remember={currentUser === undefined ? false : undefined}
                    />
                ) : (
                    <>
                        <CardList
                            cardsData={cardListItems}
                            onChange={handleSelectedMethodChange}
                            selectedCard={method.existingPaymentMethod}
                        />
                        {currentUser && (
                            <button
                                className="AddNewCardButton"
                                onClick={() =>
                                    setCardFormVisible(!cardFormVisible)
                                }
                            >
                                <Icon name={ICON_NAME.PLUS} />{' '}
                                {t('card.add-card')}
                            </button>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export const CreditCardPaymentMethodHeader = ({
    active,
}: {
    active: boolean;
}) => {
    const { t } = useI18n('payment_method');

    return (
        <div className="PaymentMethodTitle">
            <PaymentMethodBadge active={active} icon={ICON_NAME.CREDIT_CARD} />
            <div className="PaymentMethodTitle--methodName">
                {t('CREDIT_CARD')}
            </div>
        </div>
    );
};

export default CreditCardPaymentMethod;
