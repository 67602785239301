import { setContext } from 'apollo-link-context';
import { IClientContext } from './client';

export const languageLink = (language: string, overrideLanguage?: boolean) =>
    setContext((_, prevContext) => ({
        headers: {
            ...(prevContext as IClientContext).headers,
            'Accept-Language': language,
            ...(overrideLanguage
                ? {
                      'X-Force-Language': language,
                  }
                : {}),
        },
    }));
