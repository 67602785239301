import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import usePaymentConfiguration from 'components/PurchaseProcess/Payment/usePaymentConfiguration';
import { AdyenCheckoutContextProvider } from 'lib/@adyen/useAdyenCheckout';
import * as React from 'react';

import { usePaymentMethodsQuery } from '../../../graphql';

interface IProps {
    amount?: number;
    children: React.ReactNode;
    currency?: string;
}

const AdyenPaymentContainer = ({ amount, children, currency }: IProps) => {
    const config = usePaymentConfiguration();
    const { data, loading, error } = usePaymentMethodsQuery({
        variables: {
            input: {
                amount: {
                    currency: currency!,
                    value: Math.round(amount! * 100),
                },
                countryCode: 'SK',
            },
        },
        skip: !amount || !currency,
    });

    if (loading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ErrorMessage error={error} fixed noPortal />;
    }

    return (
        <AdyenCheckoutContextProvider
            options={config}
            paymentMethodsResponse={data && data.adyen.paymentMethods}
        >
            {children}
        </AdyenCheckoutContextProvider>
    );
};

export default AdyenPaymentContainer;
