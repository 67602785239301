import Icon, { ICON_NAME } from '@slkit/Icon';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

const SupportCenter = () => {
    const { t } = useI18n('support-center');

    return (
        <div className="SupportCenter">
            <div className="SupportCenter__Title">{t('title')}</div>
            <p className="SupportCenter__Copy">{t('copy')}</p>
            <a
                className="SupportCenter__Link"
                href={`tel:${(t('phone') || '').replace(/\ /g, '')}`}
            >
                <Icon name={ICON_NAME.PHONE2} /> {t('phone')}
            </a>
            <a className="SupportCenter__Link" href={`mailto:${t('email')}`}>
                <Icon name={ICON_NAME.MAIL} /> {t('email')}
            </a>
        </div>
    );
};

export default SupportCenter;
