import './index.scss';

import cx from 'classnames';
import * as React from 'react';

import { ReactComponent as CARD_NUMBER } from '../assets/svgs/cardnumber.svg';
import { ReactComponent as DOWNLOAD } from '../assets/svgs/download.svg';
import { ReactComponent as ADDON_PLACEHOLDER } from '../assets/svgs/icon-addons-placeholder.svg';
import { ReactComponent as AMEX } from '../assets/svgs/icon-amex.svg';
import { ReactComponent as ARROW_DOWN } from '../assets/svgs/icon-arrowdown.svg';
import { ReactComponent as SMALL_ARROW } from '../assets/svgs/icon-arrowright.svg';
import { ReactComponent as B2B_PURCHASES } from '../assets/svgs/icon-b2b-purchases.svg';
import { ReactComponent as BAGGAGE } from '../assets/svgs/icon-baggage.svg';
import { ReactComponent as BTNS_DESTINATION_CANCEL } from '../assets/svgs/btns-destination-cancel.svg';
import { ReactComponent as BTNS_DESTINATION_SWITCH } from '../assets/svgs/btns-destination-switch.svg';
import { ReactComponent as BUS } from '../assets/svgs/icon-bus.svg';
import { ReactComponent as CALENDAR } from '../assets/svgs/icon-calendar.svg';
import { ReactComponent as CANCEL_TICKET } from '../assets/svgs/icon-cancel-ticket.svg';
import { ReactComponent as CARD } from '../assets/svgs/icon-card.svg';
import { ReactComponent as CART } from '../assets/svgs/icon-cart3.svg';
import { ReactComponent as CHECK } from '../assets/svgs/icon-check.svg';
import { ReactComponent as CHEVRON } from '../assets/svgs/chevron.svg';
import { ReactComponent as CLOCK } from '../assets/svgs/icon-clock.svg';
import { ReactComponent as CLOSE } from '../assets/svgs/icon-close.svg';
import { ReactComponent as CLOSE2021 } from '../assets/svgs/icon-close-2021.svg';
import { ReactComponent as CROSS } from '../assets/svgs/icon-cross.svg';
import { ReactComponent as DESTINATIONS_AIRPORT } from '../assets/svgs/icon-destinations-airport.svg';
import { ReactComponent as DESTINATIONS_BUS } from '../assets/svgs/icon-destinations-bus.svg';
import { ReactComponent as DESTINATIONS_CITY } from '../assets/svgs/icon-destinations-city.svg';
import { ReactComponent as DESTINATIONS_DESTINATION } from '../assets/svgs/icon-destinations-destination.svg';
import { ReactComponent as DESTINATIONS_SOURCE } from '../assets/svgs/icon-destinations-source.svg';
import { ReactComponent as DINERS } from '../assets/svgs/icon-diners.svg';
import { ReactComponent as DISCOVER } from '../assets/svgs/icon-discover.svg';
import { ReactComponent as DOCUMENT } from '../assets/svgs/icon-document.svg';
import { ReactComponent as EYE } from '../assets/svgs/icon-eye.svg';
import { ReactComponent as EYE_CLOSED } from '../assets/svgs/icon-eye-closed.svg';
import { ReactComponent as FACEBOOK } from '../assets/svgs/icon-facebook.svg';
import { ReactComponent as GLOBE } from '../assets/svgs/icon-globe.svg';
import { ReactComponent as INFO } from '../assets/svgs/icon-info.svg';
import { ReactComponent as KARNET } from '../assets/svgs/icon-karnet.svg';
import { ReactComponent as LINK } from '../assets/svgs/icon-link.svg';
import { ReactComponent as MAESTRO } from '../assets/svgs/icon-maestro.svg';
import { ReactComponent as MAIL } from '../assets/svgs/icon-mail.svg';
import { ReactComponent as MASTERCARD } from '../assets/svgs/icon-mastercard.svg';
import { ReactComponent as MENU } from '../assets/svgs/icon-menu.svg';
import { ReactComponent as MINUS } from '../assets/svgs/icon-minus.svg';
import { ReactComponent as APPLE_PAY } from '../assets/svgs/icon-payment-applepay.svg';
import { ReactComponent as B2B } from '../assets/svgs/icon-payment-business.svg';
import { ReactComponent as CREDIT_CARD } from '../assets/svgs/icon-payment-card.svg';
import { ReactComponent as GPAY } from '../assets/svgs/icon-payment-googlepay.svg';
import { ReactComponent as VOUCHER } from '../assets/svgs/icon-payment-voucher.svg';
import { ReactComponent as PAYMENT } from '../assets/svgs/icon-payment.svg';
import { ReactComponent as PAYPAL } from '../assets/svgs/icon-paypal.svg';
import { ReactComponent as PERCENTAGE } from '../assets/svgs/icon-percentage.svg';
import { ReactComponent as PHONE2 } from '../assets/svgs/icon-phone-2.svg';
import { ReactComponent as PHONE } from '../assets/svgs/icon-phone.svg';
import { ReactComponent as PLANT } from '../assets/svgs/icon-plant.svg';
import { ReactComponent as PLUS } from '../assets/svgs/icon-plus.svg';
import { ReactComponent as PRIVACY } from '../assets/svgs/icon-privacy.svg';
import { ReactComponent as PROFILE } from '../assets/svgs/icon-profile.svg';
import { ReactComponent as QUESTION } from '../assets/svgs/icon-question.svg';
import { ReactComponent as SEARCH_CALENDAR } from '../assets/svgs/icon-search-calendar.svg';
import { ReactComponent as SEARCH_TARIFS } from '../assets/svgs/icon-search-tarifs.svg';
import { ReactComponent as SEARCH } from '../assets/svgs/icon-search.svg';
import { ReactComponent as SECURE } from '../assets/svgs/icon-secure.svg';
import { ReactComponent as SWITCH } from '../assets/svgs/icon-switch.svg';
import { ReactComponent as TICKETS } from '../assets/svgs/icon-tickets.svg';
import { ReactComponent as TIME } from '../assets/svgs/icon-time.svg';
import { ReactComponent as USER } from '../assets/svgs/icon-user2.svg';
import { ReactComponent as VISA } from '../assets/svgs/icon-visa.svg';
import { ReactComponent as WARNING } from '../assets/svgs/icon-warning.svg';
import { ReactComponent as INDICATOR } from '../assets/svgs/indicator.svg';
import { ReactComponent as MAGNIFIER } from '../assets/svgs/magnifier.svg';

import { ReactComponent as NO_EXTRA_AVAILABLE } from '../assets/svgs/icon-no-extra-available.svg';
import { ReactComponent as NO_SEATS_AVAILABLE } from '../assets/svgs/icon-no-seats-available.svg';

// import { ReactComponent as _ICON } from '../assets/svgs/icon-plus2.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/Combined Shape.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/Icon-cart.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/big-check.svg';
// import { ReactComponent as BUS } from '../assets/svg-components/icon-bus.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/circle.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/g-logo.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-bus.svg';
// import { ReactComponent as CLOSE } from '../assets/svg-components/icon-close.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-country-sk.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-gpay.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-gps.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-paypal.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-print.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/icon-small-pin.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/login-illustration.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/logo.svg';
// import { ReactComponent as _ICON } from '../assets/svgs/paypal-logo.svg';
export enum ICON_NAME {
    ADDON_PLACEHOLDER = 'ADDON_PLACEHOLDER',
    AMEX = 'AMEX',
    APPLE_PAY = 'APPLE_PAY',
    ARROW_DOWN = 'ARROW_DOWN',
    B2B = 'B2B',
    B2B_PURCHASES = 'B2B_PURCHASES',
    BAGGAGE = 'BAGGAGE',
    BTNS_DESTINATION_CANCEL = 'BTNS_DESTINATION_CANCEL',
    BTNS_DESTINATION_SWITCH = 'BTNS_DESTINATION_SWITCH',
    BUS = 'BUS',
    CALENDAR = 'CALENDAR',
    CANCEL_TICKET = 'CANCEL_TICKET',
    CARD = 'CARD',
    CARD_NUMBER = 'CARD_NUMBER',
    CART = 'CART',
    CHECK = 'CHECK',
    CHEVRON = 'CHEVRON',
    CLOCK = 'CLOCK',
    CLOSE = 'CLOSE',
    CLOSE2021 = 'CLOSE2021',
    CREDIT_CARD = 'CREDIT_CARD',
    CROSS = 'CROSS',
    DELETE = 'DELETE',
    DESTINATIONS_AIRPORT = 'DESTINATIONS_AIRPORT',
    DESTINATIONS_BUS = 'DESTINATIONS_BUS',
    DESTINATIONS_CITY = 'DESTINATIONS_CITY',
    DESTINATIONS_DESTINATION = 'DESTINATIONS_DESTINATION',
    DESTINATIONS_SOURCE = 'DESTINATIONS_SOURCE',
    DINERS = 'DINERS',
    DISCOVER = 'DISCOVER',
    DOCUMENT = 'DOCUMENT',
    DOWNLOAD = 'DOWNLOAD',
    EYE = 'EYE',
    EYE_CLOSED = 'EYE_CLOSED',
    FACEBOOK = 'FACEBOOK',
    GLOBE = 'GLOBE',
    GPAY = 'GPAY',
    INDICATOR = 'INDICATOR',
    INFO = 'INFO',
    KARNET = 'KARNET',
    LINK = 'LINK',
    MAGNIFIER = 'MAGNIFIER',
    MAIL = 'MAIL',
    MAESTRO = 'MAESTRO',
    MASTERCARD = 'MASTERCARD',
    MENU = 'MENU',
    MINUS = 'MINUS',
    NO_EXTRA_AVAILABLE = 'NO_EXTRA_AVAILABLE',
    NO_SEATS_AVAILABLE = 'NO_SEATS_AVAILABLE',
    PAYMENT = 'PAYMENT',
    PAYPAL = 'PAYPAL',
    PERCENTAGE = 'PERCENTAGE',
    PHONE = 'PHONE',
    PHONE2 = 'PHONE2',
    PLANT = 'PLANT',
    PLUS = 'PLUS',
    PRIVACY = 'PRIVACY',
    PROFILE = 'PROFILE',
    QUESTION = 'QUESTION',
    SEARCH_CALENDAR = 'SEARCH_CALENDAR',
    SEARCH_TARIFS = 'SEARCH_TARIFS',
    SEARCH = 'SEARCH',
    SECURE = 'SECURE',
    SMALL_ARROW = 'SMALL_ARROW',
    SWITCH = 'SWITCH',
    TICKETS = 'TICKETS',
    TIME = 'TIME',
    USER = 'USER',
    VISA = 'VISA',
    VOUCHER = 'VOUCHER',
    WARNING = 'WARNING',
}

const ICONS = {
    [ICON_NAME.ADDON_PLACEHOLDER]: ADDON_PLACEHOLDER,
    [ICON_NAME.AMEX]: AMEX,
    [ICON_NAME.APPLE_PAY]: APPLE_PAY,
    [ICON_NAME.ARROW_DOWN]: ARROW_DOWN,
    [ICON_NAME.B2B_PURCHASES]: B2B_PURCHASES,
    [ICON_NAME.B2B]: B2B,
    [ICON_NAME.BAGGAGE]: BAGGAGE,
    [ICON_NAME.BTNS_DESTINATION_CANCEL]: BTNS_DESTINATION_CANCEL,
    [ICON_NAME.BTNS_DESTINATION_SWITCH]: BTNS_DESTINATION_SWITCH,
    [ICON_NAME.BUS]: BUS,
    [ICON_NAME.CALENDAR]: CALENDAR,
    [ICON_NAME.CANCEL_TICKET]: CANCEL_TICKET,
    [ICON_NAME.CARD_NUMBER]: CARD_NUMBER,
    [ICON_NAME.CARD]: CARD,
    [ICON_NAME.CART]: CART,
    [ICON_NAME.CHECK]: CHECK,
    [ICON_NAME.CHEVRON]: CHEVRON,
    [ICON_NAME.CLOCK]: CLOCK,
    [ICON_NAME.CLOSE]: CLOSE,
    [ICON_NAME.CLOSE2021]: CLOSE2021,
    [ICON_NAME.CREDIT_CARD]: CREDIT_CARD,
    [ICON_NAME.CROSS]: CROSS,
    [ICON_NAME.DESTINATIONS_AIRPORT]: DESTINATIONS_AIRPORT,
    [ICON_NAME.DESTINATIONS_BUS]: DESTINATIONS_BUS,
    [ICON_NAME.DESTINATIONS_CITY]: DESTINATIONS_CITY,
    [ICON_NAME.DESTINATIONS_DESTINATION]: DESTINATIONS_DESTINATION,
    [ICON_NAME.DESTINATIONS_SOURCE]: DESTINATIONS_SOURCE,
    [ICON_NAME.DINERS]: DINERS,
    [ICON_NAME.DISCOVER]: DISCOVER,
    [ICON_NAME.DOCUMENT]: DOCUMENT,
    [ICON_NAME.DOWNLOAD]: DOWNLOAD,
    [ICON_NAME.EYE]: EYE,
    [ICON_NAME.EYE_CLOSED]: EYE_CLOSED,
    [ICON_NAME.FACEBOOK]: FACEBOOK,
    [ICON_NAME.GLOBE]: GLOBE,
    [ICON_NAME.GPAY]: GPAY,
    [ICON_NAME.INDICATOR]: INDICATOR,
    [ICON_NAME.INFO]: INFO,
    [ICON_NAME.KARNET]: KARNET,
    [ICON_NAME.LINK]: LINK,
    [ICON_NAME.MAGNIFIER]: MAGNIFIER,
    [ICON_NAME.MAESTRO]: MAESTRO,
    [ICON_NAME.MAIL]: MAIL,
    [ICON_NAME.MASTERCARD]: MASTERCARD,
    [ICON_NAME.MENU]: MENU,
    [ICON_NAME.MINUS]: MINUS,
    [ICON_NAME.NO_EXTRA_AVAILABLE]: NO_EXTRA_AVAILABLE,
    [ICON_NAME.NO_SEATS_AVAILABLE]: NO_SEATS_AVAILABLE,
    [ICON_NAME.PAYMENT]: PAYMENT,
    [ICON_NAME.PAYPAL]: PAYPAL,
    [ICON_NAME.PERCENTAGE]: PERCENTAGE,
    [ICON_NAME.PHONE]: PHONE,
    [ICON_NAME.PHONE2]: PHONE2,
    [ICON_NAME.PLANT]: PLANT,
    [ICON_NAME.PLUS]: PLUS,
    [ICON_NAME.PRIVACY]: PRIVACY,
    [ICON_NAME.PROFILE]: PROFILE,
    [ICON_NAME.QUESTION]: QUESTION,
    [ICON_NAME.SEARCH_CALENDAR]: SEARCH_CALENDAR,
    [ICON_NAME.SEARCH_TARIFS]: SEARCH_TARIFS,
    [ICON_NAME.SEARCH]: SEARCH,
    [ICON_NAME.SECURE]: SECURE,
    [ICON_NAME.SMALL_ARROW]: SMALL_ARROW,
    [ICON_NAME.SWITCH]: SWITCH,
    [ICON_NAME.TICKETS]: TICKETS,
    [ICON_NAME.TIME]: TIME,
    [ICON_NAME.USER]: USER,
    [ICON_NAME.VISA]: VISA,
    [ICON_NAME.VOUCHER]: VOUCHER,
    [ICON_NAME.WARNING]: WARNING,
};

interface IBaseProps {
    as?: 'div' | 'span' | 'p';
    badge?: number;
    className?: string;
    rect?: boolean;
    style?: React.CSSProperties;
}

interface INameProps extends IBaseProps {
    raw?: boolean;
    name: ICON_NAME;
}

interface ISvgProps extends IBaseProps {
    svg: string;
}

const Icon = React.memo(
    ({
        as = 'div',
        badge,
        className,
        rect = false,
        style,
        ...props
    }: INameProps | ISvgProps) => {
        const Component = as;
        const showBadge = !!(badge && badge > 0);
        const cname = cx('Icon', className, {
            'Icon--check': 'name' in props && props.name === ICON_NAME.CHECK,
            'Icon--rect': rect,
            'Icon--showBadge': badge && badge > 0,
        });

        const compProps: React.HTMLAttributes<HTMLElement> = {
            className: cname,
            style,
        };

        if ('name' in props) {
            const IconSvg = ICONS[props.name];
            compProps.children = (
                <>
                    <IconSvg />
                    {showBadge && <div className="Icon__Badge">{badge}</div>}
                </>
            );
        }

        if ('svg' in props) {
            let str = props.svg;
            if (showBadge) {
                str += `<div class="Icon__Badge">${badge}</div>`;
            }
            compProps.dangerouslySetInnerHTML = { __html: str };
        }

        if ('raw' in props && props.raw) {
            return <>{compProps.children}</>;
        }

        return <Component {...compProps} />;
    }
);

export default Icon;
