import * as cx from 'classnames';
import { formatDate } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { ReactComponent as INDICATOR } from '../assets/svgs/indicator.svg';
import './index.scss';

export interface ITripSmallProps {
    separateHead?: boolean;
    returnTrip?: boolean;
    showBasketNumber?: boolean;
    showNumber?: boolean;
    tripData: ITripData;
}

const TripSmall = ({
    returnTrip,
    separateHead,
    showBasketNumber,
    showNumber,
    tripData,
}: ITripSmallProps) => {
    const { t } = useI18n('trip_small');

    const discount = tripData.discount ? `(-${tripData.discount})` : '';

    return (
        <div
            className={cx('TripSmall', {
                'TripSmall--return': returnTrip,
                'TripSmall--separated-head': separateHead,
            })}
        >
            <div className="TripSmall__Head">
                <div className="TripSmall__Title">
                    <strong>
                        {formatDate(tripData.departureDateTime, 'Do MMMM YYYY')}
                    </strong>{' '}
                    {t('at')}&nbsp;
                    <strong>
                        {formatDate(
                            tripData.departureDateTime,
                            t('time-format', undefined, 'HH:mm')
                        )}
                    </strong>
                </div>
                <div className="TripSmall__Price">
                    {discount}&nbsp;<strong>{tripData.price}</strong>
                </div>
            </div>
            <div className="TripSmall__Stops">
                <Stop
                    detail={tripData.departureBusStop}
                    title={tripData.departureStopCityName}
                />
                <div className="TripSmall__Indicator">
                    <INDICATOR />
                </div>
                <Stop
                    detail={tripData.arrivalBusStop}
                    title={tripData.destinationStopCityName}
                />
            </div>
            <div className="TripSmall__Details">
                {showBasketNumber && tripData.basketNumber && (
                    <div className="TripSmall__Detail">
                        <strong className="TripSmall__DetailLabel">
                            {t('basket-number')}
                        </strong>
                        &nbsp;
                        <span className="TripSmall__DetailValue">
                            {tripData.basketNumber}
                        </span>
                    </div>
                )}
                {showNumber && tripData.ticketNumber && (
                    <div className="TripSmall__Detail">
                        <strong className="TripSmall__DetailLabel">
                            {t('number')}
                        </strong>
                        &nbsp;
                        <span className="TripSmall__DetailValue">
                            {tripData.ticketNumber}
                        </span>
                    </div>
                )}
                <div className="TripSmall__Detail">
                    <strong className="TripSmall__DetailLabel">
                        {t('details')}
                    </strong>{' '}
                    <span className="TripSmall__DetailValue">
                        {tripData.details}
                        {tripData.isReinforcement && (
                            <>
                                {' '}
                                &bull; <span>{t('reinforcement')}</span>
                            </>
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

const Stop = ({ detail, title }: { detail?: string; title?: string }) => (
    <div className="TripSmall__Stop">
        <div className="TripSmall__StopTitle">{title}&nbsp;</div>
        <div className="TripSmall__StopDetail">{detail}&nbsp;</div>
    </div>
);

export default TripSmall;
