import cx from 'classnames';
import * as React from 'react';

const DropdownOption = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cx('Dropdown__Option', className)} {...props} />
);

export default DropdownOption;
