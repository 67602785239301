import NarrowButton from '@slkit/Narrow/NarrowButton';
import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import { ApolloError } from 'apollo-client';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import usePassengersForm from 'components/PurchaseProcess/Passengers/usePassengersForm';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { focusError } from 'lib/helpers';
import useBasketEntries from 'lib/hooks/useBasketEntries';
import useI18n from 'lib/hooks/useI18n';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import * as React from 'react';
import { useCheckoutStep } from '../useCheckoutStep';
import EntryPassengersForm from './EntryPassengersForm';

const PassengersInformationStep = () => {
    const { basketId } = React.useContext(ApplicationDataContext);
    const {
        passengers,
        handlePassengerChange,
        reload,
        loading,
        error: fetchError,
        submit,
    } = usePassengersForm();
    const { entries } = useBasketEntries();
    const history = useHistory();
    const { t } = useI18n('passengers_info');
    const [submitError, setSubmitError] = React.useState<ApolloError | null>(
        null
    );
    const [shouldFocusError, setFocusError] = React.useState(false);
    const { loading: submitLoading, beginLoading, endLoading } = useLoading(
        false
    );

    useCheckoutStep(1);

    React.useEffect(() => {
        if (shouldFocusError) {
            focusError();
            setFocusError(false);
        }
    }, [shouldFocusError]);

    const handleFormSubmit = () => {
        setSubmitError(null);
        beginLoading();
        if (basketId) {
            return submit(basketId)
                .then(res => {
                    if (res) {
                        (reload ? reload() : Promise.resolve([])).then(() => {
                            endLoading();
                            history.push('/purchase/extra');
                        });
                    } else {
                        endLoading();
                        setFocusError(true);
                    }
                })
                .catch(error => {
                    endLoading();
                    setSubmitError(error);
                    setFocusError(true);
                });
        } else {
            return Promise.resolve(false);
        }
    };

    if (loading) return <LoadingIndicator />;
    if (fetchError) return <ErrorMessage error={fetchError} fixed />;

    return (
        <>
            <NarrowTitle>{t('title')}</NarrowTitle>
            <NarrowText>{t('description')}</NarrowText>
            {entries &&
                passengers &&
                entries.map((entry, i) => (
                    <EntryPassengersForm
                        key={i}
                        entry={entry}
                        passengers={passengers}
                        onChange={handlePassengerChange}
                    />
                ))}
            {submitError && (
                <ErrorMessage
                    fixed
                    error={submitError}
                    onClose={() => setSubmitError(null)}
                />
            )}
            {submitLoading && <LoadingIndicator />}
            <NarrowButton onClick={handleFormSubmit}>
                {t('next-button-extra')}
            </NarrowButton>
        </>
    );
};

export default PassengersInformationStep;
