import Icon, { ICON_NAME } from '@slkit/Icon';
import PaymentMethodBadge from '@slkit/PaymentMethodBadge';
import Promo from '@slkit/Promo';
import FormattedText from 'components/FormattedText';
import { formatPrice, getErrorMessage } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import { CurrencyEnum } from '__types__/globalTypes';

export interface IVoucherData {
    id: string;
    type: string;
    code: string;
    currency: CurrencyEnum;
    amount: number;
}

interface IProps {
    available?: boolean;
    onDelete?: (paymentId: string) => Promise<any>;
    onSubmit?: (code: string) => Promise<any>;
    voucherData?: IVoucherData[];
}

const VoucherForm = ({
    available = true,
    onDelete,
    onSubmit,
    voucherData = [],
}: IProps) => {
    const { t: tt } = useI18n('payment_method');
    const { t } = useI18n('voucher_form');
    const [inputValue, setInputValue] = React.useState('');
    const [error, setError] = React.useState<Error>();

    const handleSubmit = React.useCallback(() => {
        setError(undefined);
        if (!onSubmit || inputValue === '') return;
        onSubmit(inputValue)
            .then(() => setInputValue(''))
            .catch(setError);
    }, [inputValue, onSubmit]);

    return (
        <>
            <div
                style={{
                    boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.05)',
                    padding: '10px 24px',
                }}
            >
                <div className="PaymentMethodTitle">
                    <PaymentMethodBadge icon={ICON_NAME.VOUCHER} />
                    <span className="PaymentMethodTitle--methodName">
                        {tt('VOUCHER')}
                    </span>
                </div>
                <div
                    className="PaymentMethod__Description"
                    style={{ marginTop: 10, marginBottom: '1rem' }}
                >
                    {t(available ? 'subtitle' : 'subtitle-unavailable')}
                </div>
                {voucherData.length > 0 && (
                    <div style={{ marginBottom: 10 }}>
                        {voucherData.map((v, i) => (
                            <div key={i} className="VoucherStrip">
                                <span
                                    style={{
                                        opacity: 0.5,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {v.type}&nbsp;
                                </span>
                                <b>{v.code}</b>
                                <b style={{ marginLeft: 'auto' }}>
                                    -{formatPrice(v.amount, v.currency)}
                                </b>
                                <div
                                    className="VoucherStripClose"
                                    onClick={() => onDelete && onDelete(v.id)}
                                >
                                    <Icon name={ICON_NAME.CLOSE} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {available && (
                    <div style={{ marginTop: 10 }}>
                        <Promo
                            error={error && getErrorMessage(error)}
                            info={
                                <FormattedText wrapParagraphs>
                                    {t('info-tooltip')}
                                </FormattedText>
                            }
                            onChange={e => setInputValue(e.target.value)}
                            value={inputValue}
                            placeholder={t('placeholder')}
                            submitTitle={t('submit-title')}
                            onSubmit={handleSubmit}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default VoucherForm;
