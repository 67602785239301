import Button from '@slkit/Button';
import CSRFFields from 'components/CSRFFields';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useI18n from 'lib/hooks/useI18n';
import useMeta from 'lib/hooks/useMeta';
import * as React from 'react';

const isSignInError = (
    value: any | null | undefined
): value is appleSignIn.IAppleSignInError => {
    // tslint:disable-next-line: no-unsafe-any
    return value !== undefined && value !== null && !!value.error;
};

interface IProps {
    className?: string;
}

const AppleLoginButton = ({ className }: IProps) => {
    const clientId = useMeta('x-appleid-signin-client-id');
    const scope = useMeta('x-appleid-signin-scope');
    const { t } = useI18n('apple_login_button');
    const { basketId } = React.useContext(ApplicationDataContext);

    // can bail fast as meta will not change (no problem with hooks)
    if (!clientId || !scope) return null;

    React.useEffect(() => {
        if (!window.AppleID) return;
        const l = window.location;
        window.AppleID.auth.init({
            clientId,
            redirectURI: `${l.protocol}//${l.host}`,
            scope,
        });
    });

    const formRef = React.useRef<HTMLFormElement | null>(null);
    const tokenInputRef = React.useRef<HTMLInputElement | null>(null);
    const userInputRef = React.useRef<HTMLInputElement | null>(null);

    const handleLogin = () => {
        console.log('[AppleLoginButton] Start');
        if (!window.AppleID) {
            console.log('[AppleLoginButton] AppleID not available');
            return;
        }

        console.log('[AppleLoginButton] signIn');
        const res = window.AppleID.auth.signIn({
            usePopup: true,
        });
        if (res) {
            res.then(result => {
                console.log('[AppleLoginButton] got result');
                if (!result || isSignInError(result)) return;
                if (!result.authorization.id_token) return;
                if (
                    !tokenInputRef.current ||
                    !formRef.current ||
                    !userInputRef.current
                ) {
                    return;
                }

                tokenInputRef.current.value = result.authorization.id_token;

                const user = result.user;
                if (user) {
                    userInputRef.current.value = JSON.stringify(user);
                }

                formRef.current.submit();
            }).catch(error => {
                console.log('[AppleLoginButton] error', error);
            });
        }
    };

    return (
        <>
            <form action="/users/sign_in" method="post" ref={formRef}>
                <CSRFFields />
                <input
                    type="hidden"
                    name="user[apple_token]"
                    ref={tokenInputRef}
                />
                <input type="hidden" name="user[user]" ref={userInputRef} />
                <input type="hidden" name="basket_id" value={basketId} />
            </form>
            <Button className={className} full apple onClick={handleLogin}>
                {t('action-title')}
            </Button>
        </>
    );
};

export default AppleLoginButton;
