import useBasketContent from 'lib/hooks/useBasketContent';
import useBasketEntries from 'lib/hooks/useBasketEntries';
import useBasketServiceEntries from 'lib/hooks/useBasketServiceEntries';
import { fbpLogCheckoutStep, fbpLogPurchase } from 'lib/hooks/useFBPixel';
import { gtmLogCheckoutStep, gtmLogPurchase } from 'lib/hooks/useGTM';
import { useCallback, useEffect, useState } from 'react';

export const useCheckoutStep = (step: number) => {
    const [tracked, setTracked] = useState(false);

    const { data, loading: basketLoading } = useBasketContent();
    const { entries, loading: entriesLoading } = useBasketEntries();
    const {
        entries: services,
        loading: servicesLoading,
    } = useBasketServiceEntries();

    useEffect(() => {
        if (tracked) return;
        if (entriesLoading || servicesLoading || basketLoading) return;

        const items = [...entries, ...services];
        // GTM does not log checkout step 0
        if (step !== 0) {
            gtmLogCheckoutStep(items, step);
        }
        fbpLogCheckoutStep(
            items,
            step,
            data?.basket.content.currency || 'EUR',
            data?.basket.content.totalPrice || 0
        );

        setTracked(true);
    }, [
        data,
        basketLoading,
        entries,
        entriesLoading,
        services,
        servicesLoading,
        tracked,
    ]);
};

export const useLogPurchase = (basketId: string) => {
    const [tracked, setTracked] = useState(false);

    const { data, loading: basketLoading } = useBasketContent(basketId);
    const { entries, loading: entriesLoading } = useBasketEntries(basketId);
    const {
        entries: services,
        loading: servicesLoading,
    } = useBasketServiceEntries(basketId);

    return useCallback(() => {
        if (tracked) return;
        if (entriesLoading || servicesLoading || basketLoading) return;
        if (!data?.basket) return;

        const items = [...entries, ...services];
        gtmLogPurchase(items, {
            id: data.basket.id,
            revenue: data.basket.content.totalPrice?.toString() || '0',
            affiliation: window.__trackingAffiliateId,
            coupon: window.__trackingCoupon,
        });
        fbpLogPurchase(items, data.basket.content.currency || '');

        // reset values as this should not be tracked twice
        window.__trackingAffiliateId = undefined;
        window.__trackingCoupon = undefined;

        setTracked(true);
    }, [entries, entriesLoading, services, servicesLoading, tracked, data]);
};
