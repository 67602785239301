import BasketEntry from '@slkit/BasketEntry';
import Icon, { ICON_NAME } from '@slkit/Icon';
import NarrowText from '@slkit/Narrow/NarrowText';
import NarrowTitle from '@slkit/Narrow/NarrowTitle';
import PopupItem from '@slkit/Popup/PopupItem';
import Summary from '@slkit/Summary';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import BasketServiceEntry, {
    IBasketServiceEntry,
} from '../../Basket/BasketServiceEntry';
import PromoField from './PromoField';

interface IProps {
    summaryPrice?: string;
    entries: IBasketEntry[];
    services: IBasketServiceEntry[];
    onBack: () => void;
    onContinue: () => void;
    onDeleteEntry: (entry: IBasketEntry) => void;
    onPromoCodeSubmit: (code: string) => Promise<boolean>;
    isTravelPassesOnly?: boolean;
}

const TripSummary = ({
    entries,
    services,
    onBack,
    onContinue,
    onDeleteEntry,
    onPromoCodeSubmit,
    summaryPrice,
    isTravelPassesOnly,
}: IProps) => {
    const { t } = useI18n('trip_summary');

    return (
        <>
            <NarrowTitle>{t('title')}</NarrowTitle>
            <NarrowText>{t('text')}</NarrowText>
            <Summary summaryTitle={t('total')} summaryPrice={summaryPrice}>
                {entries.map((entry, n) => (
                    <PopupItem key={n}>
                        <BasketEntry
                            entry={entry}
                            onDelete={() => onDeleteEntry(entry)}
                        />
                    </PopupItem>
                ))}
                {services.map((entry, n) => (
                    <PopupItem key={n}>
                        <BasketServiceEntry entry={entry} />
                    </PopupItem>
                ))}
            </Summary>
            <PromoField onSubmit={onPromoCodeSubmit} />
            <div className="Narrow__Buttons">
                <button
                    className="Button--inline Button Button--back Button--secondary"
                    onClick={onBack}
                >
                    <Icon name={ICON_NAME.SMALL_ARROW} />
                    {t('back-title')}
                </button>
                <button className="Button--inline Button" onClick={onContinue}>
                    {isTravelPassesOnly
                        ? t('next-button-payment')
                        : t('button-title')}
                </button>
            </div>
        </>
    );
};

export default TripSummary;
