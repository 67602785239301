import cx from 'classnames';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

import Radio from '../Radio';

interface IProps {
    seatNumber?: number | null;
    passengerName: string;
    isPremium: boolean;
    seatPrice: string | null;
}

const Seat = ({
    seatNumber,
    passengerName,
    isPremium,
    seatPrice,
    ...rest
}: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
    const { t } = useI18n();
    const className = cx('Seat', { 'Seat--premium': isPremium });
    return (
        <div className={className}>
            <div className="Seat__Radio">
                <Radio label={t('seats_reservation.seat-for')} {...rest} />
            </div>
            <strong>{passengerName}</strong>
            <div className="Seat__Number">{`${seatNumber || '?'}${
                isPremium ? ' S+' : ''
            }`}</div>
            {isPremium && <div className="Seat__Info">{`+${seatPrice}`}</div>}
        </div>
    );
};

export default Seat;
