import Logo from '@slkit/Logo';
import useCurrentLanguage from 'lib/hooks/useCurrentLanguage';
import * as React from 'react';

const LogoLink = () => {
    const { lang } = useCurrentLanguage();

    const href = `https://www.slovaklines.sk${lang ? `/${lang}/` : ''}`;
    return (
        <a href={href}>
            <Logo />
        </a>
    );
};

export default LogoLink;
