import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { deviceTypeLink } from './client/deviceTypeLink';
import { errorLink } from './client/errorLink';
import { languageLink } from './client/languageLink';
import { Language } from './hooks/useI18n';

const buildClient = (
    uri: string = '/graphql',
    language?: Language,
    overrideLanguage?: boolean
) => {
    const httpLink = createHttpLink({
        credentials: 'include',
        uri,
    });

    let link = deviceTypeLink.concat(httpLink);

    if (language) {
        link = languageLink(language, overrideLanguage).concat(link);
    }

    link = errorLink(uri).concat(link);

    return new ApolloClient({
        cache: new InMemoryCache(),
        link,
    });
};

export default buildClient;
