import cx from 'classnames';
import * as React from 'react';

interface ISearchFormCellOwnProps {
    active?: boolean;
    as?: string;
    className?: string;
    filled?: boolean;
    disabled?: boolean;
}

export type SearchFormCellProps = ISearchFormCellOwnProps &
    React.HTMLProps<HTMLDivElement>;

const SearchFormCell = React.forwardRef<HTMLDivElement, SearchFormCellProps>(
    (props: SearchFormCellProps, ref) => {
        const {
            active = false,
            as = 'div',
            className,
            filled = false,
            disabled = false,
            ...rest
        } = props;
        const Component = as;

        React.useEffect(() => {
            const eventName = active
                ? 'sl-search-cell-activated'
                : 'sl-search-cell-deactivated';
            const event = new CustomEvent(eventName, {
                cancelable: false,
                detail: {
                    element: (ref as any)?.current,
                },
            });
            window.dispatchEvent(event);
        }, [active]);

        const compProps = {
            ...rest,
            className: cx('SearchForm__Cell', className, {
                'SearchForm__Cell--active': active,
                'SearchForm__Cell--disabled': disabled,
                'SearchForm__Cell--filled': filled,
            }),
        };

        return <Component ref={ref} {...compProps} />;
    }
);

export default SearchFormCell;
