import cx from 'classnames';
import { formatPrice } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

import { JourneyFragmentFragment } from '../../../../graphql';

interface IProps {
    buttonClassName?: string;
    className?: string;
    details?: React.ReactNode;
    notice?: boolean;
    onClick?: (journey: JourneyFragmentFragment) => void;
    selected: boolean;
    soldOut?: boolean;
    travelPass?: boolean;
    trip: JourneyFragmentFragment;
}

const TripCTAButton = ({
    buttonClassName,
    className,
    details,
    notice,
    onClick,
    selected,
    soldOut,
    travelPass,
    trip,
}: IProps) => {
    if (!onClick) return null;

    const { t } = useI18n('search_results.trip');

    const buttonOptions = {
        disabled: soldOut,
    };
    let buttonText = '';

    if (trip.isForSale) {
        const price = trip.campaignPrice || trip.regularPrice;
        buttonText = `${t('choose-for')} ${formatPrice(
            price as number,
            trip.currency
        )}`;
    }

    if (travelPass) {
        buttonText = t('travelpass');
    }

    if (selected) {
        buttonText = t('selected');
    }

    if (soldOut) {
        buttonText = t('sold-out');
    }

    return (
        <div className={className}>
            <button
                className={cx('Button', buttonClassName)}
                {...buttonOptions}
                onClick={() => onClick(trip)}
            >
                {buttonText}
            </button>
            {details && !soldOut && (
                <div
                    className={cx('Trip__Label', {
                        'Trip__Label--red': notice,
                    })}
                >
                    {details}
                </div>
            )}
        </div>
    );
};

export default TripCTAButton;
