import useCurrentLanguage from 'lib/hooks/useCurrentLanguage';
import useFocusable from 'lib/hooks/useFocusable';
import * as React from 'react';

import { COUNTRY_ALPHA } from 'components/Flag/CountryAlphaEnum';
import useI18n, { Language } from 'lib/hooks/useI18n';
import { ILanguageOption } from './LanguageOptions';

const LANGUAGES = [Language.DE, Language.EN, Language.SK];
const CountriesAlpha2 = {
    de: COUNTRY_ALPHA.de,
    en: COUNTRY_ALPHA.gb,
    sk: COUNTRY_ALPHA.sk,
};

const useLanguagePicker = () => {
    const { lang, setLang } = useCurrentLanguage();
    const { t } = useI18n('language_picker.options');
    const { ref: wrapperRef, isFocused: isOpen, setIsFocused } = useFocusable(
        false
    );

    const languages = React.useMemo(
        () =>
            LANGUAGES.map(
                key =>
                    ({
                        countryAlpha2: CountriesAlpha2[key],
                        key,
                        title: t(key),
                    } as ILanguageOption)
            ),
        [t]
    );

    const currentLanguage = React.useMemo(
        () => ({
            countryAlpha2: CountriesAlpha2[lang],
            key: lang,
            title: t(lang),
        }),
        [lang, t]
    );
    const handleSelect = React.useCallback((l: ILanguageOption) => {
        setLang(l.key);
        window.location.href = window.location.href.replace(/lang=..&?/, '');
    }, []);

    const handleClick = React.useCallback(() => setIsFocused(true), []);

    return {
        currentLanguage,
        handleClick,
        handleSelect,
        isOpen,
        languages,
        wrapperRef,
    };
};

export default useLanguagePicker;
