import { ApplicationDataContext } from 'lib/applicationDataContext';
import useBasketContent from 'lib/hooks/useBasketContent';
import useI18n from 'lib/hooks/useI18n';
import * as moment from 'moment';
import * as React from 'react';

const ERROR_MESSAGE_CUTOFF = -1 * 5 * 60 * 1000;

const remainingValid = (validUntilString: string | undefined): number => {
    if (validUntilString) {
        const validUntil = moment(validUntilString);
        const remaining = validUntil.diff(moment());
        return remaining;
    }
    return 1;
};

const BasketCheckerContainer = () => {
    console.log('[BasketChecker] Alive');
    const { basketId, expireBasket } = React.useContext(ApplicationDataContext);
    const { validUntil, error } = useBasketContent(basketId);
    const { t } = useI18n();

    const performCheck = () => {
        // This checker only should work if basketId is set, otherwise in risk of endless loop
        if (!basketId) return;

        if (error) {
            console.log(
                '[BasketChecker] Error loading basket, forgetting',
                error
            );
            // Do not show basked expiration message if there was error loading basket
            expireBasket();
            return;
        }
        const remaining = remainingValid(validUntil);
        if (remaining < 0) {
            console.log('[BasketChecker] Basket expired');
            // Show basked expiration message only if basket expired less then ERROR_MESSAGE_CUTOFF ago
            // * expired recently
            expireBasket(
                remaining > ERROR_MESSAGE_CUTOFF
                    ? t('basket.expired')
                    : undefined
            );
            return;
        }
    };

    React.useEffect(() => {
        const int = setInterval(performCheck, 500);
        return () => clearInterval(int);
    }, [basketId, validUntil, error]);

    return null;
};

export default BasketCheckerContainer;
