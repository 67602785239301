import * as React from 'react';
import './index.scss';

import Card, { ICard } from './CardListItem';

interface IProps<T extends ICard> {
    cardsData: T[];
    onChange: (card: T) => void;
    selectedCard?: T;
}

const CardList = <T extends ICard>({
    cardsData,
    onChange,
    selectedCard,
}: IProps<T>) => (
    <div className="CardList">
        {cardsData.map((card, i) => (
            <Card
                card={card}
                key={i}
                selected={!!selectedCard && card.id === selectedCard.id}
                onChange={onChange}
            />
        ))}
    </div>
);

export default CardList;
