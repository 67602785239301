import ErrorPage from '@slkit/Error';
import Narrow from '@slkit/Narrow';
import FormattedText from 'components/FormattedText';
import useI18n from 'lib/hooks/useI18n';
import Layout from 'modules/application/Layout';
import * as React from 'react';

const NotFoundPage = () => {
    const { t } = useI18n('not_found');
    return (
        <Layout>
            <div className="container">
                <div className="Page">
                    <Narrow>
                        <ErrorPage title={t('title')}>
                            <FormattedText>{t('message')}</FormattedText>
                        </ErrorPage>
                    </Narrow>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
