import PasswordHints from '@slkit/PasswordHints';
import { PasswordValidations_passwordValidations } from 'queries/__types__/PasswordValidations';
import * as React from 'react';
import usePasswordValidations from './usePasswordValidations';

interface IProps {
    onStrengthChange?: (strength: number) => void;
    password: string;
}

interface IStrengthCheckResult {
    check: PasswordValidations_passwordValidations;
    pass: boolean;
}

const PasswordStrengthCheck = ({ password, onStrengthChange }: IProps) => {
    const { loading, error, validations } = usePasswordValidations();

    const results: IStrengthCheckResult[] = React.useMemo(() => {
        if (!validations) return [];
        return validations.map(c => ({
            check: c,
            pass: password.match(c.pattern) !== null,
        }));
    }, [password, validations]);

    React.useEffect(() => {
        if (!onStrengthChange) return;
        if (!validations) return onStrengthChange(1);
        const passed = results.filter(r => r.pass);
        onStrengthChange(passed.length / validations.length);
    }, [results]);

    if (loading) return null;
    if (error) return null;

    return (
        <PasswordHints>
            {results.map((result, i) => (
                <div
                    key={i}
                    className={`PasswordHints__Item ${
                        result.pass ? 'PasswordHints__Item--disabled' : ''
                    }`}
                >
                    {result.check.name}
                </div>
            ))}
        </PasswordHints>
    );
};

export default PasswordStrengthCheck;
