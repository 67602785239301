import { PREMIUM_SEAT_RANK } from '@slkit/Bus/BusSeat';
import Seat from '@slkit/Seat';
import { formatPrice } from 'lib/helpers';
import keys from 'lodash/keys';
import * as React from 'react';
import { CurrencyEnum } from '../../../../graphql';
import { IJourneyPassengerSeatsInfo } from './useBusSeats';

interface IProps {
    passengers: IJourneyPassengerSeatsInfo;
    setSelected: (id: string) => void;
    selected?: string;
}

const Seats = ({ passengers, setSelected, selected }: IProps) => (
    <>
        {keys(passengers).map(key => {
            const seat = passengers[key].seat;
            return (
                <Seat
                    key={key}
                    checked={selected === key}
                    name="person-seat-radio"
                    value={seat.productId}
                    onChange={() => setSelected(key)}
                    seatNumber={seat.seatNumber}
                    seatPrice={
                        seat.seatPrice &&
                        formatPrice(
                            seat.seatPrice.amount as number,
                            seat.seatPrice.currency as CurrencyEnum
                        )
                    }
                    isPremium={seat.seatRank === PREMIUM_SEAT_RANK}
                    passengerName={seat.passengerName || ''}
                />
            );
        })}
    </>
);

export default Seats;
