import { IBusStopOption, IValue } from 'components/BusStopSelect/BusStopSelect';
import { TTravelPass } from 'components/TravelPassSelectModal';
import { ITripSearchFormState } from 'components/TripsSearchForm/TripsSearchFormContainer';

export const travelPassSearchFormValues = (
    pass: TTravelPass,
    state: ITripSearchFormState,
    stops: IBusStopOption[]
):
    | Pick<
          ITripSearchFormState,
          'stopFrom' | 'stopTo' | 'fareClasses' | 'dateBack'
      >
    | undefined => {
    const bonusScheme = pass.bonusSchemeGroupId;
    if (!bonusScheme) return;

    const pair = pass.validityZonePair;
    if (!pair) return;

    const validStopIds = [
        ...pair.firstZoneBusStops,
        ...pair.secondZoneBusStops,
    ].map(s => s.busStopId!);

    let stopFrom: IValue | undefined;
    let stopTo: IValue | undefined;

    // If currently selected stops are available for travelpass, do not change
    if (state.stopFrom && isValidStop(state.stopFrom, validStopIds, stops)) {
        stopFrom = state.stopFrom;
    } else {
        const stopId = pair.firstZoneBusStops.find(s => s.isMainStop)
            ?.busStopId;
        stopFrom = stopId ? { id: stopId, type: 'STOP' } : undefined;
    }

    if (state.stopTo && isValidStop(state.stopTo, validStopIds, stops)) {
        stopTo = state.stopTo;
    } else {
        const stopId = pair.secondZoneBusStops.find(s => s.isMainStop)
            ?.busStopId;
        stopTo = stopId ? { id: stopId, type: 'STOP' } : undefined;
    }

    if (!stopFrom || !stopTo) return;

    return {
        dateBack: null,
        fareClasses: { [bonusScheme]: 1 },
        stopFrom,
        stopTo,
    };
};

export const areStopsForTravelPass = (
    pass: TTravelPass,
    from: IValue | undefined,
    to: IValue | undefined,
    stops: IBusStopOption[]
): boolean => {
    const pair = pass.validityZonePair;

    // If both stops are undefined, return true
    if (!from && !to) return true;

    // If there is not validity pair, travel pass is probably valid for all?
    if (!pair) return true;

    const validStopIds = [
        ...pair.firstZoneBusStops,
        ...pair.secondZoneBusStops,
    ].map(s => s.busStopId!);

    if (!from || !to) {
        // If either of stops is missing, only validate the other to be present in either list
        const stop = (from || to) as IValue;
        return isValidStop(stop, validStopIds, stops);
    } else {
        // If both stops are present validate one is in one set and other is in other set
        // Stops can be in same set, in case user is trying to change to valid combination
        // (as in, after this change there will be next change)
        return (
            isValidStop(from, validStopIds, stops) &&
            isValidStop(to, validStopIds, stops)
        );
    }
};

const isValidStop = (
    stop: IValue,
    validStopIds: number[],
    stops: IBusStopOption[]
) => {
    return stop.type === 'CITY'
        ? isValidCityStop(stop, validStopIds, stops)
        : isValidStopStop(stop, validStopIds);
};

const isValidStopStop = (stop: IValue, validStopIds: number[]) => {
    return validStopIds.indexOf(stop.id) >= 0;
};

const isValidCityStop = (
    stop: IValue,
    validStopIds: number[],
    stops: IBusStopOption[]
) => {
    const stopOption = stops.find(s => s.type === 'CITY' && s.id === stop.id);
    if (!stopOption) return false;

    const optionStops = stopOption.stops.map(s => s.id);

    for (const stopId of validStopIds) {
        if (optionStops.indexOf(stopId) >= 0) return true;
    }

    return false;
};
