import { Observable } from "apollo-link";
import { ErrorResponse, onError } from "apollo-link-error";
import { currentUserMetaValue } from "components/Login/useCurrentUser";
import { GraphQLError } from 'graphql/error/GraphQLError';
import { promiseToObservable } from '../helpers';

interface SLGraphQLError extends GraphQLError {
    external_error_code?: number | null;
    external_error_http_status?: number | null;
    external_error_service?: string | null;
    status?: number;
}

const isExpiredSession = (error: ErrorResponse) => {
    const e = error.graphQLErrors?.[0] as SLGraphQLError | undefined;
    return e?.status === 401 || e?.external_error_http_status === 401;
};

const logout = (graphqlUri?: string) => {
    let logoutUri = '/users/sign_out.json';
    if(graphqlUri) {
        if (graphqlUri.indexOf('/graphql') >= 0) {
            logoutUri = graphqlUri.replace('/graphql', logoutUri);
        } else {
            logoutUri = graphqlUri.replace(/\/+$/, '') + logoutUri;
        }
    }

    return fetch(logoutUri, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const errorLink = (uri: string) => onError(errorResponse => {
    if (isExpiredSession(errorResponse)) {
        // If session is expired, log user out and repeat the action
        const { forward, operation } = errorResponse;

        return promiseToObservable(logout(uri)).flatMap(() => {
            // If there is currentUserMeta tag, page needs to be refreshed
            // after logout, to ensure correct user and csrf data are loaded
            //
            // Page should not be refreshed if there is no user, as we don't
            // want to refresh page if running from component in third-party page
            const shouldRefresh = currentUserMetaValue();

            if (shouldRefresh) {
                window.location.reload();
                return new Observable(s => s.complete());
            } else {
                return forward(operation);
            }
        });
    }
});
