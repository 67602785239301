import Footer from 'components/Footer';
import Navbar from 'components/Navbar/Navbar';
import * as React from 'react';

interface IProps {
    children: React.ReactNode;
}

const Layout = ({ children }: IProps) => (
    <>
        <Navbar />
        {children}
        <div id="fixed-notifications-container" />
        <Footer />
    </>
);

export default Layout;
