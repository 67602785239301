import Promo from '@slkit/Promo';
import FormattedText from 'components/FormattedText';
import { getErrorMessage } from 'lib/helpers';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

interface IProps {
    onSubmit: (code: string) => Promise<boolean>;
}

const PromoField = ({ onSubmit }: IProps) => {
    const { t } = useI18n('promo_field');
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState<Error | undefined>(undefined);

    const handleSubmit = () => {
        if (!value || value.length === 0) return;
        setError(undefined);

        onSubmit(value)
            .then(res => {
                if (res) {
                    setValue('');
                }
            })
            .catch(setError);
    };

    return (
        <Promo
            error={error && getErrorMessage(error)}
            info={
                <FormattedText wrapParagraphs>
                    {t('info-tooltip')}
                </FormattedText>
            }
            value={value}
            onChange={e => setValue(e.target.value)}
            onSubmit={handleSubmit}
            placeholder={t('placeholder')}
            submitTitle={t('apply-button-title')}
        />
    );
};

export default PromoField;
