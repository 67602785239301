import { ILanguageData } from './hooks/useLanguageData';

type TResult = ILanguageData | null;

class SLStringsStore {
    loadedStrings: { [key: string]: TResult } = {};
    pendingCallbacks: {
        [key: string]: {
            resolve: (data: TResult) => void;
            reject: (error: Error) => void;
        }[];
    } = {};

    fetchStrings(url: string) {
        console.log('[SLStringsStore] Fetch', url);
        return fetch(url)
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.log('[SLStringsStore] Data not present', url);
                }
            })
            .then(json => {
                if (!json) throw new Error('Data not present');
                console.log('[SLStringsStore] Loaded', url);

                const languageData = json as ILanguageData;
                this.loadedStrings[url] = languageData;

                this.pendingCallbacks[url].forEach(callbacks => {
                    callbacks.resolve(languageData);
                });
            })
            .catch(e => {
                console.log('[SLStringsStore] Could not load', url);
                console.log(e);

                this.pendingCallbacks[url].forEach(callbacks => {
                    callbacks.resolve(null);
                });
            })
            .finally(() => {
                delete this.pendingCallbacks[url];
            });
    }

    get(url: string): Promise<TResult> {
        if (this.loadedStrings[url]) {
            // If loaded already, return loaded data
            return Promise.resolve(this.loadedStrings[url]);
        }

        if (!!this.pendingCallbacks[url]) {
            // If there are pending requests, only add current
            return new Promise<TResult>((resolve, reject) => {
                this.pendingCallbacks[url].push({ resolve, reject });
            });
        }

        // If there are no pending requests, create pending list
        this.pendingCallbacks[url] = [];

        // Add current to pending
        const promise = new Promise<TResult>((resolve, reject) => {
            this.pendingCallbacks[url].push({ resolve, reject });
        });

        // Trigger fetch
        this.fetchStrings(url);

        return promise;
    }
}

const STORE = new SLStringsStore();

export const getStrings = (url: string): Promise<TResult> => STORE.get(url);
