import KioskFormPage from 'pages/KioskFormPage';
import KioskResultsPage from 'pages/KioskResultsPage';
import NotFoundPage from 'pages/NotFoundPage';
import PurchaseProcessPage from 'pages/PurchaseProcessPage';
import PurchaseSuccessPage from 'pages/PurchaseSuccessPage';
import SearchFormPage from 'pages/SearchFormPage';
import SearchResultsPage from 'pages/SearchResultsPage';
import TravelPassesPage from 'pages/TravelPassesPage';
import TravelPassPage from 'pages/TravelPassPage';
import * as React from 'react';
import { Route, Switch } from 'react-router';

const Routes = () => (
    <Switch>
        <Route path="/" exact component={SearchFormPage} />
        <Route path="/search" exact component={SearchResultsPage} />
        <Route path="/travel-passes" exact component={TravelPassesPage} />
        <Route
            path="/purchase/:step(summary|passengers|extra|payment)/:error?"
            exact
            component={PurchaseProcessPage}
        />
        <Route
            path="/purchase-success/:basketId"
            exact
            component={PurchaseSuccessPage}
        />

        <Route path="/travel_pass/:guid" exact component={TravelPassPage} />
        <Route path="/kiosk" exact component={KioskFormPage} />
        <Route path="/kiosk/search" exact component={KioskResultsPage} />

        <Route component={NotFoundPage} />
    </Switch>
);

export default Routes;
