import './index.scss';

import ModalBackdrop from '@slkit/Modal/ModalBackdrop';
import ModalContent from '@slkit/Modal/ModalContent';
import * as cx from 'classnames';
import * as React from 'react';

interface IProps {
    center?: boolean;
    fullscreen?: boolean;
    small?: boolean;
    white?: boolean;
    opaque?: boolean;
}

const Loader = ({ center, fullscreen, small, white, opaque }: IProps) => {
    const loader = (
        <div
            className={cx('Loader', {
                'Loader--center': center,
                'Loader--small': small,
                'Loader--white': white,
            })}
        >
            <div className="Loader__Wrapper">
                <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        className="length"
                        fill="none"
                        strokeWidth="8"
                        strokeLinecap="round"
                        cx="33"
                        cy="33"
                        r="28"
                    />
                </svg>
            </div>
        </div>
    );

    if (fullscreen) {
        return (
            <ModalBackdrop className="Loader__Modal" isActive opaque={opaque}>
                {opaque ? loader : <ModalContent>{loader}</ModalContent>}
            </ModalBackdrop>
        );
    }

    return loader;
};

export default Loader;
