import cx from 'classnames';
import * as React from 'react';

import PanelFooter from './PanelFooter';

interface IProps {
    buttons: Array<{
        title: React.ReactNode;
        onClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
        isPrimary?: boolean;
    }>;
}

const PanelActions = ({ buttons }: IProps) => (
    <PanelFooter style={{ padding: 0 }}>
        <div className="ButtonGroup">
            {buttons.map((btn, idx) => (
                <button
                    key={idx}
                    className={cx('Button', {
                        'Button--primary': btn.isPrimary === true,
                        'Button--red': btn.isPrimary !== true,
                        'Button--secondary': btn.isPrimary !== true,
                    })}
                    onClick={btn.onClick}
                >
                    {btn.title}
                </button>
            ))}
        </div>
    </PanelFooter>
);

export default PanelActions;
