import Icon, { ICON_NAME } from '@slkit/Icon';
import cx from 'classnames';
import * as React from 'react';
import './index.scss';

interface IProps {
    name?: string | null;
    from: string;
    primary?: boolean;
    to: string;
}

const JourneyPreset = ({
    className,
    name,
    from,
    primary,
    to,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & IProps) => {
    const pts = name ? name.split('>').map(s => s.trim()) : [from, to];

    return (
        <div
            className={cx('JourneyPreset', className, {
                'JourneyPreset--clickable': !!props.onClick,
                'JourneyPreset--primary': primary,
            })}
            {...props}
        >
            <span>{pts[0]}</span>
            {pts.slice(1).map((pt, i) => (
                <React.Fragment key={i}>
                    <Icon name={ICON_NAME.CHEVRON} />
                    <span>{pt}</span>
                </React.Fragment>
            ))}
        </div>
    );
};

export default JourneyPreset;
