import Icon, { ICON_NAME } from '@slkit/Icon';
import List from '@slkit/List';
import Modal from '@slkit/Modal';
import Radio from '@slkit/Radio';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

import {
    TravelAccountTravelPassesQuery,
    TravelPassCategoryEnum,
    useTravelAccountTravelPassesQuery,
} from '../../../graphql';

interface IProps {
    onClose?: () => void;
    onSelect?: (travelPass: TTravelPass) => void;
}

export type TTravelPass = NonNullable<
    TravelAccountTravelPassesQuery['me']
>['travelPasses'][0];

const PassLabel = ({ pass }: { pass: TTravelPass }) => (
    <div>
        <div>
            <div className="text-lg">
                <strong>
                    {pass.name} ({pass.bonusSchemeGroupName})
                </strong>
            </div>
        </div>
        <div>
            <div className="flex items-center text-sm">
                <span>{pass.validityZonePair!.firstZoneName}</span>
                <Icon
                    name={ICON_NAME.SWITCH}
                    className="text-primary-red ml-1/4 mr-1/4"
                />
                <span>{pass.validityZonePair!.secondZoneName}</span>
            </div>
        </div>
    </div>
);

const TravelPassSelectModal = ({ onClose, onSelect }: IProps) => {
    const { i, t } = useI18n('travelpass_select.modal');
    const { data, loading, error } = useTravelAccountTravelPassesQuery({
        variables: {
            category: TravelPassCategoryEnum.CURRENT,
        },
    });

    const passes = React.useMemo(() => {
        if (!data || !data.me) return [];
        return data.me.travelPasses;
    }, [data]);

    const [selectedPass, setSelectedPass] = React.useState<
        TTravelPass | undefined
    >(undefined);

    // When there is exactly one pass available, select it
    React.useEffect(() => {
        if (passes.length === 1) {
            setSelectedPass(passes[0]);
        }
    }, [passes]);

    const content = (() => {
        if (loading) return <LoadingIndicator fullscreen={false} center />;

        if (error) return <ErrorMessage error={error} fixed noPortal />;

        if (!data || !data.me) return null;

        const count = passes.length;

        if (count === 0) {
            return <div>{t('no-travelpass')}</div>;
        } else {
            return (
                <>
                    <div>
                        {i(t('info-message', count), {
                            count,
                        })}
                    </div>
                    <List className="mt-2">
                        {count === 1 ? (
                            <PassLabel pass={passes[0]} />
                        ) : (
                            passes.map((pass, idx) => (
                                <div className="ListItem" key={idx}>
                                    <Radio
                                        label={<PassLabel pass={pass} />}
                                        checked={pass === selectedPass}
                                        onChange={() => setSelectedPass(pass)}
                                    />
                                </div>
                            ))
                        )}
                    </List>
                </>
            );
        }
    })();

    return (
        <Modal
            onClose={onClose}
            title={t('title')}
            isActive={true}
            buttons={
                <div className="ButtonGroup">
                    <button
                        className="Button Button--secondary"
                        onClick={onClose}
                    >
                        {t('cancel')}
                    </button>
                    {passes.length > 0 && (
                        <button
                            className="Button"
                            disabled={!selectedPass}
                            onClick={() => onSelect && onSelect(selectedPass!)}
                        >
                            {t('confirm')}
                        </button>
                    )}
                </div>
            }
        >
            <div className="Modal__Item">{content}</div>
        </Modal>
    );
};

export default TravelPassSelectModal;
