import Check from '@slkit/Check';
import Icon, { ICON_NAME } from '@slkit/Icon';
import Radio from '@slkit/Radio';
import SupportedCards from 'components/AddCreditCardDialog/SupportedCards';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import './index.scss';

export enum PaymentType {
    CREDIT_CARD = 'CREDIT_CARD',
    PAYPAL = 'PAYPAL',
    APAY = 'APAY',
    GPAY = 'GPAY',
    VOUCHER = 'VOUCHER',
    ZERO_PAYMENT = 'ZERO_PAYMENT',
    B2B = 'B2B',
}

interface IProps {
    active?: boolean;
    paymentType: PaymentType;
    onSelect: () => void;
}

const PaymentMethod = ({ active = false, onSelect, paymentType }: IProps) => {
    const { t } = useI18n('payment_method');

    const icons = () => {
        switch (paymentType) {
            case PaymentType.CREDIT_CARD:
                return <SupportedCards />;
            case PaymentType.PAYPAL:
                return <Icon name={ICON_NAME.PAYPAL} rect />;
            case PaymentType.GPAY:
                return <Icon name={ICON_NAME.GPAY} rect />;
            case PaymentType.APAY:
                return <Icon name={ICON_NAME.APPLE_PAY} rect />;
            case PaymentType.VOUCHER:
                return <Icon name={ICON_NAME.VOUCHER} rect />;
            default:
                return null;
        }
    };

    return (
        <div className="PaymentMethod">
            <div className="PaymentMethod__Radio">
                {paymentType === PaymentType.VOUCHER ? (
                    <Check
                        label={t(paymentType)}
                        isChecked={active}
                        onChange={() => onSelect()}
                    />
                ) : (
                    <Radio
                        label={t(paymentType)}
                        checked={active}
                        onChange={() => onSelect()}
                    />
                )}
            </div>
            {icons()}
        </div>
    );
};

export default PaymentMethod;
