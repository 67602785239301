import Maybe from 'graphql/tsutils/Maybe';
import { formatPrice } from 'lib/helpers';
import { IBasketEntry } from 'lib/hooks/useBasketEntries';
import {
    AddonFragment,
    BasketContentQuery,
    JourneyInfoFragment,
    PassengerJourneysFragment,
} from '../../../graphql';
import { IBasketServiceEntry } from './BasketServiceEntry';

const isSameTrip = (
    a: ITripData | undefined,
    b: ITripData | undefined
): boolean => {
    if (a === undefined && b === undefined) return true;
    if (a === undefined || b === undefined) return false;

    if (a.arrivalBusStop !== b.arrivalBusStop) return false;
    if (a.arrivalDateTime !== b.arrivalDateTime) return false;
    if (a.departureBusStop !== b.departureBusStop) return false;
    if (a.departureStopCityName !== b.departureStopCityName) return false;
    if (a.departureDateTime !== b.departureDateTime) return false;
    if (a.destinationStopCityName !== b.destinationStopCityName) return false;
    if (a.isReinforcement !== b.isReinforcement) return false;

    return true;
};

const isSameEntry = (a: IEntryData, b: IEntryData): boolean => {
    if (!isSameTrip(a.inboundTripData, b.inboundTripData)) return false;
    if (!isSameTrip(a.outboundTripData, b.outboundTripData)) return false;
    return true;
};

export const buildTripData = (
    journey: Maybe<JourneyInfoFragment>,
    ticketNumber: string | null,
    basketNumber: string | null | undefined
): ITripData | undefined => {
    if (!journey || !journey.legs || journey.legs.length === 0) {
        return undefined;
    }

    const firstLeg = journey.legs[0];
    const lastLeg = journey.legs[journey.legs.length - 1];

    return {
        arrivalBusStop: lastLeg.destinationStopName!,
        arrivalDateTime: lastLeg.arrivalDateTime as string,
        basketNumber: basketNumber || null,
        departureBusStop: firstLeg.departureStopName!,
        departureDateTime: firstLeg.departureDateTime as string,
        departureStopCityName: firstLeg.departureStopCityName as string,
        destinationStopCityName: firstLeg.destinationStopCityName as string,
        // Platform is not available from basket content
        // departurePlatform: firstLeg.departurePlatform;
        details: '',
        isReinforcement: firstLeg.isReinforcement,
        price: '',
        qrCodeUrl: firstLeg.qrCodeUrl || undefined,
        ticketNumber,
    };
};

interface IEntryData {
    inboundTripData?: ITripData;
    outboundTripData: ITripData;
    passengers: PassengerJourneysFragment[];
}

export const buildBasketEntries = (
    content: BasketContentQuery['basket']['content']
): IBasketEntry[] => {
    if (!content || !content.passengers) return [];

    const data: IEntryData[] = [];

    // As ticket number is used productCode of first passenger
    // This number is only valid for one ticket. If all numbers should be used,
    // design would need to change.
    content.passengers.forEach(passenger => {
        const pData: IEntryData = {
            inboundTripData: buildTripData(
                passenger.inboundJourney,
                passenger.productCode || null,
                content.shoppingBasketNumber
            ),
            outboundTripData: buildTripData(
                passenger.outboundJourney,
                passenger.productCode || null,
                content.shoppingBasketNumber
            )!,
            passengers: [passenger],
        };

        const existing: IEntryData | undefined = data.find(e =>
            isSameEntry(e, pData)
        );

        if (existing) {
            existing.passengers.push(passenger);
        } else {
            data.push(pData);
        }
    });

    return data.map(d => {
        const fareClasses: { [key: string]: number } = {};
        let inboundSum = 0;
        let inboundDiscount = 0;
        let outboundSum = 0;
        let outboundDiscount = 0;

        const inboundAddons: AddonFragment[] = [];
        const outboundAddons: AddonFragment[] = [];

        d.passengers.map(p => {
            if (fareClasses[p.fareClass!]) {
                fareClasses[p.fareClass!] += 1;
            } else {
                fareClasses[p.fareClass!] = 1;
            }

            if (p.inboundJourney) {
                if (p.inboundJourney.totalPrice) {
                    inboundSum += p.inboundJourney.totalPrice;
                    if (p.inboundJourney.legs) {
                        p.inboundJourney.legs.forEach(leg => {
                            inboundDiscount += leg.discount || 0;
                            inboundAddons.push(...leg.addOns);
                        });
                    }
                }
            }

            if (p.outboundJourney && p.outboundJourney.totalPrice) {
                outboundSum += p.outboundJourney.totalPrice;
                if (p.outboundJourney.legs) {
                    p.outboundJourney.legs.forEach(leg => {
                        outboundDiscount += leg.discount || 0;
                        outboundAddons.push(...leg.addOns);
                    });
                }
            }
        });

        const baseDetails = Object.keys(fareClasses).map(
            k => `${fareClasses[k]}x ${k}`
        );

        const buildAddonDetails = (addons: AddonFragment[]): string[] => {
            if (addons.length === 0) return [];
            return addons.map(a => `${a.quantity}x ${a.name}`);
        };
        const iAddonsDetails = buildAddonDetails(inboundAddons);
        const oAddonsDetails = buildAddonDetails(outboundAddons);

        return {
            inboundTripData: d.inboundTripData
                ? {
                      ...d.inboundTripData,
                      details: [...baseDetails, ...iAddonsDetails].join(', '),
                      discount:
                          inboundDiscount > 0
                              ? formatPrice(inboundDiscount, content.currency!)
                              : undefined,
                      price: formatPrice(inboundSum, content.currency!),
                  }
                : undefined,
            outboundTripData: {
                ...d.outboundTripData,
                details: [...baseDetails, ...oAddonsDetails].join(', '),
                discount:
                    outboundDiscount > 0
                        ? formatPrice(outboundDiscount, content.currency!)
                        : undefined,
                price: formatPrice(outboundSum, content.currency!),
            },
            products: d.passengers,
        } as IBasketEntry;
    });
};

export const buildBasketServiceEntries = (
    basket: BasketContentQuery['basket']
): IBasketServiceEntry[] => {
    if (!basket.travelPassesByGuid) return [];

    return basket.travelPassesByGuid.map(
        service =>
            ({
                fareClass: service.bonusSchemeGroupName,
                fareClassId: service.bonusSchemeGroupId,
                from:
                    service.validityZonePair &&
                    service.validityZonePair.firstZoneName,
                name: service.name!,
                number: service.number,
                price: formatPrice(service.price!, basket.content.currency!),
                priceValue: service.price,
                currency: basket.content.currency,
                to:
                    service.validityZonePair &&
                    service.validityZonePair.secondZoneName,
            } as IBasketServiceEntry)
    );
};
