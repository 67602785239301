import { ApolloError } from 'apollo-client';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import { BuyerInfo } from 'queries/__types__/BuyerInfo';
import * as query from 'queries/BuyerInfo.graphql';
import * as React from 'react';

interface IBuyerInfoResult {
    loading: boolean;
    data?: BuyerInfo;
    error?: ApolloError;
    reload?: () => Promise<any>;
}

export default function useBuyerInfo(basketId?: string) {
    const {
        apolloClient: client,
        basketId: contextBasketId,
    } = React.useContext(ApplicationDataContext);

    const id = basketId || contextBasketId;

    const [data, setData] = React.useState<IBuyerInfoResult>({
        loading: id ? true : false,
    });

    React.useEffect(() => {
        if (!client || !id) return;

        const variables = { id };
        const q = client.watchQuery<BuyerInfo>({ query, variables });

        q.subscribe({
            error: error => {
                setData({
                    error: error as ApolloError,
                    loading: false,
                });
            },
            next: d => {
                setData({
                    data: d.data,
                    loading: false,
                    reload: () => {
                        console.log('reloading buyer info');
                        return q.refetch(variables);
                    },
                });
            },
        });
    }, [client]);

    return data;
}
