import Check from '@slkit/Check';
import Icon, { ICON_NAME } from '@slkit/Icon';
import IconBadge from '@slkit/IconBadge';
import * as cx from 'classnames';
import LoadingIndicator from 'components/LoadingIndicator';
import { IAdyenCheckoutCreateOptions } from 'lib/@adyen/adyen';
import useAdyenCardFormReducer, {
    Action,
    TCardFormFieldKey,
} from 'lib/@adyen/useAdyenCardFormReducer';
import useAdyenCheckout from 'lib/@adyen/useAdyenCheckout';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';

export interface ICardData {
    encryptedCardNumber: string;
    encryptedExpiryMonth: string;
    encryptedExpiryYear: string;
    encryptedSecurityCode: string;
    remember: boolean;
}

export type CardDataCallback = (data: ICardData | null) => void;

interface IProps {
    className?: string;
    onCardData?: CardDataCallback;
    remember?: boolean;
}

const CARD_ICONS: {
    [key: string]: ICON_NAME;
} = {
    mc: ICON_NAME.MASTERCARD,
    visa: ICON_NAME.VISA,
};

export const ADYEN_INPUT_STYLES = {
    base: {
        color: '#000000',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
    },
    placeholder: {
        color: 'rgba(99, 111, 134, 0.64)',
    },
};

const AdyentCardPaymentMethod = ({
    className,
    onCardData,
    remember: rememberOverride,
}: IProps) => {
    const { t } = useI18n('payment.card');
    const [remember, setRemember] = React.useState(rememberOverride || false);
    const [state, dispatch] = useAdyenCardFormReducer();
    const checkout = useAdyenCheckout();

    React.useEffect(() => {
        if (!onCardData) return;
        if (state.cardData) {
            onCardData({ ...state.cardData, remember });
        } else {
            onCardData(null);
        }
    }, [state.allValid, state.data, remember]);

    React.useEffect(() => {
        if (!checkout) return;

        const cfg: IAdyenCheckoutCreateOptions = {
            onAllValid: obj =>
                dispatch({
                    payload: { valid: obj.allValid },
                    type: Action.ALL_VALID,
                }),
            onBrand: brand =>
                dispatch({
                    payload: { brand },
                    type: Action.SET_BRAND,
                }),
            onConfigSuccess: () => dispatch({ type: Action.INITIALIZED }),
            onError: p => dispatch({ payload: p, type: Action.ERROR }),
            onFieldValid: p => dispatch({ payload: p, type: Action.DATA }),
            placeholders: {
                encryptedCardNumber: t('card-number-placeholder'),
                encryptedExpiryDate: t('expiration-date-placeholder'),
                encryptedSecurityCode: t('cvv-placeholder'),
            },
            styles: ADYEN_INPUT_STYLES,
            type: 'card',
        };

        try {
            checkout
                .create('securedfields', cfg)
                .mount('.js-chckt-pm__pm-holder');
            dispatch({ type: Action.INITIALIZING });
        } catch (e) {
            console.log('Could not initialize card form');
            console.log(e);
        }
    }, [checkout]);

    const fieldError = (key: TCardFormFieldKey) => {
        const e = state.errors[key];
        if (!e) return null;
        return e.i18n || e.error;
    };

    const cardIcon: ICON_NAME = state.brand
        ? CARD_ICONS[state.brand.brand] || ICON_NAME.CARD
        : ICON_NAME.CARD;

    const encryptedCardNumberError = fieldError('encryptedCardNumber');
    const encryptedExpiryDateError = fieldError('encryptedExpiryDate');
    const encryptedSecurityCodeError = fieldError('encryptedSecurityCode');

    return (
        <>
            {state.initializing && (
                <LoadingIndicator center fullscreen={false} />
            )}
            <div
                className={cx('js-chckt-pm__pm-holder', className)}
                style={{ display: state.initialized ? 'block' : 'none' }}
            >
                <input type="hidden" name="txvariant" value="card" />
                <div
                    className={cx('InputField InputField--card', {
                        'InputField--invalid': encryptedCardNumberError,
                    })}
                >
                    <div className="InputField__Card">
                        {cardIcon === ICON_NAME.CARD ? (
                            <Icon name={cardIcon} />
                        ) : (
                            <IconBadge>
                                <Icon name={cardIcon} rect />
                            </IconBadge>
                        )}
                    </div>
                    <div
                        className="InputField__Input"
                        data-cse="encryptedCardNumber"
                    />
                    {encryptedCardNumberError && (
                        <div className="InputField__Message">
                            {encryptedCardNumberError}
                        </div>
                    )}
                </div>
                <div className="Grid">
                    <div className="Grid__Column Grid__Column--1 Grid__Column--padded">
                        <div
                            className={cx('InputField', {
                                'InputField--invalid': encryptedExpiryDateError,
                            })}
                        >
                            <div
                                className="InputField__Input"
                                data-cse="encryptedExpiryDate"
                            />
                            {encryptedExpiryDateError && (
                                <div className="InputField__Message">
                                    {encryptedExpiryDateError}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="Grid__Column Grid__Column--1">
                        <div
                            className={cx('InputField', {
                                'InputField--invalid': encryptedSecurityCodeError,
                            })}
                        >
                            <div
                                className="InputField__Input"
                                data-cse="encryptedSecurityCode"
                            />
                            <div className="InputField__Info">
                                <Icon name={ICON_NAME.INFO} />
                            </div>
                            {encryptedSecurityCodeError && (
                                <div className="InputField__Message">
                                    {encryptedSecurityCodeError}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {rememberOverride === undefined && (
                    <Check
                        isChecked={remember}
                        label={t('remember-card')}
                        onChange={setRemember}
                    />
                )}
            </div>
        </>
    );
};

export default AdyentCardPaymentMethod;
