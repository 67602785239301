import { IBasketServiceEntry } from 'components/Basket/BasketServiceEntry';
import { IBusStopOption } from 'components/BusStopSelect/BusStopSelect';
import { IParsedQueryParams } from 'components/SearchResult/useQueryParams';
import { parseCurrencyXc } from 'lib/helpers';
import forEach from 'lodash/forEach';
import moment from 'moment';
import {
    JourneyFragmentFragment,
    LegAddonFragment,
    LegInfo,
} from '../../../graphql';
import {
    isBasketEntry,
    isBasketServiceEntry,
    isBusSeatProduct,
    isJourneyFragment,
    isLegAddon,
    TAnalyticsBusSeatFragment,
    TAnalyticsProductInput,
} from './useGTM';

interface IFBPEventOptions {
    content_category?: string;
    content_ids?: (number | string)[];
    content_name?: string;
    content_type?: 'product' | 'product_group';
    contents?: { id: string; quantity: number }[];
    currency?: string;
    num_items?: number;
    predicted_ltv?: number;
    search_string?: string;
    status?: boolean;
    value?: number;
}

const FBP_DEFAULT_CURRENCY = 'EUR';

const fbpLogEvent = (eventName: string, options?: IFBPEventOptions) => {
    console.log('[fbpLogEvent]', eventName, options);
    if (!window.fbq) {
        console.log('[fbpLogEvent] Not initialized');
        return;
    }
    window.fbq('track', eventName, options);
};

// Return `{from} - {to}`
export const buildSearchString = (
    data: IParsedQueryParams,
    stops: IBusStopOption[]
): string => {
    const stopOptionFrom = stops.find(
        s => s.id === data.from.id && s.type === data.from.type
    );

    const stopOptionTo = stops.find(
        s => s.id === data.to.id && s.type === data.to.type
    );

    return `${stopOptionFrom?.label} - ${stopOptionTo?.label}`;
};

// This one needs to return promise to redirect can be halted to let
// report time to process request
export const fbpLogSearch = (searchString: string): Promise<void> => {
    console.log('[fbpLogSearch]', searchString);
    return new Promise(res => {
        fbpLogEvent('Search', { search_string: searchString });
        setTimeout(() => res(), 1500);
    });
};

export const fbpLogSearchResults = (searchString: string) => {
    fbpLogEvent('SearchResults', { content_name: searchString });
};

const logAddToCart = (ids: string[], currency: string, value: number) =>
    fbpLogEvent('AddToCart', {
        content_ids: ids,
        content_name: ids[0],
        content_type: 'product',
        currency,
        value,
    });

export const fbpLogAddToCart = (
    iProducts: TAnalyticsProductInput[],
    quantity?: number
) => {
    console.log('[fbpLogAddToCart]', iProducts, quantity);
    forEach(iProducts, item => {
        if (item === null || item === undefined) return;

        if (isJourneyFragment(item)) {
            const ids = buildContentIds([item]);

            forEach(item.legs, leg => {
                forEach(leg.passengers, passenger => {
                    logAddToCart(ids, item.currency, passenger.finalPrice);
                });
            });
        } else if (isLegAddon(item)) {
            const ids = buildContentIds([item]);
            const price = (item.unitPrice ?? 0) * (quantity ?? 1);
            const currency =
                parseCurrencyXc(item.currencyXc || '') || FBP_DEFAULT_CURRENCY;
            logAddToCart(ids, currency, price);
        } else if (isBusSeatProduct(item)) {
            const ids = buildContentIds([item]);
            const price = item.seatPrice?.amount || 0;
            const currency = item.seatPrice?.currency || FBP_DEFAULT_CURRENCY;
            logAddToCart(ids, currency, price);
        }
    });
};

export const fbpLogCheckoutStep = (
    iProducts: TAnalyticsProductInput[],
    step: number,
    currency: string = FBP_DEFAULT_CURRENCY,
    basketPrice: number = 0
) => {
    console.log('[fbpLogCheckoutStep]', iProducts, step, currency, basketPrice);
    const ids = buildContentIds(iProducts);

    const eventName = [
        'InitiateCheckout',
        'CheckoutPassengers',
        'CheckoutAddons',
        'CheckoutPayment',
    ][step];
    if (eventName) {
        fbpLogEvent(eventName, {
            content_ids: ids,
            currency,
            num_items: ids.length,
            value: basketPrice,
        });
    }
};

const logPurchase = (id: string, currency: string, value: number) =>
    fbpLogEvent('Purchase', {
        content_ids: [id],
        content_name: id,
        content_type: 'product',
        currency,
        num_items: 1,
        value,
    });

export const fbpLogPurchase = (
    iProducts: TAnalyticsProductInput[],
    currency: string
) => {
    console.log('[fbpLogPurchase]', iProducts, currency);
    forEach(iProducts, item => {
        if (isBasketEntry(item)) {
            forEach(item.products, product => {
                const outLeg = product.outboundJourney?.legs?.[0];
                if (outLeg) {
                    const id = buildContentIdFromLegInfo(outLeg);
                    logPurchase(id, currency, outLeg.price || 0);
                }
                const inLeg = product.inboundJourney?.legs?.[0];
                if (inLeg) {
                    const id = buildContentIdFromLegInfo(inLeg);
                    logPurchase(id, currency, inLeg.price || 0);
                }
            });
        } else if (isBasketServiceEntry(item)) {
            const id = buildContentIdFromServiceEntry(item);
            logPurchase(id, currency, item.priceValue || 0);
        }
    });
};

const buildContentIdFromJourneyFragment = (
    item: JourneyFragmentFragment
): string => {
    const depTime = moment
        .tz(item.departureDateTime, item.departureDateTimeTimeZone)
        .format('HH:mm');
    const arrTime = moment
        .tz(item.arrivalDateTime, item.arrivalDateTimeTimeZone)
        .format('HH:mm');
    return `${item.originStopName} - ${item.destinationStopName} - ${depTime} - ${arrTime}`;
};

const buildContentIdFromLegInfo = (
    item: Pick<
        LegInfo,
        | 'arrivalDateTime'
        | 'departureDateTime'
        | 'departureStopName'
        | 'destinationStopName'
    >
): string => {
    const depTime = moment(item.departureDateTime).format('HH:mm');
    const arrTime = moment(item.arrivalDateTime).format('HH:mm');
    return `${item.departureStopName} - ${item.destinationStopName} - ${depTime} - ${arrTime}`;
};

const buildContentIdFromServiceEntry = (item: IBasketServiceEntry): string => {
    return `${item.name} - ${item.from} - ${item.to} - ${item.fareClass}`;
};

const buildContentIdFromLegAddon = (item: LegAddonFragment): string => {
    return `${item.serviceName}`;
};

const buildContentIdFromSeatProduct = (
    item: TAnalyticsBusSeatFragment
): string => {
    return `Seat ${item.seatRank} - ${item.seatNumber}`;
};

const buildContentIds = (items: TAnalyticsProductInput[]): string[] => {
    const res: string[] = [];

    forEach(items, item => {
        if (item === null || item === undefined) return;

        if (isJourneyFragment(item)) {
            res.push(buildContentIdFromJourneyFragment(item));
        } else if (isBasketEntry(item)) {
            forEach(item.products, product => {
                const outLeg = product.outboundJourney?.legs?.[0];
                if (outLeg) {
                    res.push(buildContentIdFromLegInfo(outLeg));
                }
                const inLeg = product.inboundJourney?.legs?.[0];
                if (inLeg) {
                    res.push(buildContentIdFromLegInfo(inLeg));
                }
            });
        } else if (isBasketServiceEntry(item)) {
            res.push(buildContentIdFromServiceEntry(item));
        } else if (isLegAddon(item)) {
            res.push(buildContentIdFromLegAddon(item));
        } else if (isBusSeatProduct(item)) {
            res.push(buildContentIdFromSeatProduct(item));
        }
    });

    return res;
};
