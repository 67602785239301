import cx from 'classnames';
import * as React from 'react';
// import './index.scss'; Should use styles from Tabs

interface IProps {
    children?: React.ReactNode;
    small?: boolean;
}

const GenericTabs = ({ children, small = false }: IProps) => (
    <div
        className={cx({
            Tabs: !small,
            TabsSmall: small,
        })}
    >
        {children}
    </div>
);

export default GenericTabs;
