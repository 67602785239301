import Footer from '@slkit/Footer';
import LanguagePicker from 'components/LanguagePicker';
import SupportCenterLink from 'components/SupportCenterLink';
import * as React from 'react';

const index = () => (
    <Footer>
        <LanguagePicker direction="up" />
        <SupportCenterLink />
    </Footer>
);

export default index;
