import Button from '@slkit/Button';
import NarrowText from '@slkit/Narrow/NarrowText';
import CSRFFields from 'components/CSRFFields';
import { ApplicationDataContext } from 'lib/applicationDataContext';
import useI18n from 'lib/hooks/useI18n';
import * as React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import useFacebookAppId from './useFacebookAppId';

interface IFacebookResponse {
    accessToken?: string;
    email?: string;
    id?: string;
    name?: string;
    signedRequest?: string;
    userId?: string;
}

interface IProps {
    className?: string;
    showInfo?: boolean;
}

const FacebookLoginButton = ({ className, showInfo = false }: IProps) => {
    const appId = useFacebookAppId();
    const { t } = useI18n('facebook-login-button');
    const { basketId } = React.useContext(ApplicationDataContext);

    if (!appId) return null;

    const formRef = React.useRef<HTMLFormElement | null>(null);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const handleLoginResult = (data: IFacebookResponse) => {
        if (
            !data.email ||
            !data.accessToken ||
            !formRef.current ||
            !inputRef.current
        ) {
            return;
        }
        inputRef.current.value = data.accessToken;
        formRef.current.submit();
    };

    return (
        <>
            <form action="/users/sign_in" method="post" ref={formRef}>
                <CSRFFields />
                <input type="hidden" name="user[token]" ref={inputRef} />
                <input type="hidden" name="basket_id" value={basketId} />
            </form>
            <FacebookLogin
                appId={appId}
                callback={handleLoginResult}
                fields="name,email"
                render={renderProps => (
                    <Button
                        className={className}
                        full
                        facebook
                        disabled={
                            renderProps.isDisabled ||
                            renderProps.isProcessing ||
                            !renderProps.isSdkLoaded
                        }
                        onClick={renderProps.onClick}
                    >
                        {t('action-title')}
                    </Button>
                )}
            />
            {showInfo && <NarrowText isCenter>{t('info-text')}</NarrowText>}
        </>
    );
};

export default FacebookLoginButton;
