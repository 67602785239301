import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import React from 'react';

interface IProps {
    name: string;
    price: string;
    from?: string;
    to?: string;
    fareClass?: string;
    onSearch?: () => any;
    searchTitle?: string;
}

const TravelPass = ({
    name,
    price,
    from,
    to,
    fareClass,
    onSearch,
    searchTitle,
}: IProps) => (
    <div className="TravelPass">
        <div className="TravelPass__Container">
            <div>
                <div className="TravelPass__Title TravelPass__ImportantText">
                    {name}
                </div>
                {from && to && (
                    <div className="TravelPass__SubtleText">
                        <span>{from}</span>
                        <Icon name={ICON_NAME.SWITCH} />
                        <span>{to}</span>
                    </div>
                )}
            </div>

            <div>
                <div className="TravelPass__Title TravelPass__ImportantText">
                    {price}
                </div>
                {fareClass && (
                    <div className="TravelPass__SubtleText">
                        <span>1x {fareClass}</span>
                    </div>
                )}
            </div>
        </div>

        {onSearch && (
            <div className="TravelPass__Button">
                <div className="ButtonGroup">
                    <button
                        className="Button Button--primary content-center"
                        style={{ display: 'inline-flex' }}
                        onClick={onSearch}
                    >
                        <Icon
                            name={ICON_NAME.MAGNIFIER}
                            style={{ marginRight: '0.5rem' }}
                        />
                        <span>{searchTitle}</span>
                    </button>
                </div>
            </div>
        )}
    </div>
);

export default TravelPass;
