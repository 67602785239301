import Dialog, { DialogHeader } from '@slkit/Dialog';
import Icon, { ICON_NAME } from '@slkit/Icon';
import SearchFormCell from '@slkit/SearchForm/SearchFormCell';
import SearchFormCellContent from '@slkit/SearchForm/SearchFormCellContent';
import TicketType from '@slkit/TicketType';
import { formatFareClasses } from 'lib/helpers';
import useFareClasses from 'lib/hooks/useFareClasses';
import useFocusable from 'lib/hooks/useFocusable';
import useI18n from 'lib/hooks/useI18n';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import { FareClasses_fareClasses } from 'queries/__types__/FareClasses';
import * as React from 'react';

import useFareClassesOptions from './useFareClassesOptions';

const totalCount = (value: IFareClassesValue) =>
    reduce(value, (total, current) => total + current, 0);

export interface IFareClassesValue {
    [key: string]: number;
}

interface IProps {
    changeOnUpdate?: boolean;
    onChange: (values: any) => Promise<boolean>;
    title?: string;
    value: IFareClassesValue;
}

export interface IFareClassOption {
    id: string;
    label: string;
    value: FareClasses_fareClasses;
}

const FareClassesSelect = ({
    changeOnUpdate,
    onChange,
    title,
    value,
}: IProps) => {
    const { t } = useI18n('search_form');
    const { ref, isFocused, setIsFocused } = useFocusable(false);
    const { error, loading } = useFareClasses();

    const [tempValue, setTempValue] = React.useState<IFareClassesValue>(value);

    const filled = totalCount(value) > 0;

    // When value changes, update internal temp value
    React.useEffect(() => {
        if (!isEqual(value, tempValue)) setTempValue(value);
    }, [value]);

    // When internal value changes and changeOnUpdate is set, call onChange
    React.useEffect(() => {
        if (changeOnUpdate && !isEqual(value, tempValue)) {
            // onChange can return false, in that case, reset tempValue to value
            onChange(tempValue).then(res => {
                if (!res) setTempValue(value);
            });
        }
    }, [tempValue, changeOnUpdate]);

    // When input is unfocused and value changed
    // call onChange with internal value
    React.useEffect(() => {
        if (!isFocused && !isEqual(tempValue, value)) {
            // onChange can return false, in that case, reset tempValue to value
            onChange(tempValue).then(res => {
                if (!res) setTempValue(value);
            });
        }
    }, [isFocused]);

    const handleTempChange = (id: string, count: number) => {
        if (tempValue[id] === count) return;
        setTempValue({
            ...tempValue,
            [id]: count,
        });
    };

    // Memoize options objects
    const options = useFareClassesOptions();
    const text = formatFareClasses(value, options) || <span>&nbsp;</span>;

    const label = title || t('ticket-type.placeholder');
    const handleClose = () => setIsFocused(false);

    return (
        <SearchFormCell
            active={isFocused}
            disabled={loading || !!error}
            filled={!isFocused && filled}
            ref={ref}
        >
            <div className="SearchForm__Dialog SearchForm__Dialog--FareClasses">
                <div
                    className="SearchForm__DialogBackdrop"
                    onClick={handleClose}
                />
                <Dialog>
                    <DialogHeader onClose={handleClose}>{label}</DialogHeader>
                    <div>
                        {options.map(option => (
                            <TicketType
                                key={option.id}
                                title={option.label}
                                count={tempValue[option.id] || 0}
                                onCountChange={n =>
                                    handleTempChange(option.id, Math.max(0, n))
                                }
                            />
                        ))}
                    </div>
                </Dialog>
            </div>
            <Icon name={ICON_NAME.SEARCH_TARIFS} />
            <SearchFormCellContent
                contentTitle={text}
                text={label}
                onClick={() => setIsFocused(true)}
            />
        </SearchFormCell>
    );
};

export default FareClassesSelect;
