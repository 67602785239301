import Tabs, { TabType } from '@slkit/Tabs';
import { JourneysInput, PriceClassEnum } from '__types__/globalTypes';
import useBusStopsOptions from 'components/BusStopSelect/useBusStopsOptions';
import ErrorMessage from 'components/ErrorMessage';
import { useKioskContext } from 'components/Kiosk/KioskLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import appPrompt from 'lib/appPrompt';
import {
    ApplicationDataContext,
    IApplicationDataContext,
} from 'lib/applicationDataContext';
import { getAvailableDates } from 'lib/helpers/getAvailableDates';
import useBasketContent from 'lib/hooks/useBasketContent';
import { fbpLogAddToCart } from 'lib/hooks/useFBPixel';
import { gtmLogAddToCart } from 'lib/hooks/useGTM';
import useLoading from 'lib/hooks/useLoading';
import { useHistory } from 'lib/hooks/useRouter';
import * as moment from 'moment';
import * as React from 'react';
import { JourneyFragmentFragment } from '../../../graphql';
import CharterPrompt from './CharterPrompt';
import SearchResult from './SearchResult';
import SearchResultContainer from './SearchResultContainer';
import TripDetailModal from './TripDetailModal';
import {
    buildTripDetail,
    buildVariables,
    isCharter,
    isFestival,
} from './helpers';
import useQueryParams, {
    IParsedQueryParams,
    serializeParams,
} from './useQueryParams';

const journeyPriceClass = (j: JourneyFragmentFragment): PriceClassEnum =>
    j.campaignPrice ? PriceClassEnum.Campaign : PriceClassEnum.Regular;

const SearchResultsContainer = () => {
    const options = useBusStopsOptions();

    const { isKiosk } = useKioskContext();
    const baseSearchUrl = isKiosk ? '/kiosk/search' : '/search';
    const {
        addBasketJourney,
        addCampaignCode,
        basketId,
        createBasket,
        setBasketId,
    } = React.useContext(ApplicationDataContext) as IApplicationDataContext;

    const history = useHistory();
    const { loading, beginLoading, endLoading } = useLoading();
    // Selected trip stores trip displayed in sidebar
    const [selectedTrip, setSelectedTrip] = React.useState<
        JourneyFragmentFragment | undefined
    >(undefined);
    const clearSelectedTrip = React.useCallback(
        () => setSelectedTrip(undefined),
        [setSelectedTrip]
    );
    const [activeTab, setActiveTab] = React.useState(TabType.OUTBOUND);
    const [outboundJourney, setOutboundJourney] = React.useState<
        JourneyFragmentFragment | undefined
    >();
    const { parsedQueryParams } = useQueryParams();
    const { reload: reloadBasket } = useBasketContent();

    // Make sure Inbound tab is not active if we don't have outbound journey selected
    const currentTab =
        activeTab === TabType.INBOUND && outboundJourney
            ? TabType.INBOUND
            : TabType.OUTBOUND;
    const variables = React.useMemo(
        () => buildVariables(parsedQueryParams, currentTab),
        [parsedQueryParams, currentTab]
    );

    const isRoundTrip = variables.query.isPartOfRoundTrip;
    const [submitError, setSubmitError] = React.useState<Error>();

    // If query parameters change, clear any selected
    // trip and navigate to outbound tab if needed
    React.useEffect(() => {
        setOutboundJourney(undefined);
        if (currentTab === TabType.INBOUND) {
            setActiveTab(TabType.OUTBOUND);
        }
    }, [parsedQueryParams]);

    const goToSummary = () => {
        history.push('/purchase/summary');
        return true;
    };

    const usePromoCode = (bid: string) => {
        if (!addCampaignCode || !parsedQueryParams.promoCode) return true;
        return addCampaignCode(bid, parsedQueryParams.promoCode).catch(
            () => true
        );
    };

    const handleJourneySelect = (journey: JourneyFragmentFragment) => {
        if (currentTab === TabType.OUTBOUND) {
            // SLWEB-308 | When setting outbound journey check if journey is
            // charter. In that case, search parameters need to be changed to
            // round trip and INBOUND journey needs to be selected

            if (
                isCharter(journey) &&
                (!parsedQueryParams.back ||
                    parsedQueryParams.back !== parsedQueryParams.there)
            ) {
                return appPrompt(params => <CharterPrompt {...params} />).then(
                    res => {
                        if (res) {
                            const newParams: IParsedQueryParams = {
                                ...parsedQueryParams,
                            };
                            newParams.back = newParams.there;
                            history.push(
                                `${baseSearchUrl}?${serializeParams(newParams)}`
                            );
                            return true;
                        } else {
                            return false;
                        }
                    }
                );
            }

            if (isFestival(journey)) {
                const availableDates = getAvailableDates(options, [
                    parsedQueryParams.from,
                    parsedQueryParams.to,
                ]);

                const backDate = parsedQueryParams.back;
                const thereDate = parsedQueryParams.there;

                // For festival journeys:
                // - back date needs to be set
                // - if there are any available dates, `back` date needs to be one of them
                // - if there are no available dates, `back` date needs to be same as `there` date
                const isValidBackDate =
                    backDate != null && // back date is set
                    (availableDates != null
                        ? availableDates.includes(backDate) // if available dates are set, backDate is one of them
                        : backDate === thereDate); // if available dates are not set, backDate is same as thereDate

                if (!isValidBackDate) {
                    return appPrompt(params => (
                        <CharterPrompt {...params} />
                    )).then(res => {
                        if (!res) return false;
                        const newParams: IParsedQueryParams = {
                            ...parsedQueryParams,
                        };

                        if (availableDates != null) {
                            const availableWithoutThere = availableDates.filter(
                                d => d !== thereDate
                            );
                            if (availableWithoutThere.length > 0) {
                                newParams.back = availableWithoutThere[0];
                            }
                        }

                        if (newParams.back == null) {
                            newParams.back = newParams.there;
                        }

                        history.push(
                            `${baseSearchUrl}?${serializeParams(newParams)}`
                        );
                        return true;
                    });
                }
            }
        }

        // If this is roundtrip and current tab is outbound,
        // store journey as outbound and show inbound tab
        if (isRoundTrip && currentTab === TabType.OUTBOUND) {
            setOutboundJourney(journey);
            setActiveTab(TabType.INBOUND);
            return Promise.resolve(true);
        } else {
            // These two cases should not happen, but just in case
            if (!journey) return Promise.resolve(false);
            if (isRoundTrip && !outboundJourney) return Promise.resolve(false);

            beginLoading();

            const basketIdPromise = basketId
                ? Promise.resolve(basketId)
                : createBasket!(parsedQueryParams.affiliateId).then(res => {
                      if (res.data) {
                          // store affiliateId so it can be used later in tracking
                          window.__trackingAffiliateId =
                              parsedQueryParams.affiliateId;
                          return res.data.createBasket.id;
                      } else {
                          throw new Error('Could not create basket');
                      }
                  });

            return basketIdPromise
                .then(bid => {
                    if (bid) {
                        const jInput: JourneysInput = {};

                        if (isRoundTrip && outboundJourney) {
                            jInput.outboundJourney = {
                                journeyId: outboundJourney.id,
                                priceClass: journeyPriceClass(outboundJourney),
                            };
                            jInput.inboundJourney = {
                                journeyId: journey.id,
                                priceClass: journeyPriceClass(journey),
                            };
                        } else if (!isRoundTrip) {
                            jInput.outboundJourney = {
                                journeyId: journey.id,
                                priceClass: journeyPriceClass(journey),
                            };
                        }
                        return (
                            addBasketJourney!(bid, jInput) // Add journey to basket
                                // Apply promo code to basket
                                .then(() => usePromoCode(bid))
                                // If reload is available (basket has previously existed)
                                .then(() =>
                                    reloadBasket ? reloadBasket() : true
                                )
                                .then(() => setBasketId(bid)) // Store basket in local storage
                                .then(() => {
                                    gtmLogAddToCart([outboundJourney, journey]);
                                    fbpLogAddToCart([outboundJourney, journey]);
                                })
                                .then(endLoading) // Clear loading indicator
                                .then(goToSummary) // Navigate to summary screen
                        );
                    } else {
                        endLoading();
                        return false;
                    }
                })
                .catch(error => {
                    endLoading();
                    setSubmitError(error);
                    return false;
                });
        }
    };

    const handleTabSelect = (tab: TabType) => {
        // Do not allow switching to inbound tab if outbound journey is not selected
        if (tab === TabType.INBOUND && !outboundJourney) return;
        setActiveTab(tab);
    };

    const handleDateChange = (date: string) => {
        const newParams = { ...parsedQueryParams };
        if (currentTab === TabType.INBOUND) {
            newParams.back = date;
            if (moment(newParams.there) > moment(newParams.back)) {
                newParams.there = newParams.back;
            }
        } else {
            newParams.there = date;
            if (
                newParams.back &&
                moment(newParams.there) > moment(newParams.back)
            ) {
                newParams.back = newParams.there;
            }
        }
        history.push(`${baseSearchUrl}?${serializeParams(newParams)}`);
    };

    const isTravelPass = !!variables.query.travelPassNumber;

    return (
        <>
            {loading && <LoadingIndicator fullscreen />}
            <SearchResultContainer
                variables={variables}
                outboundJourney={outboundJourney}
            >
                {({ journeys, error, loading: loadingResults }) => (
                    <>
                        {(journeys.length > 0 ||
                            currentTab === TabType.INBOUND) && (
                            <Tabs
                                activeTab={currentTab}
                                className="mt-2 mb-2"
                                dateOutbound={parsedQueryParams.there}
                                dateInbound={parsedQueryParams.back}
                                onTabSelect={handleTabSelect}
                            />
                        )}
                        <SearchResult
                            journeys={journeys}
                            date={
                                currentTab === TabType.OUTBOUND
                                    ? parsedQueryParams.there
                                    : parsedQueryParams.back!
                            }
                            loading={loadingResults}
                            error={error}
                            onDateChange={handleDateChange}
                            onJourneySelect={handleJourneySelect}
                            onJourneyDetail={setSelectedTrip}
                            selectedJourney={
                                currentTab === TabType.OUTBOUND
                                    ? outboundJourney
                                    : undefined
                            }
                            travelPass={isTravelPass}
                        />
                    </>
                )}
            </SearchResultContainer>
            <TripDetailModal
                details={buildTripDetail(selectedTrip)}
                trip={selectedTrip}
                onClose={clearSelectedTrip}
                onSelect={() =>
                    handleJourneySelect(selectedTrip!).then(
                        res => res && clearSelectedTrip()
                    )
                }
                travelPass={isTravelPass}
            />

            {submitError && (
                <ErrorMessage
                    fixed
                    error={submitError}
                    onClose={() => setSubmitError(undefined)}
                />
            )}
        </>
    );
};

export default SearchResultsContainer;
